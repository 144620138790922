import React, { useEffect, useState } from 'react';
import './AppTab.css';
import { useIntl } from 'react-intl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { appServices } from '../../../services/RestServices/appServices';
import { deviceServices } from '../../../services/RestServices/deviceServices';
import Spinner from '../../Spinner/Spinner';
import { showSuccessToast } from '../../../services/APIResponseHandler';
import UninstallIcon from '../../../assets/images/uninstallIcon.svg'
import CustomDialog from '../../CustomDialog/CustomDialog';
import { InputSwitch } from 'primereact/inputswitch';
import HoveredText from '../../HoveredText/HoveredText';

const AppTab = ({details, appData, setAppData, loading, setLoading, emptyMessageId}) => {

  const intl = useIntl();
  const { formatMessage: f } = intl;
  const APP_NAME = f({ id: 'COM_DMS_APP_NAME' });
  const APP_ACTION = f({ id: 'COM_DMS_APP_ACTION' });
  const APP_UNINSTALL = f({ id: 'COM_DMS_UNINSTALL_SMALL_CASE'})
  const [dialogState, setDialogState] = useState({
    header: "",
    body: "",
    footer: "",
    action: "",
    cancel: ""
  });
  const [dialogVisible, setDialogVisible] = useState(false);
  const [packageName, setPackageName] = useState("");
  const [switchUserAppValues, setSwitchUserAppValues] = useState([]);
  const [switchNativeAppValues, setSwitchNativeAppValues] = useState([]);
  
  const handleUninstall = (rowData) => {
    if (details.deviceInfo?.connectionStatus) {
      setDialogState({
        ...dialogState,
        header: f({ id: 'COM_DMS_UNINSTALL_APP' }),
        body: f({ id: 'COM_DMS_DO_YOU_REALLY_WANT_TO_UNINSTALL' }),
        footer:f({ id: 'COM_DMS_APP_WILL_BE_UNINSTALLED_FOR_SELECTED_DEVICE' }),
        action: "Yes",
        cancel: "No"
      });
      setDialogVisible(true);
      setPackageName(rowData.appPackageName);
    }
  }

  const handleDisable = (rowData) => {
    setDialogState({
      ...dialogState,
      header: f({ id: 'COM_DMS_DISABLE_APP' }),
      body: f({ id: 'COM_DMS_DO_YOU_REALLY_WANT_TO_DISABLE' }),
      footer:f({ id: 'COM_DMS_APP_WILL_BE_DISABLED_FOR_SELECTED_DEVICE' }),
      action: "Yes",
      cancel: "No"
    });
    setDialogVisible(true);
    setPackageName(rowData.appPackageName);
  }

  const completeAppAction = () => {
    setLoading(false);
    setPackageName("");
  }

  const manageActionOnApp = async(payload) => {
    setLoading(true);
    await Promise.all([appServices.manageAppAction(payload, packageName).then((resp) =>
      resp?.status === 200 ?
        setTimeout(() => {
          deviceServices.getAppListByDeviceId(details.deviceId).
            then((res) => {
              setAppData(res?.data);
              res?.status === 200 && showSuccessToast(`Command Sent Successfully!`)
            }
            )
          completeAppAction();
        }, 5000) : completeAppAction()
    )]);
  }

  const manageEnableOnApp = async(payload, packageName) => {
    setLoading(true);
    await Promise.all([appServices.manageAppAction(payload, packageName).then((resp) =>
      resp?.status === 200 ?
        setTimeout(() => {
          deviceServices.getAppListByDeviceId(details.deviceId).
            then((res) => {
              setAppData(res?.data);
              res?.status === 200 && showSuccessToast(`Command Sent Successfully!`)
            }
            )
          completeAppAction();
        }, 5000) : completeAppAction()
    )]);
  }

  useEffect(() => {
    if (appData?.length !== 0) {
      const initialUserAppSwitchValues = appData.thirdPartyDeviceApps?.map((row) => row.appState === "ENABLED");
      setSwitchUserAppValues(initialUserAppSwitchValues);
      const initialNativeAppSwitchValues = appData.nativeDeviceApps?.map((row) => row.appState === "ENABLED");
      setSwitchNativeAppValues(initialNativeAppSwitchValues);
    }
  }, [appData]);

  const onSwitchChangeUserApp = (rowData) => {
    if (rowData.appState === "ENABLED") {
      handleDisable(rowData);
    } else if (rowData.appState === "DISABLED") {
      const appAction = f({ id: "COM_DMS_ENABLE" });
      const payload = {
        targetDevices: {
          deviceIds: [details.deviceId],
          groupIds: []
        },
        appAction
      }
      manageEnableOnApp(payload, rowData.appPackageName);
    }
  };

  const onSwitchChangeNativeApp = (rowData) => {
    if (rowData.appState === "ENABLED") {
      handleDisable(rowData);
    } else if (rowData.appState === "DISABLED") {
      const appAction = f({ id: "COM_DMS_ENABLE" });
      const payload = {
        targetDevices: {
          deviceIds: [details.deviceId],
          groupIds: []
        },
        appAction
      }
      manageEnableOnApp(payload, rowData.appPackageName);
    }
  };

  const handleAppActionOnDevice = () => {
     if(dialogState?.header === f({ id: 'COM_DMS_DISABLE_APP' })){
      performDisableActionOnDevice('DISABLE');
    }
    else if (dialogState?.header === f({ id: 'COM_DMS_UNINSTALL_APP' })) {
      performUninstallActionOnDevice('UNINSTALL');
    }
    setDialogVisible(false);
  }

  const performDisableActionOnDevice = async (appAction) => {
    const payload = {
      targetDevices: {
        deviceIds: [details.deviceId],
        groupIds: []
      },
      appAction
    }
    manageActionOnApp(payload);
  }

  const performUninstallActionOnDevice = async (appAction) => {
    const payload = {
      targetDevices: {
        deviceIds: [details.deviceId],
        groupIds: []
      },
      appAction
    }
    manageActionOnApp(payload);
  }

  const appNameTemplate = (rowData) => {
    return (
      <>
      <div style={{display: "flex"}} id="appNameID">
        <img src={rowData.imgSrc} className="appIconStyle"/> 
        <HoveredText text={rowData.appName} className="appNameStyles"/>
      </div>
      </>
    )
  }

  const userAppManageTemplate = (rowData, rowIndex) => {
    return(
      <>
       <InputSwitch
          checked={switchUserAppValues[rowIndex.rowIndex]}
          onChange={(e) => {
            onSwitchChangeUserApp(rowData);
          }}
          className="detailsAppSwitch"
        disabled={!details.deviceInfo?.connectionStatus}
        />
      </>
    )
  }

  const nativeAppManageTemplate = (rowData, rowIndex) => {
    return(
      <>
        <InputSwitch
          checked={switchNativeAppValues[rowIndex.rowIndex]}
          onChange={(e) => {
            onSwitchChangeNativeApp(rowData);
          }}
          className="detailsAppSwitch"
          disabled={!details.deviceInfo?.connectionStatus}
        />
        </>
    )
  }

  const uninstallUserAppTemplate = (rowData) => {
    return (
      <>
        <img
        src={UninstallIcon}
        alt="uninstallButton"
        onClick={() => handleUninstall(rowData)}
        style={{ marginLeft: '1rem', cursor: 'pointer' }}
        className={`${details.deviceInfo?.connectionStatus ? "" : "disableUninstall"}`}
      />
      </>
    )
  }

  const columnAppUserApps = [
    { field: 'appName', header: APP_NAME,  style: {width: "40%"}, body: appNameTemplate },
    { field: 'appManage', header: APP_ACTION, style: { width: "30%" }, body: userAppManageTemplate },
    { field: 'appUninstall', header: APP_UNINSTALL, style: { width: "10%"}, body: uninstallUserAppTemplate}
  ];

  const columnAppNativeApps = [
    { field: 'appName', header: APP_NAME,  style: {width: "40%"}, body: appNameTemplate },
    { field: 'appManage', header: APP_ACTION, style: {width: "30%"}, body: nativeAppManageTemplate}
  ];

  return (
    <>
    {loading ? <Spinner /> : ''}
      <div className="appTabLayout">
        <div className="appTabSection">
          <div className="installedSection">
            <div className="appSectionsHeader">
              {f({ id: "COM_DMS_THIRD_PARTY_APPS" })}
            </div>
            <DataTable value={appData?.thirdPartyDeviceApps} scrollable scrollHeight="55vh" className="detailsAppTable" emptyMessage={f({ id: emptyMessageId })}>
              {columnAppUserApps.map((col) => (
              <Column {...col} />
        ))}
            </DataTable>
          </div>
          <div className="nativeSection">
          <div className="appSectionsHeader">
            {f({ id: "COM_DMS_NATIVE_APPS" })}
          </div>
          <DataTable value={appData?.nativeDeviceApps} scrollable scrollHeight="55vh" className="detailsAppTable" emptyMessage={f({ id: emptyMessageId })}>
              {columnAppNativeApps.map((col) => (
              <Column {...col} />
        ))}
            </DataTable>
          </div>
        </div>
      </div>
      {dialogVisible && <CustomDialog
       dialogState={dialogState}
       handleClick={handleAppActionOnDevice}
       dialogVisible={dialogVisible}
       setDialogVisible={setDialogVisible}/>}
    </>
  )
}

export default AppTab;
