
import { USER_MGMT_API_URL } from "../config/constants/Constants";
import { RestServiceUtils } from "../utils/RestServiceUtils";

const getUserService = (payload) => {
  return RestServiceUtils.HTTP_GET(`${USER_MGMT_API_URL}`);
};

const updateUserService = (payload) => {
  const { userId, ...rest } = payload;
  return RestServiceUtils.HTTP_PUT(`${USER_MGMT_API_URL}`, { ...rest });
};

export { getUserService, updateUserService };
