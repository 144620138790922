
import React, {useState, useEffect} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { Calendar } from 'primereact/calendar';
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import SearchIcon from '../../../assets/images/search.svg';
import export_icon from '../../../assets/images/exportIcon.svg';
import Moment from "moment";
import "./EventLogs.css";
import { useIntl } from "react-intl";
import {getEventLogsService, getEventLogExcelService} from "../../../services/EventLogService"; 
import { Button } from 'primereact/button';
import Spinner from '../../../components/Spinner/Spinner';
const EventLogs = ({ refreshPage }) => {
    const { formatMessage: f } = useIntl();

    const [selectedFilterType, setselectedFilterType] = useState("All");
    const [selectedPeriod, setSelectedPeriod] = useState( { name: f({ id: 'COM_DMS_ALL'}), code: "threeMonthsAgo" });
    const [searchText, setSearchText] = useState("");
    const [startDate, setStartDate]   = useState(Moment(new Date()).subtract(3,'months').toDate());
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(false);

    const filterBy = [
                        f({ id: 'COM_DMS_ALL'}),
                        f({ id: 'COM_DMS_USER'}),
                        f({ id: 'COM_DMS_USER_LOGIN'}),
                        f({ id: 'COM_DMS_DEVICE'}),
                        f({ id: 'COM_DMS_APP'}),
                        f({ id: 'COM_DMS_FIRMWARE'})
                    ];
    const period = [
                        { name: f({ id: 'COM_DMS_A_WEEK_AGO'}), code: "aWeekAgo" },
                        { name: f({ id: 'COM_DMS_A_MONTH_AGO'}), code: "aMonthAgo" },
                        { name: f({ id: 'COM_DMS_ALL'}), code: "threeMonthsAgo" },
                        { name: f({ id: 'COM_DMS_CUSTOM'}), code: "custom" }
                    ];

    const [eventLogRequestBody, setEventLogRequestBody] = useState({
        filterType: "",
        searchText: "",
        startDate: Moment(new Date()).subtract(3,'months').format('YYYY-MM-DD'),
        endDate: Moment(new Date()).format('YYYY-MM-DD'),
        startIndex: "0",
        pageSize: "10"
      });
    
    
    const [eventLogs, setEventLogs] = useState({});
    const [totalCount, setTotalCount] = useState({});

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        setEventLogRequestBody({...eventLogRequestBody,startIndex: event.first , pageSize: event.rows});
    };

    const getEventLogsHandler = async () => {
        setLoading(true);
        await getEventLogsService(eventLogRequestBody)
            .then(({ data }) => {
                const { eventLogs, totalCount: dataCount } = data ?? {};
                eventLogs.forEach((log) => {
                    log.eventOccuranceTime = Moment(log.eventOccuranceTime).format('YYYY-MM-DD \xa0 HH:mm');
                });
                setEventLogs(eventLogs);
                setTotalCount(dataCount);
                setLoading(false);
            });
    }

    useEffect(() => {
        getEventLogsHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventLogRequestBody, refreshPage]);
      
    const handleFilterBy = (event) => {
        const filterVal = event.target.value;
        setselectedFilterType(filterVal);
        setEventLogRequestBody({...eventLogRequestBody,filterType: filterVal});
    }

    const handlePeriodChange = (event) => {
        const periodVal = event.target.value;
        setSelectedPeriod(periodVal);
        handleRequestDates(periodVal);
    }

    
    const handleRequestDates = (periodVal) => {
        let startDateLocal = new Date();
        let endDateLocal = new Date();
        switch(periodVal.code){
            case "aWeekAgo":
            startDateLocal.setDate(endDateLocal.getDate() - 7);
                break;
            case "aMonthAgo":
            startDateLocal.setMonth(endDateLocal.getMonth() - 1);
                break;
            case "threeMonthsAgo":
            startDateLocal.setMonth(endDateLocal.getMonth() - 3);
                break;
            case "custom":
            startDateLocal.setDate(endDateLocal.getDate() - 7);
            endDateLocal.setDate(endDateLocal.getDate());
                break;
            default:
                break;
        }
        setStartDate(startDateLocal);
        setEndDate(endDateLocal);
        setEventLogRequestBody({...eventLogRequestBody,startDate: Moment(startDateLocal).format('YYYY-MM-DD'),
        endDate: Moment(endDateLocal).format('YYYY-MM-DD')});
    }

    const handleStartDateChange = (event) => {
        let startDateLocal = event.target.value;
        setStartDate(startDateLocal);
        setEventLogRequestBody({...eventLogRequestBody,startDate: Moment(startDateLocal).format('YYYY-MM-DD')});
    }

    const handleEndDateChange = (event) => {
        let endDateLocal = event.target.value;
        setEndDate(endDateLocal);
        setEventLogRequestBody({...eventLogRequestBody,endDate: Moment(endDateLocal).format('YYYY-MM-DD')});
    }

    const handleSearchText = () => {
        setEventLogRequestBody({...eventLogRequestBody,searchText: searchText});
    }
    
    const handleInputKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearchText();
          }
    }
    
    const handleEventExport = () => {
        let excelExportRequestBody = eventLogRequestBody;
        excelExportRequestBody.pageSize = -1;
        excelExportRequestBody.startIndex = -1;
        getEventLogExcelService(excelExportRequestBody)
        .then((res) => {
            let fileName = res.headers.get('content-disposition');
            if (fileName) {
                fileName = fileName.replace(/"/gi, '');
                if (fileName && fileName.length > 0) {
                    let fileNames = fileName.split(';');
                    if (fileNames.length > 0) {
                        fileNames = fileNames[1].split('=');
                        if (fileNames.length > 0) {
                            fileName = fileNames[1];
                        }
                    }
                }
            }
            const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName); 
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch((e) => console.log(e));
    }
 
    return (
        <>
        {loading ? <Spinner /> : ""}
        <div className='action-logs-container'>
            <div className = 'action-logs-topNav1'>
                <div className ='topNav-left'>
                 <div>
                    <span className="filterHeaders">Filter By</span>
                    <Dropdown value={selectedFilterType} onChange={handleFilterBy} options={filterBy} 
                  placeholder={selectedFilterType ? selectedFilterType : "Select"} className="action-logs-inputs filter-dropdown"/>
                    <span className="filterHeaders period">Period</span>
                    <Dropdown value={selectedPeriod} onChange={handlePeriodChange} options={period} optionLabel="name"
                  placeholder={selectedPeriod ? selectedPeriod.name :f({ id: 'COM_DMS_SELECT_THE_PERIOD'})} className="action-logs-inputs period-dropdown"/>
                 </div>
                 <div className="calender-block">
                    <Calendar value={startDate}  showIcon onChange={handleStartDateChange} minDate={Moment(new Date()).subtract(3,'months').toDate()}
                                maxDate={new Date()} disabled={selectedPeriod.code === "custom" ? false : true} className="calenderItems" dateFormat="yy-mm-dd"
                                readOnlyInput/>
                    <span>~</span>
                    <Calendar value={endDate} showIcon onChange={handleEndDateChange} maxDate={new Date()} minDate={startDate}
                                disabled={selectedPeriod.code === "custom" ? false : true} className="calenderItems" dateFormat="yy-mm-dd"
                                readOnlyInput />
                 </div>
                </div>
                <div className ='topNav-right'>
                    <span className="searchInputBlock">
                        <InputText placeholder={f({ id: 'COM_DMS_USER_ID'})+" / "+f({ id: 'COM_DMS_EVENT_DESCRIPTION'})} value={searchText} onKeyDown={handleInputKeyDown}
                         onChange={(event) => setSearchText(event.target.value)} className="action-logs-inputs search-logs"/>
                        <img src={SearchIcon} alt="setting" onClick={handleSearchText} className="searchIcon"/>
                    </span>
                </div>
            </div>

            <div className = 'action-logs-exportNav'>
            <Button className="export_btn action-logs-inputs" label={f({ id: 'COM_DMS_EXPORT'})} onClick={handleEventExport}><img src={export_icon} alt="" className="exportIcon"/></Button>
            
            </div>

            <div className="card table-container">
                <DataTable
                    value={eventLogs}
                    scrollHeight={"66vh"}
                    scrollable={true}
                    className ="datatable-eventLogs" >
                    <Column field="eventType" header={f({ id: 'COM_DMS_EVENT_TYPE'})}></Column>
                    <Column field="eventDescription" header={f({ id: 'COM_DMS_EVENT_DESCRIPTION'})}></Column>
                    <Column field="eventOccuranceTime" header={f({ id: 'COM_DMS_EVENT_OCCURANCE_TIME'})}></Column>
                    <Column field="ipAddress" header={f({ id: 'COM_DMS_IP'})}></Column>
                    <Column field="loginId" header={f({ id: 'COM_DMS_USER_ID'})}></Column>
                </DataTable>
                <div className="custom-paginator">
                    <Paginator
                        first={first}
                        rows={rows} 
                        totalRecords={totalCount} 
                        onPageChange={onPageChange}
                        rowsPerPageOptions={[10, 20, 30, 50]}/>
                </div>
            </div>
        </div>
            </>
    )
}

export default EventLogs;