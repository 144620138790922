import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import './AddUserPopup.css';
import { Checkbox } from 'primereact/checkbox';
import { useIntl } from 'react-intl';
import { userServices } from '../../services/RestServices/userServices';
import { validateUserDetails, validatePasswordFields, userIdMaxLength, userNameMaxLength, contactNumberMaxLength, passwordMaxLength, emailMaxLength} from '../../utils/Validations';
import CopyIcon from '../../assets/images/copyIcon.svg';
import { useSelector } from 'react-redux';
import VisibilityIcon from '../../assets/images/visibility.svg';
import VisibilityHideIcon from '../../assets/images/visibilityHideIcon.svg';
import EyeCloseIcon from '../../assets/images/eyeClose.svg';
import PasswordValidatorTooltip from '../../components/Tooltip/PasswordValidatorTooltip/PasswordValidatorTooltip';
import { showErrorToast, showSuccessToast } from '../../services/APIResponseHandler';
import { copyNavigatorToClipBoardPassword } from '../../utils/CopyClipboardUtil';
import { Tooltip } from 'primereact/tooltip';

export const AddUserPopup = (props) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const { visible, setVisible, setSearchText } = props;
  const loginUserRoleName = localStorage.getItem('loginUserRoleName');
  const { serverSettingsInfo } = useSelector((state) => state.serverSettings);
  const { passwordSettings, smtpSettings } = serverSettingsInfo ?? {};
  const { defaultPassword } = passwordSettings ?? {};
  const [checked, setChecked] = useState(true);
  const [passwordCopied, setPasswordCopied] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [focussedPassword, setFocussedPassword] = useState(false);
  const [isSendMessageEnable, setIsSendMessageEnable] = useState(false);
  const { isSmtpEnabled } = smtpSettings ?? {};

  useEffect(() => {
    if (passwordCopied) {
      setTimeout(() => {
        setPasswordCopied(false);
      }, 5000);
    }
  }, [passwordCopied]);

  const checkPasswordType = (type, setType) => {
    if (type === 'text') {
      setType('password');
    } else {
      setType('text');
    }
  };

  const roles =
    loginUserRoleName === f({ id: 'COM_DMS_USER_ADMIN' })
      ? [
          { name: f({ id: 'COM_DMS_USER_SUB_ADMIN' }) },
          { name: f({ id: 'COM_DMS_USER_DEVICE_MANAGER' }) },
          { name: f({ id: 'COM_DMS_USER_DEVICE_OPERATOR' }) },
        ]
      : loginUserRoleName === f({ id: 'COM_DMS_USER_SUB_ADMIN' })
      ? [
          { name: f({ id: 'COM_DMS_USER_DEVICE_MANAGER' }) },
          { name: f({ id: 'COM_DMS_USER_DEVICE_OPERATOR' }) },
        ]
      : '';

  const initialUserInfo = {
    loginId: '',
    userName: '',
    email: '',
    contactNumber: '',
    roleName: '',
    password: '',
    confirmPassword: '',
  };

  const [userDetails, setUserDetails] = useState(initialUserInfo);
  const [userDetailsError, setUserDetailsError] = useState('');

  const userDataInputFieldHandler = (event) => {
    const { name, value } = event.target;
    let res;

    if(name === 'password') {
      res = validateUserDetails(name, value);
      const {isValid, messageId} = res;
      if(!isValid) {
        setUserDetailsError((value === '') ? '' : f({ id: messageId }));
      } else {
        setUserDetailsError('');
      }
      setUserDetails({ ...userDetails, [name]: value?.trim() });
    } else if(name === 'confirmPassword') {
      const {password} = userDetails;
      if(/\s/.test(password)) {
        setUserDetailsError(f({ id: 'COM_DMS_INVALID_PASSWORD_CONTAIN_SPACE'}));
      } else if(password !== value){
        setUserDetailsError(f({ id: 'COM_DMS_PASSWORD_CONFIRM_PASSWORD_NOT_MATCHING'}));
      } else {
        setUserDetailsError('');
      }
      setUserDetails({ ...userDetails, [name]: value?.trim() });
    } else {
      const res = validateUserDetails(name, value);
      const { isValid, messageId} = res;
      if(!isValid) {
        setUserDetailsError((value === '') ? '' : f({ id: messageId }));
      } else {
        setUserDetailsError('');
      }
      setUserDetails({ ...userDetails, [name]: value?.trim() });
    }
  };

  const validatePassword = validatePasswordFields(userDetails.password);

  const addNewUserHandler = async (userDetails) => {
    let isValid = true;
    let messageId = '';
    let value;
    let res;
    setUserDetailsError('');

    if(isValid) {
      value = userDetails.loginId;
      res = validateUserDetails('loginId', value);
      isValid = res.isValid;
    }

    if(isValid) {
      value = userDetails.userName;
      res = validateUserDetails('userName', value);
      isValid = res.isValid;
    }
      
    if(isValid && userDetails.email !== '') {
      value = userDetails.email;
      res = validateUserDetails('email', value);
      isValid = res.isValid;
    }

    if(isValid && userDetails.contactNumber !== '') {
      value = userDetails.contactNumber;
      res = validateUserDetails('contactNumber', value);
      isValid = res.isValid;
    }

    if(isValid) {
      value = userDetails.password;
      res = validateUserDetails('password', value);
      isValid = res.isValid;
      if(isValid){
        if(userDetails.password !== userDetails.confirmPassword) {
          isValid = false;
          setUserDetailsError(f({ id: 'COM_DMS_PASSWORD_CONFIRM_PASSWORD_NOT_MATCHING' }));
        }
      } else {
        messageId = res.messageId;
        setUserDetailsError(f({ id: messageId }));
      }
    } else {
      messageId = res.messageId;
      setUserDetailsError(f({ id: messageId }));
    }

    if(isValid) {
      setUserDetailsError('');
      let addUserResponseStatus = false;
      await userServices.addUserInfo({
        ...userDetails
      }).then(({data, status}) => {
        const { loginId, email, userName } = data ?? {};
        if (status === 200) {
          if (isSendMessageEnable) {
            userServices.postNewUserSendMail({
              loginId, email, userName, password: userDetails.password
            })
          }
          addUserResponseStatus = true;
          showSuccessToast(f({ id: 'COM_DMS_USER_ADDED_SUCCESS_MESSAGE' }));
          setSearchText('');
        }
      });
      if (addUserResponseStatus) {
        setVisible(false);
      }
    }
  };

  const onHide = () => {
    setVisible(false);
    setUserDetailsError('');
  };

  const enableAddButton = () => {
    let flag = false;
    if (userDetails !== undefined) {
      if (
        userDetails.loginId === '' ||
        userDetails.userName === '' ||
        userDetails.roleName === '' ||
        userDetails.password === '' ||
        userDetails.confirmPassword === '' ||
        userDetailsError !== ''
      ) {
        flag = true;
      }
    }
    return flag;
  };

  const headerContent = () => {
    return (
      <div className="add-user-dialog-header">
        {f({ id: 'COM_DMS_ADD_USER' })}
        <Button
          className="add-user add-user-save"
          label={f({ id: 'COM_DMS_ADD' })}
          onClick={() => addNewUserHandler(userDetails)}
          disabled={enableAddButton()}
        />
        <Button
          className="add-user"
          label={f({ id: 'COM_DMS_DISCARD' })}
          onClick={onHide}
        />
      </div>
    );
  };

  useEffect(() => {
    if (checked) {
      setUserDetails({
        ...userDetails,
        password: defaultPassword,
        confirmPassword: defaultPassword,
      });
    } else {
      setUserDetails({
        ...userDetails,
        password: '',
        confirmPassword: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    document
      .querySelector('.userDetails-form .details_col #addPassword')
      ?.addEventListener('focus', () => {
        setFocussedPassword(true);
      });
    document
      .querySelector('.userDetails-form .details_col #addPassword')
      ?.addEventListener('focusout', () => {
        setFocussedPassword(false);
      });
  });

  const temporaryPasswordHandler = () => {
    !checked &&
      userServices.getTemporaryPassword().then((res) => {
        const { data } = res;
        setUserDetails({
          ...userDetails,
          password: data,
          confirmPassword: data,
        });
        setUserDetailsError('');
      });
  };

  useEffect(() => {
    passwordSettings?.isDefaultPasswordUsed ? setChecked(true) : setChecked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userDetails?.confirmPassword?.length === 0) {
      setConfirmPasswordType('password');
    }
  }, [userDetails?.confirmPassword]);

  useEffect(() => {
    if (userDetails?.password?.length === 0) {
      setPasswordType('password');
    }
  }, [userDetails?.password]);

  return (
    <div className="card flex justify-content-center">
      {passwordCopied && (
        <div className="toast-message-success">
          {f({ id: 'COM_DMS_PASSWORD_COPIED_TO_CLIPBOARD' })}
        </div>
      )}
      <Dialog
        header={headerContent}
        visible={visible}
        position={'top-right'}
        draggable={false}
        className="add-user-dialog"
        onHide={() => {
          setVisible(false);
          setUserDetailsError('');
        }}
      >
        <div>
          <span className="add-details">
            {f({ id: 'COM_DMS_INFORMATION' })}
          </span>
          <div className="userDetails-form">
            <div className="details_col">
              <label className="name_label_pos">
              <span style={{ color: 'red', fontWeight: 800 }}>*</span>{' '}
                <span>{f({ id: 'COM_DMS_USER_ID' })}</span>
              </label>
              <InputText
                name="loginId"
                className="user_details md:w-14rem"
                defaultValue={userDetails.loginId}
                type="text"
                autoComplete="no"
                maxLength={userIdMaxLength}
                onChange={userDataInputFieldHandler}
                placeholder={f({ id: 'COM_DMS_USER_ID_TXT' })}
              />
            </div>
            <div className="details_col">
              <label className="name_label_pos">
              <span style={{ color: 'red', fontWeight: 800 }}>*</span>{' '}
                <span>{f({ id: 'COM_DMS_USER_NAME' })}</span>
              </label>
              <InputText
                name="userName"
                defaultValue={userDetails.userName}
                className="user_details md:w-14rem"
                type="text"
                maxLength={userNameMaxLength}
                autoComplete="no"
                onChange={userDataInputFieldHandler}
                placeholder={f({ id: 'COM_DMS_USER_NAME_TXT' })}
              />
            </div>
            <div className="details_col">
              <label className="name_label_pos">
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{f({ id: 'COM_DMS_EMAIL_ID' })}</span>
              </label>
              <InputText
                name="email"
                defaultValue={userDetails.email}
                maxLength={emailMaxLength}
                className="user_details md:w-14rem"
                type="text"
                autoComplete="no"
                onChange={userDataInputFieldHandler}
                placeholder={f({ id: 'COM_DMS_EMAIL_TXT' })}
              />
            </div>
            <div className="details_col">
              <label className="name_label_pos">
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{f({ id: 'COM_DMS_CONTACT' })}</span>
              </label>
              <InputText
                name="contactNumber"
                defaultValue={userDetails.contactNumber}
                className="user_details md:w-14rem"
                type="text"
                maxLength={contactNumberMaxLength}
                autoComplete="no"
                onChange={userDataInputFieldHandler}
                placeholder={f({ id: 'COM_DMS_CONTACT_NO_TXT' })}
              />
            </div>
            <div className="details_col">
              <label className="name_label_pos">
                <span style={{ color: 'red', fontWeight: 800 }}>*</span>{' '}
                <span>{f({ id: 'COM_DMS_ROLE' })}</span>
              </label>
              <div className="card flex justify-content-center">
                <Dropdown
                  name="roleName"
                  value={userDetails.roleName}
                  onChange={(e) => {
                    setUserDetails({
                      ...userDetails,
                      roleName: e.value,
                    });
                  }}
                  options={roles}
                  optionLabel="name"
                  optionValue="name"
                  className="user_role_dropdown"
                  placeholder={f({ id: 'COM_DMS_SELECT_ROLE_TXT' })}
                />
              </div>
            </div>
            <div className="details_col details_pwd_col">
              <div className="user-default-pwd-field"style={{width: '100%'}}>
                <div className="user-default-password" >
                  &nbsp;&nbsp;&nbsp;&nbsp;Send Email
                </div>
                <div style={{ marginLeft: '2rem' }}>
                { (!userDetails.email || !isSmtpEnabled) && <Tooltip
                    target={`.send-mail-checkbox`}
                    position="right"
                  >
                    <div className="infoIcon_tooltipContent">{f({ id: 'COM_DMS_SMTP_DISABLED_MSG' })}</div>
                  </Tooltip>}
                  <Checkbox
                    className='send-mail-checkbox'
                    disabled={!userDetails.email || !isSmtpEnabled}
                    checked={isSendMessageEnable}
                    onChange={(e) => {
                      setIsSendMessageEnable(e.checked);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <span className="add-details">{f({ id: 'COM_DMS_PASSWORD' })}</span>
          <div className="userDetails-form">
            <div className="details_col details_pwd_col">
              {passwordSettings?.isDefaultPasswordUsed ? <div className="user-default-pwd-field">
                  <div className="user-default-password">
                    {f({ id: 'COM_DMS_DEFAULT_PWD' })}
                  </div>
                <div>
                    <Checkbox
                      checked={checked}
                      onChange={(e) => {setChecked(e.checked);
                        setUserDetailsError('');}}
                    /> 
                </div>
              </div> : <div style={{marginBottom: '1.5rem' }}/>}
              <div
                className={`copy-password ${
                  !userDetails.password ? 'disable-copy-to-clipboard' : ''
                }`}
                onClick={() => copyNavigatorToClipBoardPassword(
                  userDetails?.password,
                  setPasswordCopied,
                  showSuccessToast,
                  showErrorToast,
                  f
                )}
              >
                <img src={CopyIcon} alt="copy" className="copy-img" />
                {f({ id: 'COM_DMS_COPY_PASSWORD' })}
              </div>
            </div>
            <div className="details_col add-user-password">
              <label className="name_label_pos">
                <span style={{ color: 'red', fontWeight: 800 }}>*</span>{' '}
                <span>{f({ id: 'COM_DMS_PASSWORD' })}</span>
              </label>
              <input
                name="password"
                disabled={checked}
                type={passwordType}
                id="addPassword"
                maxLength={passwordMaxLength}
                className="password-validation"
                onChange={userDataInputFieldHandler}
                value={userDetails.password}
                placeholder={f({ id: 'COM_DMS_CREATE_PASSWORD_TXT' })}
              />
              <img
                src={`${
                  passwordType === 'password' ? userDetails?.password?.length > 0 ? EyeCloseIcon : VisibilityHideIcon : VisibilityIcon 
                }`}
                className={
                  passwordType === 'password' && userDetails?.password?.length <= 0 ? 'edit-pwd' : 'text-edit-pwd' 
                }
                onClick={() => {  if(userDetails?.password?.length > 0)checkPasswordType(passwordType, setPasswordType)}}
                alt="password"
              />
              {focussedPassword && (
                <PasswordValidatorTooltip
                  className="validator-tooltip"
                  {...validatePassword}
                />
              )}
            </div>
            {!checked && (
              <div className="details_col add-user-password">
                <label className="name_label_pos">
                  <span style={{ color: 'red', fontWeight: 800 }}>*</span>{' '}
                  <span>{f({ id: 'COM_DMS_CNF_PWD' })}</span>
                </label>
                <input
                  disabled={checked}
                  name="confirmPassword"
                  id="confirmAddPassword"
                  type={confirmPasswordType}
                  maxLength={passwordMaxLength}
                  className="password-validation"
                  onChange={userDataInputFieldHandler}
                  value={userDetails.confirmPassword}
                  placeholder={f({ id: 'COM_DMS_CNF_PWD' })}
                />
                <img
                  src={`${
                    confirmPasswordType === 'password'
                      ? userDetails?.confirmPassword?.length > 0 ? EyeCloseIcon
                      : VisibilityHideIcon
                      : VisibilityIcon
                  }`}
                  className={
                    confirmPasswordType === 'password' && userDetails?.confirmPassword?.length <= 0 ? 'edit-pwd' : 'text-edit-pwd' 
                  }
                  onClick={() => {
                    if(userDetails?.confirmPassword?.length !== 0)
                    checkPasswordType(
                      confirmPasswordType,
                      setConfirmPasswordType
                    )
                    }
                  }
                  alt="password"
                />
              </div>
            )}
          </div>
          {!checked &&
            <div
            className="temp-pwd-generator"
            onClick={temporaryPasswordHandler}
            disabled={checked}
            >
              <span>{f({ id: 'COM_DMS_GENERATE_PASSWORD' })}</span>
            </div>
          }
        </div>
        <span className="error-txt">{userDetailsError}</span>
      </Dialog>
    </div>
  );
};
