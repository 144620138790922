import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import SelectDeviceComponent from '../../../components/SelectDeviceComponent/SelectDeviceComponent';

const UpdatePopup = ({
  openUpdatePopup,
  manageAppHeader,
  activeTabIndex,
  currentFWVersion,
  isFirmwareVersion,
  isClientAppVersion,
  setActiveTabIndex,
  setOpenUpdatePopup,
  selectedGroupKeys,
  selectedCheckedKeys,
  setSelectedGroupKeys,
  setSelectedCheckedKeys,
  currentClientAppVersion,
}) => {
  const [jsonGroupTree, setJsonGroupTree] = useState([]);
  const [deviceParentIds, setDeviceParentIds] = useState([]);

  const handleSendUpdateFirmware = () => {
    setOpenUpdatePopup(false);
  };

  return (
    <Dialog
      header={manageAppHeader}
      visible={openUpdatePopup}
      position="right"
      draggable={false}
      className="manageAppsDialog"
      onHide={() => setOpenUpdatePopup(false)}
    >
      <SelectDeviceComponent
        appPackage={''}
        jsonGroupTree={jsonGroupTree}
        activeTabIndex={activeTabIndex}
        deviceParentIds={deviceParentIds}
        setJsonGroupTree={setJsonGroupTree}
        currentFWVersion={currentFWVersion}
        sendAction={handleSendUpdateFirmware}
        isFirmwareVersion={isFirmwareVersion}
        setActiveTabIndex={setActiveTabIndex}
        selectedGroupKeys={selectedGroupKeys}
        isClientAppVersion={isClientAppVersion}
        setDeviceParentIds={setDeviceParentIds}
        selectedCheckedKeys={selectedCheckedKeys}
        setSelectedGroupKeys={setSelectedGroupKeys}
        setSelectedCheckedKeys={setSelectedCheckedKeys}
        currentClientAppVersion={currentClientAppVersion}
        emptyTargetDeviceId={'COM_DMS_SELECT_TARGET_DEVICE'}
        handleSelectDeviceRightSideComponentHeader={null}
      />
    </Dialog>
  );
};

export default UpdatePopup;
