import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import './Users.css';
import { AddUserPopup } from '../../components/PopUp/AddUserPopup';
import { EditUserPopup } from '../../components/PopUp/EditUserPopup';
import { DeleteUserPopup } from '../../components/PopUp/DeleteUserPopup';
import { DevicePermissionPopup } from '../../components/PopUp/DevicePermissionPopup';
import { userServices } from '../../services/RestServices/userServices';
import { useIntl } from 'react-intl';
import { Tag } from 'primereact/tag';
import DevicePermissionIcon from '../../../src/assets/images/devicePermissionIcon.svg';
import { isAdminOrSubAdminByRoleName } from '../../utils/helper';
import {
  ADMIN,
  SUB_ADMIN,
  DEVICE_MANAGER,
  DEVICE_OPERATOR,
} from '../../../src/config/constants/CommonConstants';
import Moment from 'moment/moment';
import { Toast } from 'primereact/toast';
import { setToastComponent } from '../../services/APIResponseHandler';
import SearchBar from '../../components/SearchBar/SearchBar';
import Spinner from '../../components/Spinner/Spinner';
import AllUsersList from './AllUsersList';

const User = ({ refreshPage }) => {
  const { formatMessage: f } = useIntl();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalUsersCount, setTotalUsersCount] = useState({});
  const [userData, setUserData] = useState({});
  const initialUserPayload = {
    startIndex: 0,
    pageSize: 10,
    searchText: '',
    sortColumn: '',
  };
  const [userDataPayload, setUserDataPayload] = useState(initialUserPayload);
  const [devicePermissionUser, setDevicePermissionUser] = useState('');
  const [EditUserPageVisible, setEditUserPageVisible] = useState(false);
  const [deleteUserPageVisible, setDeleteUserPageVisible] = useState(false);
  const [DevicePermissionPageVisible, setDevicePermissionPageVisible] =
    useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [userDetailsToBeEdited, setUserDetailsToBeEdited] = useState([]);
  const [userToBeDeleted, setUserToBeDeleted] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [isAddUserClicked, setAddUserClicked] = useState(true);
  const [isEditUserClicked, setEditUserClicked] = useState(true);
  const [isDeleteUserClicked, setDeleteUserClicked] = useState(true);
  const [isDevicePermissionClicked, setDevicePermissionClicked] =
    useState(true);
  const [devicePermissionSuccess, setDevicePermissionSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showExtraGroupsUserId, setShowExtraGroupsUserId] = useState('');

  const { userInfo } = useSelector((state) => state.user);
  const { roleName: role } = userInfo ?? {};

  const toastRef = useRef(null);

  useEffect(() => {
    setToastComponent(toastRef.current);
  }, []);

  useEffect(() => {
    if (devicePermissionSuccess) {
      setTimeout(() => {
        setDevicePermissionSuccess(false);
      }, 3000);
      getUserData(userDataPayload);
    }
  }, [devicePermissionSuccess]);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setUserDataPayload({
      ...userDataPayload,
      startIndex: event.first,
      pageSize: event.rows,
    });
  };
  const [AddUserPageVisible, setAddUserPageVisible] = useState(false);

  const getUserData = async (payload) => {
    setLoading(true);
    setSelectedItems([]);
    await userServices.getAllUsersList(userDataPayload).then((res) => {
      const { userInfoList, totalCount } = res?.data ?? {};
      setUserData(userInfoList);
      setTotalUsersCount(totalCount);
    });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    userServices.getAllUsersList(initialUserPayload).then((res) => {
      const { userInfoList, totalCount } = res?.data ?? {};
      setUserData(userInfoList);
      setTotalUsersCount(totalCount);
      setUserDataPayload(initialUserPayload);
      setLoading(false);
      setSelectedItems([]);
      setSearchFilter('');
      setRows(10);
      setFirst(0);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  useEffect(() => {
    getUserData(userDataPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataPayload]);

  useEffect(() => {
    if (!AddUserPageVisible) {
      getUserData(initialUserPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AddUserPageVisible]);

  useEffect(() => {
    if (!EditUserPageVisible) {
      getUserData(userDataPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EditUserPageVisible])

  useEffect(() => {
    if (!deleteUserPageVisible) {
      getUserData(userDataPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUserPageVisible])


  const onEditUserDetails = (data) => {
    setEditUserClicked(!isEditUserClicked);
    setEditUserPageVisible(!EditUserPageVisible);
    setSelectedItems([data]);
    setUserDetailsToBeEdited({
      userName: data.userName,
      email: data.email,
      contactNumber: data.contactNumber,
      roleName: data.roleName,
    });
  };

  const joiningDateTemplate = (rowData) => {
    let joiningDate = rowData.joiningDate;
    return (
      <div className="joining-date">
        <span className="date-join user-col">
          About {Moment(joiningDate).fromNow()}
        </span>
        <span className="date-join1">
          {Moment(joiningDate).format('(YYYY-MM-DD | hh:mm)')}
        </span>
      </div>
    );
  };

  const joiningDateBody = (data) => {
    return <>{joiningDateTemplate(data)}</>;
  };

  const editUserBody = (data) => {
    const { loginId, roleName } = data ?? {};
    if (
      (role === SUB_ADMIN && (roleName === ADMIN || roleName === SUB_ADMIN)) ||
      roleName === ADMIN
    )
      return <div>{loginId}</div>;
    else
      return (
        <div
          className="edit_user user-col"
          onClick={() => onEditUserDetails(data)}
        >
          {loginId}
        </div>
      );
  };

  const devicePermissionTemplate = (user) => {
    return !(user?.roleName === 'Admin' || user?.roleName === 'Sub-Admin') ? (
      <img
        src={DevicePermissionIcon}
        className="device-permission-icon"
        alt="devicePermission"
        onClick={() => onDevicePermissionClick(user)}
      />
    ) : null;
  };

  const headerInfo = [
    {
      field: 'loginId',
      header: f({ id: 'COM_DMS_USER_ID' }),
      body: editUserBody,
    },
    {
      field: 'userName',
      className: 'user-col',
      header: f({ id: 'COM_DMS_USER_NAME' }),
    },
    {
      field: 'email',
      header: f({ id: 'COM_DMS_EMAIL' }),
      className: 'user-col',
    },
    {
      field: 'contactNumber',
      header: f({ id: 'COM_DMS_CONTACT' }),
      className: 'user-col',
    },

    {
      field: 'deviceGroups',
      header: f({ id: 'COM_DMS_DEVICE_GROUP' }),
      className: 'user-col',
    },
    {
      field: 'roleName',
      header: f({ id: 'COM_DMS_ROLE' }),
      className: 'user-col',
    },
    {
      field: 'joiningDate',
      header: f({ id: 'COM_DMS_JOINED_DATE' }),
      className: 'user-col',
      body: joiningDateBody,
    },
  ];

  if (role === 'Admin' || role === 'Sub-Admin') {
    headerInfo.push({
      field: 'devicePermission',
      header: 'Device Permission',
      body: devicePermissionTemplate,
      bodyStyle: { textAlign: 'center' },
    });
  }

  const onAddUserClick = (e) => {
    e.preventDefault();
    setAddUserClicked(!isAddUserClicked);
    setAddUserPageVisible(!AddUserPageVisible);
  };

  const onDeleteUserClick = (e) => {
    e.preventDefault();
    setDeleteUserClicked(!isDeleteUserClicked);
    setDeleteUserPageVisible(!deleteUserPageVisible);
  };

  const onDevicePermissionClick = (user) => {
    setDevicePermissionUser(user.userId);
    setDevicePermissionClicked(!isDevicePermissionClicked);
    setDevicePermissionPageVisible(!DevicePermissionPageVisible);
  };

  function DropdownButton(extraTags, rowData) {
    return (
      <div
        className="floating-window-container"
        onMouseEnter={() => setShowExtraGroupsUserId(rowData.userId)}
        onMouseLeave={() => setShowExtraGroupsUserId('')}
      >
        {extraTags?.map((tag) => (
          <div key={tag} className="tagItem">
            {tag}
          </div>
        ))}
      </div>
    );
  }

  const deviceGroupsTemplate = (rowData) => {
    let deviceGroups = rowData?.deviceGroups;
    const [g0, g1, ...restGroups] = deviceGroups ?? [];
    const deviceGroupsLen = deviceGroups?.length;
    const roleName = rowData?.roleName;
    return (isAdminOrSubAdminByRoleName(roleName)) ? "All": (!deviceGroups || deviceGroupsLen === 0) ? "-" : deviceGroups && deviceGroupsLen > 2 ? (
      <div style={{ display: 'flex' }}>
        <Tag className="setupDeviceTags" value={g0} rounded />
        <Tag className="setupDeviceTags" value={g1} rounded />
        <div>
          <div
            onMouseEnter={() => setShowExtraGroupsUserId(rowData.userId)}
            onMouseLeave={() => setShowExtraGroupsUserId('')}
          >
            <Tag
              className="setupDeviceTags extraTags"
              value={`+${deviceGroupsLen - 2}`}
              rounded
            />
          </div>
          {showExtraGroupsUserId === rowData.userId &&
            DropdownButton(restGroups, rowData)}
        </div>
      </div>
    ) : (
      <div>
        {deviceGroups &&
          deviceGroups?.length > 0 &&
          deviceGroups?.map((deviceGroup) => (
            <Tag className="setupDeviceTags" value={deviceGroup} rounded />
          ))}
      </div>
    );
  };

  const filterUserHandler = () => {
    setUserDataPayload({
      ...userDataPayload,
      searchText: searchFilter,
    });
  };

  const loginUserRoleName = localStorage.getItem('loginUserRoleName') || '';

  useEffect(() => {
    let userId = [];
    if (selectedItems !== undefined) {
      for (let i = 0; i < selectedItems.length; i++) {
        userId.push(selectedItems[i].userId);
      }
      setUserToBeDeleted(userId);
    }
  }, [selectedItems]);

  const isDeleteDisabled = () => {
    let flag = false;
    if (
      loginUserRoleName === DEVICE_MANAGER ||
      loginUserRoleName === DEVICE_OPERATOR
    ) {
      flag = true;
    }
    selectedItems.forEach((user) => {
      if (user.roleName === 'Admin' || user.roleName === loginUserRoleName) {
        flag = true;
      }
    });
    return flag;
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      filterUserHandler();
    }
  }

  return (
    <>
      {loading && <Spinner />}
    <div className="card-users">
      {(devicePermissionSuccess) && (
        <div className="toast-message-success">
          {f({ id: 'COM_DMS_CHANGES_BEEN_SAVED' })}
        </div>
      )}
      <Toast ref={toastRef} position="top-center" />
      <div className="btn-users-all">
        <Button
          className="users-action-btn"
          label={f({ id: 'COM_DMS_ADD_USER' })}
          onClick={onAddUserClick}
          disabled={!(role === ADMIN || role === SUB_ADMIN)}
        />
        <Button
          className="users-action-btn"
          label={f({ id: 'COM_DMS_DELETE_USER' })}
          onClick={onDeleteUserClick}
          disabled={
            (selectedItems !== undefined && selectedItems.length === 0) ||
            isDeleteDisabled()
          }
        />
        <div className="searchUser-right">
          <SearchBar
            placeholder={f({id: 'COM_DMS_PLACEHOLDER_USER_ID_NAME'})}
            searchHandler={filterUserHandler}
            value={searchFilter}
            setValue={setSearchFilter}
            handleInputKeyDown={handleInputKeyDown}
          />
        </div>
      </div>
      <div className="data-users-table">
        <AllUsersList
          userData={userData}
          deviceGroupsTemplate={deviceGroupsTemplate}
          devicePermissionTemplate={devicePermissionTemplate}
          setSelectedItems={setSelectedItems}
          selectedItems={selectedItems}
          headerInfo={headerInfo}
          totalUsersCount={totalUsersCount}
          onPageChange={onPageChange}
          first={first}
          rows={rows}
          joiningDateTemplate={joiningDateTemplate}
          editUserBody={editUserBody}
        />
      </div>
      {AddUserPageVisible && (
        <AddUserPopup
          setVisible={setAddUserPageVisible}
          visible={AddUserPageVisible}
          setSearchText={setSearchFilter}
        />
      )}
      {EditUserPageVisible && (
        <EditUserPopup
          setVisible={setEditUserPageVisible}
          visible={EditUserPageVisible}
          userDetailsToBeEdited={userDetailsToBeEdited}
          setUserDetailsToBeEdited={setUserDetailsToBeEdited}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setUserData={setUserData}
        />
      )}
      {deleteUserPageVisible && (
        <DeleteUserPopup
          setVisible={setDeleteUserPageVisible}
          visible={deleteUserPageVisible}
          userToBeDeleted={userToBeDeleted}
          setUserToBeDeleted={setUserToBeDeleted}
          setUserData={setUserData}
          setSelectedItems={setSelectedItems}
        />
      )}
      {DevicePermissionPageVisible && (
        <DevicePermissionPopup
          userId={devicePermissionUser}
          setVisible={setDevicePermissionPageVisible}
          visible={DevicePermissionPageVisible}
          setDevicePermissionSuccess={setDevicePermissionSuccess}
        />
      )}
    </div>
    </>
  );
};

export default User;
