import { SPECIAL_CHAR_REGEX, EMAIL_REGEX, NUMBER_REGEX, MAC_ADDRESS_REGEX } from '../config/constants/Constants';

export const userNameMaxLength = 60;
export const userIdMaxLength = 60;
export const contactNumberMaxLength = 15;
export const passwordMaxLength = 50;
export const passwordMinLength = 8;
export const tagsMaxLength = 15;
export const deviceNameMaxLength = 50;
export const groupNameMaxLength = 50;
export const emailMaxLength = 60;
export const otpMaxLength = 6;
export const templateNameMaxLength = 60;
export const firmwareNameMaxLength = 100;

const userIdLengthRangeCheck = (id) => {
  return id.length <= userIdMaxLength;
};

const userNameLengthRangeCheck = (name) => {
  return name.length <= userNameMaxLength;
};

const deviceNameLengthRangeCheck = (name) => {
  return name.length <= deviceNameMaxLength;
};

const groupNameLengthRangeCheck = (name) => {
  return name.length <= groupNameMaxLength;
};

const deviceTagLengthRangeCheck = (tag) => {
  return tag.length <= tagsMaxLength;
};

const contactNumberLengthRangeCheck = (number) => {
  return number.length <= contactNumberMaxLength;
};
export function validateUserDetails(name, value) {
  switch (name) {
    case 'loginId':
      return validateId(value);
    case 'userName':
      return validateName(value);
    case 'email':
      return validateEmail(value);
    case 'contactNumber':
    case 'mobNo':
      return validateContactNumber(value);
    case 'password':
    case 'confirmPassword':
      return validatePassword(name, value);
  }
}

export function validateGroupName(groupName) {
  let isValid = true;
  let messageId = '';
  if (!groupName || !groupNameLengthRangeCheck(groupName)) {
    messageId = 'COM_DMS_GROUP_NAME_MAX_LENGTH_EXCEEDED';
    isValid = false;
  } else if(groupName?.trim().length === 0) {
    messageId = 'COM_DMS_INVALID_GROUP_NAME';
    isValid = false;
  } else if (!/^[a-zA-Z0-9\.\-_ ]+$/.test(groupName)) {
    messageId = 'COM_DMS_GROUP_NAME_CONTAIN_ALPHA_NUM_PERIODS_HYPHEN_UNDERSCORE_SPACE';
    isValid = false;
  } else if (groupName?.trim().toLowerCase() === 'default group') {
    messageId = 'COM_DMS_DEFAULT_GROUP_UNIQUE_NAME_INVALID';
    isValid = false;
  }
  return { isValid, messageId};
}

function validateEmail(email) {
  let isValid = true;
  let messageId = '';
  if(email?.trim().length === 0) {
    isValid = false;
    messageId = 'COM_DMS_INVALID_EMAIL';
  } else if (!EMAIL_REGEX.test(email)) {
    isValid = false;
    messageId = 'COM_DMS_INVALID_EMAIL';
  }
  return { isValid, messageId};
}

function validateName(name) {
  let isValid = true;
  let messageId = '';
  if (!name || !userNameLengthRangeCheck(name)) {
    messageId = 'COM_DMS_USER_NAME_MAX_LENGTH_EXCEEDED';
    isValid = false;
  } else if(/\s{2,}/.test(name)){
    messageId = 'COM_DMS_CONSECUTIVE_SPACES_NOT_ALLOWED';
    isValid = false;
  } else if(name?.trim().length === 0) {
    messageId = 'COM_DMS_INVALID_USER_NAME';
    isValid = false;
  }
  return { isValid, messageId};
}

function validateContactNumber(number) {
  let isValid = true;
  let messageId = '';
  if (!number || !contactNumberLengthRangeCheck(number)) {
    messageId = 'COM_DMS_CONTACT_NUMBER_MAX_LENGTH_EXCEEDED';
    isValid = false;
  } else if(number?.trim().length === 0) {
    messageId = 'COM_DMS_INVALID_CONTACT_NUMBER';
    isValid = false;
  } else if(!/^\d{0}(\d+)?$/.test(number)) {
    messageId = 'COM_DMS_INVALID_CONTACT_NUMBER';
    isValid = false;
  }
  return { isValid, messageId};
}

function validateId(id) {
  let isValid = true;
  let messageId = '';
  let userId = id;

  if (!id || !userIdLengthRangeCheck(id)) {
    messageId = 'COM_DMS_USER_ID_MAX_LENGTH_EXCEEDED';
    isValid = false;
  } else if(userId?.trim().length === 0) {
    messageId = 'COM_DMS_INVALID_USER_ID';
    isValid = false;
  } else if (!/^[A-Za-z0-9.@]+$/.test(id) && !EMAIL_REGEX.test(id)) {
      messageId = 'COM_DMS_USER_ID_CONTAIN_ALPHA_NUM_PERIODS_AT';
      isValid = false;
  }

  return { isValid, messageId};
}

const validatePassword = (name, password) => {
  let isValid = true;
  let messageId = '';
  const passwordCriteria = validatePasswordFields(password);
  const {isAlNum, isOneCapital, isOneSpecial, minPasswordLength, maxPasswordLength} = passwordCriteria;
  if(!password || !(minPasswordLength && maxPasswordLength)){
    isValid = false;
    messageId = 'COM_DMS_INVALID_PASSWORD_LENGTH';
  } else if(/\s/.test(password)) {
    isValid = false;
    messageId = 'COM_DMS_INVALID_PASSWORD_CONTAIN_SPACE';
  }
  else if(password?.trim().length === 0) {
    messageId = (name === 'password') ? 'COM_DMS_INVALID_PASSWORD' : 'COM_DMS_CONFIRM_PASSWORD_INVALID';
    isValid = false;
  } else if (!isAlNum || !isOneCapital || !isOneSpecial) {
    messageId = 'COM_DMS_INVALID_PASSWORD';
    isValid = false;
  }
  return { isValid, messageId};
}

export const validatePasswordFields = (password) => {
  if (!password) {
    return {
      isAlNum: false,
      isOneCapital: false,
      isOneSpecial: false,
      minPasswordLength: false,
      maxPasswordLength: false,
      containSpace: false
    };
  }
  let isOneCapital = false;
  let isNum = false;
  let isAlpha = false;

  for (let char of password) {
    let charCode = char.charCodeAt(0);
    if (charCode > 64 && charCode < 91) {
      isOneCapital = true;
    }
    if (charCode > 47 && charCode < 58) {
      isNum = true;
    }
    if ((charCode > 96 && charCode < 123) || (charCode > 64 && charCode < 91)) {
      isAlpha = true;
    }
  }

  return {
    isAlNum: isAlpha && isNum,
    isOneCapital,
    isOneSpecial: SPECIAL_CHAR_REGEX.test(password),
    minPasswordLength: password?.trim()?.length >= passwordMinLength,
    maxPasswordLength: password?.trim()?.length <= passwordMaxLength,
    containSpace: !(/\s/.test(password))
  };
};

export const validateDeviceDetails = (name, value) => {
  switch (name) {
    case 'deviceId':
      return validateDeviceId(value);
    case 'deviceName':
      return validateDeviceName(value);
    case 'deviceTags':
      return validateDeviceTags(value);
  }
};

function validateDeviceId(deviceId) {
  let isValid = true;
  let messageId = '';
  if(deviceId?.trim().length === 0) {
    isValid = false;
    messageId = 'COM_DMS_INVALID_DEVICE_ID';
  } else if (!MAC_ADDRESS_REGEX.test(deviceId)) {
    isValid = false;
    messageId = 'COM_DMS_INVALID_DEVICE_ID';
  }

  return { isValid, messageId};
}

function validateDeviceName(deviceName) {
  let isValid = true;
  let messageId = '';
  if (!deviceName) {
    messageId = 'COM_DMS_DEVICE_NAME_NULL';
    isValid = false;
  }
  else if (!deviceNameLengthRangeCheck(deviceName)) {
    messageId = 'COM_DMS_DEVICE_NAME_MAX_LENGTH_EXCEEDED';
    isValid = false;
  } else if(deviceName?.trim().length === 0) {
    messageId = 'COM_DMS_INVALID_DEVICE_NAME';
    isValid = false;
  } else if (!/^[a-zA-Z0-9\.\-_ ]+$/.test(deviceName)) {
    messageId = 'COM_DMS_DEVICE_NAME_CONTAIN_ALPHA_NUM_PERIODS_HYPHEN_UNDERSCORE_SPACE';
    isValid = false;
  }
  return { isValid, messageId};
}

function validateDeviceTags(deviceTag) {
  let isValid = true;
  let messageId = '';
  if (!deviceTag || !deviceTagLengthRangeCheck(deviceTag)) {
    messageId = 'COM_DMS_DEVICE_TAG_MAX_LENGTH_EXCEEDED';
    isValid = false;
  } else if(deviceTag?.trim().length === 0) {
    messageId = 'COM_DMS_INVALID_DEVICE_TAG';
    isValid = false;
  } else if (!/^[a-zA-Z0-9\.\-_]+$/.test(deviceTag)) {
    messageId = 'COM_DMS_DEVICE_TAG_CONTAIN_ALPHA_NUM_PERIODS_HYPHEN_UNDERSCORE';
    isValid = false;
  }
  return { isValid, messageId};
}