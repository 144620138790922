export const GET_SERVER_SETTINGS_PENDING = 'GET_SERVER_SETTINGS_PENDING';
export const GET_SERVER_SETTINGS_SUCCESS = 'GET_SERVER_SETTINGS_SUCCESS';
export const GET_SERVER_SETTINGS_ERROR = 'GET_SERVER_SETTINGS_ERROR';

export const UPDATE_SERVER_SETTINGS_PENDING = 'UPDATE_SERVER_SETTINGS_PENDING';
export const UPDATE_SERVER_SETTINGS_SUCCESS = 'UPDATE_SERVER_SETTINGS_SUCCESS';
export const UPDATE_SERVER_SETTINGS_ERROR = 'UPDATE_SERVER_SETTINGS_ERROR';

export const UPDATE_SERVER_SETTINGS_FLAG = 'UPDATE_SERVER_SETTINGS_FLAG';

export const IS_CHANGED_PASSWORD = 'IS_CHANGED_PASSWORD';
export const RESET_SERVER_SETTINGS = 'RESET_SERVER_SETTINGS';
