import React, { useState, useEffect, useRef } from 'react';
import './DeviceMessagingTab.css';
import { Dropdown } from 'primereact/dropdown';
import ColorBox from '../ColorBox/ColorBox';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import { useIntl } from 'react-intl';
import Overlay from '../../../../../components/Overlay/Overlay';
import AddToTemplatePopup from '../AddToTemplatePopup/AddToTemplatePopup';
import DownArrowIcon from '../../../../../assets/images/down-filled-arrow.svg';
import SelectDeviceComponent from '../../../../../components/SelectDeviceComponent/SelectDeviceComponent';
import { RestServiceUtils } from '../../../../../utils/RestServiceUtils';
import {
  GROUP_MGMT_API,
  MESSAGE_ALERT_URL,
} from '../../../../../config/constants/Constants';
import flatToTree from 'flat-to-tree';
import { convertJSONDataToTreeTableFormat } from '../../../Group/convertToJSONTree';
import {
  cleanJSONTree,
  removeNullValuesFromNestedObjectOfArray,
} from '../../../../../utils/CleanJSONArrayObject';
import BoldTextIcon from '../../../../../assets/images/bold-text-icon.svg';
import ItalicTextIcon from '../../../../../assets/images/italic-text-icon.svg';
import { Paginator } from 'primereact/paginator';
import CustomDialog from '../../../../../components/CustomDialog/CustomDialog';
import { Toast } from 'primereact/toast';
import {
  BLANK_ALERT_TEMPLATE_ID,
  BLANK_MESSAGE_TEMPLATE_ID,
  DEFAULT_TEMPLATE_NAMES,
  EARTHQUAKE_ALERT_TEMPLATE_ID,
  FIRE_ALERT_TEMPLATE_ID,
  INITIAL_SELECTED_TEMPLATE,
  alertDisplay,
  alertTemplate,
  backgroundColorCodes,
  colorCodes,
  fontOptions,
  messageTemplate,
  templatePositionOptions,
  textPositionDisplay,
} from './DeviceMessagingUtils';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import DeleteIcon from '../../../../../assets/images/delete-icon.svg';
import { addNoOfDaysDayToDate } from '../../../../../utils/helper';
import { Dialog } from 'primereact/dialog';
import {
  setToastComponent,
  showSuccessToast,
} from '../../../../../services/APIResponseHandler';
import {
  BLANK_IMG_BASE64_URL,
  COMMOM_ALERT_IMAGE,
} from './TemplateConstantImages';

const DeviceMessagingTab = ({ alert, activeIndex , trigger }) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;

  const [messagePageNo, setMessagePageNo] = useState(0);
  const [alertPageNo, setAlertPageNo] = useState(0);
  const [messageRowsPerPage, setMessageRowsPerPage] = useState(6);
  const [alertRowsPerPage, setAlertRowsPerPage] = useState(6);
  const [totalNoOfTemplates, setTotalNoOfTemplate] = useState(0);
  const [templatesData, setTemplatesData] = useState([]);
  const [messageTemplateName, setMessageTemplateName] = useState('');
  const [imageUrlFile, setImageUrlFile] = useState({});
  const [currentTemplateMouseEnterId, setCurrentTemplateMouseEnterId] =
    useState('');

  const [currentMessagePageNo, setCurrentMessagePageNo] = useState(0);
  const [currentAlertPageNo, setCurrentAlertPageNo] = useState(0);

  const [isCommonImage, setIsCommonImage] = useState(false);
  const DEFAULT_TEMPLATE_IDS = new Set([
    BLANK_MESSAGE_TEMPLATE_ID,
    BLANK_ALERT_TEMPLATE_ID,
    EARTHQUAKE_ALERT_TEMPLATE_ID,
    FIRE_ALERT_TEMPLATE_ID,
  ]);
  const [currentEndTimeDuration, setCurrentEndTimeDuration] = useState(1);

  const [selectedTemplateData, setSelectedTemplateData] = useState(
    INITIAL_SELECTED_TEMPLATE
  );

  const [imageSize, setImageSize] = useState(0);
  const INITIAL_DIMENSIONS = {
    width: 0,
    height: 0,
  };
  const [imageDimensions, setImageDimensions] = useState(INITIAL_DIMENSIONS);

  const [fontColorFlag, setFontColorFlag] = useState(false);
  const [backgroundColorFlag, setBackgroundColorFlag] = useState(false);
  const [openAddToTemplate, setOpenAddToTemplate] = useState(false);
  const [sendAlertFlag, setSendAlertFlag] = useState(false);
  const [openMessageTemplateSaveAs, setOpenMessageTemplateSaveAs] =
    useState(false);

  const [jsonGroupTree, setJsonGroupTree] = useState([]);
  const [selectedCheckedKeys, setSelectedCheckedKeys] = useState([]);
  const [selectedGroupKeys, setSelectedGroupKeys] = useState([]);
  const [deviceParentIds, setDeviceParentIds] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [datetime12h, setDateTime12h] = useState();
  const [duplicateTemplateMessage, setDuplicateTemplateMessage] = useState('');
  const [resetFlag, setResetFlag] = useState(false);
  const [activeId, setActiveId] = useState(0);

  const IMAGE_RESOLUTION =
    !isCommonImage &&
    (imageSize > 3 ||
      (imageDimensions.width > 1280 && imageDimensions.height > 720));

  const setCurrentTemplateValue = (currentProperty, currentPropertyValue) => {
    setSelectedTemplateData({
      ...selectedTemplateData,
      [currentProperty]: currentPropertyValue,
    });
  };

  const onPageChange = (event) => {
    if (activeIndex === 0) {
      setCurrentMessagePageNo(event.page);
      setMessagePageNo(event.first);
      setMessageRowsPerPage(event.rows);
    } else if (activeIndex === 1) {
      setCurrentAlertPageNo(event.page);
      setAlertPageNo(event.first);
      setAlertRowsPerPage(event.rows);
    }
  };

  const toast = useRef(null);

  useEffect(() => {
    setToastComponent(toast.current);
  }, []);

  useEffect(() => {
    let pageSizeQuery = 6;
    let startIndexQuery;
    if (activeIndex === 0) {
      if (messagePageNo === 0) {
        startIndexQuery = 0;
        pageSizeQuery = 5;
      } else if (messagePageNo === 1) {
        startIndexQuery = 5;
      } else {
        startIndexQuery = 5 * currentMessagePageNo + (currentMessagePageNo - 1);
      }
    }
    if (activeIndex === 1) {
      if (alertPageNo === 0) {
        pageSizeQuery = 3;
        startIndexQuery = 0;
      } else if (alertPageNo === 1) {
        startIndexQuery = 3;
      } else {
        startIndexQuery = 3 * (1 + (currentAlertPageNo - 1) * 2);
      }
    }

    RestServiceUtils.HTTP_POST(`${MESSAGE_ALERT_URL}/templateData`, {
      startIndex: startIndexQuery,
      pageSize: pageSizeQuery,
      templateType: activeIndex === 0 ? 'MESSAGE' : 'ALERT',
    }).then(({ data }) => {
      const { items, totalCount } = data ?? [];
      setTotalNoOfTemplate(totalCount);
      const { templateId, alertImgSrc, alertImgName } = items?.[0] ?? {};

      if (activeIndex === 0) {
        if (messagePageNo === 0) {
          setActiveId(BLANK_MESSAGE_TEMPLATE_ID);
          setSelectedTemplateData(
            mapTemplateValueToCurrentData(messageTemplate)
          );
          setTemplatesData([messageTemplate, ...items]);
        } else {
          setActiveId(templateId);
          setSelectedTemplateData(mapTemplateValueToCurrentData(items[0]));
          setTemplatesData(items);
        }
      } else if (activeIndex === 1) {
        if (alertPageNo === 0) {
          setActiveId(BLANK_ALERT_TEMPLATE_ID);
          setSelectedTemplateData(
            mapTemplateValueToCurrentData(alertTemplate[0])
          );
          setImageUrlFile({
            imageUrl: alertTemplate[0].alertImgSrc,
            imageName: '',
          });
          setTemplatesData([...alertTemplate, ...items]);
        } else {
          setSelectedTemplateData(mapTemplateValueToCurrentData(items[0]));
          setActiveId(templateId);
          setImageUrlFile({
            imageUrl: alertImgSrc,
            imageName: alertImgName,
          });
          setTemplatesData(items);
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    alertPageNo,
    messagePageNo,
    messageRowsPerPage,
    alertRowsPerPage,
    trigger
  ]);

  const fontColorRef = useRef(null);
  const backgroundColorRef = useRef(null);
  const fileInputRef = useRef(null);

  const getAvilableGroups = () => {
    RestServiceUtils.HTTP_GET(GROUP_MGMT_API).then(({ data }) => {
      const convertedJSONData = flatToTree(data, {
        id: 'groupId',
        parentId: 'parentId',
      });
      convertJSONDataToTreeTableFormat(convertedJSONData);
      cleanJSONTree(convertedJSONData);
      const cleanedJSONTree =
        removeNullValuesFromNestedObjectOfArray(convertedJSONData);
      setJsonGroupTree(cleanedJSONTree);
    });
    setSelectedCheckedKeys([]);
    setDeviceParentIds([]);
  };

  useEffect(() => {
    getAvilableGroups();
  }, []);

  useEffect(() => {
    if (activeIndex === 0){
      setSelectedTemplateData(mapTemplateValueToCurrentData(messageTemplate));
    } else if (activeIndex === 1) {
      setSelectedTemplateData(mapTemplateValueToCurrentData(alertTemplate[0]));
    }
    setImageSize(0);
    setImageDimensions(INITIAL_DIMENSIONS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  const handleUploadFile = () => {
    fileInputRef.current.click();
  };

  const blobToImage = (binaryUrl) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    let img = document.createElement('img');
    img.src = binaryUrl;
    canvas.width = img.width;
    canvas.height = img.height;
    context.drawImage(img, 0, 0, img.width, img.height);

    return canvas.toDataURL();
  };

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = function () {
      let width = +this.naturalWidth,
        height = +this.naturalHeight;
      setImageDimensions({ width, height });
    };

    if (file) {
      const render = new FileReader();
      render.onload = () => {
        setImageUrlFile(() => ({
          imageUrl: render.result,
          imageName: file.name,
        }));
        setResetFlag(true);
        setImageSize(file.size / (1024 * 1024));
      };
      render.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const toggleUserClick = (e) => {
      if (!fontColorRef.current?.contains(e.target)) {
        setFontColorFlag(false);
      } else {
        setFontColorFlag(true);
      }
      if (!backgroundColorRef.current?.contains(e.target)) {
        setBackgroundColorFlag(false);
      } else {
        setBackgroundColorFlag(true);
      }
    };
    document.addEventListener('click', toggleUserClick);
    return () => {
      document.removeEventListener('click', toggleUserClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapTemplateValueToCurrentData = (data) => {
    const currentPosition = templatePositionOptions.filter(
      (pos) => pos.code === data.templatePosition
    )[0];
    const fontOption = fontOptions.filter(
      (opt) => opt.code === data.textSize
    )[0];

    const {
      templateId,
      templateName,
      templateMessage,
      backgroundColor,
      textColor,
      alertImgSrc,
      alertImgName,
      textStyle,
    } = data ?? {};

    setActiveId(templateId);

    if (activeIndex === 1) {
      setImageUrlFile({ imageUrl: alertImgSrc, imageName: alertImgName });
    }

    return {
      ...selectedTemplateData,
      templateName,
      textColor,
      backgroundColor,
      templateMessage,
      textStyle,
      textSize: fontOption,
      templatePosition: currentPosition,
    };
  };

  const handleAddTemplate = () => {
    let templateEventType;
    if (activeIndex === 0) {
      templateEventType = 'MESSAGE';
    } else if (activeIndex === 1) {
      templateEventType = 'ALERT';
    }

    const blobAlertImg = blobToImage(imageUrlFile.imageUrl)?.split(',');

    const {
      textSize,
      textColor,
      textStyle,
      templatePosition,
      backgroundColor,
      templateMessage,
    } = selectedTemplateData ?? {};
    const { imageUrl, imageName } = imageUrlFile ?? {};

    const currentImage =
      activeIndex === 1 && !isCommonImage
        ? blobToImage(imageUrl)
        : activeIndex === 1 && isCommonImage
        ? COMMOM_ALERT_IMAGE
        : '';

    const currentImageName =
      activeIndex === 1 && isCommonImage
        ? 'common-alert-img.svg'
        : blobAlertImg?.length > 1
        ? imageName
        : '';

    const templatePayload = {
      textSize: textSize?.code,
      textColor: textColor,
      textStyle: textStyle,
      alertImgSrc: currentImage,
      alertImgName: currentImageName,
      templatePosition:
        activeIndex === 0 ? templatePosition?.code : 'FULLSCREEN',
      backgroundColor: backgroundColor,
      templateMessage: templateMessage,
    };

    if (DEFAULT_TEMPLATE_IDS.has(activeId)) {
      if (activeIndex === 0 && messageTemplateName.trim() === 'Blank') {
        setDuplicateTemplateMessage(f({ id: 'COM_DMS_TEMPLATE_EXISTS_MSG' }));
        return;
      } else if (
        activeIndex === 1 &&
        DEFAULT_TEMPLATE_NAMES.has(messageTemplateName.trim())
      ) {
        setDuplicateTemplateMessage(f({ id: 'COM_DMS_TEMPLATE_EXISTS_MSG' }));
        return;
      }

      if (!messageTemplateName.trim()) {
        setDuplicateTemplateMessage(
          f({ id: 'COM_DMS_EMPTY_TEMPLATE_NAME_MSG' })
        );
        return;
      }

      RestServiceUtils.HTTP_POST_WITHOUT_TOAST_ERROR(
        `${MESSAGE_ALERT_URL}/templateData/${
          activeIndex === 0 ? 'MESSAGE' : 'ALERT'
        }`,
        { ...templatePayload, templateName: messageTemplateName.trim() }
      ).then(({ data }) => {
        const { errorCode, errorMessage } = data ?? {};
        if (errorCode) {
          setDuplicateTemplateMessage(errorMessage);
          return;
        } else {
          setResetFlag(false);
          let startIndex = 0;
          let pageSize = 5;
          if (activeIndex === 0) {
            if (currentMessagePageNo !== 0) {
              pageSize = 6;
              startIndex =
                5 * currentMessagePageNo + (currentMessagePageNo - 1);
            }
          }
          if (activeIndex === 1) {
            if (currentAlertPageNo === 0) {
              pageSize = 3;
              startIndex = 0;
            } else {
              pageSize = 6;
              startIndex = 3 * (1 + (currentAlertPageNo - 1) * 2);
            }
          }
          RestServiceUtils.HTTP_POST(`${MESSAGE_ALERT_URL}/templateData`, {
            startIndex,
            pageSize,
            templateType: activeIndex === 0 ? 'MESSAGE' : 'ALERT',
          }).then(({ data }) => {
            const { items, totalCount } = data ?? [];
            setTotalNoOfTemplate(totalCount);
            if (activeIndex === 0) {
              if (messagePageNo === 0) {
                setTemplatesData([messageTemplate, ...items]);
              } else {
                setTemplatesData(items);
              }
              setActiveId(BLANK_MESSAGE_TEMPLATE_ID);
            } else if (activeIndex === 1) {
              if (alertPageNo === 0) {
                setImageUrlFile({
                  imageUrl: alertTemplate[0].alertImgSrc,
                  imageName: alertTemplate[0].alertImgName,
                });
                setTemplatesData([...alertTemplate, ...items]);
              } else {
                setTemplatesData(items);
              }
              setActiveId(BLANK_ALERT_TEMPLATE_ID);
            }
            setResetFlag(false);
            showSuccessToast(f({ id: 'COM_DMS_NEW_TEMPLATE_TXT' }));
            setMessageTemplateName('');
            setSelectedTemplateData({
              ...INITIAL_SELECTED_TEMPLATE,
              backgroundColor: activeIndex === 0 ? '#efefef' : '#fff3cd',
              templatePosition: templatePositionOptions[2],
              templateMessage: messageTemplate.templateMessage,
            });
          });
        }
        setOpenAddToTemplate(false);
        setDuplicateTemplateMessage('');
      });
    } else {
      RestServiceUtils.HTTP_PUT(`${MESSAGE_ALERT_URL}/templateData`, {
        ...templatePayload,
        templateId: activeId,
        templateType: templateEventType,
      }).then(({ data }) => {
        const { errorCode } = data ?? {};

        if (errorCode) {
          return;
        }

        let pageSizeQuery = 6;
        let startIndexQuery;
        if (activeIndex === 0) {
          if (messagePageNo === 0) {
            startIndexQuery = 0;
            pageSizeQuery = 5;
          } else if (messagePageNo === 1) {
            startIndexQuery = 5;
          } else {
            startIndexQuery =
              5 * currentMessagePageNo + (currentMessagePageNo - 1);
          }
        }
        if (activeIndex === 1) {
          if (alertPageNo === 0) {
            pageSizeQuery = 3;
            startIndexQuery = 0;
          } else if (alertPageNo === 1) {
            startIndexQuery = 3;
          } else {
            startIndexQuery = 3 * (1 + (currentAlertPageNo - 1) * 2);
          }
        }
        RestServiceUtils.HTTP_POST(`${MESSAGE_ALERT_URL}/templateData`, {
          startIndex: startIndexQuery,
          pageSize: pageSizeQuery,
          templateType: activeIndex === 0 ? 'MESSAGE' : 'ALERT',
        }).then(({ data: updatedListData }) => {
          const { items, totalCount, errorCode } = updatedListData ?? [];

          if (errorCode) {
            return;
          }

          const currentDataItem = items?.filter(
            (item) => item.templateId === activeId
          )?.[0];
          setTotalNoOfTemplate(totalCount);
          if (activeIndex === 0) {
            if (messagePageNo === 0) {
              setTemplatesData(() => [messageTemplate, ...items]);
            } else {
              setTemplatesData(() => items);
            }
          } else if (activeIndex === 1) {
            if (alertPageNo === 0) {
              setTemplatesData(() => [...alertTemplate, ...items]);
            } else {
              setTemplatesData(() => items);
            }
          }
          setResetFlag(false);
          showSuccessToast(f({ id: 'COM_DMS_TEMPLATE_SAVE_SUCCESS' }));
          setMessageTemplateName('');
          setSelectedTemplateData(
            mapTemplateValueToCurrentData(currentDataItem)
          );
        });
        setMessageTemplateName('');
      });
    }
  };

  const leftTabContent = () => {
    const handleMessagingAlertTemplateClick = (data) => {
      const { templateId } = data ?? {};
      setActiveId(templateId);
      setIsCommonImage(false);
      setResetFlag(false);
      const currentTemplate = mapTemplateValueToCurrentData(data);
      setSelectedTemplateData(currentTemplate);
    };

    return (
      <div className="content-body-template">
        {activeIndex === 0
          ? templatesData?.map((template) => {
              const {
                templateId,
                templateName,
                backgroundColor,
                textColor,
                templatePosition,
              } = template;

              return (
                <div
                  key={templateId}
                  className={
                    templateId !== activeId
                      ? 'messaging-template'
                      : 'messaging-template active-template'
                  }
                  onMouseEnter={() =>
                    setCurrentTemplateMouseEnterId(templateId)
                  }
                  onMouseLeave={() => setCurrentTemplateMouseEnterId('')}
                  onClick={() => handleMessagingAlertTemplateClick(template)}
                >
                  <div
                    className="messaging-template-label"
                    id={`messaging_template_${templateId}`}
                    style={{
                      backgroundColor,
                      color: textColor,
                      top:
                        templatePosition === 'TOP'
                          ? '0'
                          : templatePosition === 'CENTRE'
                          ? '40%'
                          : '73.2%',
                    }}
                  >
                    <div className="messaging-template-label-name">
                      {templateName}
                    </div>
                  </div>
                  {!DEFAULT_TEMPLATE_IDS.has(templateId) &&
                    currentTemplateMouseEnterId === templateId && (
                      <div
                        className="template-delete"
                        onClick={() => {
                          setIsDeleteTemplate(true);
                          setCurrentTemplateId(templateId);
                        }}
                      >
                        <img src={DeleteIcon} alt="delete" />
                      </div>
                    )}
                  <Tooltip
                    target={`#messaging_template_${templateId}`}
                    position="top"
                  >
                    <div className="infoIcon_tooltipContent">
                      {templateName}
                    </div>
                  </Tooltip>
                </div>
              );
            })
          : templatesData?.map((template) => {
              const { templateName, alertImgSrc, templateId } = template ?? {};
              return (
                <div
                  key={templateId}
                  className={
                    templateId !== activeId
                      ? 'alert-messaging-template'
                      : 'alert-messaging-template active-template'
                  }
                  onMouseEnter={() =>
                    setCurrentTemplateMouseEnterId(templateId)
                  }
                  onMouseLeave={() => setCurrentTemplateMouseEnterId('')}
                  onClick={() => {
                    setImageSize(0);
                    setImageDimensions(INITIAL_DIMENSIONS);
                    handleMessagingAlertTemplateClick(template);
                  }}
                >
                  <img src={alertImgSrc} alt="alert" className="alert-img" />
                  <div
                    className="alert-messaging-template-label-name"
                    id={`alert_template_${templateId}`}
                  >
                    {templateName}
                  </div>
                  <Tooltip
                    target={`#alert_template_${templateId}`}
                    position="top"
                  >
                    <div className="infoIcon_tooltipContent">
                      {templateName}
                    </div>
                  </Tooltip>
                  {!DEFAULT_TEMPLATE_IDS.has(templateId) &&
                    currentTemplateMouseEnterId === templateId && (
                      <div
                        className="alert-template-delete"
                        onClick={() => {
                          setIsDeleteTemplate(true);
                          setCurrentTemplateId(templateId);
                        }}
                      >
                        <img src={DeleteIcon} alt="delete" />
                      </div>
                    )}
                </div>
              );
            })}
      </div>
    );
  };

  const resetInitialData = () => {
    setSendAlertFlag(false);
    setDateTime12h(null);
    setSelectedCheckedKeys([]);
    setSelectedGroupKeys([]);
    setCurrentEndTimeDuration(1);
  };

  const deviceComponentHeader = () => {
    return (
      <div className="device-component-header">
        <div className="device-component-header-text">
          {activeIndex === 0
            ? f({ id: 'COM_DMS_SEND_MESSAGE' })
            : f({ id: 'COM_DMS_SEND_ALERT' })}
        </div>
        <div className="device-component-header-action">
          <button onClick={resetInitialData}>
            {f({ id: 'COM_DMS_CANCEL' })}
          </button>
          <button
            onClick={handleSendAlertMessage}
            disabled={
              activeTabIndex === 0
                ? !selectedGroupKeys.length
                : !selectedCheckedKeys.length
            }
          >
            {f({ id: 'COM_DMS_SEND' })}
          </button>
        </div>
      </div>
    );
  };

  const handleSendAlertMessage = () => {
    const {
      templateName,
      textStyle,
      textSize,
      textColor,
      templatePosition,
      backgroundColor,
      templateMessage,
    } = selectedTemplateData ?? {};

    const { imageUrl, imageName } = imageUrlFile ?? {};

    let currentTextSize = textSize;
    let currentTempPosition = templatePosition;

    if (typeof templatePosition === 'object' && templatePosition !== null) {
      currentTempPosition = templatePosition.code;
    }
    if (typeof textSize === 'object' && textSize !== null) {
      currentTextSize = textSize.code;
    }

    const currentImage =
      activeIndex === 1 && !isCommonImage
        ? blobToImage(imageUrl)
        : activeIndex === 1 && isCommonImage
        ? COMMOM_ALERT_IMAGE
        : '';
    const payload = {
      event: 'START',
      messageAlertData: {
        textColor,
        textStyle,
        templateName,
        templateMessage,
        backgroundColor,
        alertImgName: imageName,
        alertImgSrc: currentImage,
        templatePosition:
          activeIndex === 0 ? currentTempPosition : 'FULLSCREEN',
        textSize: currentTextSize,
        templateType: activeIndex === 0 ? 'MESSAGE' : 'ALERT',
      },
      startTime: new Date(Date.now()).getTime(),
      endTime:
        currentEndTimeDuration === 1 || currentEndTimeDuration === 2
          ? addNoOfDaysDayToDate(new Date(), currentEndTimeDuration)
          : datetime12h.getTime(),
      sentType: activeTabIndex === 0 ? 'GROUP' : 'DEVICE',
      targetDevices: {
        deviceIds: activeTabIndex === 0 ? [] : selectedCheckedKeys,
        groupIds: activeTabIndex === 1 ? [] : selectedGroupKeys,
      },
    };

    RestServiceUtils.HTTP_POST(
      `${MESSAGE_ALERT_URL}/messageAlert`,
      payload
    ).then(({ data }) => {
      const { errorCode } = data ?? {};
      if (!errorCode) {
        if (activeIndex === 0)
          showSuccessToast(f({ id: 'COM_DMS_MESSAGE_SENT_TEXT' }));
        else {
          showSuccessToast(f({ id: 'COM_DMS_ALERT_SENT_TEXT' }));
        }
      }
      resetInitialData();
    });
  };

  const handleClickSaveAs = () => {
    let templateEventType;
    if (activeIndex === 0) {
      templateEventType = 'MESSAGE';
    } else if (activeIndex === 1) {
      templateEventType = 'ALERT';
    }

    const currentImage =
      activeIndex === 1 && !isCommonImage
        ? blobToImage(imageUrlFile.imageUrl)
        : activeIndex === 1 && isCommonImage
        ? COMMOM_ALERT_IMAGE
        : '';

    const currentTextSize =
      typeof selectedTemplateData.textSize === 'object'
        ? selectedTemplateData.textSize.code
        : selectedTemplateData.textSize;

    const currentTextPosition =
      typeof selectedTemplateData.templatePosition === 'object'
        ? selectedTemplateData.templatePosition.code
        : selectedTemplateData.templatePosition;

    const payload = {
      templateType: templateEventType,
      templateName: selectedTemplateData.templateName,
      templateMessage: selectedTemplateData.templateMessage,
      templatePosition: activeIndex === 0 ? currentTextPosition : 'FULLSCREEN',
      backgroundColor: selectedTemplateData.backgroundColor,
      textColor: selectedTemplateData.textColor,
      textSize: currentTextSize,
      textStyle: selectedTemplateData.textStyle,
      alertImgName: activeIndex === 1 ? imageUrlFile.imageName : undefined,
      alertImgSrc: currentImage,
    };

    if (
      !DEFAULT_TEMPLATE_IDS.has(activeId) ||
      activeId === EARTHQUAKE_ALERT_TEMPLATE_ID ||
      activeId === FIRE_ALERT_TEMPLATE_ID
    ) {
      if (activeIndex === 0 && messageTemplateName.trim() === 'Blank') {
        setDuplicateTemplateMessage(f({ id: 'COM_DMS_TEMPLATE_EXISTS_MSG' }));
        return;
      } else if (
        activeIndex === 1 &&
        DEFAULT_TEMPLATE_NAMES.has(messageTemplateName.trim())
      ) {
        setDuplicateTemplateMessage(f({ id: 'COM_DMS_TEMPLATE_EXISTS_MSG' }));
        return;
      }

      if (!messageTemplateName.trim()) {
        setDuplicateTemplateMessage(
          f({ id: 'COM_DMS_EMPTY_TEMPLATE_NAME_MSG' })
        );
        return;
      }
      RestServiceUtils.HTTP_POST_WITHOUT_TOAST_ERROR(
        `${MESSAGE_ALERT_URL}/templateData/${
          activeIndex === 0 ? 'MESSAGE' : 'ALERT'
        }`,
        { ...payload, templateName: messageTemplateName.trim() }
      ).then(({ data }) => {
        const { errorMessage, errorCode } = data ?? {};
        if (errorCode) {
          setDuplicateTemplateMessage(errorMessage);
          return;
        }
        setResetFlag(false);
        let startIndex = 0;
        let pageSize = 5;
        if (activeIndex === 0) {
          if (currentMessagePageNo !== 0) {
            pageSize = 6;
            startIndex = 5 * currentMessagePageNo + (currentMessagePageNo - 1);
          }
        }
        if (activeIndex === 1) {
          if (currentAlertPageNo === 0) {
            pageSize = 3;
            startIndex = 0;
          } else {
            pageSize = 6;
            startIndex = 3 * (1 + (currentAlertPageNo - 1) * 2);
          }
        }
        RestServiceUtils.HTTP_POST(`${MESSAGE_ALERT_URL}/templateData`, {
          startIndex,
          pageSize,
          templateType: activeIndex === 0 ? 'MESSAGE' : 'ALERT',
        }).then(({ data }) => {
          const { items, totalCount } = data ?? [];
          setTotalNoOfTemplate(totalCount);
          if (activeIndex === 0) {
            if (messagePageNo === 0) {
              setTemplatesData([messageTemplate, ...items]);
            } else {
              setTemplatesData(items);
            }
            setActiveId(BLANK_MESSAGE_TEMPLATE_ID);
          } else if (activeIndex === 1) {
            if (alertPageNo === 0) {
              setTemplatesData([...alertTemplate, ...items]);
            } else {
              setTemplatesData(items);
            }
            setActiveId(BLANK_ALERT_TEMPLATE_ID);
          }
          setMessageTemplateName('');
          showSuccessToast(f({ id: 'COM_DMS_NEW_TEMPLATE_TXT' }));
          setSelectedTemplateData({
            ...INITIAL_SELECTED_TEMPLATE,
            backgroundColor: '#efefef',
            templatePosition: templatePositionOptions[2],
            templateMessage: messageTemplate.templateMessage,
          });
        });

        setDuplicateTemplateMessage('');
        setOpenMessageTemplateSaveAs(false);
      });
    }
  };

  const handleDeleteTemplate = (templateId) => {
    let startIndex = 0;
    let pageSize = 5;
    if (activeIndex === 0) {
      if (currentMessagePageNo !== 0) {
        pageSize = 6;
        startIndex = 5 * currentMessagePageNo + (currentMessagePageNo - 1);
      }
    }
    if (activeIndex === 1) {
      if (currentAlertPageNo === 0) {
        pageSize = 3;
        startIndex = 0;
      } else {
        pageSize = 6;
        startIndex = 3 * (1 + (currentAlertPageNo - 1) * 2);
      }
    }
    const payload = {
      startIndex,
      pageSize,
      templateType: activeIndex === 0 ? 'MESSAGE' : 'ALERT',
    };
    RestServiceUtils.HTTP_DELETE(`${MESSAGE_ALERT_URL}/templateData`, {
      templateId,
      templateType: activeIndex === 0 ? 'MESSAGE' : 'ALERT',
    }).then(() => {
      RestServiceUtils.HTTP_POST(
        `${MESSAGE_ALERT_URL}/templateData`,
        payload
      ).then(({ data }) => {
        const { items, totalCount } = data ?? [];
        setTotalNoOfTemplate(totalCount);
        if (activeIndex === 0) {
          if (messagePageNo === 0) {
            setTemplatesData([messageTemplate, ...items]);
          } else {
            setTemplatesData(items);
          }
        } else if (activeIndex === 1) {
          if (alertPageNo === 0) {
            setTemplatesData([...alertTemplate, ...items]);
          } else {
            setTemplatesData(items);
          }
        }
        if (items.length > 0) {
          setActiveId(items[0].templateId);
          setSelectedTemplateData(mapTemplateValueToCurrentData(items[0]));
        } else if (messagePageNo === 0 || alertPageNo === 0) {
          if (activeIndex === 0 && messagePageNo === 0) {
            setActiveId(BLANK_MESSAGE_TEMPLATE_ID);
            setSelectedTemplateData(messageTemplate);
          } else if (activeIndex === 1 && alertPageNo === 0) {
            setActiveId(BLANK_ALERT_TEMPLATE_ID);
            setImageUrlFile({
              imageUrl: BLANK_IMG_BASE64_URL,
              imageName: '',
            });
            setSelectedTemplateData(alertTemplate[0]);
          }
        }
        setIsDeleteTemplate(false);
      });
    });
  };

  const dialogState = {
    header: f({ id: 'COM_DMS_DELETE_TEMPLATE' }),
    body: f({ id: 'COM_DMS_DELETE_TEMPLATE_TEXT' }),
    footer: '',
    action: f({ id: 'COM_DMS_YES' }),
    cancel: f({ id: 'COM_DMS_NO' }),
  };

  const [isDeleteTemplate, setIsDeleteTemplate] = useState(true);
  const [currentTemplateId, setCurrentTemplateId] = useState(-5);

  const handlePopupAction = () => {
    handleDeleteTemplate(currentTemplateId);
  };

  const handleClickReset = () => {
    setIsCommonImage(false);
    setImageSize(0);
    setResetFlag(false);
    setImageDimensions(INITIAL_DIMENSIONS);
    if (DEFAULT_TEMPLATE_IDS.has(activeId)) {
      if (activeIndex === 0) {
        setSelectedTemplateData(mapTemplateValueToCurrentData(messageTemplate));
      } else {
        const currentAlertTemplate = alertTemplate.filter(
          (template) => template.templateId === activeId
        )[0];
        setSelectedTemplateData(
          mapTemplateValueToCurrentData(currentAlertTemplate)
        );
      }
    } else {
      const selectedTemplateInitData = templatesData?.filter(
        (template) => template.templateId === activeId
      )[0];
      const { alertImgName, alertImgSrc } = selectedTemplateInitData ?? {};
      setImageUrlFile(() => ({
        imageUrl: alertImgSrc,
        imageName: alertImgName,
      }));
      setSelectedTemplateData(
        mapTemplateValueToCurrentData(selectedTemplateInitData)
      );
    }
  };

  const handleSelectDeviceRightSideComponentHeader = () => {
    const todaysDate = new Date(Date.now());
    const endTimeDuration = [
      { label: f({ id: 'COM_DMS_ONE_DAY' }), value: 1 },
      { label: f({ id: 'COM_DMS_TWO_DAYS' }), value: 2 },
      { label: f({ id: 'COM_DMS_CUSTOM' }), value: 3 },
    ];

    return (
      <div className="select_device_end_time">
        <div className="select_device_end_time_text">
          {f({ id: 'COM_DMS_END_TIME' })}
        </div>
        {endTimeDuration.map(({ label, value }, index) => (
          <div key={index}>
            <RadioButton
              inputId={index}
              name={label}
              value={value}
              onChange={(e) => {
                if (e.value !== 3) setDateTime12h(null);
                setCurrentEndTimeDuration(e.value);
              }}
              checked={currentEndTimeDuration === value}
            />
            <label
              htmlFor={index}
              className="select_device_end_time_label_text"
            >
              {label}
            </label>
          </div>
        ))}
        <Calendar
          className="select_device_calendar"
          value={datetime12h}
          onChange={(e) => setDateTime12h(e.value)}
          showTime
          minDate={todaysDate}
          readOnlyInput
          disabled={
            currentEndTimeDuration === 1 || currentEndTimeDuration === 2
          }
          hourFormat="12"
        />
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} position="top-center" />
      <div className="device-messaging-component">
        <CustomDialog
          dialogState={dialogState}
          handleClick={handlePopupAction}
          dialogVisible={isDeleteTemplate && currentTemplateId > 0}
          setDialogVisible={setIsDeleteTemplate}
        />
        {openAddToTemplate && (
          <Overlay className="overlay-opacity">
            <AddToTemplatePopup
              setOpenAddToTemplate={setOpenAddToTemplate}
              setTemplateName={setMessageTemplateName}
              templateName={messageTemplateName}
              handleAddTemplate={handleAddTemplate}
              templateMessageError={duplicateTemplateMessage}
              setTemplateMessageError={setDuplicateTemplateMessage}
            />
          </Overlay>
        )}
        {openMessageTemplateSaveAs && (
          <Overlay className="overlay-opacity">
            <AddToTemplatePopup
              templateMessageError={duplicateTemplateMessage}
              setTemplateMessageError={setDuplicateTemplateMessage}
              setOpenAddToTemplate={setOpenMessageTemplateSaveAs}
              setTemplateName={setMessageTemplateName}
              templateName={messageTemplateName}
              handleAddTemplate={handleClickSaveAs}
            />
          </Overlay>
        )}

        <Dialog
          visible={sendAlertFlag}
          position="right"
          draggable={false}
          header={deviceComponentHeader}
          className="select-device-component-dialog"
        >
          <SelectDeviceComponent
            appPackage={''}
            jsonGroupTree={jsonGroupTree}
            activeTabIndex={activeTabIndex}
            deviceParentIds={deviceParentIds}
            setJsonGroupTree={setJsonGroupTree}
            sendAction={handleSendAlertMessage}
            setActiveTabIndex={setActiveTabIndex}
            selectedGroupKeys={selectedGroupKeys}
            setDeviceParentIds={setDeviceParentIds}
            selectedCheckedKeys={selectedCheckedKeys}
            selectDeviceHeader={deviceComponentHeader}
            setSelectedGroupKeys={setSelectedGroupKeys}
            setSelectedCheckedKeys={setSelectedCheckedKeys}
            emptyTargetDeviceId={'COM_DMS_SELECT_TARGET_DEVICE'}
            handleSelectDeviceRightSideComponentHeader={
              handleSelectDeviceRightSideComponentHeader
            }
          />
        </Dialog>
        <div className="device-messaging-left-content">
          <div className="device-messaging-left-content-header">
            {f({ id: 'COM_DMS_TEMPLATE_LIST' })}
          </div>
          <div className="device-messaging-left-content-body">
            {leftTabContent()}
            <div className="device-messaging-left-content-pagination">
              <Paginator
                totalRecords={
                  activeIndex === 0
                    ? totalNoOfTemplates + 1
                    : totalNoOfTemplates + 3
                }
                onPageChange={onPageChange}
                rowsPerPageOptions={[10, 20, 30]}
                first={activeIndex === 0 ? messagePageNo : alertPageNo}
                rows={activeIndex === 0 ? messageRowsPerPage : alertRowsPerPage}
              />
            </div>
          </div>
        </div>
        <div className="device-messaging-right-content">
          <div className="device-messaging-right-content-header">
            <button onClick={handleClickReset} disabled={!resetFlag}>
              {f({ id: 'COM_DMS_RESET_TEXT' })}
            </button>
            {activeId !== EARTHQUAKE_ALERT_TEMPLATE_ID &&
              activeId !== FIRE_ALERT_TEMPLATE_ID && (
                <button
                  className="add-template-btn"
                  onClick={() => {
                    if (
                      activeId === BLANK_MESSAGE_TEMPLATE_ID ||
                      activeId === BLANK_ALERT_TEMPLATE_ID
                    ) {
                      setOpenAddToTemplate(true);
                    } else {
                      handleAddTemplate();
                    }
                  }}
                  disabled={
                    (activeIndex === 0 &&
                      !selectedTemplateData.templateMessage) ||
                    (activeIndex === 1 && IMAGE_RESOLUTION)
                  }
                >
                  {f({ id: 'COM_DMS_SAVE' })}
                </button>
              )}
            <button
              className="add-template-btn"
              onClick={() => {
                setOpenMessageTemplateSaveAs(true);
              }}
              disabled={
                activeId === BLANK_MESSAGE_TEMPLATE_ID ||
                activeId === BLANK_ALERT_TEMPLATE_ID ||
                (activeIndex === 0 && !selectedTemplateData.templateMessage) ||
                (activeIndex === 1 && IMAGE_RESOLUTION)
              }
            >
              {f({ id: 'COM_DMS_SAVE_AS' })}
            </button>

            <button
              onClick={() => setSendAlertFlag(true)}
              disabled={
                (activeId === BLANK_ALERT_TEMPLATE_ID && !resetFlag) ||
                (activeIndex === 0 && !selectedTemplateData.templateMessage) ||
                (activeId !== BLANK_ALERT_TEMPLATE_ID &&
                  activeIndex === 1 &&
                  (!selectedTemplateData.templateMessage ||
                    !(imageUrlFile.imageName || isCommonImage))) ||
                IMAGE_RESOLUTION
              }
            >
              {f({ id: 'COM_DMS_SEND' })}
            </button>
          </div>
          <div
            className={
              !alert
                ? 'emergency-messaging-window'
                : 'emergency-messaging-window emergency-alert-messaging-window'
            }
          >
            {!alert
              ? textPositionDisplay(selectedTemplateData)
              : alertDisplay(
                  selectedTemplateData,
                  imageUrlFile,
                  isCommonImage,
                  COMMOM_ALERT_IMAGE
                )}
          </div>
          <div className="device-messaging-right-footer">
            <div className="device-messaging-right-footer-textarea"></div>
            <div className="device-messaging-right-footer-properties">
              <textarea
                placeholder={
                  alert
                    ? f({ id: 'COM_DMS_ALERT_MESSAGE_TEXT' })
                    : f({ id: 'COM_DMS_MESSAGE_TEXT' })
                }
                maxLength={activeIndex === 0 ? 200 : 100}
                className="messaging-text-area-box"
                value={selectedTemplateData.templateMessage}
                onChange={(e) => {
                  setResetFlag(true);
                  setCurrentTemplateValue('templateMessage', e.target.value);
                }}
              />

              {!alert ? (
                <div className="device-message-position">
                  <div className="message-position-text">
                    {f({ id: 'COM_DMS_POSITION' })}
                  </div>
                  <div>
                    <Dropdown
                      optionLabel="name"
                      editable={false}
                      className="position-dropdown"
                      value={selectedTemplateData.templatePosition}
                      options={templatePositionOptions}
                      onChange={(e) => {
                        setResetFlag(true);
                        setCurrentTemplateValue('templatePosition', e.value);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="device-upload-image">
                  <div className="upload-image">
                    <div className="upload-image-text">
                      {f({ id: 'COM_DMS_UPLOAD_IMAGE' })}
                    </div>
                    <div>
                      <input
                        type="file"
                        onChange={onFileChange}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        accept="image/png,image/jiff,image/jpeg,image/jpg,image/svg"
                      />
                      <div className="upload-image-button">
                        <div
                          className={
                            activeIndex === 1 && IMAGE_RESOLUTION
                              ? 'upload-image-btn-text error-boundaries'
                              : 'upload-image-btn-text'
                          }
                        >
                          {isCommonImage
                            ? 'common-alert-img.svg'
                            : imageUrlFile.imageName ||
                              f({ id: 'COM_DMS_CHOOSE_FILE' })}
                        </div>
                        {imageUrlFile.imageName ? (
                          <Tooltip
                            target=".upload-image-btn-text"
                            position="top"
                          >
                            <div className="infoIcon_tooltipContent upload-image-tooltip">
                              {isCommonImage
                                ? 'common-alert-img.svg'
                                : imageUrlFile.imageName}
                            </div>
                          </Tooltip>
                        ) : (
                          <div className="infoIcon_tooltipContent upload-image-tooltip">
                            {imageUrlFile.imageName}
                          </div>
                        )}

                        <button
                          className={
                            activeIndex === 1 && IMAGE_RESOLUTION
                              ? 'browse-file-btn error-boundaries'
                              : 'browse-file-btn'
                          }
                          onClick={handleUploadFile}
                          disabled={isCommonImage}
                        >
                          {f({ id: 'COM_DMS_BROWSE_FILE' })}
                        </button>
                      </div>
                    </div>
                    <div
                      className={
                        activeIndex === 1 && IMAGE_RESOLUTION
                          ? 'upload-img upload-img-error'
                          : 'upload-img'
                      }
                    >
                      {f({ id: 'COM_DMS_MAX_RESOLUTION_TEXT' })}
                    </div>
                  </div>
                  <div className="common-alert-image">
                    <div className="common-alert-image-text">
                      {f({ id: 'COM_DMS_COMMON_ALERT_IMAGE' })}
                    </div>
                    <div className="common-alert-image-checkbox">
                      <Checkbox
                        checked={isCommonImage}
                        onChange={() => {
                          setResetFlag(true);
                          setIsCommonImage(!isCommonImage);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="messaging-font">
                <div className="messaging-font-size">
                  <div>{f({ id: 'COM_DMS_FONT_SIZE' })}</div>
                  <div>
                    <Dropdown
                      value={selectedTemplateData.textSize}
                      optionLabel="name"
                      editable={false}
                      options={fontOptions}
                      className="font-dropdown"
                      onChange={(e) => {
                        setResetFlag(true);
                        setCurrentTemplateValue('textSize', e.value);
                      }}
                    />
                  </div>
                </div>
                <div className="select-text">
                  <div
                    className={
                      selectedTemplateData.textStyle === 'BOLD'
                        ? 'selected-text'
                        : ''
                    }
                    onClick={() => {
                      setResetFlag(true);
                      if (selectedTemplateData.textStyle !== 'BOLD')
                        setCurrentTemplateValue('textStyle', 'BOLD');
                      else setCurrentTemplateValue('textStyle', 'PLAIN');
                    }}
                  >
                    <img
                      src={BoldTextIcon}
                      alt="icon"
                      className="icon"
                      style={{ marginTop: '0.5rem', cursor: 'pointer' }}
                    />
                  </div>
                  <div
                    className={
                      selectedTemplateData.textStyle === 'ITALIC'
                        ? 'selected-text'
                        : ''
                    }
                    onClick={() => {
                      setResetFlag(true);
                      if (selectedTemplateData.textStyle !== 'ITALIC')
                        setCurrentTemplateValue('textStyle', 'ITALIC');
                      else setCurrentTemplateValue('textStyle', 'PLAIN');
                    }}
                  >
                    <img
                      src={ItalicTextIcon}
                      alt="icon"
                      className="icon"
                      style={{ marginTop: '0.5rem', cursor: 'pointer' }}
                    />
                  </div>
                </div>
                <div className="font-color">
                  <div className="color-text">
                    {f({ id: 'COM_DMS_FONT_COLOR' })}
                  </div>
                  <div
                    className="color"
                    style={{ background: selectedTemplateData.textColor }}
                  >
                    {fontColorFlag && (
                      <ColorBox
                        type="textColor"
                        colorCodes={colorCodes}
                        setResetFlag={setResetFlag}
                        setColorCode={setCurrentTemplateValue}
                      />
                    )}
                  </div>
                  <img
                    ref={fontColorRef}
                    src={DownArrowIcon}
                    alt="down"
                    className="down-arrow"
                    onClick={() => {
                      setFontColorFlag(!fontColorFlag);
                    }}
                  />
                </div>
                <div className="text-background-color">
                  <div className="background-color-text">
                    {f({ id: 'COM_DMS_BACKGROUND_COLOR' })}
                  </div>
                  <div
                    className="background-color"
                    style={{ background: selectedTemplateData.backgroundColor }}
                  >
                    {backgroundColorFlag && (
                      <ColorBox
                        type="backgroundColor"
                        setResetFlag={setResetFlag}
                        colorCodes={backgroundColorCodes}
                        setColorCode={setCurrentTemplateValue}
                      />
                    )}
                  </div>
                  <img
                    ref={backgroundColorRef}
                    src={DownArrowIcon}
                    alt="down"
                    className="down-arrow"
                    onClick={() => {
                      setBackgroundColorFlag(!backgroundColorFlag);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceMessagingTab;
