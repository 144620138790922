export const cleanJSONTree = (jsonTree) => {
  if (Array.isArray(jsonTree)) {
    return jsonTree
      .map((item) => cleanJSONTree(item))
      .filter((item) => item !== null);
  } else if (typeof jsonTree === "object" && jsonTree !== null) {
    const cleanJSONTreeObj = {};
    for (const [k, v] of Object.entries(jsonTree)) {
      const cleanVal = cleanJSONTree(v);
      if (cleanVal !== null) {
        cleanJSONTreeObj[k] = cleanVal;
      }
    }
    return Object.keys(cleanJSONTreeObj).length ? cleanJSONTreeObj : null;
  } else return jsonTree;
};

export const removeNullValuesFromNestedObjectOfArray = (jsonObject) => {
  if (Array.isArray(jsonObject)) {
    return jsonObject
      ?.map(removeNullValuesFromNestedObjectOfArray)
      ?.filter((data) => data !== null && data !== undefined);
  } else if (
    typeof jsonObject === "object" &&
    jsonObject !== null &&
    jsonObject !== undefined
  ) {
    const newJSONObject = {};
    for (const [k, v] of Object.entries(jsonObject)) {
      const updatedValues = removeNullValuesFromNestedObjectOfArray(v);
      if (updatedValues !== null && updatedValues !== undefined) {
        newJSONObject[k] = updatedValues;
      }
    }
    return Object.keys(newJSONObject).length > 0 ? newJSONObject : null;
  } else return jsonObject;
};
