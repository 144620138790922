import React from "react";
import Overlay from "../Overlay/Overlay";
import './Spinner.css'

const Spinner = () => {
  return (
    <Overlay>
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    </Overlay>
  );
};

export default Spinner;
