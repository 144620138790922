import React from "react";
import "./WarningMessage.css";

const WarningMessage = ({ text, className }) => {
  return (
    <div
      className={className ? `warning-message ${className}` : `warning-message`}
    >
      {text}
    </div>
  );
};

export default WarningMessage;
