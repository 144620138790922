
import { EVENT_LOG_API } from "../config/constants/Constants";
import { RestServiceUtils } from "../utils/RestServiceUtils";
import SetAuthToken from "../utils/SetAuthToken";
const getEventLogsService = (payload) => {
  const user = JSON.parse(localStorage.getItem("user"));
  SetAuthToken(user);
  return RestServiceUtils.HTTP_POST(`${EVENT_LOG_API}/filter`, payload);
  };

  const getEventLogExcelService = (payload) => {
    const user = JSON.parse(localStorage.getItem("user"));
    SetAuthToken(user);
    return RestServiceUtils.HTTP_POST(`${EVENT_LOG_API}/export`, payload,{responseType: 'arraybuffer'});
  }
  export {getEventLogsService, getEventLogExcelService};

