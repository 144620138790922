import axios from "axios";
import { API_KEY } from "../config/constants/Constants";
import { LOGIN_PATH } from '../router/RouteConstants';

const SetAuthToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user === undefined || user === null) {
    window.location.href = `${window.location.pathname}${LOGIN_PATH}`;
    localStorage.clear();
  } else {
    const { token } = user ?? {};
    if (token) {
      localStorage.setItem("loginUserId", user.userId);
      axios.defaults.headers.common[API_KEY] = token;
    } else {
      delete axios.defaults.headers.common[API_KEY];
    }
  }
};

export default SetAuthToken;