import React from 'react';
import './AppTable.css';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { useIntl } from 'react-intl';

const AppTable = ({
  columns,
  data,
  first,
  onPageChange,
  rows,
  totalCount,
  className,
  hidePagination,
  emptyMessageId,
  tabClassName,
  pageClassName,
}) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  return (
    <div className={tabClassName || 'app_table'}>
      <DataTable
        value={data}
        scrollHeight="66vh"
        scrollable={true}
        className={className}
        emptyMessage={f({ id: emptyMessageId })}
      >
        {columns.map((col) => (
          <Column {...col} />
        ))}
      </DataTable>
      {!hidePagination && (
        <div
          className={
            pageClassName
              ? `${pageClassName} custom-paginator `
              : 'custom-paginator'
          }
        >
          <Paginator
            first={first}
            rows={rows}
            totalRecords={totalCount}
            onPageChange={onPageChange}
            rowsPerPageOptions={[10, 20, 30, 50]}
            className="app-install-paginator"
          />
        </div>
      )}
    </div>
  );
};

export default AppTable;
