import React, { useState , useEffect, useRef } from 'react';
import './EmergencyMessaging.css';
import { TabPanel, TabView } from 'primereact/tabview';
import DeviceMessagingTab from './DeviceMessagingTab/DeviceMessagingTab';
import MessagingHistory from './MessagingHistory/MessagingHistory';

const EmergencyMessaging = ({ refreshPage }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [trigger, setTrigger] = useState(true);
  const initialRender = useRef(true)

  useEffect(() => {
    if(initialRender.current){
      initialRender.current = false;
      return;
    }
    setActiveIndex(0)
    setTrigger(!trigger)
  } , [refreshPage])

  return (
    <TabView
      className="device-messaging-tab"
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
    >
      <TabPanel header="Message" className="messaging">
        <DeviceMessagingTab activeIndex={activeIndex} trigger={trigger} />
      </TabPanel>
      <TabPanel header="Alert" className="alert">
        <DeviceMessagingTab alert activeIndex={activeIndex} trigger={trigger} />
      </TabPanel>
      <TabPanel header="History" className="history">
        <MessagingHistory />
      </TabPanel>
    </TabView>
  );
};

export default EmergencyMessaging;
