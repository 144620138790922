import React, { useEffect, useState } from 'react';
import './Data.css';
import { ProgressBar } from 'primereact/progressbar';
import { RadioButton } from 'primereact/radiobutton';
import { SemiCircleProgress } from 'react-semicircle-progressbar';
import Spinner from '../../Spinner/Spinner';

const Data = ({ details, loading }) => {

const timeFrames = [
    { name: `Last 7 Days`, key: 'last7' },
    { name: `Last 30 Days`, key: 'last30' }];
    
const [availableRAM, setAvailableRAM] = useState(0.0);
const [usedRAM, setUsedRAM] = useState(0.0);
const [totalRAM, setTotalRAM] = useState(0.0);
const [availableMemory, setAvailableMemory] = useState(0.0);
const [usedMemory, setUsedMemory] = useState(0.0);
const [totalMemory, setTotalMemory] = useState(0.0);
const [customUsage, setCustomUsage] = useState(0.0);
const [ todayUsage, setTodayUsage ] = useState(0.0);
const [yesterdayUsage, setYesterdayUsage ] = useState(0.0);
const [usageTime, setUsageTime] = useState(timeFrames[0]);
    
useEffect(() => {
    if (usageTime.key === 'last7') {
        setCustomUsage(details?.deviceUsageInfo?.last7DaysUsage);
    } else if (usageTime.key === 'last30') {
        setCustomUsage(details?.deviceUsageInfo?.last30DaysUsage);
    }
}, [usageTime, details]);

useEffect(() => {
    if (usageTime.key === 'last7') {
        setCustomUsage(details?.deviceUsageInfo?.last7DaysUsage);
    } else if (usageTime.key === 'last30') {
        setCustomUsage(details?.deviceUsageInfo?.last30DaysUsage);
    }
}, []);
    
useEffect(() => {
    setTodayUsage(details?.deviceUsageInfo?.todayUsage);
    setYesterdayUsage(details?.deviceUsageInfo?.yesterdayUsage);
},[details]);

useEffect(() => {
    setAvailableRAM((((details?.deviceHardwareInfo?.memoryAvailableSize / 1024) / 1024) / 1024).toFixed(1));
    setUsedRAM((((details?.deviceHardwareInfo?.memoryUsageSize / 1024) / 1024) / 1024).toFixed(1));
    setAvailableMemory((((details?.deviceHardwareInfo?.diskAvailableSize / 1024) / 1024) / 1024).toFixed(1));
    setUsedMemory((((details?.deviceHardwareInfo?.diskUsageSize / 1024) / 1024) / 1024).toFixed(1));
}, []);
    
useEffect(() => {
    setTotalRAM((parseFloat(availableRAM) + parseFloat(usedRAM)).toFixed(1));
    setTotalMemory((parseFloat(availableMemory) + parseFloat(usedMemory)).toFixed(1));
}, [availableRAM, usedRAM]);

  return (
    <>
    {loading ? <Spinner /> : ''}
      <div className="device_data_tab">
        <div className="data_upper">
            <div className="data_upper_usage_left">
                <div className="custom_usage">
                    <div className="customUsage_text">Usage</div>
                    <div className="data_header_right">
                        {timeFrames.map((category) => {
                            return (
                            <div key={category.key} className="flex align-items-center">
                                <RadioButton className="timeOptions" inputId={category.key} name="category" value={category} onChange={(e) => setUsageTime(e.value)} checked={usageTime.key === category.key}/>
                                    <label htmlFor={category.key} className="actionLabels">{category.name}</label>
                            </div>
                            );
                        })}
                    </div>
                    </div>
                    <div className="line_text"/>
                <div className="totalUsage">
                    <div className="usageContent1">Total</div>
                          <div className="usageContent2">{isNaN(customUsage) ? parseFloat(0.0) : (parseFloat(customUsage)/60).toFixed(1)} <span>h.</span></div>
                </div>
                <div className="line_text_2"/>
                <div className="averageUsage">
                <SemiCircleProgress
                        percentage={isNaN(customUsage) ? 0 : ((parseFloat(customUsage/60)/(usageTime.key === 'last7' ? (24 * 7) : (24 * 30))) * 100).toFixed(1)}
                        size={{
                            width: 350,
                            height: 350,
                        }}
                        strokeWidth={8}
                        strokeColor="rgb(42, 93, 120)"
                        strokeLinecap="square"
                        hasBackground={true}
                />
               <span style={{textAlign: "center"}}>
               <span className="setCustomContentCount">{isNaN(customUsage) ? parseFloat(0.00) : (parseFloat((customUsage/60) / (usageTime.key === 'last7' ? 7 : 30))).toFixed(2)} <span>h. per day</span>
                    <br />
                    <span id="avgText">Average</span>
                </span></span> 
                </div>
            </div>
            <div className="data_upper_usage_right">
                <div className="usage_1">
                    <div className="usage_text">Today's Usage</div>
                    <SemiCircleProgress
                        percentage={isNaN(todayUsage) ? 0 : ((parseFloat((todayUsage > 1440 ? 1440 : todayUsage)/60).toFixed(1)/24) * 100)}
                        size={{
                            width: 250,
                            height: 250,
                        }}
                        strokeWidth={8}
                        strokeColor="rgb(24, 144, 194)"
                        strokeLinecap="square"
                        hasBackground={true}
                    />
                    <span className="setContentCount">{isNaN(todayUsage) ? parseFloat(0.0) : parseFloat(todayUsage/60).toFixed(1)} <span>h.</span></span>
                </div>
                <div className="usage_2">
                    <div className="usage_text">Yesterday's Usage</div>
                    <SemiCircleProgress
                        percentage={isNaN(yesterdayUsage) ? 0 : ((parseFloat((yesterdayUsage > 1440 ? 1440 : yesterdayUsage)/60).toFixed(1)/24) * 100)}
                        size={{
                            width: 250,
                            height: 250,
                        }}
                        strokeWidth={8}
                        strokeColor="rgb(113, 197, 221)"
                        strokeLinecap="square"
                        hasBackground={true}
                    />
                   <span className="setContentCount">{isNaN(yesterdayUsage) ? parseFloat(0.0) : parseFloat(yesterdayUsage/60).toFixed(1)} <span>h.</span></span>
                </div>
            </div>
        </div>
        <div className="data_lower">
        <div className="data_lower_usage">
            <div className="resourceUsage">
                <div className="resourceTypeLayout">
                    <div>
                        <div className="resourceInfoPos">
                            <span className="resourceInfo">RAM</span>
                            <br />
                                      <span className="resourceInfo r_info">{isNaN(usedRAM) ? 0 : usedRAM}GB / {isNaN(totalRAM) ? 0 : totalRAM}GB</span>
                        </div>
                        <div className="card resourceCard ram">
                            <ProgressBar value={((usedRAM/totalRAM) * 100).toFixed(1)}></ProgressBar>
                        </div>
                    </div>
                </div>
                <div className="resourceTypeLayout">
                <div>
                        <div className="resourceInfoPos">
                            <span className="resourceInfo">Memory</span>
                            <br />
                                <span className="resourceInfo r_info">{isNaN(usedMemory) ? 0 : usedMemory}GB / {isNaN(totalMemory) ? 0 : totalMemory}GB</span>
                        </div>
                        <div className="card resourceCard mem">
                            <ProgressBar value={((usedMemory/totalMemory) * 100).toFixed(1)}></ProgressBar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </div>
    </>
  )
}

export default Data;
