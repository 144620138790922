import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { authenticate } from "../utils/Authenticate";
import { LOGIN_PATH } from "./RouteConstants";

const PrivateRoute = () => {
  const isAuthenticated = authenticate();
  return <>{isAuthenticated ? <Outlet /> : <Navigate to={LOGIN_PATH} />}</>;
};

export default PrivateRoute;
