import React, { useState, useRef } from 'react';
import './UploadFirmwarePopup.css';
import Overlay from '../../../components/Overlay/Overlay';
import { RestServiceUtils } from '../../../utils/RestServiceUtils';
import { firmwareNameMaxLength } from '../../../../src/utils/Validations';
import { FIRMWARE_URL } from '../../../config/constants/Constants';
import { useIntl } from 'react-intl';
import { showSuccessToast } from '../../../services/APIResponseHandler';

const UploadFirmwarePopup = ({
  setClickedUploadFirmware,
  setFirmwareUploadPercentage,
  setFirmwareData,
  setIsFwUploaded,
  pageSize,
}) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const fileInputRef = useRef(null);

  const handleUploadApk = () => {
    fileInputRef.current.click();
  };

  const [firmwareVersionName, setFirmwareVersionName] = useState('');
  const [firmwareVersionError, setFirmwareVersionError] = useState(false);
  const [isFirmwareNameEmpty, setIsFirmwareNameEmpty ] = useState(false);
  const [isFirmwarePresent , setIsFirmwarePresent ] = useState(true);
  const [uploadedFile, setUploadedFile] = useState('');

  const handleUpdateFirmware = () => {
    if(!firmwareVersionName.trim()){
      setIsFirmwareNameEmpty(true);
      setFirmwareVersionName('');
      return;
    }
    if(!uploadedFile){
      setIsFirmwarePresent(false);
      return;
    }
    RestServiceUtils.HTTP_GET(`${FIRMWARE_URL}/availableFwName`, {
      fwName: firmwareVersionName.trim(),
    }).then(({ data }) => {
      if (data === 'available') {
        setIsFwUploaded(true);
        RestServiceUtils.HTTP_POST(
          `${FIRMWARE_URL}/upload`,
          { file: uploadedFile },
          {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const percentage = Math.round((loaded * 100) / total);
              setFirmwareUploadPercentage(percentage);
            },
            headers: {
              'Content-Type': 'multipart/form-data',
              fwName: firmwareVersionName.trim(),
            },
          }
        ).then(({ data }) => {
          const { errorCode } = data ?? {};
          if (errorCode) {
            setIsFwUploaded(false);
            setClickedUploadFirmware(false);
          } else {
            RestServiceUtils.HTTP_POST(`${FIRMWARE_URL}`, {
              startIndex: 0,
              pageSize,
            }).then(({ data }) => {
              const { items } = data;
              showSuccessToast(f({ id: 'COM_DMS_NEW_FIRMWARE_UPLOADED' }));
              setFirmwareData(items);
              setIsFwUploaded(false);
            });
          }
        });
        setClickedUploadFirmware(false);
      } else {
        setUploadedFile('');
        setFirmwareVersionError(true);
      }
    });
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedFile(file);
      setIsFirmwarePresent(true);
    };
  };
  return (
    <>
      <Overlay className="upload-firmware-overlay">
        <div className="upload-firmware-popup">
          <div className="upload-firmware-header">
            {f({ id: 'COM_DMS_UPLOAD_FIRMWARE' })}
          </div>
          <div className="upload-firmware-body">
            <div className="upload-firmware-input-field">
              <label
                htmlFor="upload-firmware-version"
                className={
                  firmwareVersionError ? 'upload-firmware-version-error' : ''
                }
              >
                {f({ id: 'COM_DMS_FIRMWARE_VERSION_NAME' })}
              </label>
              <input
                type="text"
                alt="firmware-version"
                value={firmwareVersionName}
                placeholder={f({ id: 'COM_DMS_FIRMWARE_VERSION_NAME' })}
                onChange={(e) => {
                  setFirmwareVersionError(false);
                  setIsFirmwareNameEmpty(false);
                  setFirmwareVersionName(e.target.value);
                }}
                className={
                  firmwareVersionError || isFirmwareNameEmpty ? 'firmware-version-input-error' : ''
                }
                style={{paddingLeft : '0.5rem' , marginLeft: '0.5rem'}}
                maxLength={firmwareNameMaxLength}
              />
              {firmwareVersionError && (
                <div className="upload-firmware-input-field-error">
                  Firmware with same firmware version is already present
                </div>
              )}
              {isFirmwareNameEmpty && (
                <div className="upload-firmware-input-field-error">
                  {f({ id: 'COM_DMS_EMPTY_FIRMWARE_NAME_MSG' })}   
                </div>
              )}
            </div>
            <div className="upload-firmware-file">
              <div className="upload-firmware-file-text">
                {f({ id: 'COM_DMS_UPLOAD' })}
              </div>
              <div className= {isFirmwarePresent ? "upload-firmware-file-browse" : "upload-firmware-file-browse firmware-file-not-available-error"} >
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={onFileChange}
                  onClick={(e) => (e.target.value = '')}
                  style={{ display: 'none' }}
                />
                <div>
                  {!uploadedFile
                    ? f({ id: 'COM_DMS_CHOOSE_FILE' })
                    : uploadedFile.name}
                </div>
                <button
                  className="upload-firmware-browse-btn"
                  onClick={handleUploadApk}
                >
                  {f({ id: 'COM_DMS_BROWSE_FILE' })}
                </button>
              </div>
            </div>
            {!isFirmwarePresent && (
                <div className="upload-firmware-not-available-error">
                  {f({ id: 'COM_DMS_SELECT_FIRMWARE_FILE_MSG' })}
                </div>
              )}
          </div>
          <div className="upload-firmware-footer">
            <button
              className="cancel-btn"
              onClick={() => {
                setClickedUploadFirmware(false);
                setFirmwareVersionError(false);
              }}
            >
              {f({ id: 'COM_DMS_CANCEL' })}
            </button>
            <button className="upload-btn" onClick={handleUpdateFirmware}>
              {f({ id: 'COM_DMS_UPLOAD' })}
            </button>
          </div>
        </div>
      </Overlay>
    </>
  );
};

export default UploadFirmwarePopup;
