export const privacyPolicyContent = `<html><head>
<title>Samsung Whiteboard DMS Privacy Policy</title>
</head><body><p align="center" style="text-align: center">
<span style="font-size: 10pt"><span><b><u><span lang="EN-US" style="font-size: 12.0pt"><span>Samsung Whiteboard DMS
                        Privacy Policy</span></span></u></b></span></span>
</p>
<p class="MsoBodyText">
<br>
</p>
<p class="MsoBodyText">
<span style="font-size: 12pt"><span><span lang="EN-US">Effective:&nbsp;
            August, 31<sup>st</sup>, 2023
    </span></span></span>
</p>
<p class="MsoBodyText">
<br>
</p>
<p class="MsoBodyText">
<span style="font-size: 12pt"><span><span lang="EN-US">Samsung</span><span>
            Electronics Co., Ltd. </span><span lang="EN-US">(“<b>Samsung</b>,”
    </span><span>) </span><span lang="EN-US">knows
            how important privacy is to its customers and their employees and
            partners, and we strive to be clear about how we collect, use,
            disclose, transfer and store your information.</span>&nbsp; <span>This
            Privacy Policy provides an overview of our information practices
            with respect to personal information </span><span>collected
            through </span><span lang="EN-US">Samsung Whiteboard DMS
            &nbsp;services </span><span>(the "</span><b><span lang="EN-US">Business Services</span></b><span>")</span><span lang="EN-US">.</span>&nbsp; </span></span>
</p>
<p class="MsoBodyText">
<br>
</p>
<p class="MsoBodyText">
<span style="font-size: 12pt"><span><span lang="EN-US">This
            Privacy Policy may be updated periodically to reflect changes in our
            personal information practices with respect to the Business Services
            or changes in the applicable law. We will indicate at the top of
            this Privacy Policy when it was most recently updated. If we update
            the Privacy Policy, we will let you know in advance about changes we
            consider to be material by placing a notice on the Business Services
            or by emailing you, where appropriate.</span></span></span>
</p>
<p class="MsoBodyText">
<br>
</p>
<p class="MsoBodyText">
<span style="font-size: 12pt"><span><b><span lang="EN-US">What information do we collect about you?</span></b></span></span>
</p>
<p style="text-align: justify">
<span style="font-size: 10pt"><span style="text-autospace: ideograph-numeric ideograph-other"><span style="word-break: keep-all"><span><span lang="EN-US" style="font-size: 12.0pt"><span>We may
                        collect various types of personal information in connection with
                        the Business Services.</span></span></span></span></span></span>
</p>
<p style="text-align: justify">
<br>
</p>
<p style="text-align: justify">
<span style="font-size: 10pt"><span style="text-autospace: ideograph-numeric ideograph-other"><span style="word-break: keep-all"><span><span lang="EN-US" style="font-size: 12.0pt"><span>For <a>example</a></span></span><span lang="EN-US" style="font-size: 12.0pt"><span>:</span></span></span></span></span></span>
</p>
<p style="text-align: justify">
<br>
</p>


<p>
<span style="font-size: 12pt; margin-left: 8px;"> • Server usage
    data, such as number of displays, users, contents, schedules,
    rulesets, playlists, datalink servers, remote control servers, and
    organizations;</span>
</p>
<p>
<span style="font-size: 12pt; margin-left: 8px;"> • Frequency
    data (i.e., how many times users have used server menus); </span>
</p>
<p>
<span style="font-size: 12pt; margin-left: 8px;"> • Software
    License Key </span>
</p>
<p>
<span style="font-size: 12pt; margin-left: 8px;"> • Content
    data, such as content type, size, resolution and edit history; </span>
</p>
<p>
<span style="font-size: 12pt; margin-left: 8px;"> • Device data,
    such as number of devices per organization/type, frequency of remote
    control, tag usage, edit history, and firmware version;</span>
</p>
<p>
<span style="font-size: 12pt; margin-left: 8px;"> • User data,
    such as number of users per organization, login history, number of
    roles, language, and edit history; and </span>
</p>
<p>
<span style="font-size: 12pt; margin-left: 8px;"> • Schedule
    data, such as number of schedules by type, device, date and content </span>
</p>


<p style="margin-left: 48px; text-align: justify">
<br>
</p>
<p class="MsoBodyText">
<span style="font-size: 12pt"><span style="page-break-after: avoid"><span><b><span lang="EN-US">How do we use your information?</span></b></span></span></span>
</p>
<p class="MsoBodyText">
<span style="font-size: 12pt"><span style="page-break-after: avoid"><span><span>We
                may use information we collect for the following <a>purposes</a>
        </span><span>: </span></span></span></span>
</p>



<p>
<span style="font-size: 12pt; margin-left: 8px;">• to provide
    better products and services;</span>
</p>
<p>
<span style="font-size: 12pt; margin-left: 8px;">• to respond to
    your questions or requests for information;</span>
</p>
<p>
<span style="font-size: 12pt; margin-left: 8px;">• to understand
    the way companies use the Business Services so that we can improve
    them and develop new products and services; </span>
</p>
<p>
<span style="font-size: 12pt; margin-left: 8px;">• to protect
    the rights, property, or safety of Samsung, or any of our respective
    affiliates, business partners, employees or customers, for example, in
    legal proceedings, internal investigations and investigations by
    competent authorities; and</span>
</p>
<p>
<span style="font-size: 12pt; margin-left: 8px;">• otherwise
    with your separate consent where required by applicable law or as
    described to at the time your information is collected.</span>
</p>



<p style="margin-left: 48px; text-indent: 0cm" class="MsoListBullet2">
<br>
</p>
<p style="text-indent: 0cm" class="MsoListBullet">
<br>
</p>
<p class="MsoBodyText">
<span style="font-size: 12pt"><span><b><span lang="EN-US">To whom do we disclose your information?</span></b></span></span>
</p>
<p style="text-align: justify">
<span style="font-size: 10pt"><span style="text-autospace: ideograph-numeric ideograph-other"><span style="word-break: keep-all"><span><span lang="EN-US" style="font-size: 12.0pt"><span>We do not
                        disclose your information to third parties for their own
                        independent marketing or business purposes without your
                        consent.&nbsp; But we may share your information with the
                        following <a>entities</a>
                </span></span><span lang="EN-US" style="font-size: 12.0pt"><span>:</span></span></span></span></span></span>
</p>
<p style="text-align: justify">
<br>
</p>


<p>
<span style="font-size: 12pt; margin-left: 8px;"> • Samsung
    affiliates; </span>
</p>
<p>
<span style="font-size: 12pt; margin-left: 8px;"> • third
    parties when necessary to provide you&nbsp;with requested products and
    services.&nbsp; For example, we may disclose your payment data to
    financial institutions as appropriate to process transactions that you
    have requested; </span>
</p>
<p>
<span style="font-size: 12pt; margin-left: 8px;"> • other
    parties: (i) to comply with the law or respond to compulsory legal
    process (such as a search warrant or other court order); (ii) to
    verify or enforce compliance with the policies governing our Business
    Services; (iii) to protect the rights, property, or safety of Samsung,
    or any of our respective affiliates, business partners, or customers;
    (iv) as part of a merger or transfer, or in the event of a bankruptcy;
</span>
</p>
<p>
<span style="font-size: 12pt; margin-left: 8px;"> • with other
    third parties when you consent to or request such sharing. </span>
</p>



<p style="margin-left: 48px; text-indent: 0cm" class="MsoListBullet2">
<br>
</p>
<p class="MsoBodyText">
<br>
</p>
<p class="MsoBodyText">
<span style="font-size: 12pt"><span style="page-break-after: avoid"><span><b><span lang="EN-US">What do we do to keep your
                    information secure?</span></b></span></span></span>
</p>
<p class="MsoBodyText">
<span style="font-size: 12pt"><span style="page-break-after: avoid"><span><span>We
                have put in place reasonable physical and technical measures to
                safeguard the information we collect in connection with the </span><span>Business
                Services</span><span lang="EN-US">.</span>&nbsp; <span>However,
                please note that although we take reasonable steps to protect your
                information, no website, Internet transmission, computer system or
                wireless connection is completely secure.&nbsp; </span></span></span></span>
</p>
<p class="MsoBodyText">
<br>
</p>
<p class="MsoBodyText">
<span style="font-size: 12pt"><span style="background-color: white"><span><b><span lang="EN-US">Can you access your information?</span></b></span></span></span>
</p>
<p class="MsoBodyText">
<span style="font-size: 12pt"><span style="background-color: white"><span><span>Under
                the laws of some jurisdictions, you may have the right to request
                details about the information we collect about you and to correct
                inaccuracies in that information.</span>&nbsp; <span>We
                may decline to process requests that are unreasonably repetitive,
                require disproportionate technical effort, jeopardize the privacy
                of others, are extremely impractical, or for which access is not
                otherwise required by local law.&nbsp; If you would like to make a
                request to access your information, please contact </span><span>us
                by the methods outlined in the <b>Contact</b> section of this
                Privacy Policy.
        </span></span></span></span>
</p>
<p class="MsoBodyText">
<br>
</p>
<p align="left" style="text-align: left">
<span style="font-size: 10pt"><span style="background-color: white"><span><span lang="EN-US" style="font-size: 12.0pt"><span>If
                    you request deletion of personal information, you acknowledge that
                    you may not be able to access or use the Business Services and
                    that residual personal information may continue to reside in
                    Samsung's records and archives for some time, but Samsung will not
                    use that information for commercial purposes.&nbsp; You understand
                    that, despite your request for deletion, Samsung reserves the
                    right to keep your personal information, or a relevant part of it,
                    if Samsung has suspended, limited, or terminated your access to
                    the website for violating the Samsung Terms of Use, when necessary
                    to </span></span><span lang="EN-US" style="font-size: 12.0pt"><span>protect
                    the rights, property, or safety of Samsung, or any of our
                    respective affiliates, business partners, employees or customers</span></span><span lang="EN-US" style="font-size: 12.0pt"><span>.</span></span></span></span></span>
</p>
<p class="MsoBodyText">
<br>
</p>
<p class="MsoBodyText">
<br>
</p>
<p class="MsoBodyText">
<span style="font-size: 12pt"><span><b><span lang="EN-US">Your Choices</span></b></span></span>
</p>
<p style="text-align: justify">
<span style="font-size: 10pt"><span><span lang="EN-US" style="font-size: 12.0pt"><span>We offer you certain
                choices in connection with the personal information we obtain about
                you.&nbsp; </span></span></span></span>
</p>
<p style="text-align: justify">
<span style="font-size: 10pt"><span><span lang="EN-US" style="font-size: 12.0pt"><span>To update your
                preferences or submit a request, please contact us as specified in
                the <b><u>Contact</u></b> section below.
        </span></span></span></span>
</p>
<p style="text-align: justify">
<br>
</p>
<p style="text-align: justify">
<span style="font-size: 10pt"><span><span lang="EN-US" style="font-size: 12.0pt"><span>The Business Services
                may offer choices related to the collection, deletion and sharing
                of certain information and communications related to the Business
                Services. If you decline to allow the Business Services to collect,
                store or share certain information, you may not be able to use all
                of the features available through the Business Services.</span></span></span></span>
</p>
<p class="MsoBodyText">
<br>
</p>
<p style="text-align: justify">
<br>
</p>
<p style="text-align: justify">
<span style="font-size: 10pt"><span style="text-autospace: ideograph-numeric ideograph-other"><span style="word-break: keep-all"><span><b><span lang="EN-US" style="font-size: 12.0pt"><span>Contact</span></span></b></span></span></span></span>
</p>
<p style="text-align: justify">
<span style="font-size: 10pt"><span style="text-autospace: ideograph-numeric ideograph-other"><span style="word-break: keep-all"><span><span lang="EN-US" style="font-size: 12.0pt"><span>If you have
                        any questions regarding this policy, please contact us at: </span></span><a><span lang="EN-US" style="font-size: 12.0pt"><span>ebtdisplay@samsung.com</span></span></a></span></span></span></span>
</p>

</body></html>`