import './Login.css';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import LoginCard from '../../components/LoginCard/LoginCard';
import CustomButton from '../../components/CustomButton/CustomButton';
import Visibility from '../../assets/images/visibility.svg';
import EyeCloseIcon from '../../assets/images/eyeClose.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getSMTPInfo, getUserAuth } from '../../redux/actions/AuthAction';
import {MfaVerificationPopup} from '../../components/PopUp/MfaVerificationPopup';
import {MfaRegistrationPopup} from '../../components/PopUp/MfaRegistrationPopup';
import SamsungDMSHeader from '../../components/SamsungDMSHeader/SamsungDMSHeader';
import {
  FORGOT_PWD_PATH,
  HOME_PATH,
  USER_AGREEMENT,
} from '../../router/RouteConstants';
import { GET_AUTH_USER_PENDING } from '../../redux/constants/AuthConstant';
import ErrorWarningMessage from '../../components/ErrorWarningMessage/ErrorWarningMessage';
import { useIntl } from 'react-intl';
import OkCard from '../../components/OkCard/OkCard';
import VisibilityHideIcon from '../../assets/images/visibilityHideIcon.svg';
import { Toast } from 'primereact/toast';
import FloatLabel from '../../components/FloatLabel';

const Login = () => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const [userid, setUserid] = useState('');
  const [password, setPassword] = useState('');
  const [hide, setHide] = useState(true);
  const [errors, setErrors] = useState({
    userid: '',
    password: '',
  });
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [touched, setTouched] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [showPolicyError, setShowPolicyError] = useState(false);
  const [ isOtpVerified, setIsOtpVerified]=useState(false);
  const [ showMfa , setShowMfa]= useState(false);
  const [registrationDone,setRegistrationDone]=useState(true);
  const [ secretKey, setSecretKey]= useState('');
  const [ qrCode,setQrCode]=useState('');
  const {
    isAuthenticated: isAuthenticatedServer,
    isSmtpEnabled: isSMTP,
    authErrorCode,
    errorDetails,
    isMfaEnabled
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const updateOtpVerificationStatus = (val)=>{
    setIsOtpVerified(val);
  }
  const showError = () => {
    toast.current.show({
      severity: 'error',
      summary: `${f({ id: 'COM_DMS_ERROR' })}`,
      detail: `${f({ id: 'COM_DMS_ERROR_MESSAGE' })}`,
      life: 2000,
    });
  };

  const formHandleSubmit = async (e) => {
    e.preventDefault();
    if (showPolicyError) {
      return;
    }
    setTouched(false);
    setShowPolicyError(false);
    if (!userid || !userid.trim()) {
      setUserid('');
      setErrors({
        userid: f({ id: 'COM_DMS_INVALID_USER_ID_TEXT' }),
      });
      return;
    }
    if (!password) {
      setPassword('');
      setErrors({
        password: f({ id: 'COM_DMS_INVALID_PWD_TEXT' }),
      });
      return;
    }
    if (!checked) {
      setShowPolicyError(true);
      setTimeout(() => {
        setShowPolicyError(false);
      }, 5000);
      return;
    }
    dispatch(getUserAuth({ loginId: userid, password }));   
  };

  useEffect(() => {
    dispatch({ type: GET_AUTH_USER_PENDING });
    dispatch(getSMTPInfo());
  }, [dispatch]);

  useEffect(() => {
    if (touched) {
      setErrors({ userid: '', password: '' });
    }
  }, [touched]);

  useEffect(() => {
    document
      .querySelector('.login-form .input #userid')
      ?.addEventListener('focus', () => {
        setTouched(true);
      });

    document
      .querySelector('.login-form .input #password')
      ?.addEventListener('focus', () => {
        setTouched(true);
      });
  });

  const changePasswordType = () => {
    if (password?.length === 0) {
      return;
    }
    if (passwordType === 'password') {
      setPasswordType('text');
    }
    if (passwordType === 'text') {
      setPasswordType('password');
    }
  };

  const handleClickForgotPwd = () => {
    setHide(false);
  };

  useEffect(() => {
    if(authErrorCode == 401011){
      setSecretKey(errorDetails.otpAuthInfo.secretKey);
      setQrCode(errorDetails.otpAuthInfo.qrCode)
      setRegistrationDone(false);
      setShowMfa(true);
    }
    if(authErrorCode == 401010){
      setRegistrationDone(true);
      setShowMfa(true);
    }
    if (
      authErrorCode !== '' &&
      authErrorCode !== undefined &&
      (authErrorCode === 401003 || authErrorCode === 500000)
    ) {
      showError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authErrorCode]);

  useEffect(() => {
    if (password?.length === 0) {
      setPasswordType('password');
    }
  }, [password]);

  if (isAuthenticatedServer) {
    if(!isMfaEnabled){
      return navigate(HOME_PATH);
    }
    else if(isOtpVerified){
      return navigate(HOME_PATH);
    }
  }
  const updateShowMfaPopup = (val) => {
    setShowMfa(val);
  }
  return (
    <> { !registrationDone && showMfa && isMfaEnabled &&(
      <>
      <SamsungDMSHeader />
      <MfaRegistrationPopup
      showMfa = {showMfa}
      updateOtpVerification = {updateOtpVerificationStatus}
      registrationCode = {secretKey}
      qrCode = {qrCode}
      userid = {userid}
      password = {password}
      setShowMfa={updateShowMfaPopup}
      />
      </>
    )}
       {registrationDone && showMfa && isMfaEnabled &&(
         <>
        <SamsungDMSHeader />
       <MfaVerificationPopup
        visible = {showMfa}
        updateOtpVerification = {updateOtpVerificationStatus}
        userid = {userid}
        password = {password}
        updateMfa={updateShowMfaPopup}
        />
        </>)}     
      
      <Toast ref={toast} />
      {!hide && (
        <OkCard
          headerText={f({ id: 'COM_DMS_MAIL_NOT_CONFIGURE_TEXT' })}
          text={f({ id: 'COM_DMS_PWD_RESET_ADMIN' })}
          handleOkClick={() => setHide(!hide)}
        />
      )}
      {(!showMfa) && <LoginCard>
        {errors.userid ? (
          <ErrorWarningMessage text={errors.userid} className="err-warning" />
        ) : errors.password ? (
          <ErrorWarningMessage text={errors.password} className="err-warning" />
        ) : showPolicyError ? (
          <ErrorWarningMessage
            text={f({ id: 'COM_DMS_AGREEMENT_POLICY' })}
            className="err-warning"
          />
        ) : authErrorCode == 401000 ? (
          <ErrorWarningMessage
            text={f({
              id: 'COM_DMS_LOGIN_UNAUTHORIZED_TEXT',
            })}
            className="err-warning"
          />
        ) : (
          authErrorCode == 401001 && (
            <ErrorWarningMessage
              text={f({
                id: 'COM_DMS_UNAUTHORIZED_USER_LOCKED',
              })}
              className="err-warning"
            />
          )
        )}
        <form onSubmit={formHandleSubmit} className="login-form">
          <div>{f({ id: 'COM_DMS_SIGN_IN' })}</div>
          <div className="input">
            <FloatLabel
              value={userid}
              label={f({ id: 'COM_DMS_USER_ID' })}
              className={userid ? 'float-input' : ''}
            >
              <input
                id="userid"
                type="text"
                value={userid}
                className={errors.userid ? 'error-boundary' : ''}
                onChange={(e) => setUserid(e.target.value)}
              />
            </FloatLabel>
          </div>
          <div className="input">
            <FloatLabel
              value={password}
              label={f({ id: 'COM_DMS_PASSWORD' })}
              className={password ? 'float-input' : ''}
            >
              <input
                id="password"
                value={password}
                type={passwordType}
                onChange={(e) => setPassword(e.target.value)}
                className={errors.password ? 'error-boundary' : ''}
              />
              <div className="visibility-icon" onClick={changePasswordType}>
              {passwordType === 'password' ? password?.length > 0 ?  (
                  <img src={EyeCloseIcon} alt="visibility" />
                ) : (
                  <img src={VisibilityHideIcon} alt="visibility" />
                ) : (
                  <img src={Visibility} alt="visibility" />
                )}
              </div>
            </FloatLabel>
          </div>

          <div className="login-agreement">
            {!showPolicyError ? (
              <Checkbox
                checked={checked}
                className="login-tooltip-checkbox"
                onChange={() => setChecked(!checked)}
              />
            ) : (
                <Checkbox
                  checked={checked}
                  className={`login-tooltip-checkbox p-invalid`}
                    onChange={() => { setChecked(!checked); setShowPolicyError(false); }}
                />
            )}
            <p className="tooltip-text">
              {f({ id: 'COM_DMS_I_ACCEPT' })}{' '}
              <Link
                className="link"
                to={USER_AGREEMENT}
                target="_blank"
                rel="noopener noreferrer"
              >
                {f({ id: 'COM_DMS_USER_AGREEMENT' })}
              </Link>.
            </p>
          </div>
          <CustomButton type="submit" text="Sign In" className="signin-btn" />
        </form>
        {isSMTP ? (
          <Link className="link forgot-password-link" to={FORGOT_PWD_PATH}>
            {f({ id: 'COM_DMS_FORGOT_PWD' })}
          </Link>
        ) : (
          <div className="link login-forgot-btn" onClick={handleClickForgotPwd}>
            {f({ id: 'COM_DMS_FORGOT_PWD' })}
          </div>
        )}
      </LoginCard>}
    </>
  );
};

export default Login;
