import React from "react";

import "./PasswordValidatorTooltip.css";
import { passwordMinLength } from "../../../utils/Validations";



const Tooltip = ({
  isAlNum,
  isOneCapital,
  isOneSpecial,
  minPasswordLength,
  className,
}) => {
  return (
    <>
      {!(isAlNum && isOneCapital && isOneSpecial && minPasswordLength) ? (
        <div className={`tooltip ${className}`}>
          <div className="pwd-txt">Password must :</div>
          <ul>
            <li className={isAlNum ? 'green-tooltip' : ''}>Alphanumeric</li>
            <li className={isOneCapital ? 'green-tooltip' : ''}>
              1 capital letter
            </li>
            <li className={isOneSpecial ? 'green-tooltip' : ''}>
              1 special character
            </li>
            <li className={minPasswordLength ? 'green-tooltip' : ''}>Minimum {passwordMinLength} character's</li>
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default Tooltip;
