export const userAgreementContent = `
<html>
  <head>
    <title>Terms and Conditions</title>
  </head>
  <body>
    <p align="center" style="text-align: center; font-size: 2rem">
      Terms and Conditions for Samsung Whiteboard DMS
    </p>

    <p style="margin:0 1rem">
    This Terms and Conditions of Use (“Terms”) is entered into by and between Samsung Electronics Co., Ltd. (“Samsung”) and the Licensee (“you”) and governs your use of Samsung Whiteboard DMS and related software (“Software”). The Software is deemed to be delivered and accepted by you on the date you first install on the Device (defined below) and connect to the server. You agree to be bound by the Terms from the acceptance date. If you do not have the authority to enter into the Terms or you do not agree to with the Terms, do not use the Software. In the event you install the Software as a system integrator or act as agent of your client, you are responsible to obtain the necessary authority to agree on behalf of your client and to also ensure that your client is aware of these terms.
    </p>
    <ol>
      <li style="font-size: 1.5rem; font-weight: 500">Definitions</li>
      <p>
        <strong> Device: </strong>

        The device and server on which the Software is to be installed, which is owned or controlled by you.
        <br />

        <strong> Effective Date: </strong>
        Pursuant to the Terms, the date of installation of the Software on Device(s) and connection to the server.
        <br />
        <strong> Manual: </strong>
        A Samsung-provided document explaining the functions of and the method of using the Software. 
        <br />
        <strong> Product: </strong>
        Samsung’s electronic whiteboard for Android.
        <br />
        <strong> Territory: </strong>
        The country or countries in which distribution of Software is permitted under the conditions as provided by Samsung to you upon acceptance of this Terms, which may be updated by Samsung from time to time. <br />
        <strong> Use: </strong>

        When referred in connection with the Licensee’s use of the Software, it shall mean an act of loading, executing, accessing, utilizing, or displaying the Software. <br />
        <strong> Website: </strong>
        The website which is operated by Samsung in order to provide the Software for download, which may change from time to time (currently at www.samsung.com).
      </p>

      <li style="font-size: 1.5rem; font-weight: 500">Rights of Licensee</li>
      <p>
        <strong> 2.1 Grant of License </strong>
        <p style="margin-left:1.5rem; margin-top: -1rem">
        Subject to the terms and conditions of the Terms, Samsung hereby grants to you, and you hereby accept, a non-exclusive and non-transferable license to use the Software for the purpose of operating the Product in the Territory. Except as expressly provided in the Terms, you shall not have any express or implied rights.
        </p>
      </p>
      <p>
        <strong> 2.2 Copies </strong>
        <p style="margin-left:1.5rem; margin-top: -1rem">
        You may make such number of copies of the Software  (only up to 5,000) to be installed on the Device to be used for the purpose for which the license has been granted.  Unless otherwise authorized by Samsung, each License shall not be used for more than the number of Products purchased.
        </p>
      </p>
      <p>
        <strong>
            2.3 Restrictions on License
        </strong>
        <p style="margin-left:1.5rem; margin-top: -1rem">
        Unless expressly authorized by Samsung in writing or otherwise permitted under applicable law, you shall not: 
      
        <div style="margin-left: 2rem; margin-top: -1rem">

            (i)&nbsp;&nbsp;  sublicense, assign, lease, transfer, copy or provide to any third party the Software license hereunder or any other rights under the Terms;
        </div>
        <div style="margin-left: 2rem">

            (ii)&nbsp; make and/or install more copies of the Software than the limitation provided under Section 2.2 of the Terms;
        </div>
        <div style="margin-left: 2rem">

            (iii) remove, modify, or conceal any product identification, copyright, proprietary, intellectual property notices or other marks;
        </div>
        <div style="margin-left: 2rem">

            (iv) modify, adapt or create derivative works of all or any part of the Software in any way other than expressly permitted by Samsung; or
        </div>

        <div style="margin-left: 2rem">

        (v)
        reverse engineer, disassemble, decrypt, decompile or otherwise attempt to derive the source code of the Software.
    </div>
        </p>
      </p>

      <li style="font-size: 1.5rem; font-weight: 500">Intellectual Property</li>
      <p>
        <strong>
            3.1 Ownership of Intellectual Property 
        </strong>
        <p style="margin-left: 1.5rem; margin-top: -1rem">
        Samsung and its licensors retain ownership in all intellectual property rights in and to the Software including copyrights, all underlying technology and associated documentation related thereto. You hereby authorize Samsung to use any feedback or ideas you provide related to your use of the Software for any purpose.
        </p>
      </p>

      <li style="font-size: 1.5rem; font-weight: 500">Audit</li>
      <p>
        <strong>
            4.1 Samsung’s Audit Rights

        </strong>
        <p style="margin-left:1.5rem;margin-top:-1rem">

        During the license term for the Software and for a period of three (3) years after its expiration or termination, you will take reasonable steps to maintain complete and accurate records of your use of the Software sufficient to verify compliance with the Terms. For the duration the Terms are in effect and for three (3) years after the expiration of the term, no more than once per twelve (12) month period, you will allow Samsung and its auditors the right to examine such records and any relevant books, systems, and accounts, upon reasonable advance notice, during your normal business hours.  Any exercise by Samsung of rights pursuant to this Article shall not constitute the waiver of other rights or remedies permitted by law.
        </p>
      </p>

      <li style="font-size: 1.5rem; font-weight: 500">Limited Warranty</li>

      <p>
        <strong>

            5.1 Warranty 
        </strong>
        <p style="margin-left:1.5rem;margin-top:-1rem">

        For the period of one year (“Warranty Period”) from the Effective Date, Samsung warrants that the Software shall materially conform to the Manual if it is used without any material modification thereof. The warranty to be provided by Samsung shall be effective only to you, subject to you verifying to Samsung that you are the original Licensee/purchaser of the Product.
        </p>
        <strong>
            5.2 Software Upgrades and Updates 
        </strong>
        <p style="margin-left:1.5rem;margin-top:0">
        During the Term of this Agreement,
        </p>
		<p>
        <div style="margin-left: 2rem; margin-top: -1rem">
            (i) &nbsp;the Licensee can purchase an additional service package for upgrading to a newer version of the Software or adding new functionalities; and
        </div>

        <div style="margin-left: 2rem;">
            (ii) any minor Software updates such as bug fixes may be provided at no additional cost to the Licensee during the Warranty Period.
        </div>
		</p>
        <strong>
            5.3 Scope of Warranty
        </strong>
        <p style="margin-left:1.5rem;margin-top:0">
        The warranty set forth in this Article shall not apply:
        <div style="margin-left: 2rem; margin-top: -1rem">
            (i) &nbsp;if the Software has been misused, abused, abnormally used or otherwise used in a manner not in accordance with the Manual; or
        </div>
        <div style="margin-left: 2rem;">
            (ii) if any defect at issue is attributable to the modification or expansion, or abnormal maintenance of the Software, or functional errors caused by you or a third party’s software or hardware.
        </div>
        </p>
        <strong>
            5.4 Disclaimer of Warranties
        </strong>
        <p style="margin-left:1.5rem;margin-top:0">
        Samsung disclaims any warranty including any implied warranty of merchantability or fitness for particular purpose, and does not warrant that the functions of the Software will satisfy the objectives internally set by you, or has been designed to meet all of your business requirements, or can work with the combination or in the environment, including network services, chosen by you, or will be free from any interruption or defect. Samsung disclaims any responsibility for any costs of installation or operation of the Software. 
        </p>
      </p>

      <li style="font-size: 1.5rem; font-weight: 500">Term and Termination</li>

      <p>
        <strong> 6.1 Term </strong>
        <p style="margin-left:1.5rem; margin-top:-1rem">
        The Terms shall come into effect on the Effective Date of the license granted under the Terms, and shall remain in full force and effect for 12 months from the Effective Date. For the avoidance of doubt, no extension of these terms is granted under this Terms unless otherwise agreed in a separate agreement. UPON EXPIRATION OF THE TERMS, YOU MAY CONTINUE TO USE THE SOFTWARE BUT SAMSUNG HAS NO FURTHER OBLIGATION TO PROVIDE MAINTENANCE, SUPPORT OR UPDATES TO THE SOFTWARE AND WILL NOT BE RESPONSIBLE FOR ANY DAMAGES THAT OCCUR AFTER SUCH EXPIRATION.
        </p>
        
        <strong>
            6.2 Effect of Expiration
        </strong>
        <p style="margin-left:1.5rem; margin-top:0">
        (1) If the Terms are expired, the license granted to you pursuant to Article 2 shall become null and void, in which case you shall cease to use the Software, delete and remove from all Devices, the Software and all copies thereof so that they will not be restorable.
        
        
        </p>
        <p style="margin-left:1.5rem; margin-top:-1rem">
        (2) Article 2.3 (Restrictions on License), Article 3 (Intellectual Property), Article 4 (Audit), Article 7 (Indemnification), and Article 8 (Limitation of Liability) shall survive the expiration of the Terms.

        </p>
        <p style="margin-left:1.5rem; margin-top:-1rem">
        (3) The expiration hereof pursuant to this Article shall not affect any rights and remedies of Samsung.

        </p>
      </p>

      <li style="font-size: 1.5rem; font-weight: 500">Indemnification</li>
      <p>
        <strong>
            7.1 Indemnification by You
        </strong>
        <p style="margin-left:1.5rem; margin-top:-1rem">
        You agree to defend, indemnify and hold Samsung harmless from and against all liabilities brought against or incurred by Samsung for all claims arising under or in connection with any infringement or alleged infringement of any intellectual property rights made by any person as a result (whether directly or indirectly) of the Software being used in combination with other software, elements or data which were not provided by Samsung, but for which the infringement would not have occurred.
        </p>

        <strong>
            7.2 Indemnification Process
        </strong>
        <p style="margin-left:1.5rem; margin-top:0">
            Upon receipt of any claim pursuant to Article 7.1, from a third party,
            you shall:
        </p>
        <p style="margin-left:1.5rem; margin-top:-1rem">
            (i)&nbsp;&nbsp; promptly notify Samsung in writing of the claim;
        </p>
        <p style="margin-left:1.5rem; margin-top:-1rem">
            (ii)&nbsp; not make any admission, compromise or settle the claim without the
            prior written consent of the indemnifying party;
        </p>
        <p style="margin-left:1.5rem; margin-top:-1rem">
        (iii) allow Samsung, in its discretion, to decide what action, if any, to
        take and to control and conduct the claim; and
        </p>
        <p style="margin-left:1.5rem; margin-top:-1rem">
            (iv) provide Samsung, at your cost, with all assistance as it may
            reasonably require in the conduct of the claim.
        </p>
      </p>

      <li style="font-size: 1.5rem; font-weight: 500">
        Limitation of Liability
      </li>
      <p>
      In no event will Samsung or its affiliates be liable for the following, whether based in warranty, contract, tort (including negligence) or any other theory of liability or whether relating to or arising out of the Terms, the Software or otherwise, even if it has been advised of the possibility of such damages:
        <p style="margin-left:1.5rem; margin-top:-1rem">
            (i) &nbsp;&nbsp;indirect, incidental, exemplary, special or consequential damages; 
        </p>
        <p style="margin-left:1.5rem; margin-top:-1rem">
            (ii) &nbsp;loss or corruption of data or interrupted or loss of business; or 
        </p>
        <p style="margin-left:1.5rem; margin-top:-1rem">
            (iii) loss of revenue, profits, goodwill or anticipated sales or savings.
        </p>
      </p>

      <li style="font-size: 1.5rem; font-weight: 500">Force Majeure</li>
      <p>
      Neither party will be responsible for failure of performance due to an event beyond the affected party’s reasonable control, including without limitation, accidents, riots, fire, severe weather events, acts of God, compliance with any law or governmental order, pandemic, acts of terrorism, or the stability or availability of the internet or portions thereof.
      </p>

      <li style="font-size: 1.5rem; font-weight: 500">Miscellaneous</li>

      <p>
        <strong>
            10.1 Transfer, Assignment and Subcontract by Samsung 
        </strong>
        <p style="margin-left:2rem; margin-top:-1rem">
            Samsung may transfer or assign its status as a party to the Terms to a
            third party, and may delegate or subcontract its rights or obligations
            under the Terms to a third party.
        </p>
        <strong>
            10.2 Severability
        </strong>
        <p style="margin-left:2rem; margin-top:0">
            Even if any provision of the Terms is held to be invalid, illegal or
            unenforceable, the remaining provisions shall not be affected thereby,
            and the Terms shall be construed as if such invalid, illegal or
            unenforceable provision had not been included in the Terms from the
            beginning.
        </p>
        <strong>
            10.3 No Waiver
        </strong>
        <p style="margin-left:2rem; margin-top:0">
            A waiver of any right under the Terms is only effective if it is in
            writing and it applies only to the party to whom the waiver is addressed
            and to the circumstances for which it is given. Unless specifically
            provided otherwise, rights arising under this Terms are cumulative and
            do not exclude rights provided by law.
        </p>
        <strong>
            10.4 No partnership or agency
        </strong>
        <p style="margin-left:2rem; margin-top:0">
            Nothing in this Terms is intended to, or shall be deemed to, establish
            any partnership or joint venture between any of the parties, constitute
            any party the agent of another party, nor authorize any party to make or
            enter into any commitments for or on behalf of any other party.
        </p>
        
        <strong>
            10.5 Changes to Software and the Terms
        </strong>
        <p style="margin-left:2rem; margin-top:0">
         (a) Samsung reserves the right, in its sole discretion, to change, improve and correct the Software. The Software may not be available during maintenance breaks and other times. Samsung may also decide to discontinue the Software or any part thereof in its sole discretion.
        </p>
        <p style="margin-left:2rem; margin-top:-1rem">
        (b) Samsung, in its sole discretion, may make changes to the Terms from time to time. After the change of the terms, your continued use of the Software shall be deemed as acceptance to the updated Terms.        </p>
       
        <strong>
            10.6 Entire Agreement
        </strong>
        <p style="margin-left:2rem; margin-top:0">
        The Terms shall constitute the entire agreement between both parties, and supersede all prior or contemporaneous agreements, proposals, understandings, representations, purchase orders, and declarations of intent, whether written or oral.  For instance, any other purchase terms in relation to the Terms herewith shall not in any way supersede, amend, modify or otherwise supplement the terms and conditions of the Terms.
        </p>
        
        <strong>
            10.7 Governing Law and Jurisdiction
        </strong>
        <p style="margin-left:2rem; margin-top:0">
        This Terms shall be governed by and construed in accordance with the laws of the State of New York, USA (excluding conflict of laws provisions which may direct the application of another jurisdiction’s laws). All disputes, controversies or claims between the parties arising out of or in connection with this Terms (including its existence, validity or termination) shall be finally resolved by arbitration to be held in New York, New York, USA and conducted in English under the Rules of Arbitration of the International Chamber of Commerce; provided, however, that each party may enforce its or its affiliates’ intellectual property rights in any court of competent jurisdiction, including but not limited to equitable relief. The arbitration tribunal shall consist of one arbitrator to be appointed according to the ICC rules. The arbitral award shall be final and binding on the parties. Except to the extent entry of judgment and any subsequent enforcement may require disclosure, all matters relating to the arbitration, including the award, shall be held in confidence.
        </p>
    </p>
    </ol>
  </body>
</html>`;
