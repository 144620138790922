import { RestServiceUtils } from '../../utils/RestServiceUtils';
import { TAGS, DEVICES_URL } from '../../config/constants/Constants';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const getAllTagList = () => {
  const URL = TAGS + '/tags';
  return RestServiceUtils.HTTP_GET(URL, {}, config);
};

const getTagByDeviceId = (id) => {
  const URL = `${DEVICES_URL}/${id}/getTags`;
  return RestServiceUtils.HTTP_GET(URL, {}, config);
};

const commonTagsOfMultipleDevices = (payload) => {
  const URL = `${DEVICES_URL}/allTags`;
  return RestServiceUtils.HTTP_POST(URL, payload, config);
};

const addTagsToMultipleDevices = (payload) => {
  const URL = `${DEVICES_URL}/addTags`;
  return RestServiceUtils.HTTP_POST(URL, payload, config);
};

const removeDeviceTags = (payload) => {
  const URL = `${DEVICES_URL}/removeTags`;
  return RestServiceUtils.HTTP_POST(URL, payload, config);
};

const applyUrlRestrictions = (payload) => {
  const URL = `${DEVICES_URL}/restriction`;
  return RestServiceUtils.HTTP_POST(URL, payload, config);
}

export const tagServices = {
  getAllTagList,
  getTagByDeviceId,
  removeDeviceTags,
  addTagsToMultipleDevices,
  commonTagsOfMultipleDevices,
  applyUrlRestrictions
};
