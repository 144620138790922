import React, { useState, useEffect, useRef } from 'react';
import './UserInfo.css';
import { useIntl } from 'react-intl';
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, updateUserInfo, updateUserPassword, resetUserUpdateFlag } from "../../redux/actions/UserManagementAction";

import Spinner from '../../components/Spinner/Spinner';
import ChangePassword from '../ChangePassword/ChangePassword';
import { InputText } from 'primereact/inputtext';
import { isEmpty, isNil } from 'lodash';
import { validateUserDetails, userNameMaxLength, contactNumberMaxLength, emailMaxLength  } from '../../utils/Validations';
import {
  setToastComponent,
  showSuccessToast,
} from '../../services/APIResponseHandler';
import { Toast } from 'primereact/toast';

const UserInfo = ({ setPasswordSuccess, setHideMsg, refreshPage }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const toastRef = useRef();
  const { formatMessage: f } = intl;

  const [temporaryPwd, setTemporaryPwd] = useState('');
  const [newPwd, setNewPwd] = useState('');
  const [confirmNewPwd, setConfirmNewPwd] = useState('');
  const [isAnyDataChanged, setisAnyDataChanged] = useState(true);
  
  const user = JSON.parse(localStorage.getItem('user'));
  const { userId } = user ?? {};
  const userSelector = useSelector((state) => state.user);
  const {
    userInfo: userMgmtInfo,
    userError,
    loading,
    updateUserInfoFlag,
  } = userSelector ?? {};
  const { contactNumber, email, loginId, userName } = userMgmtInfo ?? {};
  const [changePassword, setChangePassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const CHANGE_PASSWORD_TXT = f({
    id: 'COM_DMS_CHANGE_PWD_TXT',
  });
  const CURR_PWD = f({ id: 'COM_DMS_CURR_PWD' });
  const CURR_PWD_TXT = f({ id: 'COM_DMS_CURR_PWD_TEXT' });
  const CHILD_TEXT = f({ id: 'COM_DMS_SECURE_PWD_TEXT' });

  const handleCancel = () => {
    setTemporaryPwd('');
    setNewPwd('');
    setConfirmNewPwd('');
    setChangePassword(false);
    setErrorMessage('');
  };
  const [cancelFlag, setCancelFlag] = useState(false);
  const initialInfo = {
    userId: '',
    userName: '',
    email: '',
    contactNumber: '',
  };
  const [userInfo, setUserInfo] = useState(initialInfo);
  const [userDetailsError, setUserDetailsError] = useState('');

  useEffect(() => {
    setUserInfo({
      userId: loginId,
      userName,
      email,
      contactNumber,
    });
  }, [userMgmtInfo]);

  useEffect(() => {
    const isChanged =( email == userInfo.email) &&
    (userName === userInfo.userName )&&
    (contactNumber === userInfo.contactNumber);
    setisAnyDataChanged(isChanged)

  },[userInfo])

  useEffect(() => {
    if (cancelFlag) {
      setUserInfo({
        userId: loginId,
        userName,
        email: isNil(email) ? '' : email,
        contactNumber: isNil(contactNumber) ? '' : contactNumber,
      });
      setCancelFlag(false);
      setUserDetailsError('');
    }
  }, [cancelFlag]);

  useEffect(() => {
    setUserInfo({
      userId: loginId,
      userName,
      email,
      contactNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactNumber, email, loginId, userName]);

  const handleSaveUserInfo = () => {
    let isValid = true;
    let messageId = '';
    let value;
    let res;
    setUserDetailsError('');

    if (isValid && !isEmpty(userInfo.userName)) {
      value = userInfo.userName;
      res = validateUserDetails('userName', value);
      isValid = res.isValid;
    }

    if (isValid && !isEmpty(userInfo.email)) {
      value = userInfo.email;
      res = validateUserDetails('email', value);
      isValid = res.isValid;
    }

    if (isValid && !isEmpty(userInfo.contactNumber)) {
      value = userInfo.contactNumber;
      res = validateUserDetails('contactNumber', value);
      isValid = res.isValid;
    }

    if (isValid) {
      setUserDetailsError('');
      dispatch(
        updateUserInfo({
          userId,
          email: userInfo.email?.trim(),
          userName: userInfo.userName?.trim(),
          contactNumber: userInfo.contactNumber?.trim(),
        })
      );
      dispatch(getUser({ userId }));
    } else {
      messageId = res.messageId;
      setUserDetailsError(f({ id: messageId }));
    }
  };

  const setUserInfoField = (e, field) => {
    setUserDetailsError('');
    setUserInfo({ ...userInfo, [field]: e.target.value });
  };

  const handleChangePassword = () => {
    let isValid = true;
    let value;
    let res;

    if (isValid) {
      value = newPwd;
      res = validateUserDetails('password', value);
      isValid = res.isValid;
      setErrorMessage(f({ id: 'COM_DMS_INVALID_NEW_PASSWORD' }));
    }

    if (isValid) {
      if (newPwd !== confirmNewPwd) {
        setErrorMessage(f({ id: 'COM_DMS_PASSWORD_MISMATCH' }));
      } else {
        setHideMsg(false);
        setErrorMessage('');
        dispatch(
          updateUserPassword({
            userId,
            password: newPwd,
            currentPassword: temporaryPwd,
            confirmPassword: confirmNewPwd,
            successMessage: f({ id: 'COM_DMS_PWD_RESET_SUCCESS'}),
            setErrorMessage: setErrorMessage
          })
        );
      }
    }
  };

  useEffect(() => {
    if (!(userError && userError?.errorCode)) {
      setTemporaryPwd('');
      setNewPwd('');
      setConfirmNewPwd('');
      setChangePassword(false);
      setPasswordSuccess(true);
      setTimeout(() => {
        setHideMsg(true);
      }, 3000);
    } else if (userError.errorCode == 400702) {
      setErrorMessage(userError.errorMessage);
    } else if (userError.errorCode == 400714) {
      setErrorMessage(userError.errorMessage);
    }
  }, [userError]);

  useEffect(() => {
    dispatch(getUser({ userId }));
    setToastComponent(toastRef.current);
  }, [refreshPage]);

  useEffect(() => {
    if (updateUserInfoFlag) {
      showSuccessToast(f({ id: 'COM_DMS_CHANGES_BEEN_SAVED' }));
      dispatch(resetUserUpdateFlag());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateUserInfoFlag]);

  const handleCancelUserInfo = () => {
    setCancelFlag(true);
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {changePassword && (
            <ChangePassword
              changePwd
              newPwd={newPwd}
              currentPwd={CURR_PWD}
              setNewPwd={setNewPwd}
              text={CHANGE_PASSWORD_TXT}
              childText={CHILD_TEXT}
              handleCancel={handleCancel}
              temporaryPwd={temporaryPwd}
              currentPwdTxt={CURR_PWD_TXT}
              confirmNewPwd={confirmNewPwd}
              setTemporaryPwd={setTemporaryPwd}
              setConfirmNewPwd={setConfirmNewPwd}
              onSubmit={handleChangePassword}
              errorMessage={errorMessage}
            />
          )}
          <div className="user-info-button-position">
            <div className="userInfo-left-btn">
              <Button
                label={f({ id: 'COM_DMS_DISCARD_CHANGES' })}
                className="user-info-cancel-btn"
                onClick={handleCancelUserInfo}
                disabled={isAnyDataChanged}
              />
              <Button
                label={f({ id: 'COM_DMS_SAVE_CHANGES' })}
                className="user-info-btn user-info-save"
                onClick={handleSaveUserInfo}
                disabled={!userInfo?.userName?.trim() || isAnyDataChanged}
              />
            </div>
            <div className="userInfo-right-btn">
              <Button
                label={f({ id: 'COM_DMS_CHANGE_PWD_TXT' })}
                className="user-info-btn user-info-change-pwd-btn"
                onClick={() => setChangePassword(true)}
              />
            </div>
          </div>
          <div className="user-info">
            <div className="user-information">
              <div className="user-information-text">
                {f({ id: 'COM_DMS_USER_INFO' })}
              </div>
            </div>
            <div className="user-information">
              <div className="userdetails_col">
                <label className="userInfo_label_pos">
                  <sup className="ast-color">*</sup>
                  <span style={{ fontFamily: 'SamsungOne-600' }}>
                    {f({ id: 'COM_DMS_USER_ID' })}
                  </span>
                </label>
                <InputText
                  className="userInfo_details  md:w-14rem"
                  disabled
                  value={userInfo.userId}
                  onChange={(e) => setUserInfoField(e, 'userId')}
                  placeholder={f({ id: 'COM_DMS_USER_ID_TXT' })}
                />
              </div>
              <div className="userdetails_col">
                <label className="userInfo_label_pos">
                  <sup className="ast-color">*</sup>
                  <span style={{ fontFamily: 'SamsungOne-600' }}>
                    {f({ id: 'COM_DMS_USER_NAME' })}
                  </span>
                </label>
                <InputText
                  className="userInfo_details  md:w-14rem"
                  value={userInfo.userName}
                  maxLength={userNameMaxLength}
                  onChange={(e) => setUserInfoField(e, 'userName')}
                  placeholder={f({ id: 'COM_DMS_USER_NAME_TXT' })}
                />
              </div>
              <div className="userdetails_col">
                <label className="userInfo_label_pos">
                  <span style={{ fontFamily: 'SamsungOne-600' }}>
                    {f({ id: 'COM_DMS_EMAIL_ID' })}
                  </span>
                </label>
                <InputText
                  className="userInfo_details  md:w-14rem"
                  value={userInfo.email}
                  maxLength={emailMaxLength}
                  onChange={(e) => setUserInfoField(e, 'email')}
                  placeholder={f({ id: 'COM_DMS_EMAIL_TXT' })}
                />
              </div>
              <div className="userdetails_col">
                <label className="userInfo_label_pos">
                  <span style={{ fontFamily: 'SamsungOne-600' }}>
                    {f({ id: 'COM_DMS_MOBILE' })}
                  </span>
                </label>
                <InputText
                  className="userInfo_details  md:w-14rem"
                  value={userInfo.contactNumber}
                  maxLength={contactNumberMaxLength}
                  onChange={(e) => setUserInfoField(e, 'contactNumber')}
                  placeholder={f({ id: 'COM_DMS_CONTACT_NO_TXT' })}
                />
              </div>
            </div>
          </div>
          <div className="errorMessagePosition">
            <span className="error-txt">{userDetailsError}</span>
          </div>
        </>
      )}
    </>
  );
};

export default UserInfo;
