import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { useIntl } from 'react-intl';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import './MfaRegistrationPopup.css'
import QRCode from "react-qr-code";
import GooglePlayIcon from '../../../src/assets/images/play-store-icon.png';
import AppStoreIcon from '../../../src/assets/images/app-store-icon.png';
import { otpMaxLength } from '../../utils/Validations';
import { getUserAuth , resetAuthErrorCode} from '../../redux/actions/AuthAction';
import { useSelector, useDispatch } from 'react-redux';

export const MfaRegistrationPopup = (props) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const dispatch = useDispatch();
  const { showMfa, updateOtpVerification, setShowMfa, registrationCode, qrCode, userid, password } = props;
  const [isRegistrationSuccessfull, setIsRegistrationSuccessfull] = useState(false);
  const { isAuthenticated, authErrorCode } = useSelector(state => state.auth);
  const [errorMsg, setErrorMsg] = useState('');

  const onHide = () => {
    dispatch(resetAuthErrorCode()); 
    updateOtpVerification(false);
    setShowMfa(false);
    setIsRegistrationSuccessfull(false);
  };
  const close = () => {
    updateOtpVerification(true);
    setShowMfa(false);
    setIsRegistrationSuccessfull(false);
  };
  useEffect(() => {
    if(authErrorCode == 401000){
      setErrorMsg(f({id : 'COM_DMS_INCORRECT_OTP_TEXT'}))
    } else if (authErrorCode == 401001) {
      setErrorMsg(f({id: 'COM_DMS_ACCOUNT_BLOCK_MSG'}))
    } else if (isAuthenticated) {
      setIsRegistrationSuccessfull(true);
    }

  }, [authErrorCode]);

  const RegistrationSuccessContent = () => {
    return (
      <div className="mfa-success-container"><div className="mfa-success-content">{f({ id: 'COM_DMS_OTP_REGISTRATION_COMPLETE' })}</div>
        <div className="mfa-registration-footer">
          <Button
            className="mfa-success-ok-button"
            label={f({ id: 'COM_DMS_OK' })}
            onClick={close}
          />
        </div>
      </div>
    )

  }
  const RegistrationGuideContent = () => {
    const [authenticationCode, setAuthenticationCode] = useState('');
    const handleOtpInputField = (event) => {
      const { value } = event.target;
      if (/^\d*$/.test(value)) {
        setErrorMsg('')
        setAuthenticationCode(value?.trim())
      }
    }
    const handleNext = () => {
      dispatch(getUserAuth({ loginId: userid, password, totp: authenticationCode }));
    }
    const enableNextButton = () => {
      if (authenticationCode.length == otpMaxLength) {
        return false;
      }
      return true;
    }
    return (
      <div className="mfa-registration-dialog-container">
        <div className="mfa-registration-content">
          <p>{f({ id: 'COM_DMS_OTP_REGISTRATION_STEP_ONE' })}</p>
          <div className="download-app-icon-row">
            <img className="download-app-icon-left" src={GooglePlayIcon}></img>
            <img className="download-app-icon-right" src={AppStoreIcon}></img>
          </div>
          <div className="authenticator-list" >
            <p style={{ marginBottom: '0.5rem' }}>{f({ id: 'COM_DMS_OTP_AUTHENTICATOR_OPTIONS' })}</p>
            <ul>
              <li>{f({ id: 'COM_DMS_OTP_AUTHENTICATOR_GOOGLE' })}</li>
              <li>{f({ id: 'COM_DMS_OTP_AUTHENTICATOR_MICROSOFT' })}</li>
              <li>{f({ id: 'COM_DMS_OTP_AUTHENTICATOR' })}</li>
            </ul>
            <p style={{ fontWeight: 600 }}>{f({ id: 'COM_DMS_OTP_REGISTRATION_STEP_TWO' })}</p>
            <p style={{ fontWeight: 600 }}>{f({ id: 'COM_DMS_OTP_REGISTRATION_STEP_THREE' })}</p>
          </div>

          <div className="mfa-registration-qr-row">
            <QRCode className="mfa-registration-qr-code" value={qrCode}></QRCode>
            <div className="mfa-registration-code">{registrationCode}</div>
          </div>

          <p>{f({ id: 'COM_DMS_OTP_REGISTRATION_STEP_FOUR' })}</p>
          <div>

          <InputText
            className={errorMsg ? 'mfa-registration-input-field mfa-text-error': 'mfa-registration-input-field'}
            type="text"
            name="otp"
            autoComplete="no"
            maxLength={otpMaxLength}
            onChange={handleOtpInputField}
            value={authenticationCode}
            placeholder={f({ id: 'COM_DMS_ENTER_CODE' })}
            />
           {!!errorMsg && <div className='mfa-text-error-msg'>{errorMsg }</div>}
            </div>

        </div>
        <div className="mfa-registration-footer">
          <Button
            className="mfa-registration-cancel-button"
            label={f({ id: 'COM_DMS_CANCEL' })}
            onClick={onHide}
          />
          <Button
            className="mfa-registration-continue-button"
            label={f({ id: 'COM_DMS_CONTINUE' })}
            disabled={enableNextButton()}
            onClick={handleNext}
          />
        </div>
      </div>
    )
  }
  const headerContent = () => {
    return (
      <div className="mfa-registration-dialog-header">
        {f({ id: 'COM_DMS_REGISTER_OTP_TWO_STEP_VERIFICATION' })}
      </div>
    );
  };

  const BodyContent = () => {
    if (isAuthenticated) {
      return (<RegistrationSuccessContent />)
    }
    else {
      return (<RegistrationGuideContent />)
    }
  }
  return (
    <Dialog
      className={isRegistrationSuccessfull ? "mfa-success-dialog" : "mfa-registration-dialog"}
      header={headerContent}
      visible={showMfa}
      position={'centre'}
      onHide={() => {
        setIsRegistrationSuccessfull(false);
      }}
    >
      <BodyContent></BodyContent>
    </Dialog>
  )
}