import React, { useEffect, useState } from 'react';
import './MessagingHistoryView.css';
import Overlay from '../../../../../components/Overlay/Overlay';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RestServiceUtils } from '../../../../../utils/RestServiceUtils';
import { MESSAGE_ALERT_HISTORY_URL } from '../../../../../config/constants/Constants';
import { textSizeMap } from '../DeviceMessagingTab/DeviceMessagingUtils';
import CrossPopupIcon from '../../../../../assets/images/cross-popup-icon.svg';
import { useIntl } from 'react-intl';

const MessagingHistoryView = ({
  openMessageHistory,
  setOpenMessageHistory,
  historyId,
  setHistoryId,
}) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const [messageHistoryData, setMessageHistoryData] = useState([]);
  const [messageTemplateData, setMessageTemplateData] = useState([]);
  const deviceHistoryColumns = [
    { field: 'deviceName', header: f({ id: 'COM_DMS_DEVICE_NAME' }) },
    { field: 'groupName', header: f({ id: 'COM_DMS_GROUP' }) },
    { field: 'deviceId', header: f({ id: 'COM_DMS_MAC_ID' }) },
    { field: 'ipAddress', header: f({ id: 'COM_DMS_IP' }) },
  ];

  useEffect(() => {
    RestServiceUtils.HTTP_GET(
      `${MESSAGE_ALERT_HISTORY_URL}/historyData/${historyId}`
    ).then(({ data }) => {
      const { errorCode, templateData, targetDevices } = data ?? {};
      if (!errorCode) {
        setMessageHistoryData(targetDevices);
        setMessageTemplateData(templateData);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderAlertHistoryTemplate = () => {
    const {
      alertImgSrc,
      backgroundColor,
      textColor,
      textSize,
      textStyle,
      templateMessage,
    } = messageTemplateData ?? {};
    return (
      <div className="alert-template" style={{ backgroundColor }}>
        <img src={alertImgSrc} alt="alert-img" className="alert-template-img" />
        <div
          className="alert-template-name"
          style={{
            color: textColor,
            textStyle,
            fontSize: `${textSizeMap.get(textSize)} rem`,
          }}
        >
          {templateMessage}
        </div>
      </div>
    );
  };

  return (
    <Overlay className="message-history-overlay">
      <div className="message-history-dialog">
        <img
          src={CrossPopupIcon}
          alt="cancel"
          onClick={() => {
            setHistoryId(-1);
            setOpenMessageHistory(!openMessageHistory);
          }}
          className="message-history-cross-icon"
        />
        <div className="message-history-window">
          {renderAlertHistoryTemplate()}
        </div>
        <div className="message-history-table">
          <div className="device-table-text">
            {f({ id: 'COM_DMS_TARGET_DEVICE' })}
          </div>
          <div className="device-table">
            <DataTable value={messageHistoryData}>
              {deviceHistoryColumns.map((col) => (
                <Column {...col} />
              ))}
            </DataTable>
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export default MessagingHistoryView;
