export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_USER_PASSWORD_FAILURE = "UPDATE_USER_PASSWORD_FAILURE";

export const RESET_USER_MGMT = 'RESET_USER_MGMT';
export const RESET_UPDATE_USER_FLAG = 'RESET_UPDATE_USER_FLAG';

export const GET_USER_PENDING = 'GET_USER_PENDING';