import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import search from "../../assets/images/search.svg";
import MinusIcon from '../../assets/images/minusIcon.svg';
import PlusIcon from '../../assets/images/plusIcon.svg';
import GroupIcon from '../../assets/images/groupIcon.svg';
import { useIntl } from "react-intl";
import { InputText } from "primereact/inputtext";
import "./SelectGroup.css";
import { GroupSuggestPopup } from "../PopUp/GroupSuggest";
import { GroupManagementService } from "../../services/RestServices/GroupManagementService";
import { changeAttributesOfObject } from "../../utils/Utils";
import {
  cleanJSONTree,
  removeNullValuesFromNestedObjectOfArray
} from "../../utils/CleanJSONArrayObject";
import { deviceServices } from "../../services/RestServices/deviceServices";
import flatToTree from "flat-to-tree";
import { showSuccessToast } from '../../services/APIResponseHandler';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import axios from "axios";
import { GROUP_MGMT_API } from "../../config/constants/Constants";
import { RestServiceUtils } from "../../utils/RestServiceUtils";
export const SelectGroup = props => {
  const { formatMessage: f } = useIntl();
  const setVisible = props.setVisible;
  const visible = props.visible;
  const [nodes, setNodes] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [groupData, setGroupData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");
  const selectedDeviceList = props.deviceToBeDeleted;
  useEffect(() => {
   GroupManagementService.getDMSGroups().then(({ data }) => {
      const convertedJSONData = flatToTree(data, {
        id: "groupId",
        parentId: "parentId"
      });
      cleanJSONTree(convertedJSONData);
      const cleanedJSONTree = removeNullValuesFromNestedObjectOfArray(
        convertedJSONData
      );
      const nodesData = processData(cleanedJSONTree);
      setNodes(nodesData);
    });
  }, []);

  useEffect(() => {
      setSelectedKey("");
  },[nodes]);

  const headerContent = () => {
    return (
      <>
      <div>
        <span className="approveDevice-header">Approve</span>
       
      <Button
        label="Approve"
        className="select-device select-device-save"
        onClick={approveDeviceHandler}
        autoFocus
        disabled={!(selectedKey === 0 || selectedKey)}
      />
      <Button
        label="Cancel"
        className="select-device"
        onClick={() => {setVisible(false); }}
      />
   
      </div>
      <div className="header-second-row">
      <div className="selectGroup-header">Select Group</div>
      <div className="search-block">
          <InputText
            placeholder={f({ id: 'COM_DMS_GROUP_NAME_TEXT' })}
            value={searchText}
            onChange={e => {
              disablePopup(e.target.value);
              setSearchText(e.target.value);
            }}
            onKeyDown={handleInputKeyDown}
            className="action-logs-inputs search-input"
          />
          <img
            src={search}
            alt="setting"
            onClick={filterDeviceHandler}
            className="approveDevice-searchIcon"
          />
          {popupVisible && (
          <GroupSuggestPopup
            setVisible={setPopupVisible}
            visible={popupVisible}
            groups={suggestions}
            searchText={searchText}
            setSearchText={setSearchText}
            setNodes={setNodes}
          />
        )}
        </div>
      </div>
      </>
    );
  };

  const handleInputKeyDown = event => {
    if (event.key === "Enter") {
      filterDeviceHandler();
    }
  };

  const disablePopup = e => {
    setPopupVisible(false);
  };

  const suggestionBuilder = () => {
    let map = new Map();
    let currSuggestions = [];
    Object.values(groupData).forEach(val =>
      map.set(
        [val.groupName, val.groupId],
        val.parents
          ? val.parents.map(parent => [parent.groupName, parent.groupId])
          : []
      )
    );

    for (const x of map.entries()) {
      const currentNode = x[0];
      const parents = x[1];
      if (currentNode[0].toLowerCase().includes(searchText.toLowerCase())) {
        currSuggestions.push({
          groupName: currentNode[0],
          groupId: currentNode[1],
          groupParents: [...parents]
        });
      }
    }
    setSuggestions(currSuggestions);
  }

  useEffect(() => {
    suggestionBuilder();
  }, [groupData]);

  const filterDeviceHandler = async (e) => {
    if (!searchText) {
      await GroupManagementService.getDMSGroups().then(({ data }) => {
        const convertedJSONData = flatToTree(data, {
          id: "groupId",
          parentId: "parentId"
        });
        cleanJSONTree(convertedJSONData);
        const cleanedJSONTree = removeNullValuesFromNestedObjectOfArray(
          convertedJSONData
        );
        const nodesData = processData(cleanedJSONTree);
        setNodes(nodesData);
        setPopupVisible(false);
      });
    } else {
      await Promise.all([
        GroupManagementService.getGroupListFilter(searchText).then(res => {
          const { data } = res ?? {};
          setGroupData(data);
          setPopupVisible(true);
        })
      ]);
    }
  };

  const approveDeviceHandler = async () => {
    await Promise.all([
      deviceServices.approveDevices(selectedDeviceList, selectedKey).then((res) => 
      res?.status === 200 && showSuccessToast("Device Approved Successfully!"))
    ]);
    deviceServices.getAllDeviceList(props.deviceDataPayload).then(res => {
      const { items, totalCount } = res?.data ?? {};
      props.setDeviceList(items);
      props.setDevicesCount(totalCount);
      props.setSelectedItems([]);
      setVisible(!visible);
    });
  };

  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }

    const expanded = options.expanded;
    return (
      <button
        type="button"
        className="p-treetable-toggler p-link"
        style={options.buttonStyle}
        tabIndex={-1}
        onClick={options.onClick}
      >
        <span>
          {expanded ? (
            <img src={MinusIcon} className="icon" alt="minus" />
          ) : (
            <img src={PlusIcon} className="icon" alt="plus" />
          )}
        </span>
      </button>
    );
  };


  const groupBody = ({ data, key, label }) => {
    const { groupName } = data ?? {};
    return (
      <>
        <img
          src={GroupIcon}
          alt="group"
          style={{ marginRight: '1rem' }}
          className="icon"
        />
        {label}
      </>
    );
  };

  
  const selectGroupHeader = () => {
    return (
      <div className="group-header">{f({ id: 'COM_DMS_GROUP_NAME' })}</div>
    );
  };

  const mapping = [
    ["groupId", "key"],
    ["groupName", "label"],
    ["parentId", "data"]
  ];

  const processData = data => {
    if (!data) return;
    data.forEach(currNode => {
      changeAttributesOfObject(currNode, mapping);
      if (currNode.children && currNode.children.length > 0)
        processData(currNode.children);
    });
    return data;
  };

  return (
    <div className="card flex flex-column align-items-center">
      <Dialog
        header={headerContent}
        visible={visible}
        className = "selectGroup-dialog"
        position={"right"}
        draggable={false}
        resizable={false}
        onHide={() => setVisible(false)}
      >

        <TreeTable
          className="group-tree"
          value={nodes}
          selectionMode="single"
          selectionKeys={selectedKey}
          togglerTemplate={togglerTemplate}
          onSelectionChange={e => setSelectedKey(e.value)}
        >
          <Column
            field="groupName"
            header={selectGroupHeader}
            expander
            body={groupBody}
          />
        </TreeTable>
      </Dialog>
    </div>
  );
};
