const unsecuredCopyToClipboard = (
  text,
  showSuccessToast,
  showErrorToast,
  f
) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
    showSuccessToast(f({ id: 'COM_DMS_PASSWORD_COPIED_TO_CLIPBOARD' }));
  } catch (err) {
    showErrorToast(f({ id: 'COM_DMS_COPY_CLIPBOARD_ERROR' }));
  }
  document.body.removeChild(textArea);
};

export const copyNavigatorToClipBoardPassword = (
  text,
  setText,
  showSuccessToast,
  showErrorToast,
  f
) => {
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(text).then(() => {
      setText(true);
    });
  } else {
    unsecuredCopyToClipboard(text, showSuccessToast, showErrorToast, f);
  }
};
