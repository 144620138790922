import React, { useState, useEffect } from 'react';
import './ForgotPassword.css';
import LoginCard from '../../components/LoginCard/LoginCard';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { clearChangeValidateOTPError, clearResetOTPError, resetUserAuth, validateOTP } from '../../redux/actions/AuthAction';
import { LOGIN_PATH, UPDATE_PWD_PATH } from '../../router/RouteConstants';
import { GET_AUTH_USER_PENDING } from '../../redux/constants/AuthConstant';
import EyeCloseIcon from '../../assets/images/eyeClose.svg';
import Visibility from "../../assets/images/visibility.svg";
import VisibilityHideIcon from "../../assets/images/visibilityHideIcon.svg";
import { useIntl } from 'react-intl';
import back from '../../assets/images/back.svg';
import ErrorWarningMessage from '../../components/ErrorWarningMessage/ErrorWarningMessage';
import FloatLabel from '../../components/FloatLabel';

const ForgotPassword = () => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const [isVerifiedMail, setIsVerifiedMail] = useState(false);
  const [minutes, setMinutes] = useState(9);
  const [seconds, setSeconds] = useState(59);
  const [hide, setHide] = useState(true);
  const [userId, setUserId] = useState('');
  const [code, setCode] = useState('');
  const [codeType, setCodeType] = useState("password");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let timerId;
  let isSMTP = true;
  const [flag, setFlag] = useState(false);

  const {
    resetUserInfo,
    loading,
    verifiedMail,
    resetErrorCode,
    validateOtpError,
    isValidatedOTPSuccess,
  } = useSelector((state) => state.auth);
  const { userId: id, otpToken } = resetUserInfo ?? {};

  const toggleEyeIcon = () => {
    if(code?.length === 0){return;}
    if (codeType === "password") {
      setCodeType("text");
    }
    if (codeType === "text") {
      setCodeType("password");
    }
  }

  useEffect(() => {
    if (!loading && !isVerifiedMail && verifiedMail) {
      setIsVerifiedMail(true);
    }
  }, [isVerifiedMail, loading, verifiedMail]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!isVerifiedMail) {
      dispatch(resetUserAuth({ loginIdOrEmail: userId }));
    } else {
      if (!isSMTP) {
        setHide(false);
      }
      setFlag(true);
    }
  };

  useEffect(() => {
    if (flag) {
      dispatch(validateOTP({ otp: code, userId: id, otpToken }));
      setFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag]);

  useEffect(() => {
    if (isValidatedOTPSuccess) {
      navigate(UPDATE_PWD_PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidatedOTPSuccess]);

  const handleCancelForgotPwd = () => {
    setIsVerifiedMail(false);
    navigate(LOGIN_PATH);
    dispatch({ type: GET_AUTH_USER_PENDING });
  };

  const [timerFlag, setTimerFlag] = useState(false);
  useEffect(() => {
    if (timerFlag) {
      dispatch(resetUserAuth({ loginIdOrEmail: userId }));
      setTimerFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerFlag]);

  useEffect(() => {
    if (code?.length === 0) {
      setCodeType('password');
    }
  }, [code]);

  const handleResetCode = () => {
    clearTimeout(timerId);
    setMinutes(9);
    setSeconds(59);
    setCode('')
    setTimerFlag(true);
  };

  useEffect(() => {
    if (isVerifiedMail && !loading && (minutes !== 0 || seconds !== 0)) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timerId = setTimeout(() => {
        if (seconds === 0) setMinutes(minutes - 1);
        setSeconds((seconds - 1 + 60) % 60);
      }, 1000);
    }
  }, [minutes, seconds, isVerifiedMail]);

  return (
    <LoginCard
      text={f({ id: 'COM_DMS_PWD_RESET_ADMIN' })}
      isSMTP={isSMTP}
      hide={hide}
    >
      {validateOtpError.errorCode == 401003 && (
        <ErrorWarningMessage
          text={f({ id: 'COM_DMS_INVALID_OTP' })}
          className="err-warning"
          authFlag
          dispatchMethod={clearChangeValidateOTPError}
        />
      )}
      {validateOtpError.errorCode == 501001 && (
        <ErrorWarningMessage
          text={f({ id: 'COM_DMS_RESET_PASSWORD_BLOCK' })}
          className="err-warning"
          authFlag
          dispatchMethod={clearChangeValidateOTPError}
        />
      )}
      {resetErrorCode == 500001 && (
        <ErrorWarningMessage
          text={f({ id: 'COM_DMS_USER_DATA_NOT_FOUND' })}
          className="err-warning"
          authFlag
          dispatchMethod={clearChangeValidateOTPError}
        />
      )}
      {resetErrorCode == 400703 && (
        <ErrorWarningMessage
          text={f({ id: 'COM_DMS_USER_DATA_NOT_FOUND' })}
          className="err-warning"
          authFlag
          dispatchMethod={clearResetOTPError}
        />
      )}
      {resetErrorCode == 501001 && (
        <ErrorWarningMessage
          text={f({ id: 'COM_DMS_RESET_PASSWORD_BLOCK' })}
          className="err-warning"
          authFlag
          dispatchMethod={clearResetOTPError}
        />
      )}
      <form onSubmit={handleFormSubmit} className="forgot-password-form">
        {isVerifiedMail ? (
          <>
            <div className="forgot-password-text">
              {f({ id: 'COM_DMS_VERIFICATION_CODE' })}
            </div>
            <div className="forgot-pwd-subtext">
              {f({ id: 'COM_DMS_VERIFICATION_TEXT' })}
            </div>
          </>
        ) : (
          <>
            <div className="forgot-password-text">
              {f({ id: 'COM_DMS_FORGOT_PWD' })}
            </div>
            <div className="forgot-pwd-subtext">
              {f({ id: 'COM_DMS_MAIL_TEXT' })}
            </div>
          </>
        )}
        {!isVerifiedMail ? (
          <div className="forgotPasswordUserId">
            <FloatLabel
              value={userId}
              label={f({ id: 'COM_DMS_USER_ID_EMAIL' })}
              className={
                userId && !resetErrorCode
                  ? 'float-input'
                  : userId && resetErrorCode
                  ? 'error-text'
                  : ''
              }
            >
              <input
                value={userId}
                id="userId"
                className={resetErrorCode ? 'error-boundary' : ''}
                onChange={(e) => setUserId(e.target.value)}
              />
            </FloatLabel>
          </div>
        ) : (
          <>
            <div className="input">
              <FloatLabel
                value={code}
                label={f({ id: 'COM_DMS_VERIFICATION_CODE' })}
                className={
                  code && !validateOtpError.errorCode
                    ? 'float-input'
                    : code && validateOtpError.errorCode
                    ? 'error-text'
                    : ''
                }
              >
                <input
                  type={codeType}
                  id="code"
                  value={code}
                  maxLength={6}
                  className={validateOtpError.errorCode ? 'error-boundary' : ''}
                  onChange={(e) => setCode(e.target.value)}
                  disabled={minutes === 0 && seconds === 0}
                />
                 <div className="eyeIcon" onClick={toggleEyeIcon}>
              {codeType === "password" ? code?.length > 0 ? (
                      <img src={EyeCloseIcon} alt="visibility" />
                    ) : (
                      <img src={VisibilityHideIcon} alt="visibility" className="hiddenEye"/>
                    ) : (
                <img src={Visibility} alt="visibility" />
              ) }
              </div>
              </FloatLabel>
            </div>
            <div className="forgot-pwd-reset-field">
              <div className="time_code_expire">
                {`${f({ id: 'COM_DMS_TIME_REMAINING' })} 0${minutes} : `}
                {seconds < 10 ? `0${seconds}` : seconds}{' '}
              </div>

              <div
                className={
                  !minutes && !seconds
                    ? 'resend-code'
                    : 'resend-code resend_blur'
                }
                onClick={handleResetCode}
              >
                <u>{f({ id: 'COM_DMS_RESEND_CODE' })}</u>
              </div>
            </div>
          </>
        )}

        <div className="send-code-btn">
          <CustomButton
            type="Submit"
            text={f({ id: 'COM_DMS_CONTINUE' })}
            disabled={
              !isVerifiedMail
                ? !userId.trim()
                : (!minutes && !seconds) || code.length !== 6
            }
            className={!isVerifiedMail ? 'pwd_continue' : 'code_submit'}
          />
          <br />
          <div onClick={handleCancelForgotPwd} className="cancelPwdLink">
            <img src={back} alt="back" className="backIcon" />
            {f({ id: 'COM_DMS_BACK_TO_LOGIN' })}
          </div>
        </div>
      </form>
    </LoginCard>
  );
};

export default ForgotPassword;
