import React, { useEffect, useRef, useState } from 'react';
import './AppInstall.css';
import { Button } from 'primereact/button';
import SearchBar from '../../../components/SearchBar/SearchBar';
import AppTable from '../../../components/AppTable/AppTable';
import { useIntl } from 'react-intl';
import { APP_URL } from '../../../config/constants/Constants';
import { RestServiceUtils } from '../../../utils/RestServiceUtils';
import AppInstallPopup from './AppInstallPopup';
import Spinner from '../../../components/Spinner/Spinner';
import { Toast } from 'primereact/toast';
import UninstallIcon from '../../../assets/images/deleteIconBlack.svg';
import AndroidIcon from '../../../assets/images/androidIcon.png'
import { setToastComponent, showSuccessToast } from '../../../services/APIResponseHandler';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';

const AppInstall = ({ refreshPage }) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const APP_NAME = f({ id: 'COM_DMS_APP_NAME' });
  const VERSION = f({ id: 'COM_DMS_VERSION' });
  const APP_SIZE = f({ id: 'COM_DMS_APP_SIZE' });
  const APP_INSTALL = f({ id: 'COM_DMS_APP_INSTALL' });
  const SEARCH = f({ id: 'COM_DMS_SEARCH' });
  const INITIAL_ROWS = 10;
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(INITIAL_ROWS);
  const fileInputRef = useRef(null);
  const [devices, setDevices] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [appData, setAppData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [recordCount, setRecordCount] = useState(0);
  const [searchTextVal, setSearchTextVal] = useState('');
  const [appRowData, setAppRowData] = useState({});
  const [deleteAppDialogVisible, setDeleteAppDialogVisible] = useState(false);
  const deleteAppDialogState = {
    header: f({ id: "COM_DMS_ARE_YOU_SURE" }),
    body: f({ id: "COM_DMS_DELETE_APP_MESSAGE" }),
    footer: f({ id: "COM_DMS_UNDONE" }),
    action: f({ id: "COM_DMS_DELETE" }),
    cancel: f({ id: "COM_DMS_CANCEL" })
  };
  const [selectedAppToBeDeleted, setSelectedAppToBeDeleted] = useState("");
  const CANCEL = f({ id: 'COM_DMS_CANCEL' });
  const INSTALL = f({ id: 'COM_DMS_INSTALL' });

  const toast = useRef(null);

  useEffect(() => {
    setToastComponent(toast.current);
  }, []);

  const deleteAppDialogHandler = () => {
    RestServiceUtils.HTTP_DELETE(`${APP_URL}/delete/${selectedAppToBeDeleted}`).then((res) => {
      res?.status === 200 && showSuccessToast(f({ id: 'COM_DMS_APP_DELETE_MESSAGE' }))
      initialAppDataFetch(first, rows, '', '');
      setFirst(0);
      setRows(INITIAL_ROWS);
      setSearchTextVal('');
    });

    setDeleteAppDialogVisible(false);
  }

  const appInstallBtn = (data) => {
    const handleAppAction = () => {
      setAppRowData(data);
      setOpenDialog(true);
    };

    return (
    <>
      <Button className="manageApp_btn" onClick={handleAppAction}>{f({ id: 'COM_DMS_INSTALL' })}</Button>
      <img
      src={UninstallIcon}
      alt="uninstallButton"
      onClick={() => {setSelectedAppToBeDeleted(data?.appPackageName); setDeleteAppDialogVisible(true);}}
      className="app_install_delete_icon"
      />
    </>
    );
  };

  const initialAppDataFetch = (
    startIndex,
    pageSize,
    searchText,
    sortColumn
  ) => {
    setLoading(true);
    RestServiceUtils.HTTP_POST(`${APP_URL}/upload/filter`, {
      startIndex,
      pageSize,
      searchText,
      sortColumn,
    })
      .then(({ data }) => {
        const filteredAppData = [];
        const { totalCount, uploadedApps } = data ?? {};
        uploadedApps?.forEach((val) => {
          filteredAppData.push({
            appImg: val.imgSrc,
            appName: val.appName,
            size: val.appSize,
            version: val.appVersionName,
            appPackageName: val.appPackageName,
          });
        });
        setFirst(startIndex);
        setRows(pageSize);
        setRecordCount(totalCount);
        setLoading(false);
        setAppData(filteredAppData);
      })
      .catch((err) => {
        console.log('Error : ', err);
        setLoading(false);
      });
  };

  useEffect(() => {
    initialAppDataFetch(first, rows, '', '');
    setFirst(0);
    setRows(INITIAL_ROWS);
    setSearchTextVal('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const appNameCol = (data) => {
    const { appImg, appName } = data ?? {};
    return (
      <div style={{ display: 'flex' }}>
        <img
          src={appImg !== "" ? appImg : AndroidIcon}
          style={{ height: '24px', marginRight: '1rem' }}
          alt='appImg'
        />
        <div>{appName}</div>
      </div>
    );
  };

  const appSizeBody = (data) => {
    const { size } = data ?? {};
    return <>{size} MB</>;
  };

  const columns = [
    {
      field: 'appName',
      header: APP_NAME,
      body: appNameCol,
      style: { width: '30%' },
    },
    { field: 'size', header: APP_SIZE, body: appSizeBody, style: { width: '20%' } },
    { field: 'version', header: VERSION, style: { width: '40%' } },
    {
      field: 'appInstall',
      header: APP_INSTALL,
      body: appInstallBtn,
      style: { width: '10%' },
    },
  ];

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    initialAppDataFetch(event.first, event.rows, searchTextVal, '');
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.name?.length > 4 && file.name.endsWith('.apk')) {
        setLoading(true);
        RestServiceUtils.HTTP_POST(
          APP_URL,
          { file },
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
          .then(({ data }) => {
            setLoading(false);
            if(data === 'success') {
              showSuccessToast(f({id: 'COM_DMS_APP_UPLOAD_SUCCESS_MESSAGE'}));
            }
            // const { errorMessage } = data ?? {};
            // if (errorMessage) {
            //   toast.current.show({
            //     severity: 'error',
            //     summary: errorMessage,
            //     life: 2000,
            //   });
            // }
            initialAppDataFetch(0, rows, searchTextVal, '');
          })
          .catch((err) => {
            setLoading(false);
          });
      }else {
        toast.current.show({
          severity: 'error',
          summary: 'Please upload a valid apk file',
          life: 2000,
        });
      }
    }
  };

  const handleUploadApk = () => {
    fileInputRef.current.click();
  };

  const handleInstallApps = () => {
    const deviceIds = devices.map((device) => device.data.deviceMac);
    const appAction = 'INSTALL';
    const payload = {
      targetDevices: {
        deviceIds: deviceIds,
        groupIds: []
      },
      appAction,
    };
    setOpenDialog(false);
    RestServiceUtils.HTTP_POST(
      `${APP_URL}/${appRowData.appPackageName}`,
      payload
    ).then((res) => res?.status === 200 && showSuccessToast(f({ id: 'COM_DMS_APP_INSTALL_MESSAGE' })));
  };

  const searchAppHandler = () => {
    initialAppDataFetch(0, rows, searchTextVal, '')
  }

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      searchAppHandler();
    }
  }

  const appInstallHeader = () => {
    return (
      <div className="install-app-header-container">
        <div className="manageAppsHeader">
          <div className="manageAppsHeaderContent">{'Install App'}</div>
          <div>
            <Button
              className="manageAppCancel manageApp_btn"
              label={CANCEL}
              onClick={() => setOpenDialog(false)}
            />
            <Button
              className="manageAppAction manageApp_btn"
              label={INSTALL}
              disabled={!devices?.length}
              onClick={handleInstallApps}
            />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <img
            src={appRowData?.appImg !== ""? appRowData?.appImg : AndroidIcon }
            className="appIconStyle"
            alt='app-icon'
          />
          <div className="appNameStyle">{appRowData?.appName}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} position="top-center"/>
      {loading && <Spinner />}
      <div className="app-install">
        {openDialog && (
          <AppInstallPopup
            devices={devices}
            setDevices={setDevices}
            appRowData={appRowData}
            openDialog={openDialog}
            manageAppHeader={appInstallHeader}
            setOpenDialog={setOpenDialog}
          />
        )}
        <div className="app-install-header">
          <div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={onFileChange}
              onClick={(e) => (e.target.value = "")}
              style={{ display: 'none' }}
            />
            <Button className="app-upload-btn" onClick={handleUploadApk}>
              {f({ id: 'COM_DMS_UPLOAD_APP' })}
            </Button>
          </div>
          <div>
            <SearchBar
              placeholder={SEARCH}
              value={searchTextVal}
              setValue={setSearchTextVal}
              className='app-install-search'
              searchHandler={searchAppHandler}
              handleInputKeyDown={handleInputKeyDown}
            />
          </div>
        </div>
        <div className="app-install-content">
          <AppTable
            columns={columns}
            data={appData}
            first={first}
            rows={rows}
            totalCount={recordCount}
            onPageChange={onPageChange}
            emptyMessageId={'COM_DMS_NO_APPS_TO_INSTALL'}
            className="app-install-table"
          />
        </div>
        {deleteAppDialogVisible  && (
         <CustomDialog
         dialogState={deleteAppDialogState}
         handleClick={deleteAppDialogHandler}
         dialogVisible={deleteAppDialogVisible}
         setDialogVisible={setDeleteAppDialogVisible}/>
      )}
      </div>
    </>
  );
};

export default AppInstall;
