import React from "react";
import "./ResetSuccess.css";
import LoginCard from "../../components/LoginCard/LoginCard";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useNavigate } from "react-router";
import { LOGIN_PATH } from "../../router/RouteConstants";
import { useIntl } from "react-intl";
import success_pwd from "../../assets/images/success_pwd.png"

const ResetSuccess = () => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const navigate = useNavigate();

  const handleResetSuccess = () => {
    navigate(LOGIN_PATH);
  };

  return (
    <LoginCard>
      <img src={success_pwd} alt="Success" />
      <div className="reset-success">
        <div className="reset-success-header">
          {f({ id: "COM_DMS_PASSWORD_RESET_SUCCESS" })}
        </div>
        <div className="reset-success-text">
          {f({ id: "COM_DMS_NEW_PWD_TXT" })}
        </div>
        <CustomButton
          className="reset-success-btn"
          text="Ok"
          onClick={handleResetSuccess}
        />
      </div>
    </LoginCard>
  );
};

export default ResetSuccess;
