
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "./Datatable.css"
import { useIntl } from 'react-intl';


export default function DataTableComponent(props) {

    const intl = useIntl();
    const { formatMessage: f } = intl;

    return (
        <>
        <div className={`datatable-users ${props.className}`}>
            <DataTable value={props.data}
                className = 'users-checkbox'
                selectionMode="checkbox"
                selection={props.selectedItems}
                onSelectionChange={(e) => props.setSelectedItems(e.value)}
                scrollable
                scrollHeight="66vh"
                emptyMessage={f({ id: 'COM_DMS_NO_RESULT' })}
                >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                    {props.headerInfo.map(data => <Column field={data.field} header={data.header} style={data.width ? { width: data.width } : {}}  body={(data.field === "loginId" && props.editUserBody) || (data.field === "deviceName" && props.editDeviceNameTemplate) || (data.field === "tags" && props.deviceTagsTemplate) || (data.field === "deviceGroups" && props.deviceGroupsTemplate) || (data.field === "devicePermission" && props.devicePermissionTemplate) || (data.field === "registerDate" && props.registeredDateTemplate) || (data.field === "joiningDate" && props.joiningDateTemplate) || (data.field === "deviceScreenPreview" && props.screenPreviewTemplate) || (data.field === "connectionStatus" && (props.connectionStatusTemplate))} />)}
            </DataTable>
        </div>
        </>
    );
}

