import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { TreeTable } from 'primereact/treetable';
import './DevicePermissionPopup.css';
import { useIntl } from 'react-intl';
import flatToTree from 'flat-to-tree';
import {
  cleanJSONTree,
  removeNullValuesFromNestedObjectOfArray,
} from '../../../src/utils/CleanJSONArrayObject';
import { convertJSONDataToTreeTableFormat } from '../../../src/modules/Devices/Group/convertToJSONTree';
import { userServices } from '../../services/RestServices/userServices';
import { GroupManagementService } from '../../services/RestServices/GroupManagementService';
import MinusIcon from '../../../src/assets/images/minusIcon.svg';
import PlusIcon from '../../../src/assets/images/plusIcon.svg';
import GroupIcon from '../../../src/assets/images/groupIcon.svg';
import ArrowDownIcon from '../../../src/assets/images/arrowDownIcon.svg';

export const DevicePermissionPopup = (props) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const setVisible = props.setVisible;
  const visible = props.visible;
  const userId = props.userId;

  const [jsonGroupTree, setJsonGroupTree] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedCheckedKeys, setSelectedCheckedKeys] = useState([]);
  const [initialFlattenedData, setInitialFlattenedData] = useState([]);
  const [partialCheckedParentKeys, setPartialCheckedParentKeys] = useState([]);

  const onHide = () => {
    setVisible(false);
  };

  useEffect(() => {
    GroupManagementService.getAllGroups().then(({ data }) => {
      const convertedJSONData = flatToTree(data, {
        id: 'groupId',
        parentId: 'parentId',
      });
      setInitialFlattenedData(data);
      convertJSONDataToTreeTableFormat(convertedJSONData);
      cleanJSONTree(convertedJSONData);
      const cleanedJSONTree =
        removeNullValuesFromNestedObjectOfArray(convertedJSONData);
      setJsonGroupTree(cleanedJSONTree);
    });

    userServices.getUserDevicePermissions(userId).then(({ data }) => {
      setSelectedCheckedKeys(data?.parentGroupIds);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fillParentIds = (tree, id, res) => {
    if (id === -1) {
      return res;
    }

    let findNode = tree.filter((data) => data?.groupId === id);
    if (findNode.length > 0) {
      res.push(findNode[0].groupId);
      fillParentIds(tree, findNode[0].parentId, res);
    }
  };

  useEffect(() => {
    const partialKeys = [];
    for (let idx = 0; idx < selectedCheckedKeys?.length; idx++) {
      const res = [];
      fillParentIds(initialFlattenedData, selectedCheckedKeys[idx], res);
      partialKeys.push(res);
    }
    setPartialCheckedParentKeys(partialKeys.flat());

    setSelectedKeys([]);
    let selectedData = [];
    selectedCheckedKeys?.forEach((groupId) => {
      let subGroupIds = [];
      getAllChildren(jsonGroupTree, subGroupIds, groupId);
      selectedData.push(subGroupIds.flat());
    });
    setSelectedKeys(selectedData.flat());
  }, [selectedCheckedKeys, jsonGroupTree]);

  const getAllChildren = (treeData, subGroupIds, key) => {
    for (let index = 0; index < treeData?.length; index++) {
      if (treeData[index]?.key === key) {
        subGroupIds.push(treeData[index]?.allChildren);
      }
      getAllChildren(treeData[index]?.children, subGroupIds, key);
    }
  };

  const addGroupBody = ({ data, key }) => {
    const { groupName } = data ?? {};
    const selectedData = new Set(selectedKeys);
    const partialCheckedKeysData = new Set(partialCheckedParentKeys);
    const selectedCheckedData = new Set(selectedCheckedKeys);

    return (
      <>
        <img src={GroupIcon} alt="group" style={{ marginRight: '1rem' }} />
        <div
          style={{ display: 'inline' }}
          className={
            !selectedCheckedData.has(key) ? '' : 'device-permission-group'
          }
        >
          {groupName}
        </div>
        {partialCheckedKeysData.has(key) && !selectedData.has(key) && (
          <img
            src={ArrowDownIcon}
            alt="devicePermissionIcon"
            className="device-permission-partial-check"
          />
        )}
      </>
    );
  };

  const selectGroupBody = ({ data, key }) => {
    const selectedData = new Set(selectedKeys);
    const selectedCheckedData = new Set(selectedCheckedKeys);
    const partialCheckedKeysData = new Set(partialCheckedParentKeys);

    return (
      <Checkbox
        checked={selectedData.has(key)}
        className={`${
          partialCheckedKeysData.has(key) && !selectedData.has(key)
            ? 'partial-checked-key'
            : ''
        }`}
        disabled={selectedData.has(key) && !selectedCheckedData.has(key)}
        onChange={() => {
          if (selectedData.has(key)) {
            selectedCheckedData.delete(key);
          } else {
            const subGroupIds = [];
            getAllChildren(jsonGroupTree, subGroupIds, key);
            subGroupIds?.flat()?.forEach((id) => {
              if (selectedCheckedData.has(id)) {
                selectedCheckedData.delete(id);
              }
            });
            selectedCheckedData.add(key);
          }
          setSelectedCheckedKeys(Array.from(selectedCheckedData));
        }}
      />
    );
  };

  const setUserGroups = () => {
    userServices.setUserDevicePermissions(userId, selectedCheckedKeys).then((res) => {
      if (res?.status === 200) {
        props.setDevicePermissionSuccess(true);
      }
    });
  };

  const headerContent = () => {
    return (
      <div className="add-user-dialog-header">
        {f({ id: 'COM_DMS_DEVICE_PERMISSIONS' })}
        <Button
          className="add-user add-user-save device-perm-btns"
          label={f({ id: 'COM_DMS_SAVE_CHANGES' })}
          onClick={() => {
            setUserGroups();
            onHide();
          }}
        />
        <Button
          className="add-user device-perm-btns"
          label={f({ id: 'COM_DMS_CLOSE' })}
          onClick={onHide}
        />
      </div>
    );
  };

  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }
    const expanded = options.expanded;
    return (
      <button
        type="button"
        className="p-treetable-toggler p-link"
        style={options.buttonStyle}
        tabIndex={-1}
        onClick={options.onClick}
      >
        <span>
          {expanded ? (
            <img src={MinusIcon} className="icon" alt="minus" />
          ) : (
            <img src={PlusIcon} className="icon" alt="plus" />
          )}
        </span>
      </button>
    );
  };

  return (
    <div className="card flex justify-content-center">
      <Dialog
        modal
        header={headerContent}
        visible={visible}
        className="device-permission-dialog"
        position={'bottom-right'}
        draggable={false}
        onHide={() => setVisible(false)}
      >
        <div>
          <span className="device-permission-header">
            {f({ id: 'COM_DMS_SELECT' })}
          </span>

          <div className="device-permission-table card flex justify-content-center">
            <TreeTable
              className="device-permission-tree-table"
              value={jsonGroupTree}
              togglerTemplate={togglerTemplate}
            >
              <Column
                field="checkbox"
                style={{ width: '25px' }}
                body={selectGroupBody}
              ></Column>
              <Column
                field="groupName"
                header=""
                expander
                body={addGroupBody}
              />
            </TreeTable>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
