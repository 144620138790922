import React, { useEffect, useState, useRef, useCallback } from 'react';
import './MessagingHistory.css';
import AppTable from '../../../../../components/AppTable/AppTable';
import ViewIcon from '../../../../../assets/images/visibility.svg';
import RecallIcon from '../../../../../assets/images/undo-icon.svg';
import RecallDisableIcon from '../../../../../assets/images/undo-disabled-icon.svg';
import MessagingHistoryView from './MessagingHistoryView';
import { RestServiceUtils } from '../../../../../utils/RestServiceUtils';
import { MESSAGE_ALERT_HISTORY_URL } from '../../../../../config/constants/Constants';
import DownArrowIcon from '../../../../../assets/images/downArrow.svg';
import UpArrorIcon from '../../../../../assets/images/upArrow.svg';
import { RadioButton } from 'primereact/radiobutton';
import {
  setToastComponent,
  showErrorToast,
  showSuccessToast,
} from '../../../../../services/APIResponseHandler';
import { Toast } from 'primereact/toast';
import CustomDialog from '../../../../../components/CustomDialog/CustomDialog';
import { useIntl } from 'react-intl';

const MessagingHistory = ({ refreshPage }) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const toastRef = useRef(null);
  const [historyId, setHistoryId] = useState(-1);

  const INITIAL_HISTORY_PAYLOAD = {
    startIndex: 0,
    pageSize: 10,
  };

  const statusFilterOptions = [
    { label: f({ id: 'COM_DMS_ALL' }), value: f({ id: 'COM_DMS_ALL' }) },
    { label: f({ id: 'COM_DMS_ACTIVE' }), value: f({ id: 'COM_DMS_ACTIVE' }) },
    {
      label: f({ id: 'COM_DMS_INACTIVE' }),
      value: f({ id: 'COM_DMS_INACTIVE' }),
    },
    {
      label: f({ id: 'COM_DMS_RECALLED' }),
      value: f({ id: 'COM_DMS_RECALLED' }),
    },
  ];

  const [openMessageHistory, setOpenMessageHistory] = useState(false);
  const [openStatusFilter, setOpenStatusFilter] = useState(false);
  const [filteredStatus, setFilteredStatus] = useState('All');
  const [currentViewHistoryId, setCurrentViewHistoryId] = useState(-1);
  const [historyData, setHistoryData] = useState([]);
  const [totalHistoryRecordCount, setTotalHistoryRecordCount] = useState(10);
  const [historyPayload, setHistoryPayload] = useState(INITIAL_HISTORY_PAYLOAD);
  const [isRecalledPopup, setIsRecalledPopup] = useState(false);

  const getInitialHistoryData = useCallback(() => {
    RestServiceUtils.HTTP_POST(`${MESSAGE_ALERT_HISTORY_URL}`, {
      ...historyPayload,
      status: filteredStatus,
    }).then(({ data }) => {
      const { items, totalCount } = data ?? {};
      setHistoryData(items);
      setTotalHistoryRecordCount(totalCount);
    });
  }, [filteredStatus, historyPayload]);

  useEffect(() => {
    getInitialHistoryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredStatus, historyPayload ]);

  const statusBody = (data) => {
    const { status } = data ?? {};
    return <div>{status}</div>;
  };

  const startTimeBody = (data) => {
    const { startTime } = data ?? {};
    const newDate = new Date(startTime);

    return <div>{newDate.toLocaleString()}</div>;
  };

  const endTimeBody = (data) => {
    const { endTime } = data ?? {};
    const newDate = new Date(endTime);
    return <div>{newDate.toLocaleString()}</div>;
  };

  const viewBody = (data) => {
    const { id: historyId } = data ?? {};
    return (
      <div className="messaging-history-icon-div">
        <img
          src={ViewIcon}
          alt="view"
          onClick={() => {
            setCurrentViewHistoryId(historyId);
            setOpenMessageHistory(true);
          }}
          className="icon messaging-history-view-icon"
        />
      </div>
    );
  };

  const recallBody = (data) => {
    const { id, status } = data ?? {};
    return (
      <div className="messaging-history-recall-icon-div">
        {status === 'Active' ? (
          <img
            src={RecallIcon}
            alt="recall"
            onClick={() => {
              setHistoryId(id);
              setIsRecalledPopup(true);
            }}
            className="icon messaging-history-recall-icon"
          />
        ) : (
          <img
            src={RecallDisableIcon}
            alt="recall"
            className="icon messaging-history-recall-icon"
          />
        )}
      </div>
    );
  };

  const statusFilterComponent = () => {
    return (
      <div className="messaging-status-header">
        {statusFilterOptions.map(({ label, value }, index) => (
          <div key={index}>
            <RadioButton
              inputId={index}
              name={label}
              value={value}
              onChange={(e) => {
                setFilteredStatus(e.value);
                setOpenStatusFilter(false);
                setHistoryPayload(INITIAL_HISTORY_PAYLOAD);
              }}
              checked={filteredStatus === value}
            />
            <label htmlFor={index}>{label}</label>
          </div>
        ))}
      </div>
    );
  };

  const statusHeader = () => {
    return (
      <div className="message-history-status-header">
        <div>{f({ id: 'COM_DMS_STATUS' })}</div>
        <div>
          <img
            src={openStatusFilter ? UpArrorIcon : DownArrowIcon}
            alt="down"
            className="message-history-status-header-src"
            onClick={() => setOpenStatusFilter(!openStatusFilter)}
          />
        </div>
        {openStatusFilter && statusFilterComponent()}
      </div>
    );
  };

  const historyColumns = [
    {
      field: 'alertName',
      header: f({ id: 'COM_DMS_ALERT_NAME' }),
      style: { width: '20%' },
    },
    {
      field: 'startTime',
      header: f({ id: 'COM_DMS_SENT_ON' }),
      body: startTimeBody,
      style: { width: '24%' },
    },
    {
      field: 'endTime',
      header: f({ id: 'COM_DMS_END_TIME' }),
      body: endTimeBody,
      style: { width: '24%' },
    },
    {
      field: 'status',
      header: statusHeader,
      body: statusBody,
      style: { width: '16%' },
    },
    {
      field: 'view',
      header: f({ id: 'COM_DMS_VIEW' }),
      body: viewBody,
      style: { width: '8%' },
    },
    {
      field: 'recall',
      header: f({ id: 'COM_DMS_RECALL' }),
      body: recallBody,
      style: { width: '8%' },
    },
  ];

  useEffect(() => {
    setToastComponent(toastRef.current);
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setHistoryPayload({ startIndex: event.first, pageSize: event.rows });
  };

  const dialogState = {
    header: f({ id: 'COM_DMS_RECALL_ALERT' }),
    body: f({ id: 'COM_DMS_RECALL_ALERT_TEXT' }),
    footer: '',
    action: f({ id: 'COM_DMS_YES' }),
    cancel: f({ id: 'COM_DMS_NO' }),
  };

  const handlePopupAction = () => {
    const recallActiveAlertPayload = {
      event: 'STOP',
      historyId: [historyId],
    };

    RestServiceUtils.HTTP_POST(
      `${MESSAGE_ALERT_HISTORY_URL}/recallMessageAlert`,
      recallActiveAlertPayload
    ).then(({ data }) => {
      if (data === 'success') {
        showSuccessToast(f({ id: 'COM_DMS_ALERT_RECALL_SUCCESS_MSG' }));
        getInitialHistoryData();
      } else {
        showErrorToast(f({ id: 'COM_DMS_ALERT_RECALL_ERROR_MSG' }));
      }
      setIsRecalledPopup(false);
    });
  };

  return (
    <>
      <Toast ref={toastRef} position="top-center" />
      <CustomDialog
        dialogState={dialogState}
        handleClick={handlePopupAction}
        dialogVisible={isRecalledPopup}
        setDialogVisible={setIsRecalledPopup}
      />
      {openMessageHistory && (
        <MessagingHistoryView
          historyId={currentViewHistoryId}
          setHistoryId={setCurrentViewHistoryId}
          openMessageHistory={openMessageHistory}
          setOpenMessageHistory={setOpenMessageHistory}
        />
      )}
      <div className="messaging-history">
        <AppTable
          className="messaging-history-table"
          columns={historyColumns}
          data={historyData}
          first={first}
          rows={rows}
          onPageChange={onPageChange}
          totalCount={totalHistoryRecordCount}
          emptyMessageId={'COM_DMS_NO_HISTORY_RECORDS'}
        />
      </div>
    </>
  );
};

export default MessagingHistory;
