import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import './EditUserPopup.css';
import { Checkbox } from 'primereact/checkbox';
import { useIntl } from 'react-intl';
import { userServices } from '../../services/RestServices/userServices';
import upArrow from '../../assets/images/upArrow.svg';
import downArrow from '../../assets/images/downArrow.svg';
import CopyIcon from '../../assets/images/copyIcon.svg';
import { isAdminUser } from '../../utils/helper';
import VisibilityIcon from '../../assets/images/visibility.svg';
import VisibilityHideIcon from '../../assets/images/visibilityHideIcon.svg';
import EyeCloseIcon from '../../assets/images/eyeClose.svg';
import { contactNumberMaxLength, emailMaxLength, passwordMaxLength, userNameMaxLength, validatePasswordFields, validateUserDetails  } from '../../utils/Validations';
import PasswordValidatorTooltip from '../../components/Tooltip/PasswordValidatorTooltip/PasswordValidatorTooltip';
import { useSelector } from 'react-redux';
import { showErrorToast, showSuccessToast } from '../../services/APIResponseHandler';
import { copyNavigatorToClipBoardPassword } from '../../utils/CopyClipboardUtil';


export const EditUserPopup = (props) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const visible = props.visible;
  const [defaultPasswordChecked, setDefaultPasswordChecked] = useState(true);
  const [passwordCopied, setPasswordCopied] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const loginUserRoleName = localStorage.getItem('loginUserRoleName');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [focussedPassword, setFocussedPassword] = useState(false);
  const [isMFAInfoChecked, setIsMFAInfoChecked] = useState(false);

  const validatePassword = validatePasswordFields(props.userDetailsToBeEdited.password);

  useEffect(() => {
    if (passwordCopied) {
      setTimeout(() => {
        setPasswordCopied(false);
      }, 3000);
    }
  }, [passwordCopied]);
  const { serverSettingsInfo } = useSelector((state) => state.serverSettings);
  const { passwordSettings } = serverSettingsInfo ?? {};
  const [password, setPassword] = useState((passwordSettings?.isDefaultPasswordUsed) ? localStorage.getItem('DefaultPassword') : '');
  const [confirmPassword, setConfirmPassword] = useState((passwordSettings?.isDefaultPasswordUsed) ? localStorage.getItem('DefaultPassword') : '');

  const roles =
    loginUserRoleName === f({ id: 'COM_DMS_USER_ADMIN' })
      ? [
          { name: f({ id: 'COM_DMS_USER_SUB_ADMIN' }) },
          { name: f({ id: 'COM_DMS_USER_DEVICE_MANAGER' }) },
          { name: f({ id: 'COM_DMS_USER_DEVICE_OPERATOR' }) },
        ]
      : loginUserRoleName === f({ id: 'COM_DMS_USER_SUB_ADMIN' })
      ? [
          { name: f({ id: 'COM_DMS_USER_DEVICE_MANAGER' }) },
          { name: f({ id: 'COM_DMS_USER_DEVICE_OPERATOR' }) },
        ]
      : '';

  const [expandKey, setExpandKey] = useState(false);
  const [userDetailsError, setUserDetailsError] = useState('');

  const checkPasswordType = (type, setType) => {
    if (type === 'text') {
      setType('password');
    } else {
      setType('text');
    }
  };

  useEffect(() => {
    document
      .querySelector('.userDetails-form .details_col #editPassword')
      ?.addEventListener('focus', () => {
        setFocussedPassword(true);
      });
    document
      .querySelector('.userDetails-form .details_col #editPassword')
      ?.addEventListener('focusout', () => {
        setFocussedPassword(false);
      });
  });

  const userDataInputFieldHandler = (event) => {

    const { name, value } = event.target;
    let res;

    if(name === 'password') {
      res = validateUserDetails(name, value);
      const {isValid, messageId} = res;
      if(!isValid) {
        setUserDetailsError((value === '') ? '' : f({ id: messageId }));
      } else {
        setUserDetailsError('');
      }
      setPassword(value?.trim());
      props.setUserDetailsToBeEdited({...props.userDetailsToBeEdited, [name]: value?.trim()});
    } else if(name === 'confirmPassword') {
      const {password} = props.userDetailsToBeEdited;
      if(/\s/.test(password)) {
        setUserDetailsError(f({ id: 'COM_DMS_INVALID_PASSWORD_CONTAIN_SPACE'}));
      } else if(password !== value){
        setUserDetailsError(f({ id: 'COM_DMS_PASSWORD_CONFIRM_PASSWORD_NOT_MATCHING'}));
      } else {
        setUserDetailsError('');
      }
      setConfirmPassword(value?.trim());
      props.setUserDetailsToBeEdited({...props.userDetailsToBeEdited, [name]: value?.trim()});
    } else {
      const res = validateUserDetails(name, value);
      const { isValid, messageId} = res;
      if(!isValid) {
        setUserDetailsError((value === '') ? '' : f({ id: messageId }));
      } else {
        setUserDetailsError('');
      }
      props.setUserDetailsToBeEdited({...props.userDetailsToBeEdited, [name]: value?.trim()});
    }    
  };

  const editUserDetailHandler = async (userDetails) => {
    let isValid = true;
    let messageId = '';
    let value;
    let res;
    setUserDetailsError('');

    if(isValid) {
      value = userDetails.userName;
      res = validateUserDetails('userName', value);
      isValid = res.isValid;
    }

    if(isValid && userDetails.email !== '') {
      value = userDetails.email;
      res = validateUserDetails('email', value);
      isValid = res.isValid;
    }

    if(isValid && userDetails.contactNumber !== '') {
      value = userDetails.contactNumber;
      res = validateUserDetails('contactNumber', value);
      isValid = res.isValid;
    }

    if(isValid && expandKey) {
      value = password;
      res = validateUserDetails('password', value);
      isValid = res.isValid;
      if(isValid) {
          if(!defaultPasswordChecked && password !== confirmPassword) {
            isValid = false;
            setUserDetailsError(f({ id: 'COM_DMS_PASSWORD_CONFIRM_PASSWORD_NOT_MATCHING' }));
          }
        } else {
          messageId = res.messageId;
          setUserDetailsError(f({ id: messageId }));
        }
    }

    if(isValid) {
      setUserDetailsError('');
      await Promise.all([
        userServices
          .editUserInfo({...userDetails, password : (expandKey) ? password : '', confirmPassword : (expandKey) ? confirmPassword : '', resetMfaInfo: isMFAInfoChecked}, props.selectedItems[0].userId)
          .then((res) => {
            res?.status === 200 && showSuccessToast("User Info Updated Successfully!")
          }),
      ]);
      props.setVisible(false);
      props.setSelectedItems([]);
    } else {
      messageId = res.messageId;
      setUserDetailsError(f({ id: messageId }));
    }
    
  };

  const onHide = () => {
    props.setVisible(false);
    props.setSelectedItems([]);
  };

  const enableEditButton = () => {
    let flag = false;
    if (props.userDetailsToBeEdited !== undefined) {
      if (
        props.userDetailsToBeEdited.userName === '' ||
        props.userDetailsToBeEdited.roleName === '' ||
        userDetailsError !== ''
      ) {
        flag = true;
      }
    }
    return flag;
  };

  const headerContent = () => {
    return (
      <div className="edit-user-dialog-header">
        {f({ id: 'COM_DMS_EDIT_USER' })}
        <Button
          className="edit-user edit-user-save"
          label={f({ id: 'COM_DMS_SAVE' })}
          onClick={() => editUserDetailHandler(props.userDetailsToBeEdited)}
          disabled={
            enableEditButton() || (
              expandKey && ((!defaultPasswordChecked &&
                (!password ||
                  !confirmPassword)) ||
                  (defaultPasswordChecked && !password))
            )
          }
        />
        <Button
          className="edit-user"
          label={f({ id: 'COM_DMS_DISCARD' })}
          onClick={onHide}
        />
      </div>
    );
  };

  const temporaryPasswordHandler = () => {
    setUserDetailsError('');
    !defaultPasswordChecked &&
      userServices.getTemporaryPassword().then((res) => {
        const { data } = res;
        setConfirmPassword(data);
        setPassword(data);
      });
  };

  useEffect(() => {
    passwordSettings?.isDefaultPasswordUsed ? setDefaultPasswordChecked(true) : setDefaultPasswordChecked(false);
  }, []);

  useEffect(() => {
    if (confirmPassword?.length === 0) {
      setConfirmPasswordType('password');
    }
  }, [confirmPassword]);

  useEffect(() => {
    if (password?.length === 0) {
      setPasswordType('password');
    }
  }, [password]);

  return (
    <div className="card flex justify-content-center">
      {passwordCopied && (
        <div className="toast-message-success">
          {f({ id: 'COM_DMS_PASSWORD_COPIED_TO_CLIPBOARD' })}
        </div>
      )}
      <Dialog
        header={headerContent}
        visible={visible}
        className="edit-user-dialog"
        position={'bottom-right'}
        draggable={false}
        onHide={onHide}
      >
        <div>
          <span className="edit-details">
            {f({ id: 'COM_DMS_INFORMATION' })}
          </span>
          <div className="userDetails-form">
            <div className="details_col">
              <label className="name_label_pos">
              <span style={{ color: 'red', fontWeight: 800 }}>*</span>{' '}
                <span>{f({ id: 'COM_DMS_USER_ID' })}</span>
              </label>
              <InputText
                name="loginId"
                className="user_details md:w-14rem"
                defaultValue={props.selectedItems[0].loginId}
                type="text"
                autoComplete="no"
                disabled
                placeholder={f({ id: 'COM_DMS_USER_ID_TXT' })}
              />
            </div>
            <div className="details_col">
              <label className="name_label_pos">
              <span style={{ color: 'red', fontWeight: 800 }}>*</span>{' '}
                <span>{f({ id: 'COM_DMS_USER_NAME' })}</span>
              </label>
              <InputText
                name="userName"
                defaultValue={props.userDetailsToBeEdited.userName}
                className="user_details md:w-14rem"
                type="text"
                maxLength={userNameMaxLength}
                autoComplete="no"
                onChange={userDataInputFieldHandler}
                placeholder={f({ id: 'COM_DMS_USER_NAME_TXT' })}
              />
            </div>
            <div className="details_col">
              <label className="name_label_pos">
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{f({ id: 'COM_DMS_EMAIL_ID' })}</span>
              </label>
              <InputText
                name="email"
                defaultValue={props.userDetailsToBeEdited.email}
                className="user_details md:w-14rem"
                type="text"
                autoComplete="no"
                maxLength={emailMaxLength}
                onChange={userDataInputFieldHandler}
                placeholder={f({ id: 'COM_DMS_EMAIL_TXT' })}
              />
            </div>
            <div className="details_col">
              <label className="name_label_pos">
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{f({ id: 'COM_DMS_CONTACT' })}</span>
              </label>
              <InputText
                name="contactNumber"
                defaultValue={props.userDetailsToBeEdited.contactNumber}
                className="user_details md:w-14rem"
                type="text"
                maxLength={contactNumberMaxLength}
                autoComplete="no"
                onChange={userDataInputFieldHandler}
                placeholder={f({ id: 'COM_DMS_CONTACT_NO_TXT' })}
              />
            </div>

            <div className="details_col">
              <label className="name_label_pos">
              <span style={{ color: 'red', fontWeight: 800 }}>*</span>{' '}
                <span>{f({ id: 'COM_DMS_ROLE' })}</span>
              </label>
              <div className="card flex justify-content-center">
                <Dropdown
                  name="roleName"
                  value={props.userDetailsToBeEdited.roleName}
                  onChange={(e) => {
                    props.setUserDetailsToBeEdited({
                      ...props.userDetailsToBeEdited,
                      roleName: e.value,
                    });
                  }}
                  options={roles}
                  optionLabel="name"
                  optionValue="name"
                  className="user_role_dropdown"
                  placeholder={f({ id: 'COM_DMS_SELECT_ROLE_TXT' })}
                />
              </div>
            </div>
          </div>


          {isAdminUser() && 
            <div className='edit-mfa-user-info'>
              <div>{f({ id: 'COM_DMS_RESET_MFA_INFO' })}</div>  
              <Checkbox
                disabled={!props?.selectedItems?.[0]?.mfaInfoPresent}
                checked={isMFAInfoChecked}
                onChange={() => setIsMFAInfoChecked(!isMFAInfoChecked)}
              />
            </div>
          }
          {isAdminUser() && (
            <div className="userDetails-form">
              <div className="details_col">
                <span className="edit_user_header">
                  {f({ id: 'COM_DMS_RESET_PWD' })}
                </span>
                <img
                  src={expandKey ? upArrow : downArrow}
                  alt="setting"
                  className="pwd-arrow-icon"
                  onClick={() => setExpandKey((prev) => !prev)}
                />
              </div>
            </div>
          )}
          {isAdminUser() && expandKey && (
            <>
              <div className="userDetails-form">
                <div className="details_col">
                  {passwordSettings?.isDefaultPasswordUsed ? 
                  <div className="user_default_pwd_fields">
                  <div className="name_label_pos">
                    <span>{f({ id: 'COM_DMS_DEFAULT_PWD' })}</span>
                  </div>
                  <div>
                    <Checkbox
                      checked={defaultPasswordChecked}
                      onChange={(e) => {
                        setDefaultPasswordChecked(e.checked);
                        let updatedPassword = e.checked
                          ? `${localStorage.getItem('DefaultPassword')}`
                          : '';
                        setConfirmPassword(updatedPassword);
                        setPassword(updatedPassword);
                      }}
                    />
                  </div>
                </div> : expandKey && <div style={{marginBottom: '3rem' }}/>}
                  {expandKey && (
                    <div
                      className={`copy-password ${
                        !password ? 'disable-copy-to-clipboard' : ''
                      }`}
                      onClick={() => copyNavigatorToClipBoardPassword(
                        password,
                        setPasswordCopied,
                        showSuccessToast,
                        showErrorToast,
                        f
                      )}
                    >
                      <img src={CopyIcon} className="copy-img" alt="copy" />
                      {f({ id: 'COM_DMS_COPY_PASSWORD' })}
                    </div>
                  )}
                </div>
                <div className="details_col edit-user-popup-password">
                  <label htmlFor="password">
                    {intl.formatMessage({ id: 'COM_DMS_PASSWORD' })}
                  </label>
                  <input
                    id="editPassword"
                    name="password"
                    type={passwordType}
                    maxLength={passwordMaxLength}
                    className="user_details md:w-14rem"
                    value={password}
                    onChange={userDataInputFieldHandler}
                    disabled= {defaultPasswordChecked}
                    placeholder={f({ id: 'COM_DMS_CREATE_PASSWORD_TXT' })}
                  />
                   <img
                    src={`${
                      passwordType === 'password' ? password?.length > 0 ? EyeCloseIcon : VisibilityHideIcon : VisibilityIcon 
                    }`}
                    className={
                      passwordType === 'password' && password?.length <= 0 ? 'edit-pwd' : 'text-edit-pwd' 
                    }
                    onClick={() => {  if(password?.length > 0)checkPasswordType(passwordType, setPasswordType)}}
                    alt="password"
                  />
                  {focussedPassword && (
                    <PasswordValidatorTooltip
                      className="validator-tooltip"
                      {...validatePassword}
                    />
                  )}
                </div>
                {!defaultPasswordChecked && (
                  <div className="details_col edit-user-popup-password">
                    <label htmlFor="password">
                      {intl.formatMessage({ id: 'COM_DMS_CNF_PWD' })}
                    </label>
                    <input
                      type={confirmPasswordType}
                      disabled = {defaultPasswordChecked}
                      id="confirmEditPassword"
                      name="confirmPassword"
                      maxLength={passwordMaxLength}
                      value={confirmPassword}
                      className="user_details md:w-14rem"
                      onChange={userDataInputFieldHandler}
                      placeholder={f({ id: 'COM_DMS_CNF_PWD' })}
                    />
                    <img
                      src={`${
                        confirmPasswordType === 'password'
                        ?  confirmPassword?.length > 0 ? EyeCloseIcon
                        : VisibilityHideIcon
                        : VisibilityIcon
                      }`}
                      className={
                        confirmPasswordType === 'password' && confirmPassword?.length <= 0 ? 'edit-pwd' : 'text-edit-pwd'
                      }
                      onClick={() => {
                        if(confirmPassword?.length !== 0)
                        checkPasswordType(
                          confirmPasswordType,
                          setConfirmPasswordType
                        )
                        }
                      }
                      alt="password"
                    />
                  </div>
                )}
              </div>
              {!defaultPasswordChecked && 
                <div
                  className="temp-pwd-generator"
                  onClick={temporaryPasswordHandler}
                  disabled={defaultPasswordChecked}
                >
                  <span>{f({ id: 'COM_DMS_GENERATE_PASSWORD' })}</span>
                </div>
              }
            </>
          )}
        </div>
        <span className="error-txt">{userDetailsError}</span>
      </Dialog>
    </div>
  );
};
