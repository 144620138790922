import React from 'react';
import './DeleteGroup.css';
import Overlay from '../../../../components/Overlay/Overlay';
import { Button } from 'primereact/button';
import { useIntl } from 'react-intl';
import { showSuccessToast } from "../../../../services/APIResponseHandler";
import { GroupManagementService } from '../../../../services/RestServices/GroupManagementService';
const DeleteGroup = ({ setOpenDeleteGroup, selectedIds, setFlag }) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const groupId = selectedIds[0];
  const deleteSelectedGroup = async () => {
    await GroupManagementService.deleteSelectedGroups(groupId).then(
      (res) =>
        res?.status === 200 &&
        showSuccessToast(f({id: 'COM_DMS_GROUP_DELETE_SUCCESSFUL'}))
    );
    setFlag(true);
    setOpenDeleteGroup(false);
  };

  return (
    <Overlay className="delete-group-overlay">
      <div className="delete-group">
        <div className="delete-group-header">{`${f({
          id: 'COM_DMS_DELETE_GROUP',
        })} !`}</div>
        <div className="delete-group-subtext">
          {f({ id: 'COM_DMS_DELETE_GROUP_TEXT' })}
        </div>
        <div className="delete-group-subtext-2">
          {f({ id: 'COM_DMS_DELETE_GROUP_SUB_TEXT' })}
        </div>
        <div className="delete-group-btns">
          <Button
            className="delete-btn"
            label={f({ id: 'COM_DMS_DELETE' })}
            onClick={deleteSelectedGroup}
          />
          <Button
            className="cancel-btn"
            label={f({id: 'COM_DMS_CANCEL'})}
            onClick={() => setOpenDeleteGroup(false)}
          />
        </div>
      </div>
    </Overlay>
  );
};

export default DeleteGroup;
