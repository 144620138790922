import {
  getServerSettingsService,
  getCommonServerSettingsService,
  updateServerSettingsService,
} from '../../services/ServerSettings';
import SetAuthToken from '../../utils/SetAuthToken';
import {
  GET_SERVER_SETTINGS_ERROR,
  GET_SERVER_SETTINGS_PENDING,
  GET_SERVER_SETTINGS_SUCCESS,
  RESET_SERVER_SETTINGS,
  UPDATE_SERVER_SETTINGS_ERROR,
  UPDATE_SERVER_SETTINGS_FLAG,
  UPDATE_SERVER_SETTINGS_PENDING,
  UPDATE_SERVER_SETTINGS_SUCCESS,
} from '../constants/ServerSettingsConstant';

export const getServerSettings = () => async (dispatch) => {
  dispatch({ type: GET_SERVER_SETTINGS_PENDING });
  const user = JSON.parse(localStorage.getItem('user'));
  SetAuthToken(user);
  try {
    const authRes = await getServerSettingsService();
    const { data } = authRes;
    localStorage.setItem(
      'DefaultPassword',
      data.passwordSettings.defaultPassword
    );
    dispatch({ type: GET_SERVER_SETTINGS_SUCCESS, payload: data });
  } catch ({ message }) {
    dispatch({ type: GET_SERVER_SETTINGS_ERROR, payload: message });
  }
};

export const getCommonServerSettings = () => async (dispatch) => {
  dispatch({ type: GET_SERVER_SETTINGS_PENDING });
  const user = JSON.parse(localStorage.getItem('user'));
  SetAuthToken(user);
  try {
    const authRes = await getCommonServerSettingsService();
    const { data } = authRes;
    const defaultPassword = data?.passwordSettings?.defaultPassword;
    if(defaultPassword){
      localStorage.setItem(
        'DefaultPassword',
        data.passwordSettings.defaultPassword
      );
    }
    dispatch({ type: GET_SERVER_SETTINGS_SUCCESS, payload: data });
  } catch ({ message }) {
    dispatch({ type: GET_SERVER_SETTINGS_ERROR, payload: message });
  }
};

export const updateServerSettings = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_SERVER_SETTINGS_PENDING });
  const user = JSON.parse(localStorage.getItem('user'));
  SetAuthToken(user);
  try {
    const authRes = await updateServerSettingsService(payload);
    const { data } = authRes;
    dispatch({ type: UPDATE_SERVER_SETTINGS_SUCCESS, payload: data });
  } catch ({ message }) {
    dispatch({ type: UPDATE_SERVER_SETTINGS_ERROR, payload: message });
  }
};

export const resetUpdateInfo = () => async (dispatch) => {
  dispatch({ type: UPDATE_SERVER_SETTINGS_FLAG });
};

export const resetServerSettings = () => async (dispatch) => {
  dispatch({ type: RESET_SERVER_SETTINGS })
}