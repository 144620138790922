import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import './DeleteUserPopup.css';
import { useIntl } from 'react-intl';
import { userServices } from '../../services/RestServices/userServices';
import { showSuccessToast } from '../../services/APIResponseHandler';

export const DeleteUserPopup = (props) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const setVisible = props.setVisible;
  const visible = props.visible;

  const deleteUserHandler = async () => {
    await Promise.all([userServices.deleteUserInfo(props.userToBeDeleted).then((res) =>
      res?.status === 200 && showSuccessToast("User(s) Deleted Successfully!"))]);
    props.setUserToBeDeleted([]);
    setVisible(false);
    props.setSelectedItems([]);
  };

  const footerContent = (
    <div className="delete-btns-position">
      <Button
        label={f({ id: 'COM_DMS_DELETE' })}
        onClick={deleteUserHandler}
        className="delete_button"
      />
      <Button
        label={f({ id: 'COM_DMS_CANCEL' })}
        onClick={() => setVisible(false)}
        className="delete-cancel-button"
      />
    </div>
  );

  const headerContent = () => {
    return (
      <div className="delete-flex-title">
        {intl.formatMessage({ id: 'COM_DMS_DELETE_USER_TXT' })}
      </div>
    );
  };

  return (
    <div className="card flex justify-content-center">
      <Dialog
        header={headerContent}
        visible={visible}
        style={{ align: 'center', width: '35vw' }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        <div className="text-flex">
          {intl.formatMessage({ id: 'COM_DMS_DELETE_USER_MESSAGE' })}
        </div>
        <div className="text-flex-1">
          {intl.formatMessage({ id: 'COM_DMS_UNDONE' })}
        </div>
      </Dialog>
    </div>
  );
};
