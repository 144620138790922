import React, { useEffect, useState, useMemo } from 'react';
import './Dashboard.css';
import { useIntl } from 'react-intl';
import Card from '../../components/Card/Card';
import { Chart } from 'primereact/chart';
import AppIcon from '../../assets/images/androidIcon.png';
import DashboardActiveDeviceIcon from '../../assets/images/dash-active-device-icon.svg';
import DashboardEnrolledDevicesIvon from '../../assets/images/dash-enrolled-devices-icon.svg';
import DashboardRegisteredrUsersIcon from '../../assets/images/dash-reg-users-icon.svg';
import { RestServiceUtils } from '../../utils/RestServiceUtils';
import Spinner from '../../components/Spinner/Spinner';
import { monthLabels } from '../../utils/Utils';
import { DASHBOARD_URL } from '../../config/constants/Constants';
import { Tooltip } from 'primereact/tooltip';
import CenteredDashboardPopup from './CenteredDashboardPopup';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

const Dashboard = ({
  refreshPage,
  handleUser,
  handleDevice,
  setCurrentDevice,
  setConnectedDevicesFlag,
}) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const [loading, setLoading] = useState(true);
  const [pieChartData, setPieChartData] = useState({});
  const [lineChartData, setLineChartData] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [pieChartOptions, setPieChartOptions] = useState({});
  const [isAppPopupOpen, setIsAppPopupOpen] = useState(false);
  const [lineChartOptions, setLineChartOptions] = useState({});
  const [errorWarningType, setErrorWarningType] = useState('');
  const userRole = localStorage.getItem('loginUserRoleName');
  const [appTableData, setAppTableData] = useState([]);

  const USING = f({ id: 'COM_DMS_USING' });
  const OLD_VERSION = f({ id: 'COM_DMS_OLD_VERSION' });

  const USER_ADMIN_ROLES_FLAG =
    userRole === 'Admin' || userRole === 'Sub-Admin';
  const DEVICE_MANAGER_FLAG = userRole === 'Device-Manager';

  const {
    enrolledDeviceCount,
    activeDeviceCount,
    registeredUserCount,
    mostUsedApps,
    recentlyUsedApps,
    deviceEnrollmentTrend,
    deviceFirmware,
    errorAndWarnings,
  } = dashboardData ?? {};

  const {
    screenOnTimeWarningCount,
    unauthorizedAccessWarningCount,
    highStorageAndRamWarningCount,
  } = errorAndWarnings ?? {};

  const enrollmentTrends = useMemo(() => {
    const currentYMDate = new Date();

    const currentMonth =
      currentYMDate.getMonth() < 9
        ? '0' + (currentYMDate.getMonth() + 1)
        : currentYMDate.getMonth() + 1;

    let currentYMDateString = `${
      currentYMDate.getFullYear() + '-' + currentMonth
    }`;
    const deviceLabels = [];
    const deviceCount = [];
    let currYear, currMonth;
    const API_TREND_MAP = new Map();

    deviceEnrollmentTrend?.forEach(({ enrollmentDate, enrollmentCount }) => {
      API_TREND_MAP.set(enrollmentDate, enrollmentCount);
    });
    for (let index = 0; index < 12; index++) {
      currYear = currentYMDateString.split('-')[0];
      currMonth = currentYMDateString.split('-')[1];
      deviceLabels.unshift(
        monthLabels[currMonth - 1] + "'" + currYear.toString()?.substring(2)
      );
      if (currMonth <= 1) {
        currMonth = 12;
        currYear--;
      } else {
        currMonth--;
      }
      if (API_TREND_MAP.has(currentYMDateString)) {
        deviceCount.unshift(API_TREND_MAP.get(currentYMDateString));
      } else {
        deviceCount.unshift(0);
      }
      currentYMDateString = `${currYear}-${
        currMonth < 10 ? '0' + currMonth : currMonth
      }`;
    }
    return { deviceLabels, deviceCount };
  }, [deviceEnrollmentTrend]);

  const loadInitialDashboardPageData = () => {};

  useEffect(() => {
    const { deviceLabels, deviceCount } = enrollmentTrends ?? {};
    const {
      newFirmwareDeviceCount,
      oldFirmwareDeviceCount,
      latestDeviceFirmwareVersion,
    } = deviceFirmware ?? {};
    const lineData = {
      labels: deviceLabels,
      datasets: [
        {
          fill: true,
          yAxisID: 'y',
          tension: 0,
          borderColor: '#4598ea',
          backgroundColor: '#dae2e9',
          data: deviceCount,
        },
      ],
    };

    const lineOptions = {
      stacked: false,
      maintainAspectRatio: false,
      aspectRatio: 0.9,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        min: 0,
      },
    };

    let MAX_VALUE = 0;
    deviceEnrollmentTrend?.forEach(({ _, enrollmentCount }) => {
      MAX_VALUE = Math.max(MAX_VALUE, enrollmentCount);
    });

    if (MAX_VALUE < 6) {
      lineOptions.scales.y.max = 6;
    }

    setLineChartData(lineData);
    setLineChartOptions(lineOptions);

    const pieData = {
      labels: [OLD_VERSION, LATEST_VERSION],
      datasets: [
        {
          data: [oldFirmwareDeviceCount, newFirmwareDeviceCount],
          backgroundColor: ['#f29db4', '#4598ea'],
          borderColor: ['#f29db4', '#4598ea'],
          latestDeviceFirmwareVersion,
        },
      ],
    };
    setPieChartData(pieData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceFirmware, enrollmentTrends]);

  const loadInitialData = () => {
    setLoading(true);
    RestServiceUtils.HTTP_GET(DASHBOARD_URL).then(({ data }) => {
      setLoading(false);
      setDashboardData(data);
    });

    const pieOptions = {
      plugins: {
        legend: {
          display: false,
          position: 'right',
          labels: {
            usePointStyle: true,
          },
        },
      },
    };

    setPieChartOptions(pieOptions);
  };

  useEffect(() => {
    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const ALL = f({ id: 'COM_DMS_ALL' });
  const ENROLLED_DEVICES = f({ id: 'COM_DMS_ENROLLED_DEVICES' });
  const ACTIVE_DEVICES = f({ id: 'COM_DMS_ACTIVE_DEVICES' });
  const REGISTERED_USERS = f({ id: 'COM_DMS_REGISTERED_USERS' });
  const LATEST_VERSION = f({ id: 'COM_DMS_LATEST_VERSION' });
  const CANCEL = f({ id: 'COM_DMS_CANCEL' });
  const CLEAR = f({ id: 'COM_DMS_CLEAR' });
  const RECENTLY_INSTALLED_APPS = f({ id: 'COM_DMS_RECENT_INSTALLED_APPS' });
  const INSTALLATION = f({ id: 'COM_DMS_INSTALLATION' });
  const INSTALLATIONS = f({ id: 'COM_DMS_INSTALLATIONS' });
  const DEVICE_NAME = f({ id: 'COM_DMS_DEVICE_NAME' });
  const MAC_ID = f({ id: 'COM_DMS_MAC_ID' });
  const IP_ADDRESS = f({ id: 'COM_DMS_EVENT_IP_ADDRESS' });

  const ERROR_WARNING_DEVICES = f({ id: 'COM_DMS_ERROR_WARNING_DEVICES' });
  const SCREEN_ON_TIME_BEYOND_LIMIT = f({
    id: 'COM_DMS_SCREEN_ON_TIME_BEYOND_LIMIT',
  });
  const UNAUTHORIZED_ACCESS_ATTEMPTED = f({
    id: 'COM_DMS_UNAUTHORIZED_ACCESS_ATTEMPTED',
  });
  const HIGH_STORAGE_RAM_USAGE = f({ id: 'COM_DMS_HIGH_STORAGE_RAM_USAGE' });

  const headerContentData = [
    {
      count: enrolledDeviceCount || 0,
      text: ENROLLED_DEVICES,
      imgSrc: DashboardEnrolledDevicesIvon,
    },
    {
      count: activeDeviceCount || 0,
      text: ACTIVE_DEVICES,
      imgSrc: DashboardActiveDeviceIcon,
    },
  ];

  if (USER_ADMIN_ROLES_FLAG) {
    headerContentData.push({
      count: registeredUserCount,
      text: REGISTERED_USERS,
      imgSrc: DashboardRegisteredrUsersIcon,
    });
  }

  const DashboardHeaderCard = (count, text, imgSrc, key) => {
    const actionOnCardClick = () => {
      if (key === 0) {
        setConnectedDevicesFlag(false);
        setCurrentDevice(ALL);
        handleDevice();
      } else if (key === 1) {
        setConnectedDevicesFlag(true);
        setCurrentDevice(ALL);
        handleDevice();
      } else {
        handleUser();
      }
    };
    return (
      <Card
        className={`${
          USER_ADMIN_ROLES_FLAG
            ? 'dashboard-header-card'
            : 'dashboard-header-card dashboard-header-operator-card'
        }`}
        key={key}
        onCardClick={actionOnCardClick}
      >
        <div>
          <div className="dashboard-header-card-text">{count || 0}</div>
          <div className="dashboard-header-card-subtext">{text}</div>
        </div>
        <div>
          <img src={imgSrc} alt="user" />
        </div>
      </Card>
    );
  };

  const appPopupHeader = (text) => {
    return (
      <div className="recent-apps-popup-header">
        <div className="recent-apps-heading">{text}</div>
        <div></div>
      </div>
    );
  };

  const appPopupBody = (isClearable, warningCount, warningText) => {
    const appTableColumns = [
      { field: 'deviceName', header: DEVICE_NAME },
      { field: 'deviceId', header: MAC_ID },
      { field: 'ipAddress', header: IP_ADDRESS },
    ];

    const handleClearField = () => {
      RestServiceUtils.HTTP_PUT(
        `${DASHBOARD_URL}/clearWarning/${warningText}`
      ).then(() => {
        setAppTableData([]);
        loadInitialData();
      });
    };

    return (
      <div className="recent-apps-popup-body">
        {isClearable && (
          <button
            className="popup-clear-btn"
            onClick={handleClearField}
            disabled={!warningCount}
          >
            {CLEAR}
          </button>
        )}
        <DataTable value={appTableData}>
          {appTableColumns?.map((col) => (
            <Column {...col} />
          ))}
        </DataTable>
      </div>
    );
  };

  const appPopupFooter = (cancelType, setType) => {
    return (
      <div className="recent-apps-popup-footer">
        <button
          className="recent-apps-cancel-btn"
          onClick={() => {
            if (cancelType === 'app') {
              setType(false);
            } else {
              setType('');
            }
            loadInitialDashboardPageData();
          }}
        >
          {CANCEL}
        </button>
      </div>
    );
  };

  const DashboardAppsComponent = (
    appName,
    noOfInstallations,
    appImgSrc,
    index,
    isRecentApp,
    appPackageName
  ) => {
    const handleAppClick = () => {
      setIsAppPopupOpen(true);
      setErrorWarningType('');
      RestServiceUtils.HTTP_GET(
        `${DASHBOARD_URL}/appInstallInfo/${appPackageName}`
      ).then(({ data }) => {
        setAppTableData(() => data);
      });
    };

    return (
      <div key={index} className="dashboard-app-details">
        <div className="dashboard-app-details-img">
          <img src={appImgSrc || AppIcon} alt="app" />
        </div>
        <div className="dashboard-app-details-content">
          <Tooltip
            target={`#dashboard_app_${index}_${isRecentApp ? '0' : '1'}`}
            position="top"
          >
            <div className="infoIcon_tooltipContent">{appName}</div>
          </Tooltip>
          <div
            id={`dashboard_app_${index}_${isRecentApp ? '0' : '1'}`}
            className={!isRecentApp ? 'dashboard_most_used_app_content' : ''}
          >
            {appName}
          </div>
          {isRecentApp && (
            <div
              className="no_of_installations"
              onClick={handleAppClick}
            >{`${noOfInstallations} ${
              noOfInstallations > 1 ? INSTALLATIONS : INSTALLATION
            }`}</div>
          )}
        </div>
      </div>
    );
  };

  const handleErrorWarningPopup = (warningType, warningName, warningCount) => {
    setIsAppPopupOpen(false);
    setErrorWarningType(warningName);
    if (!warningCount) {
      setAppTableData(() => []);
      return;
    }
    RestServiceUtils.HTTP_GET(
      `${DASHBOARD_URL}/warningDeviceInfo/${warningType}`
    ).then(({ data }) => {
      setAppTableData(() => data);
    });
  };

  return (
    <>
      {loading || !userRole ? (
        <Spinner />
      ) : (
        <div className="dashboard">
          {isAppPopupOpen && (
            <CenteredDashboardPopup
              popupHeader={() => appPopupHeader(RECENTLY_INSTALLED_APPS)}
              popupBody={appPopupBody}
              popupFooter={() => appPopupFooter('app', setIsAppPopupOpen)}
            />
          )}
          {errorWarningType === SCREEN_ON_TIME_BEYOND_LIMIT && (
            <CenteredDashboardPopup
              popupHeader={() => appPopupHeader(SCREEN_ON_TIME_BEYOND_LIMIT)}
              popupBody={appPopupBody}
              popupFooter={() =>
                appPopupFooter('errorWarning', setErrorWarningType)
              }
            />
          )}
          {errorWarningType === UNAUTHORIZED_ACCESS_ATTEMPTED && (
            <CenteredDashboardPopup
              popupHeader={() => appPopupHeader(UNAUTHORIZED_ACCESS_ATTEMPTED)}
              popupBody={() =>
                appPopupBody(
                  true,
                  unauthorizedAccessWarningCount,
                  'unauthorizedAccess'
                )
              }
              popupFooter={() =>
                appPopupFooter('errorWarning', setErrorWarningType)
              }
            />
          )}
          {errorWarningType === HIGH_STORAGE_RAM_USAGE && (
            <CenteredDashboardPopup
              popupHeader={() => appPopupHeader(HIGH_STORAGE_RAM_USAGE)}
              popupBody={() =>
                appPopupBody(
                  false,
                  highStorageAndRamWarningCount,
                  'highStorageAndRam'
                )
              }
              popupFooter={() =>
                appPopupFooter('errorWarning', setErrorWarningType)
              }
            />
          )}
          <div className="dashboard-header">
            {headerContentData?.map(({ count, text, imgSrc }, idx) =>
              DashboardHeaderCard(count, text, imgSrc, idx)
            )}
          </div>
          <div className="dashboard-content">
            <div className="dashboard-content-right">
              <div
                className={
                  !(USER_ADMIN_ROLES_FLAG || DEVICE_MANAGER_FLAG)
                    ? 'dashboard-content-right-chart-header'
                    : ''
                }
              >
                <Card className="dashboard-content-right-chart">
                  <div>{f({ id: 'COM_DMS_TREND_OF_DEVICE_ENROLLMENT' })}</div>
                  <Chart
                    type="line"
                    data={lineChartData}
                    options={lineChartOptions}
                  />
                </Card>
              </div>
              {(USER_ADMIN_ROLES_FLAG || DEVICE_MANAGER_FLAG) && (
                <div>
                  <Card className="dashboard-content-right-apps">
                    <div>{f({ id: 'COM_DMS_MOST_USED_APPS' })}</div>
                    <div className="dashboard-right-apps-container">
                      {mostUsedApps?.map(
                        (
                          {
                            appName,
                            installationCount,
                            appImgSrc,
                            appPackageName,
                          },
                          index
                        ) =>
                          DashboardAppsComponent(
                            appName,
                            installationCount,
                            appImgSrc,
                            index,
                            false,
                            appPackageName
                          )
                      )}
                    </div>
                  </Card>
                  <Card className="dashboard-content-right-apps recent-apps">
                    <div>{RECENTLY_INSTALLED_APPS}</div>
                    <div className="dashboard-right-apps-container">
                      {recentlyUsedApps?.map(
                        (
                          {
                            appName,
                            installationCount,
                            appImgSrc,
                            appPackageName,
                          },
                          index
                        ) =>
                          DashboardAppsComponent(
                            appName,
                            installationCount,
                            appImgSrc,
                            index,
                            true,
                            appPackageName
                          )
                      )}
                    </div>
                  </Card>
                </div>
              )}
            </div>
          </div>
          <div className="dashboard-footer">
            <Card className="dashboard-footer-left">
              <div className="dashboard-footer-left-text">
                {ERROR_WARNING_DEVICES}
              </div>
              <div>
                <Card
                  className="left-card"
                  onCardClick={() =>
                    handleErrorWarningPopup(
                      'screenOn',
                      SCREEN_ON_TIME_BEYOND_LIMIT,
                      screenOnTimeWarningCount
                    )
                  }
                >
                  <div className="left-card-text">
                    {screenOnTimeWarningCount || 0}
                  </div>
                  <div className="left-card-text2">
                    {SCREEN_ON_TIME_BEYOND_LIMIT}
                  </div>
                </Card>
                <Card
                  className="left-card"
                  onCardClick={() =>
                    handleErrorWarningPopup(
                      'unauthorizedAccess',
                      UNAUTHORIZED_ACCESS_ATTEMPTED,
                      unauthorizedAccessWarningCount
                    )
                  }
                >
                  <div className="left-card-text">
                    {unauthorizedAccessWarningCount || 0}
                  </div>
                  <div className="left-card-text2">
                    {UNAUTHORIZED_ACCESS_ATTEMPTED}
                  </div>
                </Card>
                <Card
                  className="left-card"
                  onCardClick={() =>
                    handleErrorWarningPopup(
                      'highStorageAndRam',
                      HIGH_STORAGE_RAM_USAGE,
                      highStorageAndRamWarningCount
                    )
                  }
                >
                  <div className="left-card-text">
                    {highStorageAndRamWarningCount || 0}
                  </div>
                  <div className="left-card-text2">
                    {HIGH_STORAGE_RAM_USAGE}
                  </div>
                </Card>
              </div>
            </Card>
            <Card className="dashboard-footer-right">
              <div>{f({ id: 'COM_DMS_DEVICE_FIRMWARE' })}</div>
              <Chart
                type="pie"
                data={pieChartData}
                width="12rem"
                height="12rem"
                options={pieChartOptions}
              />
              {pieChartData.datasets[0].data[0] ||
              pieChartData.datasets[0].data[1] ? (
                <div className="pie-chart-legends">
                  <div className="pie-firmware-version">
                    {LATEST_VERSION}:{' '}
                    {pieChartData.datasets[0].latestDeviceFirmwareVersion}
                  </div>
                  <div
                    className="pie-legend-flex"
                    style={{ marginBottom: '1rem' }}
                  >
                    <div className="pie-old-version-legend"></div>
                    <div>{`${USING} ${OLD_VERSION}`}</div>
                  </div>
                  <div className="pie-legend-flex">
                    <div className="pie-new-version-legend"></div>
                    <div>{`${USING} ${LATEST_VERSION}`}</div>
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
