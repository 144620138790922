import axios from 'axios';
import SetAPIAuthKey from './SetAPIAuthKey';
import { RedirectToLoginOnTokenExpiration } from './Utils';
import { showErrorToast } from '../services/APIResponseHandler';
import ErrorCodes from '../config/constants/ErrorCodes.json';

const HTTP_GET = async (url, params, config, needPasswordReset, changeAfterLogin) => {
    SetAPIAuthKey();
    RedirectToLoginOnTokenExpiration();
    try{
        return await axios.get(url, {params: params}, config).then((response) => {
            return response;
        })
    }
    catch(error){
        const ERROR = error.response;
        {!(needPasswordReset && changeAfterLogin) &&
            showErrorToast(ERROR?.status === 500 ? "Internal Server Error" : ERROR?.data && ERROR?.data?.errorMessage ? ERROR.data.errorMessage : ErrorCodes[ERROR?.status])};
        return error.response;
    }
}

const HTTP_DELETE = async (url, data, config, needPasswordReset, changeAfterLogin) => {
    SetAPIAuthKey();
    RedirectToLoginOnTokenExpiration();
    try{
        return await axios.delete(url, {data: data}, config).then((response) => {
            return response;
        })
    }
    catch(error){
        const ERROR = error.response;
        {!(needPasswordReset && changeAfterLogin) &&
            showErrorToast(ERROR?.status === 500 ? "Internal Server Error" : ERROR?.data && ERROR?.data?.errorMessage ? ERROR.data.errorMessage : ErrorCodes[ERROR?.status])};
        return error.response;
    }
}

const HTTP_POST = async (url, payload, config, needPasswordReset, changeAfterLogin) => {
    SetAPIAuthKey();
    RedirectToLoginOnTokenExpiration();
    try {
        return await axios.post(url, payload, config).then((response) => {
                return response;
         })
    }
    catch (error) {
        const ERROR = error.response;
        {!(needPasswordReset && changeAfterLogin) &&
            showErrorToast(ERROR?.status === 500 ? "Internal Server Error" : ERROR?.data && ERROR?.data?.errorMessage ? ERROR.data.errorMessage : ErrorCodes[ERROR?.status])};
        return error.response;
    }
};

const HTTP_POST_WITHOUT_TOAST_ERROR = async (url, payload, config, needPasswordReset, changeAfterLogin) => {
    SetAPIAuthKey();
    RedirectToLoginOnTokenExpiration();
    try {
        return await axios.post(url, payload, config).then((response) => {
                return response;
         })
    }
    catch (error) {
        return error.response;
    }
};

const HTTP_PUT = async (url, payload, config, needPasswordReset, changeAfterLogin) => {
    SetAPIAuthKey();
    RedirectToLoginOnTokenExpiration();
    try{
        return await axios.put(url, payload, config).then((response) => {
            return response;
        })
    }
    catch(error){
        const ERROR = error.response;
        {!(needPasswordReset && changeAfterLogin) &&
            showErrorToast(ERROR?.status === 500 ? "Internal Server Error" : ERROR?.data && ERROR?.data?.errorMessage ? ERROR.data.errorMessage : ErrorCodes[ERROR?.status])};
        return error.response;
    }
}

const HTTP_ALL = async (urls, params, config, needPasswordReset, changeAfterLogin) => {
    SetAPIAuthKey();
    RedirectToLoginOnTokenExpiration();
    try {
       return axios
      .all(urls.map((endpoint) => axios.get(endpoint,{params: params}, config)))
       .then((response) => {
            return response;
        })
    }
    catch(error){
        const ERROR = error.response;
        {!(needPasswordReset && changeAfterLogin) &&
            showErrorToast(ERROR?.status === 500 ? "Internal Server Error" : ERROR?.data && ERROR?.data?.errorMessage ? ERROR.data.errorMessage : ErrorCodes[ERROR?.status])};
        return error.response;
    }
}

export const RestServiceUtils = {
    HTTP_GET,
    HTTP_DELETE,
    HTTP_POST,
    HTTP_PUT,
    HTTP_ALL,
    HTTP_POST_WITHOUT_TOAST_ERROR,
}
  