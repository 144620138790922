import { Route, Routes } from "react-router-dom";
import Login from "../pages/Login/Login";
import ForgotPassword from "../pages/Login/ForgotPassword";
import UpdatePassword from "../pages/Login/UpdatePassword";
import PrivateRoute from "./PrivateRoute";
import {
  FORGOT_PWD_PATH,
  HOME_PATH,
  LOGIN_PATH,
  PRIVACY_POLICY,
  RESET_SUCCESS_PATH,
  UPDATE_PWD_PATH,
  USER_AGREEMENT,
} from "./RouteConstants";
import Home from "../modules/Home/Home";
import ResetSuccess from "../modules/ResetSuccess/ResetSuccess";
import PrivacyPolicy from '../components/AggrementAndPrivacyPages/PrivacyPolicy/PrivacyPolicy'
import UserAgreement from '../components/AggrementAndPrivacyPages/UserAgreement/UserAgreement';

const RoutePaths = () => {
  return (
    <Routes>
      <Route exact element={<PrivateRoute />}>
        <Route exact path={HOME_PATH} element={<Home />} />
      </Route>
      <Route exact path={RESET_SUCCESS_PATH} element={<ResetSuccess />} />
      <Route exact path={UPDATE_PWD_PATH} element={<UpdatePassword />} />
      <Route exact path={LOGIN_PATH} element={<Login />} />
      <Route exact path={USER_AGREEMENT} element={<UserAgreement />} />
      <Route exact path={PRIVACY_POLICY} element={ <PrivacyPolicy />} />
      <Route exact path={FORGOT_PWD_PATH} element={<ForgotPassword />} />
    </Routes>
  );
};

export default RoutePaths;
