import React, { useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import './UrlPermissionPopup.css';
import { InputText } from 'primereact/inputtext';
import {
  URL_REGEX,
  DOMAIN_NAME_FROM_URL,
} from '../../../../config/constants/Constants';
import CheckCircle from '../../../../assets/images/checkCircle.svg';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';
import { tagServices } from '../../../../services/RestServices/tagServices';
import { useIntl } from 'react-intl';
import { showSuccessToast } from '../../../../services/APIResponseHandler';

const UrlPermissionPopup = ({
  setShowUrlPermission,
  setSelectTag,
  devices,
}) => {
  const { formatMessage: f } = useIntl();
  const [allowListUrls, setAllowListUrls] = useState([]);
  const [blockListUrls, setBlockListUrls] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [urlValue, setUrlValue] = useState('');
  const [urlError, setUrlError] = useState('');
  const [addUrlToggler, setAddUrlToggler] = useState(true);
  const [showSaveSyncPopup, setShowSaveSyncPopup] = useState(false);
  const [showResetURLPopup, setShowResetURLPopup] = useState(false);
  const deviceIds = devices.map((device) => device.deviceId);
  const dialogState = {
    header: f({ id: 'COM_DMS_SAVE_SYNC' }),
    body: f({ id: 'COM_DMS_SAVE_SYNC_MSG' }),
    footer: '',
    action: f({ id: 'COM_DMS_YES' }),
    cancel: f({ id: 'COM_DMS_NO' }),
  };
  const dialogResetState = {
    header: f({ id: 'COM_DMS_RESET_TEXT' }),
    body: f({ id: 'COM_DMS_RESET_URL_PERMISSION_TEXT' }),
    footer: f({ id: 'COM_DMS_UNDONE' }),
    action: f({ id: 'COM_DMS_YES' }),
    cancel: f({ id: 'COM_DMS_NO' }),
  };

  const COMMAND_SUCCESS_MESSAGE = f({
    id: 'COM_DMS_COMMAND_SENT_SUCCESSFULLY',
  });

  useEffect(() => {
    if (urlValue) {
      let domain = urlValue?.match(DOMAIN_NAME_FROM_URL)[1];
      if (activeIndex === 0) {
        addUrlToAllowList(domain);
      } else if (activeIndex === 1) {
        addUrlToBlockList(domain);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUrlToggler]);

  const handleAddUrl = () => {
    const tempUrl = urlValue.toLocaleLowerCase();
    setUrlValue(tempUrl);
    if (!URL_REGEX.test(tempUrl)) {
      setUrlError(f({ id: 'COM_DMS_INVALID_URL' }));
    } else {
      setAddUrlToggler(!addUrlToggler);
    }
  };

  const addUrlToAllowList = (domain) => {
    if (allowListUrls.includes(domain)) {
      setUrlError(f({ id: 'COM_DMS_URL_ALREADY_PRESENT' }));
    } else {
      setAllowListUrls([...allowListUrls, domain]);
      setUrlError('');
      setUrlValue('');
    }
  };

  const addUrlToBlockList = (domain) => {
    if (blockListUrls.includes(domain)) {
      setUrlError(f({ id: 'COM_DMS_URL_ALREADY_PRESENT' }));
    } else {
      setBlockListUrls([...blockListUrls, domain]);
      setUrlError('');
      setUrlValue('');
    }
  };

  const onUrlChange = (e) => {
    setUrlValue(e.target.value);
  };

  const handleSaveSync = () => {
    setShowSaveSyncPopup(true);
  };

  const handlePopupAction = () => {
    if (activeIndex === 0) {
      let requestBody = {
        deviceIds: deviceIds,
        type: f({ id: 'COM_DMS_ALLOW' }),
        urls: allowListUrls,
      };
      tagServices
        .applyUrlRestrictions(requestBody)
        .then(
          (res) =>
            res?.status === 200 && showSuccessToast(COMMAND_SUCCESS_MESSAGE)
        );
      setAllowListUrls([]);
    } else if (activeIndex === 1) {
      let requestBody = {
        deviceIds: deviceIds,
        type: f({ id: 'COM_DMS_BLOCK' }),
        urls: blockListUrls,
      };
      tagServices
        .applyUrlRestrictions(requestBody)
        .then(
          (res) =>
            res?.status === 200 && showSuccessToast(COMMAND_SUCCESS_MESSAGE)
        );
      setBlockListUrls([]);
    }
    setShowSaveSyncPopup(false);
    setShowUrlPermission(false);
    setSelectTag(null);
  };

  const handleRemoveUrl = (data) => {
    if (activeIndex === 0) {
      setAllowListUrls((urls) => urls.filter((val) => val !== data));
    } else if (activeIndex === 1) {
      setBlockListUrls((urls) => urls.filter((val) => val !== data));
    }
  };

  const renderUrls = (data) => {
    return (
      <div key={`${data}`} className="url-info-row">
        <div className="url-info-data">{data}</div>
        <div style={{ marginRight: '0.5rem' }}>
          <img
            src={CheckCircle}
            alt="check circle"
            className="remove-url"
            onClick={() => handleRemoveUrl(data)}
          />
        </div>
      </div>
    );
  };

  const handleResetUrls = () => {
    setShowResetURLPopup(true);
  };

  const handleResetPopupAction = () => {
    let requestBody = {
      deviceIds: deviceIds,
      type: f({ id: 'COM_DMS_RESET' }),
    };
    tagServices.applyUrlRestrictions(requestBody).then((res) => {
      if (res?.status === 200) {
        showSuccessToast(COMMAND_SUCCESS_MESSAGE);
        setShowResetURLPopup(false);
        setShowUrlPermission(false);
        setSelectTag(null);
      }
    });
  };

  return (
    <>
      <Dialog
        visible
        position="bottom-right"
        draggable={false}
        className="url-permission-dialog"
        onHide={() => {
          setShowUrlPermission(false);
          setSelectTag(null);
        }}
      >
        <div className="urlPermission-topNav">
          <div className="heading">{f({ id: 'COM_DMS_URL_PERMISSIONS' })}</div>
          <div className="urlPermission-topNav-buttons">
            <Button
              className="cancel-btn"
              onClick={() => {
                setShowUrlPermission(false);
                setSelectTag(null);
              }}
            >
              {f({ id: 'COM_DMS_CANCEL' })}
            </Button>
            <Button className="reset-btn" onClick={handleResetUrls}>
              {f({ id: 'COM_DMS_RESET_TEXT' })}
            </Button>
            <Button
              disabled={
                activeIndex === 0
                  ? !allowListUrls.length
                  : !blockListUrls.length
              }
              className="save-sync"
              onClick={handleSaveSync}
            >
              {f({ id: 'COM_DMS_SAVE_AND_SYNC' })}
            </Button>
          </div>
        </div>

        <div className="urlPerm-tabBar">
          <Button
            className={
              urlValue?.length <= 0 ? 'disabled-add-btn add-urls' : 'add-urls'
            }
            disabled={urlValue?.length <= 0}
            onClick={handleAddUrl}
          >
            {f({ id: 'COM_DMS_ADD' })}
          </Button>
          <InputText
            value={urlValue}
            placeholder="Enter URL"
            onChange={(e) => onUrlChange(e)}
            className="addUrl-input"
          />
          <span className="url-error">{urlError}</span>
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => {
              setActiveIndex(e.index);
            }}
            className="urlPermission-tabView"
          >
            <TabPanel header="Allow List">
              <div className="url-list">
                <div className="url-list-table">
                  <div className="url-list-header">
                    <div>{f({ id: 'COM_DMS_URLS' })}</div>
                  </div>
                  {allowListUrls?.length > 0 ? (
                    allowListUrls?.map((device) => renderUrls(device))
                  ) : (
                    <div className="empty-table-msg">
                      {f({ id: 'COM_DMS_EMPTY_ALLOW_LIST_MSG' })}
                    </div>
                  )}
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Block List">
              <div className="url-list">
                <div className="url-list-table">
                  <div className="url-list-header">
                    <div>{f({ id: 'COM_DMS_URLS' })}</div>
                  </div>
                  {blockListUrls?.length > 0 ? (
                    blockListUrls?.map((device) => renderUrls(device))
                  ) : (
                    <div className="empty-table-msg">
                      {f({ id: 'COM_DMS_EMPTY_BLOCK_LIST_MSG' })}
                    </div>
                  )}
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>
        {showSaveSyncPopup && (
          <CustomDialog
            dialogState={dialogState}
            handleClick={handlePopupAction}
            dialogVisible={showSaveSyncPopup}
            setDialogVisible={setShowSaveSyncPopup}
          />
        )}
        {showResetURLPopup && (
          <CustomDialog
            dialogState={dialogResetState}
            handleClick={handleResetPopupAction}
            dialogVisible={showResetURLPopup}
            setDialogVisible={setShowResetURLPopup}
          />
        )}
      </Dialog>
    </>
  );
};

export default UrlPermissionPopup;
