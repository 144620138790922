import { showSuccessToast } from '../../services/APIResponseHandler';
import { userServices } from '../../services/RestServices/userServices';
import {
  getUserService,
  updateUserService,
} from '../../services/UserManagementService';
import SetAuthToken from '../../utils/SetAuthToken';
import {
  GET_USER_FAILURE,
  GET_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_FAILURE,
  RESET_USER_MGMT,
  RESET_UPDATE_USER_FLAG,
  GET_USER_PENDING,
} from '../constants/UserManagementConstant';
import { signOut } from './AuthAction';
import { resetServerSettings } from './ServerSettingsAction';

const user = JSON.parse(localStorage.getItem('user'));
const { refreshToken } = user ?? {};

export const getUser = (payload) => async (dispatch) => {
  dispatch({ type: GET_USER_PENDING })
  const user = JSON.parse(localStorage.getItem('user'));
  SetAuthToken(user);
  try {
    const userRes = await getUserService(payload);
    const { data } = userRes;
    dispatch({ type: GET_USER_SUCCESS, payload: data });
  } catch ({ message }) {
    dispatch({ type: GET_USER_FAILURE, payload: message });
  }
};

export const updateUserInfo = (payload) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("user"));
  SetAuthToken(user);
  try {
    const userRes = await updateUserService(payload);
    const { data } = userRes;
    dispatch({ type: UPDATE_USER_SUCCESS, payload: data });
  } catch ({ response }) {
    const { data } = response;
    dispatch({ type: UPDATE_USER_FAILURE, payload: data });
  }
};

export const updateUserPassword = (payload) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("user"));
  SetAuthToken(user);
  try {
    const{ successMessage,setErrorMessage, userId, ...rest } = payload;
    const userRes = await userServices.changeUserPassword(rest);
    const { data, status } = userRes;
    if (status === 200) {
      dispatch({ type: UPDATE_USER_PASSWORD_SUCCESS, payload: data });
      showSuccessToast(successMessage);
      setTimeout(() => {
        localStorage.clear();
        dispatch(signOut({ refreshToken }));
        dispatch(resetServerSettings());
      }, 1500);
    } else {
      setErrorMessage(data.errorMessage);
    }
  } catch ({ response }) {
    const { data } = response;
    dispatch({ type: UPDATE_USER_PASSWORD_FAILURE, payload: data });
  }
};

export const resetUserUpdateFlag = () => async (dispatch) => {
  dispatch({ type: RESET_UPDATE_USER_FLAG });
};

export const resetUser = () => async (dispatch) => {
  dispatch({ type: RESET_USER_MGMT });
};
