import {
  GET_SERVER_SETTINGS_ERROR,
  GET_SERVER_SETTINGS_PENDING,
  GET_SERVER_SETTINGS_SUCCESS,
  IS_CHANGED_PASSWORD,
  RESET_SERVER_SETTINGS,
  UPDATE_SERVER_SETTINGS_ERROR,
  UPDATE_SERVER_SETTINGS_FLAG,
  UPDATE_SERVER_SETTINGS_PENDING,
  UPDATE_SERVER_SETTINGS_SUCCESS,
} from '../constants/ServerSettingsConstant';

const initialState = {
  loading: false,
  serverSettingsInfo: {},
  serverSettingsError: {},
  updateUserSuccess: false,
  changedPassword: false,
};

const serverSettings = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SERVER_SETTINGS_PENDING:
    case UPDATE_SERVER_SETTINGS_PENDING:
      return {
        ...state,
        loading: true,
        serverSettingsError: {},
        serverSettingsInfo: {},
      };
    case GET_SERVER_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        serverSettingsInfo: payload,
        serverSettingsError: {},
      };
    case UPDATE_SERVER_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateUserSuccess: true,
        serverSettingsInfo: payload,
        serverSettingsError: {},
      };
    case GET_SERVER_SETTINGS_ERROR:
    case UPDATE_SERVER_SETTINGS_ERROR:
      return {
        ...state,
        loading: false,
        serverSettingsInfo: {},
        serverSettingsError: payload,
      };
    case UPDATE_SERVER_SETTINGS_FLAG:
      return {
        ...state,
        updateUserSuccess: false,
      };
    case IS_CHANGED_PASSWORD:
      return { ...state, changedPassword: true }
    case RESET_SERVER_SETTINGS:
      return { ...initialState };
    default:
      return state;
  }
};

export default serverSettings;
