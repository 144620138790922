export const GET_AUTH_USER_PENDING = "GET_AUTH_USER_PENDING";
export const GET_AUTH_USER_SUCCESS = "GET_AUTH_USER_SUCCESS";
export const GET_AUTH_USER_ERROR = "GET_AUTH_USER_ERROR";

export const RESET_USER_ERROR = "RESET_USER_ERROR";
export const RESET_USER_SUCCESS = "RESET_USER_SUCCESS";

export const IS_AUTHENTICATED_SERVER = "IS_AUTHENTICATED_SERVER";

export const VALIDATE_OTP_ERROR = "VALIDATE_OTP_ERROR";
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS";

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const RESET_INITIAL_AUTH_STATE = "RESET_INITIAL_AUTH_STATE";

export const IS_SMTP_ENABLED = "IS_SMTP_ENABLED";

export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_FAILURE = "SIGN_OUT_FAILURE";

export const DASHBOARD_HEADER_TEXT = "DASHBOARD_HEADER_TEXT";

export const CLEAR_VALIDATE_OTP_ERROR = 'CLEAR_VALIDATE_OTP_ERROR';
export const CLEAR_RESET_OTP_ERROR = 'CLEAR_RESET_OTP_ERROR';
export const CLEAR_CHANGE_PASSWORD_ERROR = 'CLEAR_CHANGE_PASSWORD_ERROR';

export const RESET_AUTH_ERROR_CODE = 'RESET_AUTH_ERROR_CODE';