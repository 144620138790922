import React from 'react';
import './Setup.css';
import DataTableComponent from '../../../../components/DataTable/Datatable';
import { Paginator } from 'primereact/paginator';

const Setup = ({editDeviceNameTemplate, deviceList, devicesCount, first, rows, onPageChange, selectedItems, setSelectedItems, deviceTagsTemplate, screenPreviewTemplate, connectionStatusTemplate}) => {

  const headerInfo = [
    {field: "connectionStatus", header: "", width: '1px'},
    { field: "deviceName", header: "Device Name" },
    // { field: "deviceId", header: "Ethernet MAC" },
    { field: "deviceScreenPreview", header: "Screen Preview" },
    { field: "deviceCurrentInputSource", header: "Input Source" },
    { field: "groupName", header: "Group" },
    { field: "tags", header: "Tags" }
  ];

  return (
    <>
    <div className="device-information-table">
    <DataTableComponent className="devices-table-layout" 
      data={deviceList} headerInfo={headerInfo} selectedItems={selectedItems} setSelectedItems={setSelectedItems} editDeviceNameTemplate={editDeviceNameTemplate} deviceTagsTemplate={deviceTagsTemplate} screenPreviewTemplate={screenPreviewTemplate} connectionStatusTemplate={connectionStatusTemplate}/>
     <div className="custom-paginator">
        <Paginator first={first} rows={rows} totalRecords={devicesCount} onPageChange={onPageChange} rowsPerPageOptions={[10,20,30,50]}/>
      </div>
  </div>
    </>
  )
}

export default Setup
