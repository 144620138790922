import { RestServiceUtils } from '../../utils/RestServiceUtils';
import {
  DEVICES,
  APPROVE_DEVICES,
  GROUP_MGMT_API,
} from '../../config/constants/Constants';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const configExport = {
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'arraybuffer',
};

const getAllDeviceList = (payload, needPasswordReset, changeAfterLogin) => {
  const URL = DEVICES + '/filter';
  return RestServiceUtils.HTTP_POST(
    URL,
    payload,
    config,
    needPasswordReset,
    changeAfterLogin
  );
};

const deleteDevices = (deviceIdList) => {
  const URL = DEVICES + '/devices';
  return RestServiceUtils.HTTP_DELETE(URL, { deviceIds: deviceIdList }, config);
};

const exportDevices = (payload) => {
  const URL = DEVICES + '/export';
  return RestServiceUtils.HTTP_POST(URL, payload, configExport);
};

const approveDevices = (payload, groupId) => {
  const URL = `${APPROVE_DEVICES}/${groupId}`;
  const params = {
    deviceIds: payload,
  };
  return RestServiceUtils.HTTP_POST(URL, params, config);
};

const setDeviceName = (deviceName, deviceIds) => {
  const URL = DEVICES + '/setName';
  return RestServiceUtils.HTTP_POST(
    URL,
    { deviceName: deviceName, deviceIds: deviceIds },
    config
  );
};

const triggerScreenPreviewEvent = (deviceId, event) => {
  const URL = DEVICES + `/devices/${deviceId}/screenThumbnail`;
  return RestServiceUtils.HTTP_POST(URL, { event }, config);
};

const getScreenPreview = (deviceId, width, height) => {
  const URL = DEVICES + `/devices/${deviceId}/screenThumbnail`;
  return RestServiceUtils.HTTP_GET(
    URL,
    { width, height, cache: Date.now() },
    config
  );
};

const getDevicesByGroupAndAppPackage = (key, appPackageName) => {
  const URL = `${GROUP_MGMT_API}/${key}/devices`;
  return RestServiceUtils.HTTP_GET(URL, { appPackageName }, config);
};

const getDevicesByFirmwareVersion = (key, fwBuildVersionCode) => {
  const URL = `${GROUP_MGMT_API}/${key}/devices`;
  return RestServiceUtils.HTTP_GET(URL, { fwBuildVersionCode }, config);
};

const getDevicesByClientAppVersion = (key, clientAppVersionCode) => {
  const URL = `${GROUP_MGMT_API}/${key}/devices`;
  return RestServiceUtils.HTTP_GET(URL, { clientAppVersionCode }, config);
};

const getDeviceInfoById = (deviceId) => {
  const URL = `${DEVICES}/devices/${deviceId}/details`;
  return RestServiceUtils.HTTP_GET(URL, {}, config);
};

const getAppListByDeviceId = (deviceId) => {
  const URL = `${DEVICES}/devices/${deviceId}/appDetails`;
  return RestServiceUtils.HTTP_GET(URL, {}, config);
};

const triggerDeviceLockEvent = (payload) => {
  const URL = `${DEVICES}/devices/deviceLock`;
  RestServiceUtils.HTTP_POST(URL, payload, config);
};

const getDeviceUsageInfo = (deviceId) => {
  const URL = `${DEVICES}/devices/${deviceId}/refreshUsageTime`;
  RestServiceUtils.HTTP_GET(URL, {}, config);
};

export const deviceServices = {
  getAllDeviceList,
  deleteDevices,
  exportDevices,
  approveDevices,
  setDeviceName,
  triggerScreenPreviewEvent,
  getScreenPreview,
  getDevicesByGroupAndAppPackage,
  getDeviceInfoById,
  getAppListByDeviceId,
  triggerDeviceLockEvent,
  getDeviceUsageInfo,
  getDevicesByFirmwareVersion,
  getDevicesByClientAppVersion,
};
