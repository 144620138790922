import React from 'react';
import '../Devices/All/SetupTab/Setup.css';
import DataTableComponent from '../../components/DataTable/Datatable';
import { Paginator } from 'primereact/paginator';

const AllUsersList = ({userData, deviceGroupsTemplate, devicePermissionTemplate, setSelectedItems, selectedItems, headerInfo, totalUsersCount, onPageChange, first, rows, joiningDateTemplate, editUserBody}) => {


  return (
    <>
    <div className="device-information-table">
    <DataTableComponent className="datatable-users" 
      data={userData} headerInfo={headerInfo} selectedItems={selectedItems} setSelectedItems={setSelectedItems} deviceGroupsTemplate={deviceGroupsTemplate}
       devicePermissionTemplate={devicePermissionTemplate} joiningDateTemplate={joiningDateTemplate} editUserBody={editUserBody}/>
     <div className="custom-paginator">
        <Paginator first={first} rows={rows} totalRecords={totalUsersCount} onPageChange={onPageChange} rowsPerPageOptions={[10,20,30,50]}/>
      </div>
  </div>
    </>
  )
}

export default AllUsersList