let toast;

export const setToastRef = (ref) => {
  toast = ref;
};

export const showSuccessToast = (message) => {
  if (toast) {
    toast.show({ severity: 'success', summary: message, life: 3000 });
  }
};

export const showErrorToast = (message) => {
  if (toast) {
    toast.show({ severity: 'error', summary: message, life: 3000 });
  }
};

export const setToastComponent = (component) => {
    setToastRef(component);
  };

