import { LOGIN_PATH } from '../../router/RouteConstants';
import {
  changePaswordService,
  getUserAuthService,
  loginConfigService,
  resetUserAuthService,
  signOutService,
  validateOTPService,
} from '../../services/AuthService';
import SetAuthToken from '../../utils/SetAuthToken';
import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  CLEAR_CHANGE_PASSWORD_ERROR,
  CLEAR_RESET_OTP_ERROR,
  CLEAR_VALIDATE_OTP_ERROR,
  DASHBOARD_HEADER_TEXT,
  GET_AUTH_USER_ERROR,
  GET_AUTH_USER_PENDING,
  GET_AUTH_USER_SUCCESS,
  IS_AUTHENTICATED_SERVER,
  IS_SMTP_ENABLED,
  RESET_INITIAL_AUTH_STATE,
  RESET_USER_ERROR,
  RESET_USER_SUCCESS,
  SIGN_OUT_FAILURE,
  SIGN_OUT_SUCCESS,
  VALIDATE_OTP_ERROR,
  VALIDATE_OTP_SUCCESS,
  RESET_AUTH_ERROR_CODE
} from '../constants/AuthConstant';

export const getUserAuth = (payload) => async (dispatch) => {
  dispatch({ type: GET_AUTH_USER_PENDING });
  try {
    const authRes = await getUserAuthService(payload);
    const { data } = authRes;
    SetAuthToken(data);
    dispatch({ type: GET_AUTH_USER_SUCCESS, payload: data });
  } catch ({ response }) {
    const { data } = response ?? {};
    dispatch({ type: GET_AUTH_USER_ERROR, payload: data });
  }
};

export const isAuthenticated = () => async (dispatch) => {
  dispatch({ type: IS_AUTHENTICATED_SERVER });
};

export const resetUserAuth = (payload) => async (dispatch) => {
  dispatch({ type: GET_AUTH_USER_PENDING });
  try {
    const resetAuthRes = await resetUserAuthService(payload);
    const { data } = resetAuthRes;
    dispatch({ type: RESET_USER_SUCCESS, payload: data });
  } catch ({ response }) {
    const { data } = response ?? {};
    const { errorCode } = data;
    dispatch({ type: RESET_USER_ERROR, payload: errorCode });
  }
};

export const validateOTP = (payload) => async (dispatch) => {
  dispatch({ type: GET_AUTH_USER_PENDING });
  try {
    const validateRes = await validateOTPService(payload);
    const { data } = validateRes;
    dispatch({ type: VALIDATE_OTP_SUCCESS, payload: data });
  } catch ({ response }) {
    const { data } = response ?? {};
    dispatch({ type: VALIDATE_OTP_ERROR, payload: data });
  }
};

export const changePassword = (payload) => async (dispatch) => {
  try {
    const changePasswordRes = await changePaswordService(payload);
    const { data } = changePasswordRes;
    dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: data });
  } catch ({ response }) {
    const { data } = response ?? {};
    dispatch({ type: CHANGE_PASSWORD_ERROR, payload: data });
  }
};

export const getSMTPInfo = () => async (dispatch) => {
  const smtpRes = await loginConfigService();
  const { data } = smtpRes;
  dispatch({ type: IS_SMTP_ENABLED, payload: data });
};

export const signOut = (payload) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem('user'));
  SetAuthToken(user);
  try {
    const signOutRes = await signOutService(payload);
    const { data } = signOutRes;
    dispatch({ type: SIGN_OUT_SUCCESS, payload: data });
  } catch ({ response, message }) {
    if (response === undefined || response.status !== 200) {
      window.location.href = `${window.location.pathname}${LOGIN_PATH}`;
    }
    dispatch({ type: SIGN_OUT_FAILURE, payload: message });
  }
};

export const resetAuthState = () => (dispatch) => {
  dispatch({ type: RESET_INITIAL_AUTH_STATE });
};

export const dashboardHeaderMessage = (payload) => (dispatch) => {
  dispatch({ type: DASHBOARD_HEADER_TEXT, payload });
};

export const clearChangeValidateOTPError = () => (dispatch) => {
  dispatch({ type: CLEAR_VALIDATE_OTP_ERROR });
};

export const clearResetOTPError = () => (dispatch) => {
  dispatch({ type: CLEAR_RESET_OTP_ERROR });
};

export const clearChangePasswordError = () => (dispatch) => {
  dispatch({ type: CLEAR_CHANGE_PASSWORD_ERROR });
};

export const resetAuthErrorCode = () =>(dispatch) => {
  dispatch({ type: RESET_AUTH_ERROR_CODE });
}