import React, { useState, useRef, useEffect } from 'react';
import './Update.css';
import AppTable from '../../../components/AppTable/AppTable';
import { Button } from 'primereact/button';
import { useIntl } from 'react-intl';
import { Toast } from 'primereact/toast';
import { RestServiceUtils } from '../../../utils/RestServiceUtils';
import {
  APP_URL,
  CLIENT_APP_URL,
  FIRMWARE_URL,
} from '../../../config/constants/Constants';
import Spinner from '../../../components/Spinner/Spinner';
import UpdatePopup from './UpdatePopup';
import AndroidIcon from '../../../assets/images/androidIcon.png';
import {
  setToastComponent,
  showSuccessToast,
} from '../../../services/APIResponseHandler';
import { TabPanel, TabView } from 'primereact/tabview';
import UninstallIcon from '../../../assets/images/deleteIconBlack.svg';
import UploadFirmwarePopup from './UploadFirmwarePopup';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import Overlay from '../../../components/Overlay/Overlay';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import SearchBar from '../../../components/SearchBar/SearchBar';

const Update = ({ refreshPage }) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const toast = useRef(null);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [clientAppData, setClientAppData] = useState([]);
  const APK_ERROR = f({ id: 'COM_DMS_APK_MISMATCH_ERROR' });
  const APP_INSTALL = f({ id: 'COM_DMS_APP_INSTALL' });
  const APP_NAME = f({ id: 'COM_DMS_APP_NAME' });
  const VERSION = f({ id: 'COM_DMS_VERSION' });
  const SIZE = f({ id: 'COM_DMS_SIZE' });
  const CANCEL = f({ id: 'COM_DMS_CANCEL' });
  const UPDATE = f({ id: 'COM_DMS_UPDATE' });
  const CLIENT_UPDATE = f({ id: 'COM_DMS_CLIENT_UPDATE' });
  const FIRMWARE_UPDATE = f({ id: 'COM_DMS_FIRMWARE_UPDATE' });
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const INSTALL = f({ id: 'COM_DMS_INSTALL' });
  const [firmwareData, setFirmwareData] = useState([]);
  const [clickedUploadFirmware, setClickedUploadFirmware] = useState(false);
  const [firmwareUploadPercentage, setFirmwareUploadPercentage] = useState(0);
  const [isFwUploaded, setIsFwUploaded] = useState(false);
  const [currentFWVersion, setCurrentFWVersion] = useState('');
  const [selectedGroupKeys, setSelectedGroupKeys] = useState([]);
  const [selectedCheckedKeys, setSelectedCheckedKeys] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalFwCount, setTotalFwCount] = useState(0);
  const [updateSearchText, setUpdateSearchText] = useState('');
  const [currentClientAppVersion, setCurrentClientAppVersion] = useState('');

  useEffect(() => {
    setToastComponent(toast.current);
  }, []);

  const getClientApp = () => {
    setLoading(true);
    RestServiceUtils.HTTP_GET(CLIENT_APP_URL).then(({ data }) => {
      if (!data || data?.error) {
        setLoading(false);
        return;
      }
      const filteredAppData = [];
      filteredAppData.push({
        appImg: data.imgSrc,
        appName: data.appName,
        size: data.appSize,
        version: data.appVersionName,
        appPackageName: data.appPackageName,
        appVersionCode: data.appVersionCode,
      });
      setClientAppData(filteredAppData);
      setLoading(false);
    });
  };

  const getInitialFirmwareData = () => {
    RestServiceUtils.HTTP_POST(`${FIRMWARE_URL}`, {
      startIndex: 0,
      pageSize: 10,
      searchText: '',
    }).then(({ data }) => {
      const { items, totalCount } = data ?? {};
      setFirmwareData(items);
      setTotalFwCount(totalCount);
    });
  };

  useEffect(() => {
    getClientApp();
    getInitialFirmwareData();
    setUpdateSearchText('');
    setPageNo(0);
    setRowsPerPage(10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const appUpdateBtn = (data) => {
    const { appVersionCode } = data ?? {};
    const handleAppAction = () => {
      setOpenUpdatePopup(true);
      setCurrentClientAppVersion(appVersionCode);
    };
    return (
      <Button className=" manageApp_btn" onClick={handleAppAction}>
        {f({ id: 'COM_DMS_UPDATE' })}
      </Button>
    );
  };

  const appNameCol = (data) => {
    const { appImg, appName } = data ?? {};
    return (
      <div style={{ display: 'flex' }}>
        <img
          src={appImg !== '' ? appImg : AndroidIcon}
          alt="app"
          style={{ height: '24px', marginRight: '1rem' }}
        />
        <div>{appName}</div>
      </div>
    );
  };

  const appSizeBody = (data) => {
    const { size } = data ?? {};
    return <>{size} MB</>;
  };

  useEffect(() => {
    if (activeTab === 1) {
      getInitialFirmwareData();
      setCurrentClientAppVersion('');
    } else {
      getClientApp();
      setCurrentFWVersion('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, rowsPerPage, activeTab]);

  const firmwareActionBody = (firmwareData) => {
    const { fwBuildVersionCode } = firmwareData;

    return (
      <div
        className="firmware-action-buttons"
        style={{ display: 'flex', justifyContent: 'space-around' }}
      >
        <Button
          className="manageApp_btn"
          onClick={() => {
            setCurrentFWVersion(fwBuildVersionCode);
            setOpenUpdatePopup(!openUpdatePopup);
          }}
        >
          {f({ id: 'COM_DMS_INSTALL' })}
        </Button>
        <img
          src={UninstallIcon}
          alt="uninstallButton"
          onClick={() => {
            setCurrentFWVersion(fwBuildVersionCode);
            setDialogVisible(true);
          }}
        />
      </div>
    );
  };

  const clientUpdateColumns = [
    {
      field: 'appName',
      header: APP_NAME,
      body: appNameCol,
      style: { width: '70%' },
    },
    { field: 'version', header: VERSION, style: { width: '10%' } },
    { field: 'size', header: SIZE, body: appSizeBody, style: { width: '10%' } },
    {
      field: 'appInstall',
      header: APP_INSTALL,
      body: appUpdateBtn,
      style: { width: '10%' },
    },
  ];

  const firmwareSizeBody = (data) => {
    const { fwSize } = data ?? {};
    return <>{(fwSize / (1024 * 1024 * 1024)).toFixed(2)} GB</>;
  };

  const firmwareColumns = [
    {
      field: 'fwName',
      header: f({ id: 'COM_DMS_FIRMWARE_VERSION' }),
      style: { width: '45%' },
    },
    {
      field: 'fwSize',
      header: f({ id: 'COM_DMS_FIRMWARE_SIZE' }),
      style: { width: '45%' },
      body: firmwareSizeBody,
    },
    {
      field: 'action',
      align: 'center',
      header: f({ id: 'COM_DMS_ACTION' }),
      body: firmwareActionBody,
      style: { width: '10%' },
    },
  ];

  const handleUploadApk = () => {
    fileInputRef.current.click();
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.name?.length > 4 && file.name.endsWith('.apk')) {
        setLoading(true);
        RestServiceUtils.HTTP_POST(
          CLIENT_APP_URL,
          { file },
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
          .then(({ data }) => {
            if (data === 'success') {
              showSuccessToast(
                f({ id: 'COM_DMS_CLIENT_APP_UPLOAD_SUCCESS_MESSAGE' })
              );
            }
            setLoading(false);
            getClientApp();
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } else {
        toast.current.show({
          severity: 'error',
          summary: APK_ERROR,
          life: 2000,
        });
      }
    }
  };

  const handleAppUpdate = () => {
    setOpenUpdatePopup(false);
    const appAction = 'UPGRADE';
    const payload = {
      targetDevices: {
        deviceIds: activeTabIndex === 0 ? [] : selectedCheckedKeys,
        groupIds: activeTabIndex === 0 ? selectedGroupKeys : [],
      },
      appAction,
    };
    RestServiceUtils.HTTP_POST(
      `${APP_URL}/${clientAppData?.[0]?.appPackageName}`,
      payload
    ).then(
      (res) =>
        res?.status === 200 &&
        showSuccessToast(f({ id: 'COM_DMS_CLIENT_APP_UPDATE_COMMAND_SENT' }))
    );
  };

  const handleInstallFirmware = () => {
    RestServiceUtils.HTTP_POST(`${FIRMWARE_URL}/deploy`, {
      fwBuildVersionCode: currentFWVersion,
      targetDevices: {
        deviceIds: activeTabIndex === 0 ? [] : selectedCheckedKeys,
        groupIds: activeTabIndex === 0 ? selectedGroupKeys : [],
      },
    }).then((res) => {
      const { data } = res ?? {};
      if (!data.errorCode) {
        showSuccessToast(f({ id: 'COM_DMS_FW_UPDATE_SUCCESS_MSG' }));
      }
      setActiveTabIndex(0);
      setOpenUpdatePopup(false);
    });
  };

  const manageAppHeader = () => {
    return (
      <>
        <Toast ref={toast} position="top-center" />
        <div className="manageAppsHeader">
          <div className="manageAppsHeaderContent">
            {activeTab === 0 ? CLIENT_UPDATE : FIRMWARE_UPDATE}
          </div>
          <div>
            <Button
              className="manageAppCancel manageApp_btn"
              label={CANCEL}
              onClick={() => {
                setActiveTabIndex(0);
                setOpenUpdatePopup(false);
              }}
            />
            <Button
              className="manageAppAction manageApp_btn"
              label={activeTab === 0 ? UPDATE : INSTALL}
              onClick={
                activeTab === 0 ? handleAppUpdate : handleInstallFirmware
              }
              disabled={
                activeTabIndex === 0
                  ? !selectedGroupKeys.length
                  : !selectedCheckedKeys.length
              }
            />
          </div>
        </div>
        {activeTab === 0 ? (
          <div style={{ display: 'flex' }}>
            <img
              src={clientAppData?.[0]?.appImg}
              className="appIconStyle"
              alt="app-icon"
            />
            <div className="appNameStyle">{clientAppData?.[0]?.appName}</div>
          </div>
        ) : (
          <div style={{ fontSize: '1.25rem', fontWeight: '500' }}>
            {f({ id: 'COM_DMS_VERSION' })} : {currentFWVersion}
          </div>
        )}
      </>
    );
  };

  const UpdateComponent = (columns, appData) => {
    const onPageChange = (event) => {
      setPageNo(event.first);
      setRowsPerPage(event.rows);
    };

    const firmwareSearchHandler = () => {
      RestServiceUtils.HTTP_POST(`${FIRMWARE_URL}`, {
        startIndex: pageNo,
        pageSize: rowsPerPage,
        searchText: updateSearchText,
      }).then(({ data }) => {
        const { items, totalCount } = data ?? {};
        setFirmwareData(items);
        setTotalFwCount(totalCount);
      });
    };

    const handleSearchInputKeyDown = (e) => {
      if (e.key === 'Enter') {
        firmwareSearchHandler();
      }
    };

    return (
      <div className="update">
        {loading && <Spinner />}
        <UpdatePopup
          manageAppHeader={manageAppHeader}
          openUpdatePopup={openUpdatePopup}
          activeTabIndex={activeTabIndex}
          currentFWVersion={currentFWVersion}
          isFirmwareVersion={activeTab === 1}
          isClientAppVersion={activeTab === 0}
          setActiveTabIndex={setActiveTabIndex}
          selectedGroupKeys={selectedGroupKeys}
          setOpenUpdatePopup={setOpenUpdatePopup}
          selectedCheckedKeys={selectedCheckedKeys}
          setSelectedGroupKeys={setSelectedGroupKeys}
          setSelectedCheckedKeys={setSelectedCheckedKeys}
          appPackage={clientAppData?.[0]?.appPackageName}
          currentClientAppVersion={currentClientAppVersion}
        />

        <div
          className={
            activeTab === 1
              ? 'app-install-header firmware-update-header'
              : 'app-install-header'
          }
        >
          <div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={onFileChange}
              onClick={(e) => (e.target.value = '')}
              style={{ display: 'none' }}
            />
            <Button
              className="app-upload-btn"
              onClick={
                activeTab === 0
                  ? handleUploadApk
                  : () => setClickedUploadFirmware(!clickedUploadFirmware)
              }
            >
              {activeTab === 0
                ? f({ id: 'COM_DMS_UPLOAD_CLIENT_APP' })
                : f({ id: 'COM_DMS_UPLOAD_FIRMWARE' })}
            </Button>
          </div>
          {activeTab === 1 && (
            <div>
              <SearchBar
                placeholder={f({ id: 'COM_DMS_SEARCH' })}
                value={updateSearchText}
                setValue={setUpdateSearchText}
                searchHandler={firmwareSearchHandler}
                handleInputKeyDown={handleSearchInputKeyDown}
              />
            </div>
          )}
        </div>
        <AppTable
          data={appData}
          first={pageNo}
          rows={rowsPerPage}
          columns={columns}
          totalCount={totalFwCount}
          onPageChange={onPageChange}
          hidePagination={activeTab === 0}
          emptyMessageId={
            activeTab === 0
              ? 'COM_DMS_NO_CLIENT_APP_TO_UPDATE'
              : 'COM_DMS_NO_FIRMWARE_UPDATE'
          }
          tabClassName={
            activeTab === 0 ? 'app-update-table' : 'app-firmware-table'
          }
        />
      </div>
    );
  };

  const dialogState = {
    header: f({ id: 'COM_DMS_DELETE_FIRMWARE' }),
    body: f({ id: 'COM_DMS_DELETE_FIRMWARE_TEXT' }),
    footer: '',
    action: f({ id: 'COM_DMS_YES' }),
    cancel: f({ id: 'COM_DMS_NO' }),
  };

  const [dialogVisible, setDialogVisible] = useState(false);

  const handlePopupAction = () => {
    RestServiceUtils.HTTP_DELETE(FIRMWARE_URL, {
      fwBuildVersionCode: currentFWVersion,
    }).then(() => {
      RestServiceUtils.HTTP_POST(`${FIRMWARE_URL}`, {
        startIndex: 0,
        pageSize: rowsPerPage,
      }).then(({ data }) => {
        const { items } = data;
        setDialogVisible(false);
        setFirmwareData(items);
      });
    });
  };

  return (
    <>
      <Toast ref={toast} position="top-center" />
      {clickedUploadFirmware && (
        <UploadFirmwarePopup
          pageSize={rowsPerPage}
          setIsFwUploaded={setIsFwUploaded}
          setFirmwareData={setFirmwareData}
          firmwareUploadPercentage={firmwareUploadPercentage}
          setClickedUploadFirmware={setClickedUploadFirmware}
          setFirmwareUploadPercentage={setFirmwareUploadPercentage}
        />
      )}

      {activeTab === 1 && (
        <CustomDialog
          dialogState={dialogState}
          handleClick={handlePopupAction}
          dialogVisible={dialogVisible}
          setDialogVisible={setDialogVisible}
        />
      )}

      {isFwUploaded && (
        <Overlay className="update-progress-overlay">
          <div className="update-circular-progress">
            <CircularProgressbarWithChildren
              value={firmwareUploadPercentage}
              styles={buildStyles({
                rotation: 0,
                strokeLinecap: 'butt',
                trailColor: '#d6d6d6',
                backgroundColor: '#3e98c7',
                pathTransitionDuration: 0.5,
                pathColor: `rgba(62, 152, 199, ${66 / 100})`,
              })}
            >
              <div style={{ fontSize: '1rem', marginTop: -10, color: '#f88' }}>
                <strong>{firmwareUploadPercentage}%</strong>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </Overlay>
      )}

      <div className="device-update">
        <TabView
          activeIndex={activeTab}
          onTabChange={(e) => {
            setActiveTab(e.index);
          }}
        >
          <TabPanel header={CLIENT_UPDATE}>
            {UpdateComponent(clientUpdateColumns, clientAppData)}
          </TabPanel>
          <TabPanel header={FIRMWARE_UPDATE}>
            {UpdateComponent(firmwareColumns, firmwareData)}
          </TabPanel>
        </TabView>
      </div>
    </>
  );
};

export default Update;
