import React, { useState, useEffect, useRef } from 'react';
import { ListBox } from 'primereact/listbox';
import './DevicePermissionPopup.css';
import './GroupSuggest.css';
import { GroupManagementService } from '../../services/RestServices/GroupManagementService';
import {
  cleanJSONTree,
  removeNullValuesFromNestedObjectOfArray,
} from '../../utils/CleanJSONArrayObject';
import { convertJSONDataToTreeTableFormat } from '../../modules/Devices/Group/convertToJSONTree';
import { changeAttributesOfObject } from '../../utils/Utils';
import flatToTree from 'flat-to-tree';
import { Tooltip } from 'primereact/tooltip';

export const GroupSuggestPopup = ({
  visible,
  setNodes,
  className,
  setVisible,
  searchText,
  isGroupList,
  treeTableNode,
  setSearchText,
  groups: suggestions,
}) => {
  const [currentGroupId, setCurrentGroupId] = useState(null);
  const [currentGroupName, setCurrentGroupName] = useState(null);

  const searchRef = useRef(null);
  const mapping = [
    ['groupId', 'key'],
    ['groupName', 'label'],
    ['parentId', 'data'],
  ];

  const processData = (data) => {
    if (!data) return;
    data.forEach((currNode) => {
      changeAttributesOfObject(currNode, mapping);
      if (currNode.children && currNode.children.length > 0)
        processData(currNode.children);
    });
    return data;
  };

  React.useEffect(() => {
    const handleCloseGroupSuggest = (e) => {
      if (searchRef.current && !searchRef.current.contains(e.target))
        setVisible(false);
    };
    document.addEventListener('click', handleCloseGroupSuggest, true);
    return () => {
      document.removeEventListener('click', handleCloseGroupSuggest);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDotClick = () => {
    setSearchText(currentGroupName);
    GroupManagementService.getGroupList(currentGroupId).then(({ data }) => {
      const convertedJSONData = flatToTree(data, {
        id: 'groupId',
        parentId: 'parentId',
      });

      let finalNodes;

      if (!treeTableNode) {
        finalNodes = processData(convertedJSONData);
      } else {
        cleanJSONTree(convertedJSONData);
        const cleanedJSONTree =
          removeNullValuesFromNestedObjectOfArray(convertedJSONData);
        convertJSONDataToTreeTableFormat(cleanedJSONTree);
        finalNodes = cleanedJSONTree;
      }
      setNodes(finalNodes);
    });
    setVisible(!visible);
  };

  const itemTemplate = (option) => {
    const { groupParents, groupName, groupId } = option ?? {};
    const firstGroup = groupParents[0];
    const [firstGroupName, firstGroupId] = firstGroup ?? [];

    return (
      <div className="flex align-items-center group-suggestion-list">
        {groupParents.length > 0 && (
          <>
            <div
              data={firstGroupName}
              className="group-suggestion-list-box"
              onClick={(e) => setGroupDetails(e, firstGroupName, firstGroupId)}
            >
              {firstGroupName}
            </div>
            <div> {'>'} </div>
          </>
        )}
        {renderParents(groupParents, groupName)}
        <div
          data={groupName}
          className="group-suggestion-list-box"
          onClick={(e) => setGroupDetails(e, groupName, groupId)}
        >
          {groupName}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (currentGroupId !== null || currentGroupName !== null) handleDotClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroupId, currentGroupName]);

  const setGroupDetails = (e, gName, groupID) => {
    e.stopPropagation();
    setCurrentGroupId(groupID);
    setCurrentGroupName(gName);
    setSearchText(gName);
    const resNodes = [];
    GroupManagementService.getGroupList(groupID).then(({ data }) => {
      let finalNodes; 
      if (isGroupList) {
        const filteredData = data?.filter((val) => val.groupId === +groupID);
        const { groupId, groupName, deviceCount } = filteredData[0] ?? {};
        const currentNodeData = {
          key: groupId,
          data: {
            type: 'group',
            groupName,
            deviceCount,
          },
          leaf: false,
        };
        resNodes.push(currentNodeData);
        finalNodes = resNodes;
      } else {
        const convertedJSONData = flatToTree(data, {
          id: 'groupId',
          parentId: 'parentId',
        });

        if (!treeTableNode) {
          finalNodes = processData(convertedJSONData);
        } else {
          cleanJSONTree(convertedJSONData);
          const cleanedJSONTree =
            removeNullValuesFromNestedObjectOfArray(convertedJSONData);
          convertJSONDataToTreeTableFormat(cleanedJSONTree);

          finalNodes = cleanedJSONTree;
        }
      }
      setNodes(finalNodes);
    });
    setVisible(!visible);
  };

  const renderParents = function (parents, currentGroupName) {
    const groupLength = parents?.length || 0;
    const secondLastGroup = parents[groupLength - 1];
    const [secondLastGroupName, secondLastGroupId] = secondLastGroup ?? [];
    const previusRecords = [];
    let uniqueId = [];
    for (let idx = 0; idx < groupLength; idx++) {
      uniqueId.push(parents[idx][1]);
    }
    for (let idx = 0; idx < groupLength; idx++) {
      previusRecords.push(parents[idx][0]);
    }

    uniqueId = uniqueId.join('_');
    previusRecords.push(currentGroupName);

    return (
      <>
        {groupLength > 2 && (
          <>
            <Tooltip target={`#group${uniqueId}`} position="bottom">
              <div className="infoIcon_tooltipContent">
                {previusRecords.join(' > ')}
              </div>
            </Tooltip>
            <div id={`group${uniqueId}`}>{`... `}</div>
            <div>&nbsp;{`>`}</div>
          </>
        )}

        {groupLength >= 2 && (
          <>
            <div
              data={secondLastGroupName}
              className="group-suggestion-list-box"
              onClick={(e) =>
                setGroupDetails(e, secondLastGroupName, secondLastGroupId)
              }
            >
              {`${secondLastGroupName}`}
            </div>
            <div>&nbsp;{`>`}</div>
          </>
        )}
      </>
    );
  };

  return (
    <div ref={searchRef}>
      <ListBox
        value={searchText}
        options={suggestions}
        itemTemplate={itemTemplate}
        className={className ? `${className} li-box` : 'li-box'}
      />
    </div>
  );
};
