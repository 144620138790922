export const convertJSONDataToTreeTableFormat = (treeData) => {
  if (!treeData) return;
  for (let index = 0; index < treeData?.length; index++) {
    const { parentId, groupId, children, groupName } = treeData[index] ?? {};
    treeData[index].key = groupId;
    treeData[index].data = {
      groupId,
      groupName,
      parentId,
    };
    treeData[index].groupId = null;
    treeData[index].groupName = null;
    treeData[index].parentId = null;
    treeData[index].children = children;
    treeData[index].leaf = false;
    treeData[index].allChildren = [groupId];
    convertJSONDataToTreeTableFormat(treeData[index]?.children);
    for (let ch = 0 ; ch < treeData[index]?.children.length ; ch++) {
      treeData[index].allChildren.push(...treeData[index]?.children[ch]?.allChildren);
    }
    treeData[index].data = {...treeData[index].data , "allChildren" : treeData[index].allChildren};
  }
};
