import {
  BLANK_IMG_BASE64_URL,
  EARTHQUAKE_ALERT_URL,
  FIREALERT_IMG_BASE64_URL,
} from './TemplateConstantImages';

export const colorCodes = [
  '#4F4A45',
  '#03458a',
  '#3d2f00',
  '#690000',
  '#004b00',
  '#ffffff',
];

export const backgroundColorCodes = [
  '#343a40',
  '#1c85d5',
  '#febc22',
  '#f7471c',
  '#47d78a',
  '#1976d2',
  '#cce5ff',
  '#fff3cd',
  '#f8d7da',
  '#d4edd9',
];

// Options for font size
export const fontOptions = [
  { name: 'Small', code: 18 },
  { name: 'Medium', code: 22 },
  { name: 'Large', code: 26 },
];

// mapping of textSize to Device text size
export const textSizeMap = new Map([
  [18, 1],
  [22, 1.25],
  [26, 1.5],
]);

// Options for display position - Top, Center, Bottom
export const templatePositionOptions = [
  { name: 'Top', code: 'TOP' },
  { name: 'Center', code: 'CENTRE' },
  { name: 'Bottom', code: 'BOTTOM' },
];

export const DEFAULT_TEMPLATE_NAMES = new Set([
  'Blank',
  'Earthquake Alert',
  'Fire Alert',
]);

export const BLANK_MESSAGE_TEMPLATE_ID = -1;
export const BLANK_ALERT_TEMPLATE_ID = -2;
export const EARTHQUAKE_ALERT_TEMPLATE_ID = -3;
export const FIRE_ALERT_TEMPLATE_ID = -4;

export const messageTemplate = {
  templateType: 'MESSAGE',
  templateId: BLANK_MESSAGE_TEMPLATE_ID,
  templateName: 'Blank',
  templateMessage: 'Sample Text',
  templatePosition: 'BOTTOM',
  backgroundColor: '#efefef',
  textColor: '#2d2d2d',
  textSize: 18,
  textStyle: 'PLAIN',
};

export const alertTemplate = [
  {
    templateId: BLANK_ALERT_TEMPLATE_ID,
    templateName: 'Blank',
    textStyle: 'BOLD',
    alertImgName: 'blank.svg',
    textSize: 18,
    textColor: '#252a33',
    backgroundColor: '#fff3cd',
    alertImgSrc: BLANK_IMG_BASE64_URL,
    templateMessage: 'Sample Alert',
  },
  {
    templateId: EARTHQUAKE_ALERT_TEMPLATE_ID,
    textStyle: 'ITALIC',
    alertImgName: 'earthquake-alert.svg',
    textSize: 18,
    textColor: '#252a33',
    backgroundColor: '#fff3cd',
    templateName: 'Earthquake Alert',
    alertImgSrc: EARTHQUAKE_ALERT_URL,
    templateMessage: 'Earthquake Alert',
  },
  {
    templateId: FIRE_ALERT_TEMPLATE_ID,
    textStyle: 'ITALIC',
    textColor: '#252a33',
    textSize: 22,
    alertImgName: 'fire-alert.svg',
    alertImgSrc: FIREALERT_IMG_BASE64_URL,
    templateName: 'Fire Alert',
    backgroundColor: '#fff3cd',
    templateMessage: 'Fire Alert',
  },
];

export const INITIAL_SELECTED_TEMPLATE = {
  templateName: '',
  textStyle: '',
  alertImgName: '',
  alertImgSrc: '',
  textSize: fontOptions[0],
  textColor: colorCodes[0],
  templatePosition: templatePositionOptions[0],
  backgroundColor: backgroundColorCodes[0],
  templateMessage: messageTemplate.templateMessage,
};

export const textPositionDisplay = (selectedTemplateData) => {
  let positionVal = '0';
  let fontSizeVal;
  let textDecoration;
  let fontWeight;
  let fontStyleVal;

  const {
    textStyle,
    textSize,
    textColor,
    templatePosition,
    backgroundColor,
    templateMessage,
  } = selectedTemplateData ?? {};

  switch (templatePosition?.code) {
    case 'TOP':
      positionVal = 0;
      break;
    case 'CENTRE':
      positionVal = 'calc(22.5vh - 2rem)';
      break;
    default:
      positionVal = 'calc(45vh - 4.12rem)';
      break;
  }
  switch (textSize?.code) {
    default:
      fontSizeVal = `${textSizeMap.get(textSize?.code)}rem`;
      break;
  }
  switch (textStyle) {
    case 'BOLD':
      fontWeight = 700;
      break;
    case 'ITALIC':
      fontStyleVal = 'ITALIC';
      break;
    default:
      textDecoration = 'none';
      fontWeight = 400;
      break;
  }

  const textPropertiesObj = {
    marginTop: positionVal,
    fontSize: fontSizeVal,
    textDecoration,
    fontWeight,
    fontStyle: fontStyleVal,
    color: textColor,
    backgroundColor,
  };

  return (
    <div style={{ ...textPropertiesObj }}>
      <marquee>{templateMessage}</marquee>
    </div>
  );
};

export const alertDisplay = (
  selectedTemplateData,
  imageUrlFile,
  isCommonImage,
  SampleImageText
) => {
  let positionVal = '0';
  let fontSizeVal;
  let textDecoration;
  let fontWeight;
  let fontStyleVal;

  const { textStyle, textSize, textColor, backgroundColor, templateMessage } =
    selectedTemplateData ?? {};

  switch (textSize?.code) {
    default:
      fontSizeVal = `${textSizeMap.get(textSize?.code)}rem`;
      break;
  }
  switch (textStyle) {
    case 'BOLD':
      fontWeight = 700;
      break;
    case 'ITALIC':
      fontStyleVal = 'ITALIC';
      break;
    default:
      textDecoration = 'none';
      fontWeight = 400;
      break;
  }

  const textPropertiesObj = {
    marginTop: positionVal,
    fontSize: fontSizeVal,
    textDecoration,
    fontWeight,
    fontStyle: fontStyleVal,
    color: textColor,
    backgroundColor,
  };
  const { imageUrl } = imageUrlFile;

  return (
    <div className="alert-image-div" style={{ backgroundColor }}>
      <img src={!isCommonImage ? imageUrl : SampleImageText} alt="alert" />
      <div className="alert-message-text" style={textPropertiesObj}>
        {templateMessage}
      </div>
    </div>
  );
};
