import React, { useEffect, useState } from 'react';
import './Screen.css';
import { InputSwitch } from 'primereact/inputswitch';
import { useIntl } from 'react-intl';
import { deviceServices } from '../../../services/RestServices/deviceServices';
import Spinner from '../../Spinner/Spinner';

const Screen = ({
  details,
  screenPreviewAction,
  setScreenPreviewAction,
  updatedScreen,
  setUpdatedScreen,
  loading
}) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const [screenPreview, setScreenPreview] = useState("");
  const [toggleScreen, setToggleScreen] = useState(false);

  useEffect(() => {
    setUpdatedScreen(!updatedScreen);
  }, []);

  const triggerScreenPreviewHandler = async () => {
    if (screenPreviewAction) {
      deviceServices.getScreenPreview(details.deviceId, 995, 525).then((response) => {
        if (response.status === 200) {
          const { src } = response?.data ?? '';
          setScreenPreview(src);
        } else setScreenPreview("");
      });
    }
    setUpdatedScreen((updatedScreen) => !updatedScreen);
  };

  useEffect(() => {
      if(screenPreviewAction){
        if(toggleScreen){
          deviceServices.triggerScreenPreviewEvent(
            details.deviceId,
            'START'
          ).then((response) => {
            if(screenPreviewAction){
              if(response.status === 200){
                triggerScreenPreviewHandler();
              }
              else{
                setScreenPreview("");
              }
            }
          })
        }
        else if (details.deviceInfo.deviceScreenPreview === 'START') {
          triggerScreenPreviewHandler();
        }
      }
      else if(!screenPreviewAction && toggleScreen){
        deviceServices.triggerScreenPreviewEvent(
          details.deviceId,
          'STOP'
        )
      }
  },[screenPreviewAction]);

  useEffect(() => {
    if (screenPreviewAction && details.deviceInfo?.connectionStatus) {
      setTimeout(() => triggerScreenPreviewHandler(), 16000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedScreen]);

  return (
    <>
      {loading ? <Spinner /> : ''}
      <div className="toggleScreenCapture">
        <span className="toggleText">
          {f({ id: 'COM_DMS_DETAILS_SCREEN_PREVIEW' })}
        </span>
        <InputSwitch
          checked={screenPreviewAction}
          onChange={(e) => {setScreenPreviewAction(e.value);
                          setToggleScreen(true);}}
          className="detailsScreenView"
        />
        <span className="screenStatusText">
          {screenPreviewAction ? f({ id: 'COM_DMS_ON' }) : f({ id: 'COM_DMS_OFF' })}
        </span>
      </div>
      <div className="screenBox">
        <div className="screenCaptureBox">
          <div id="captureID">
            {screenPreviewAction && screenPreview !== "" ? (
              <img
                src={screenPreview}
                className="setBckColor"
                alt="screen-preview"
              />
            ) : (
              <div className="screenCapture" style={{ opacity: '1' }}>
                  {screenPreviewAction && screenPreview === "" ?
                    details.deviceInfo?.connectionStatus ?
                    <span className="off off_head">
                    {f({ id: 'COM_DMS_SCREEN_PREVIEW_AWAITED' })}
                    </span> : 
                    <span className="off off_head">
                      {f({ id: 'COM_DMS_NO_PREVIEW' })}
                    </span> :
                    <>
                      <span className="off off_head">
                      {f({ id: 'COM_DMS_SCREEN_CAPTURE_OFF' })}
                      </span>
                      <br />
                      <br />
                      <span className="off">
                      {f({ id: 'COM_DMS_SCREEN_CAPTURE_OFF_SUBTEXT' })}
                      </span>
                    </>}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Screen;
