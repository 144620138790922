import React from 'react';
import './CenteredDashboardPopup.css';
import Overlay from '../../components/Overlay/Overlay';

const CenteredDashboardPopup = ({ popupHeader, popupBody, popupFooter }) => {
  return (
    <div>
      <Overlay className="centered-dashboard-overlay">
        <div className="centered-dashboard-popup">
          {popupHeader()}
          {popupBody()}
          {popupFooter()}
        </div>
      </Overlay>
    </div>
  );
};

export default CenteredDashboardPopup;
