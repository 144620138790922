import React, { useState, useEffect } from 'react';
import './Home.css';
import UserInfo from '../UserInfo/UserInfo';
import SamsungDMSHeader from '../../components/SamsungDMSHeader/SamsungDMSHeader';
import Users from '../Users/Users';
import { useIntl } from 'react-intl';
import ServerSettings from '../ServerSettings/ServerSettings';
import { useSelector, useDispatch } from 'react-redux';
import ChangePassword from '../ChangePassword/ChangePassword';
import {
  getUser,
  resetUser,
  updateUserPassword,
} from '../../redux/actions/UserManagementAction';
import { getCommonServerSettings } from '../../redux/actions/ServerSettingsAction';
import All from '../Devices/All/All';
import Group from '../Devices/Group/Group';
import Update from '../Devices/Update/Update';
import EventLogs from '../Stats/EventLogs/EventLogs';
import OkCard from '../../components/OkCard/OkCard';
import { addMonths, compareDates } from '../../utils/DateDifference';
import {
  dashboardHeaderMessage,
  signOut,
} from '../../redux/actions/AuthAction';
import { useNavigate } from 'react-router';
import { LOGIN_PATH } from '../../router/RouteConstants';
import StatsIcon from '../../assets/images/analytics_black_24dp.svg';
import DeviceIcon from '../../assets/images/device-menu-icon.svg';
import PersonIcon from '../../assets/images/person_search_black_24dp.svg';
import SettingsIcon from '../../assets/images/settings_black_24dp.svg';
import AppsIcon from '../../assets/images/dashboard_customize_black_24dp.svg';
import UpArrow from '../../assets/images/upArrow.svg';
import DownArrow from '../../assets/images/downArrow.svg';
import UserActiveIcon from '../../assets/images/person_search_black_-1.svg';
import WarningMessage from '../../components/WarningMessage/WarningMessage';
import Unapproved from '../Devices/Unapproved/Unapproved';
import DeviceDetails from '../Devices/All/DeviceDetails/DeviceDetails';
import AppManagement from '../Apps/AppManagement/AppManagement';
import AppInstall from '../Apps/AppInstall/AppInstall';
import { validateUserDetails } from '../../utils/Validations';
import SamsungDMSFooter from '../../components/SamsungDMSFooter/SamsungDMSFooter';
import {
  isAdminOrSubAdmin,
  isDeviceManager,
  isDeviceOperator,
} from '../../utils/helper';
import DeviceActiveIcon from '../../assets/images/device-menu-icon-selected.svg';
import AppActiveIcon from '../../assets/images/dashboard_customize_black_-1.svg';
import StatsActiveIcon from '../../assets/images/analytics_black_-1.svg';
import SettingsActiveIcon from '../../assets/images/settings_black_-1.svg';
import Dashboard from '../Dashboard/Dashboard';
import DashboardActiveIcon from '../../assets/images/dash-icon-selected.svg';
import DashboardIcon from '../../assets/images/dash-icon-deselected.svg';
import EmergencyMessaging from '../Devices/All/EmergencyMessaging/EmergencyMessaging';
import { RestServiceUtils } from '../../utils/RestServiceUtils';
import { USERS as USERS_URL } from '../../../src/config/constants/Constants';

const Home = () => {
  const logoutUser = () => {};
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const dispatch = useDispatch();
  const DEVICE = f({ id: 'COM_DMS_DEVICE' });
  const APPS = f({ id: 'COM_DMS_APPS' });
  const STATS = f({ id: 'COM_DMS_STATS' });
  const USERS = f({ id: 'COM_DMS_USERS' });
  const DASHBOARD = f({ id: 'COM_DMS_DASHBOARD' });
  const SETTINGS = f({ id: 'COM_DMS_SETTINGS' });
  const MY_INFO = f({ id: 'COM_DMS_MY_INFO' });
  const SERVER_SETTINGS = f({ id: 'COM_DMS_SERVER_SETTINGS' });

  const [currentActive, setCurrentActive] = useState(DASHBOARD);
  const [currentSetting, setCurrentSetting] = useState(MY_INFO);

  const [open, setOpen] = useState(true);
  const [devicesCount, setDevicesCount] = useState({});
  const [connectedDevicesFlag, setConnectedDevicesFlag] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const navigate = useNavigate();

  const { serverSettingsInfo } = useSelector((state) => state.serverSettings);
  const { dashboardText } = useSelector((state) => state.auth);
  const { passwordSettings } = serverSettingsInfo ?? {};
  const { changeAfterLogin, changePeriod, preventReuse } =
    passwordSettings ?? {};
  const { userInfo, userError } = useSelector((state) => state.user);
  const { needPasswordReset, passwordChangeDate, isUserPopup, roleName } =
    userInfo ?? {};
  localStorage.setItem('loginUserRoleName', roleName);
  const user = JSON.parse(localStorage.getItem('user'));
  const { userId } = user ?? {};

  const CHANGE_PWD_TXT = `${f({ id: 'COM_DMS_WELCOME' })} !`;
  const CHILD_TEXT = f({ id: 'COM_DMS_SECURE_PWD_TEXT' });
  const CURR_PWD = f({ id: 'COM_DMS_CURR_PWD' });
  const CURR_PWD_TXT = f({ id: 'COM_DMS_CURR_PWD_TEXT' });
  const ALL = f({ id: 'COM_DMS_ALL' });
  const GROUP = f({ id: 'COM_DMS_GROUP' });
  const UPDATE = f({ id: 'COM_DMS_UPDATE' });
  const UNAPPROVED = f({ id: 'COM_DMS_UNAPPROVED' });
  const APP_INSTALL = f({ id: 'COM_DMS_APP_INSTALLATION' });
  const APP_MANAGEMENT = f({ id: 'COM_DMS_APP_MANAGEMENT' });
  const MESSAGING_ALERT = f({ id: 'COM_DMS_MESSAGE_OR_ALERT' });

  const lastPasswordChangeDate = new Date(passwordChangeDate);
  const currentDate = new Date();
  currentDate?.setHours(0, 0, 0, 0);
  lastPasswordChangeDate?.setHours(0, 0, 0, 0);
  const updatedDate = addMonths(lastPasswordChangeDate, changePeriod);
  const isExpired = compareDates(currentDate, updatedDate);

  const dashboardMap = new Map();
  if (isAdminOrSubAdmin()) {
    dashboardMap.set(STATS, ['Event Logs']);
  }

  const [currentStats, setCurrentStats] = useState('');
  dashboardMap.set(
    SETTINGS,
    isAdminOrSubAdmin() ? [MY_INFO, SERVER_SETTINGS] : [MY_INFO]
  );

  if (isAdminOrSubAdmin()) {
    dashboardMap.set(DEVICE, [ALL, GROUP, UPDATE, UNAPPROVED, MESSAGING_ALERT]);
  } else if (isDeviceManager()) {
    dashboardMap.set(DEVICE, [ALL, GROUP, UPDATE]);
  } else {
    dashboardMap.set(DEVICE, [ALL, GROUP]);
  }
  if (!isDeviceOperator()) {
    dashboardMap.set(APPS, [APP_INSTALL, APP_MANAGEMENT]);
  }

  const [currentDevice, setCurrentDevice] = useState(ALL);
  const [temporaryPwd, setTemporaryPwd] = useState('');
  const [newPwd, setNewPwd] = useState('');
  const [confirmNewPwd, setConfirmNewPwd] = useState('');
  const [okVerified, setOkVerified] = useState(false);
  const [pageToShow, setPageToShow] = useState('ALL');
  const [refreshPage, setRefreshPage] = useState(false);
  const [currentApp, setCurrentApp] = useState(APP_INSTALL);
  const [deviceDetails, setDeviceDetails] = useState([]);
  const [hideMsg, setHideMsg] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [deviceID, setDeviceID] = useState('');
  const [deviceList, setDeviceList] = useState([]);
  const [showFirstTimePopup, setShowFirstTimePopup] = useState(false);

  useEffect(() => {
    if (needPasswordReset) {
      if (changeAfterLogin) {
        setShowFirstTimePopup(true);
      } else if (changeAfterLogin === false) {
        RestServiceUtils.HTTP_PUT(
          `${USERS_URL}/updateNeedPasswordReset`,
          { needPasswordReset: false }
        ).then(() => {
          setShowFirstTimePopup(false);
        });
      }
    } else {
      setShowFirstTimePopup(false);
    }
  }, [changeAfterLogin, needPasswordReset, userId, showFirstTimePopup]);

  useEffect(() => {
    dispatch(getUser({ userId }));
    dispatch(getCommonServerSettings());
    dispatch(dashboardHeaderMessage(MY_INFO));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CHANGE_PASSWORD_TXT = f({
    id: 'COM_DMS_CHANGE_PWD_TXT',
  });

  const handleOkClick = () => {
    setOkVerified(true);
  };

  const handleSettings = () => {
    if (currentActive !== SETTINGS) {
      setOpen(true);
      setCurrentDevice(ALL);
    } else setOpen(!open);
    setCurrentActive(SETTINGS);
  };

  const handleMyInfoClick = () => {
    setOpen(true);
    setCurrentSetting(MY_INFO);
    setCurrentActive(SETTINGS);
  };

  const handleDevice = () => {
    if (currentActive !== DEVICE) {
      setPageToShow('ALL');
      setOpen(true);
      setCurrentSetting(MY_INFO);
    } else setOpen(!open);
    setCurrentActive(DEVICE);
  };

  const handleApps = () => {
    if (currentActive !== APPS) {
      setOpen(true);
      setCurrentApp(APP_INSTALL);
    } else setOpen(!open);
    setCurrentActive(APPS);
  };

  const handleStats = () => {
    if (currentActive !== STATS) {
      setOpen(true);
      setCurrentStats('Event Logs');
    } else setOpen(!open);
    setCurrentActive(STATS);
  };

  const handleUser = () => {
    setCurrentActive(USERS);
    setRefreshPage((refreshPage) => !refreshPage);
  };

  useEffect(() => {
    switch (currentActive) {
      case DASHBOARD:
        dispatch(dashboardHeaderMessage(DASHBOARD));
        break;
      case SETTINGS:
        dispatch(dashboardHeaderMessage(currentSetting));
        break;
      case DEVICE:
        dispatch(dashboardHeaderMessage(currentDevice));
        break;
      case USERS:
        dispatch(dashboardHeaderMessage(USERS));
        break;
      case APPS:
        dispatch(dashboardHeaderMessage(currentApp));
        break;
      case STATS:
        dispatch(dashboardHeaderMessage(currentStats));
        break;
      default:
        dispatch(dashboardHeaderMessage(currentActive));
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActive, currentSetting, currentDevice, currentStats, currentApp]);

  const filterPayload = {
    startIndex: 0,
    pageSize: 10,
    searchText: '',
    isEnrolled: true,
    groupId: '',
    tagIds: [],
    inputSources: [],
    connectionStatus: '',
    screenPreview: ''
  };

  const renderRightSideMenu = () => {
    switch (currentActive) {
      case SETTINGS:
        if (currentSetting === MY_INFO) {
          return (
            <UserInfo
              setPasswordSuccess={setPasswordSuccess}
              setHideMsg={setHideMsg}
              refreshPage={refreshPage}
            />
          );
        }
        return <ServerSettings refreshPage={refreshPage} />;
      case DASHBOARD:
        return (
          <Dashboard
            refreshPage={refreshPage}
            handleUser={handleUser}
            handleDevice={handleDevice}
            setDeviceList={setDeviceList}
            setCurrentDevice={setCurrentDevice}
            setDevicesCount={setDevicesCount}
            connectedDevicesFlag={connectedDevicesFlag}
            setConnectedDevicesFlag={setConnectedDevicesFlag}
          />
        );
      case DEVICE:
        if (currentDevice === ALL) {
          return pageToShow === 'ALL' ? (
            <All
              filterPayload={filterPayload}
              deviceList={deviceList}
              setDeviceList={setDeviceList}
              setPageToShow={setPageToShow}
              refreshPage={refreshPage}
              setDetails={setDeviceDetails}
              setRefreshPage={setRefreshPage}
              setDeviceID={setDeviceID}
              setDevicesCount={setDevicesCount}
              devicesCount={devicesCount}
              setConnectedDevicesFlag={setConnectedDevicesFlag}
              connectedDevicesFlag={connectedDevicesFlag}
            />
          ) : (
            <DeviceDetails
              setPageToShow={setPageToShow}
              details={deviceDetails}
              setDetails={setDeviceDetails}
              deviceID={deviceID}
            />
          );
        } else if (currentDevice === UNAPPROVED) {
          return <Unapproved refreshPage={refreshPage} />;
        } else if (currentDevice === GROUP) {
          return <Group refreshPage={refreshPage} />;
        } else if (currentDevice === MESSAGING_ALERT) {
          return <EmergencyMessaging refreshPage={refreshPage} />;
        } else return <Update refreshPage={refreshPage} />;

      case APPS:
        if (currentApp === APP_INSTALL) {
          return <AppInstall refreshPage={refreshPage} />;
        } else {
          return <AppManagement refreshPage={refreshPage} />;
        }
      case STATS:
        return <EventLogs refreshPage={refreshPage} />;
      default:
        return <Users refreshPage={refreshPage} />;
    }
  };

  const handleChangeFirstTimeLoginPassword = () => {
    let isValid = true;
    let res;
    let value;

    value = newPwd;
    res = validateUserDetails('password', value);
    isValid = res.isValid;

    if (isValid) {
      if (newPwd !== confirmNewPwd) {
        isValid = false;
        setErrorMessage(f({ id: 'COM_DMS_PASSWORD_MISMATCH' }));
      } else {
        setErrorMessage('');
        dispatch(
          updateUserPassword({
            userId,
            currentPassword: temporaryPwd,
            password: newPwd,
            confirmPassword: confirmNewPwd,
            setErrorMessage: setErrorMessage,
            successMessage: f({
              id: 'COM_DMS_PWD_RESET_SUCCESS',
            }),
          })
        );
      }
    } else {
      setErrorMessage(f({ id: res.messageId }));
    }
  };

  useEffect(() => {
    if (!(userError && userError?.errorCode)) {
      setTemporaryPwd('');
      setNewPwd('');
      setConfirmNewPwd('');
    } else if (userError.errorCode == 400702) {
      setErrorMessage(userError.errorMessage);
    } else if (userError.errorCode == 400714) {
      setErrorMessage(userError.errorMessage);
    }
  }, [userError]);

  const handleCancelFirstTimeLogin = () => {
    dispatch(resetUser());
    const user = JSON.parse(localStorage.getItem('user'));
    const { refreshToken } = user ?? {};
    dispatch(signOut({ refreshToken }));
    navigate(LOGIN_PATH);
  };

  return (
    <>
      {showFirstTimePopup && (
        <ChangePassword
          text={CHANGE_PWD_TXT}
          childText={CHILD_TEXT}
          temporaryPwd={temporaryPwd}
          setTemporaryPwd={setTemporaryPwd}
          newPwd={newPwd}
          currentPwd={CURR_PWD}
          currentPwdTxt={CURR_PWD_TXT}
          setNewPwd={setNewPwd}
          confirmNewPwd={confirmNewPwd}
          setConfirmNewPwd={setConfirmNewPwd}
          onSubmit={handleChangeFirstTimeLoginPassword}
          handleCancel={handleCancelFirstTimeLogin}
          hideBackground
          errorMessage={errorMessage}
        />
      )}
      {changePeriod > 0 && isExpired && okVerified && (
        <ChangePassword
          changePwd
          text={CHANGE_PASSWORD_TXT}
          childText={CHILD_TEXT}
          temporaryPwd={temporaryPwd}
          setTemporaryPwd={setTemporaryPwd}
          newPwd={newPwd}
          currentPwd={CURR_PWD}
          currentPwdTxt={CURR_PWD_TXT}
          setNewPwd={setNewPwd}
          confirmNewPwd={confirmNewPwd}
          setConfirmNewPwd={setConfirmNewPwd}
          onSubmit={handleChangeFirstTimeLoginPassword}
          handleCancel={handleCancelFirstTimeLogin}
        />
      )}
      <div className="home">
        {preventReuse && changePeriod > 0 && isExpired && !okVerified && (
          <OkCard
            handleOkClick={handleOkClick}
            text={f({ id: 'COM_DMS_OK_TEXT' })}
          />
        )}
        <SamsungDMSHeader
          isLogin
          currentActive={currentActive}
          text={dashboardText}
          logoutUser={logoutUser}
          handleSettings={handleMyInfoClick}
        />
        <div className="home-content">
          <div className="home-left-panel">
            <div
              className={
                currentActive === DASHBOARD
                  ? `home-left-panel-item home-active-icon home-left-main-menu-item`
                  : `home-left-panel-item`
              }
              onClick={() => {
                setCurrentActive(DASHBOARD);
                setRefreshPage((refreshPage) => !refreshPage);
              }}
            >
              <div>
                <img
                  src={
                    currentActive === DASHBOARD
                      ? DashboardActiveIcon
                      : DashboardIcon
                  }
                  alt="person"
                  className="icons_position"
                />
              </div>
              <div>{DASHBOARD}</div>
              <div></div>
            </div>
            <div
              className={
                currentActive === DEVICE
                  ? 'home-left-panel-item home-left-main-menu-item'
                  : 'home-left-panel-item'
              }
              onClick={handleDevice}
            >
              <div>
                <img
                  src={currentActive === DEVICE ? DeviceActiveIcon : DeviceIcon}
                  alt="device"
                  className="icons_position"
                />
              </div>
              <div>{DEVICE}</div>
              <div>
                {currentActive === DEVICE && open ? (
                  <img src={UpArrow} alt="up" className="home-arrow-icon" />
                ) : (
                  <img src={DownArrow} alt="down" className="home-arrow-icon" />
                )}
              </div>
            </div>
            {open &&
              currentActive === DEVICE &&
              dashboardMap.get(DEVICE).map((val, idx) => (
                <div
                  key={idx}
                  className={
                    currentDevice === val
                      ? `home-left-child-item home-active-icon`
                      : `home-left-child-item`
                  }
                  onClick={() => {
                    setCurrentDevice(val);
                    setRefreshPage((refreshPage) => !refreshPage);
                    setPageToShow('ALL');
                  }}
                >
                  {val}
                </div>
              ))}
            {!isDeviceOperator() && (
              <div
                className={
                  currentActive === APPS
                    ? 'home-left-panel-item home-left-main-menu-item'
                    : 'home-left-panel-item'
                }
                onClick={handleApps}
              >
                <div>
                  <img
                    src={currentActive === APPS ? AppActiveIcon : AppsIcon}
                    alt="apps"
                    className="icons_position"
                  />
                </div>
                <div>{APPS}</div>
                <div className="home-left-panel-item">
                  {currentActive === APPS && open ? (
                    <img src={UpArrow} alt="up" className="home-arrow-icon" />
                  ) : (
                    <img
                      src={DownArrow}
                      alt="down"
                      className="home-arrow-icon"
                    />
                  )}
                </div>
              </div>
            )}
            {open &&
              currentActive === APPS &&
              dashboardMap.get(APPS).map((val, idx) => (
                <div
                  key={idx}
                  className={
                    currentApp === val
                      ? `home-left-child-item home-active-icon`
                      : `home-left-child-item`
                  }
                  onClick={() => {
                    setCurrentApp(val);
                    setRefreshPage((refreshPage) => !refreshPage);
                  }}
                >
                  {val}
                </div>
              ))}
            {isAdminOrSubAdmin() && (
              <div
                className={
                  currentActive === USERS
                    ? `home-left-panel-item home-active-icon home-left-main-menu-item`
                    : `home-left-panel-item`
                }
                onClick={handleUser}
              >
                <div>
                  <img
                    src={currentActive === USERS ? UserActiveIcon : PersonIcon}
                    alt="person"
                    className="icons_position"
                  />
                </div>
                <div>{USERS}</div>
                <div></div>
              </div>
            )}
            {isAdminOrSubAdmin() && (
              <div
                className={
                  currentActive === STATS
                    ? 'home-left-panel-item home-left-main-menu-item'
                    : 'home-left-panel-item'
                }
                onClick={handleStats}
              >
                <div>
                  <img
                    src={currentActive === STATS ? StatsActiveIcon : StatsIcon}
                    alt="stats"
                    className="icons_position"
                  />
                </div>
                <div>{STATS}</div>
                <div>
                  {currentActive === STATS && open ? (
                    <img src={UpArrow} alt="up" className="home-arrow-icon" />
                  ) : (
                    <img
                      src={DownArrow}
                      alt="down"
                      className="home-arrow-icon"
                    />
                  )}
                </div>
              </div>
            )}
            {open &&
              currentActive === STATS &&
              dashboardMap.get(STATS).map((val, idx) => (
                <div
                  key={idx}
                  className={
                    currentStats === val
                      ? `home-left-child-item home-active-icon`
                      : `home-left-child-item`
                  }
                  onClick={() => {
                    setCurrentStats(val);
                    setRefreshPage((refreshPage) => !refreshPage);
                  }}
                >
                  {val}
                </div>
              ))}
            <div
              className={
                currentActive === SETTINGS
                  ? 'home-left-panel-item home-left-main-menu-item'
                  : 'home-left-panel-item'
              }
              onClick={handleSettings}
            >
              <div>
                <img
                  src={
                    currentActive === SETTINGS
                      ? SettingsActiveIcon
                      : SettingsIcon
                  }
                  alt="settings"
                  className="icons_position"
                />
              </div>
              <div>{SETTINGS}</div>
              <div>
                {currentActive === SETTINGS && open ? (
                  <img src={UpArrow} alt="up" className="home-arrow-icon" />
                ) : (
                  <img src={DownArrow} alt="down" className="home-arrow-icon" />
                )}
              </div>
            </div>
            {open &&
              currentActive === SETTINGS &&
              dashboardMap.get(SETTINGS).map((val, idx) => (
                <div
                  key={idx}
                  className={
                    currentSetting === val
                      ? `home-left-child-item home-active-icon`
                      : `home-left-child-item`
                  }
                  onClick={() => {
                    setCurrentSetting(val);
                    setRefreshPage((refreshPage) => !refreshPage);
                  }}
                >
                  {val}
                </div>
              ))}
          </div>
          <div className="home-right-panel">
            <div>
              {passwordSuccess && isUserPopup && (
                <WarningMessage
                  className={hideMsg ? 'hide-msg' : 'home-warning-message'}
                  text={f({
                    id: 'COM_DMS_PWD_RESET_SUCCESS',
                  })}
                />
              )}
            </div>
            {renderRightSideMenu()}
          </div>
        </div>
      </div>
      {needPasswordReset && changeAfterLogin && <SamsungDMSFooter />}
    </>
  );
};

export default Home;
