import React, { useEffect, useState } from 'react';
import './UpdatePassword.css';
import LoginCard from '../../components/LoginCard/LoginCard';
import CustomButton from '../../components/CustomButton/CustomButton';
import Visibility from '../../assets/images/visibility.svg';
import { useNavigate } from 'react-router-dom';
import { LOGIN_PATH, RESET_SUCCESS_PATH } from '../../router/RouteConstants';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword, changePasswordErrorCode, clearChangePasswordError, resetAuthState } from '../../redux/actions/AuthAction';
import { useIntl } from 'react-intl';
import { GET_AUTH_USER_PENDING } from '../../redux/constants/AuthConstant';
import PasswordValidatorTooltip from '../../components/Tooltip/PasswordValidatorTooltip/PasswordValidatorTooltip';
import VisibilityHideIcon from '../../assets/images/visibilityHideIcon.svg';
import EyeCloseIcon from '../../assets/images/eyeClose.svg';
import ErrorWarningMessage from '../../components/ErrorWarningMessage/ErrorWarningMessage';
import FloatLabel from '../../components/FloatLabel';
import BackIcon from '../../assets/images/back.svg';
import { validateUserDetails, validatePasswordFields, passwordMaxLength} from "../../utils/Validations";

const UpdatePassword = () => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const navigate = useNavigate();
  const { resetUserInfo, validateInfo, changePasswordError, changedPassword } =
    useSelector((state) => state.auth);
  const { userId } = resetUserInfo ?? {};
  const { authToken } = validateInfo ?? {};

  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [firstPasswordType, setFirstPasswordType] = useState("password");
  const [confirmPassword, setConfirmPassword] = useState('');
  const [focussedPassword, setFocussedPassword] = useState(false);
  const [flag, setFlag] = useState(false);
  const dispatch = useDispatch();

  const pwdValidation = validatePasswordFields(password);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);

  useEffect(() => {
    if (flag) {
      dispatch(
        changePassword({
          userId,
          authToken,
          confirmPassword,
          newPassword: password,
        })
      );
      setFlag(false);
    }
  }, [flag]);

  useEffect(() => {
    if (changedPassword && !Object.keys(changePasswordError).length) {
      navigate(RESET_SUCCESS_PATH);
      dispatch(resetAuthState());
    }
  }, [changePasswordError]);

  const formHandleSubmit = (e) => {
    e.preventDefault();
    let isValid = true;
    let res;
    let value;

    value = password;
    res = validateUserDetails('password', value);
    isValid = res.isValid;

    if(isValid) {
      if(password !== confirmPassword){
        setInvalidPassword(false);
        setPasswordMismatch(true);
      } else {
        setFlag(true);
      }
    } else {
      setInvalidPassword(true);
    }
  };

  const changePasswordType = (type, setType) => {
    if(confirmPassword?.length === 0){return;}
    if (passwordType === "password") {
      setPasswordType("text");
    }
    if (passwordType === "text") {
      setPasswordType("password");
    }
  };

  const changeFirstPasswordType = () => {
    if(password?.length === 0){return;}
    if (firstPasswordType === "password") {
      setFirstPasswordType("text");
    }
    if (firstPasswordType === "text") {
      setFirstPasswordType("password");
    }
  };

  useEffect(() => {
    dispatch({ type: GET_AUTH_USER_PENDING });
  }, [dispatch]);

  useEffect(() => {
    document
      .querySelector('.update-password-form #password')
      ?.addEventListener('focus', () => {
        setFocussedPassword(true);
      });
    document
      .querySelector('.update-password-form #password')
      ?.addEventListener('focusout', () => {
        setFocussedPassword(false);
      });
  }, [focussedPassword]);

  const handleCancelForgotPwd = () => {
    navigate(LOGIN_PATH);
    dispatch({ type: GET_AUTH_USER_PENDING });
  };

  useEffect(() => {
    if (confirmPassword?.length === 0) {
      setPasswordType('password');
    }
  }, [confirmPassword]);

  useEffect(() => {
    if (password?.length === 0) {
      setFirstPasswordType('password');
    }
  }, [password]);

  return (
    <LoginCard text={f({ id: 'COM_DMS_PWD_RESET_ADMIN' })} hide={true}>
      {changePasswordError.errorCode == 400714 && (
        <ErrorWarningMessage
          text={changePasswordError.errorMessage}
          className="err-warning"
          authFlag
          dispatchMethod={clearChangePasswordError}
        />
      )}
      {invalidPassword && (
        <ErrorWarningMessage
         text={f({ id: 'COM_DMS_INVALID_PASSWORD' })}
          className="err-warning"
          authFlag
        />
      )}
      {passwordMismatch && (
        <ErrorWarningMessage
         text={f({ id: 'COM_DMS_PASSWORD_MISMATCH' })}
          className="err-warning"
          authFlag
        />
      )}
      <form onSubmit={formHandleSubmit} className="update-password-form">
      <div className="update-password-text">
          {f({ id: 'COM_DMS_CREATE_PASSWORD' })}
        </div>
        <div className="new_password_text">
          {f({ id: 'COM_DMS_NEW_PASSWORD_TXT' })}
        </div>
        <div className="input">
          <FloatLabel
            value={password}
            label="Password"
            className={password ? 'float-input' : ''}
          >
            <input
              id="password"
              type={firstPasswordType}
              value={password}
              maxLength={passwordMaxLength}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FloatLabel>
          {focussedPassword && (
            <PasswordValidatorTooltip
              className="update-tooltip"
              {...pwdValidation}
            />
          )}
          <div className="visibility-icon" onClick={changeFirstPasswordType}>
              {firstPasswordType === "password" ? password?.length > 0 ? (
                      <img src={EyeCloseIcon} alt="visibility"/>
                    ) : (
                      <img src={VisibilityHideIcon} alt="visibility" />
                    ) : (
                <img src={Visibility} alt="visibility" />
              ) }
            </div>
        </div>
        <div className="input">
          <FloatLabel
            label="Confirm Password"
            value={confirmPassword}
            className={confirmPassword ? 'float-input' : ''}
          >
            <input
              id="confirmPassword"
              type={passwordType}
              value={confirmPassword}
              maxLength={passwordMaxLength}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </FloatLabel>
          <div className="visibility-icon" onClick={changePasswordType}>
          {passwordType === "password" ? confirmPassword?.length > 0 ? (
                      <img src={EyeCloseIcon} alt="visibility"/>
                    ) : (
                      <img src={VisibilityHideIcon} alt="visibility" />
                    ) : (
                <img src={Visibility} alt="visibility" />
              ) }
          </div>
        </div>

        <CustomButton
          type="submit"
          text="Reset Password"
          className="update-pwd-btn"
          disabled={!(password && confirmPassword)}
        />
        <br />
        <div onClick={handleCancelForgotPwd} className="cancelPwdLink">
        <img src={BackIcon} alt="back" className="backIcon" />
          {f({ id: 'COM_DMS_BACK_TO_LOGIN' })}
        </div>
      </form>
    </LoginCard>
  );
};

export default UpdatePassword;
