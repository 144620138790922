import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import './SelectCommandPopup.css';
import { Dropdown } from 'primereact/dropdown';
import { RestServiceUtils } from '../../../utils/RestServiceUtils';
import { DEVICES_URL } from '../../../config/constants/Constants';
import CloseIcon from '../../../assets/images/closeIcon.svg';
import { showSuccessToast } from '../../../services/APIResponseHandler';

const SelectCommandPopup = ({ setSelectCommandPopup, deviceIds, groupIds }) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const [inputSource, setInputSource] = useState(null);

  const inputSources = [
    { name: 'HDMI1', code: 'HDMI1' },
    { name: 'HDMI2', code: 'HDMI2' },
    { name: 'HDMI3', code: 'HDMI3' },
    { name: 'ANDROID', code: 'ANDROID' },
    { name: 'VGA1', code: 'VGA1' },
    { name: 'TYPE_C1', code: 'TYPE_C1' },
  ];

  const COMMAND_SENT_MSG = f({ id: 'COM_DMS_LAST_COMMAND_MESSAGE' });

  const devicesPowerOn = () => {
    if (!deviceIds) {
      RestServiceUtils.HTTP_POST(`${DEVICES_URL}/powerOn`, { groupIds }).then((res) =>
      res?.status === 200 && showSuccessToast(COMMAND_SENT_MSG));
    } else {
      RestServiceUtils.HTTP_POST(`${DEVICES_URL}/powerOn`, { deviceIds }).then((res) =>
      res?.status === 200 && showSuccessToast(COMMAND_SENT_MSG));
    }
  };

  const devicesPowerOff = () => {
    if (!deviceIds) {
      RestServiceUtils.HTTP_POST(`${DEVICES_URL}/powerOff`, { groupIds }).then((res) =>
      res?.status === 200 && showSuccessToast(COMMAND_SENT_MSG));
    } else {
      RestServiceUtils.HTTP_POST(`${DEVICES_URL}/powerOff`, { deviceIds }).then((res) =>
      res?.status === 200 && showSuccessToast(COMMAND_SENT_MSG));
    }
  };

  const rebootDevices = () => {
    if (!deviceIds) {
      RestServiceUtils.HTTP_POST(`${DEVICES_URL}/reboot`, { groupIds }).then((res) =>
      res?.status === 200 && showSuccessToast(COMMAND_SENT_MSG));
    } else {
      RestServiceUtils.HTTP_POST(`${DEVICES_URL}/reboot`, { deviceIds }).then((res) =>
      res?.status === 200 && showSuccessToast(COMMAND_SENT_MSG));
    }
  };

  const changeInputSource = () => {
    if (!deviceIds) {
      RestServiceUtils.HTTP_PUT(`${DEVICES_URL}/changeSource`, {
        groupIds,
        inputSource: inputSource.code,
      }).then((res) =>
      res?.status === 200 && showSuccessToast(COMMAND_SENT_MSG));
    } else {
      RestServiceUtils.HTTP_PUT(`${DEVICES_URL}/changeSource`, {
        deviceIds,
        inputSource: inputSource.code,
      }).then((res) =>
      res?.status === 200 && showSuccessToast(COMMAND_SENT_MSG));
    }
  };

  const deviceLock = (event) => {
    if (!deviceIds) {
      RestServiceUtils.HTTP_POST(`${DEVICES_URL}/deviceLock`, {
        groupIds,
        event,
      }).then((res) =>
      res?.status === 200 && showSuccessToast(COMMAND_SENT_MSG));
    } else {
      RestServiceUtils.HTTP_POST(`${DEVICES_URL}/deviceLock`, {
        deviceIds,
        event,
      }).then((res) =>
      res?.status === 200 && showSuccessToast(COMMAND_SENT_MSG));
    }
  };

  const settingsLock = (event) => {
    if (!deviceIds) {
      RestServiceUtils.HTTP_POST(`${DEVICES_URL}/settingsLock`, {
        groupIds,
        event,
      }).then((res) =>
      res?.status === 200 && showSuccessToast(COMMAND_SENT_MSG));
    } else {
      RestServiceUtils.HTTP_POST(`${DEVICES_URL}/settingsLock`, {
        deviceIds,
        event,
      }).then((res) =>
      res?.status === 200 && showSuccessToast(COMMAND_SENT_MSG));
    }
  };

  return (
    <Dialog
      visible
      draggable={false}
      position="bottom-right"
      className="select-command-dialog"
      onHide={() => setSelectCommandPopup(false)}
    >
      <div className="select-command-header">
        <div className="select-command-header-text">
          {f({ id: 'COM_DMS_SELECT_COMMAND' })}
        </div>
        <div
          className="close-select-command"
          onClick={() => setSelectCommandPopup(false)}
        >
          <img src={CloseIcon} alt="close" />
        </div>
      </div>

      <div className="select-command-body">
        <div className="power">
          <div className="power-header">{f({ id: 'COM_DMS_POWER' })}</div>
          <div className="power-body">
            <div className="power-btn">
              <div onClick={devicesPowerOn}>{f({ id: 'COM_DMS_ON' })}</div>
              <div onClick={devicesPowerOff}>{f({ id: 'COM_DMS_OFF' })}</div>
            </div>
            <div className="reboot-btn" onClick={rebootDevices}>
              {f({ id: 'COM_DMS_REBOOT' })}
            </div>
          </div>
        </div>
        <div className="input">
          <div className="input-header">{f({ id: 'COM_DMS_INPUT' })}</div>
          <div className="input-body">
            <Dropdown
              optionLabel="name"
              value={inputSource}
              placeholder="Select"
              options={inputSources}
              className="input-dropdown"
              onChange={(e) => setInputSource(e.value)}
            />
            {inputSource ? (
              <div className="input-ok-btn" onClick={changeInputSource}>
                {f({ id: 'COM_DMS_APPLY' })}
              </div>
            ) : (
              <div className="disabled-input-btn">
                {f({ id: 'COM_DMS_APPLY' })}
              </div>
            )}
          </div>
        </div>
        <div className="settings">
          <div className="settings-header">{f({ id: 'COM_DMS_SETTINGS' })}</div>
          <div className="settings-body">
            <div className="device">
              <div>{f({ id: 'COM_DMS_DEVICE' })}</div>
              <div className="device-btn">
                <div onClick={() => deviceLock('START')}>
                  {f({ id: 'COM_DMS_LOCK' })}
                </div>
                <div onClick={() => deviceLock('STOP')}>
                  {f({ id: 'COM_DMS_UNLOCK' })}
                </div>
              </div>
            </div>

            <div className="settings-lock">
              <div>{f({ id: 'COM_DMS_SETTINGS' })}</div>
              <div className="settings-lock-btn">
                <div onClick={() => settingsLock('START')}>
                  {f({ id: 'COM_DMS_LOCK' })}
                </div>
                <div onClick={() => settingsLock('STOP')}>
                  {f({ id: 'COM_DMS_UNLOCK' })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SelectCommandPopup;