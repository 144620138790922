import jwtDecode from 'jwt-decode';
import { LOGIN_PATH } from '../router/RouteConstants';

export const monthLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const renameKey = (object, oldKey, newKey) => {
  object[newKey] = object[oldKey];
  delete object[oldKey];
};

export const changeAttributesOfObject = (object, attributeMapping) => {
  attributeMapping.forEach((map) => renameKey(object, map[0], map[1]));
};

export const RedirectToLoginOnTokenExpiration = () => {
  const user = JSON.parse(localStorage?.getItem('user'));
  if (user === undefined || user === null) {
    window.location.href = `${window.location.pathname}${LOGIN_PATH}`;
    localStorage.clear();
  } else {
    const { token } = user ?? {};
    let jwtExpTime = jwtDecode(token).exp;
    if (jwtExpTime * 1000 < new Date().getTime()) {
      localStorage.removeItem('user');
      window.location.href = `${window.location.pathname}${LOGIN_PATH}`;
    }
  }
};
