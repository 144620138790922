import React, { useRef, useState } from 'react';

const HoveredText = ({ text, className, onClick}) => {

const [isHovered, setIsHovered] = useState(false);
    const textRef = useRef(null);
    
    const handleMouseEnter = () => {
        const { scrollWidth, clientWidth } = textRef.current;
        if (scrollWidth > clientWidth) {
          setIsHovered(true);
        }
      };

  return (
    <>
      <span className={`${className} ${isHovered ? 'hovered' : ''}`} ref={textRef} onMouseEnter={handleMouseEnter} onMouseLeave={() => setIsHovered(false)} onClick={onClick}>{text}</span>
            {isHovered && (
              <div className="text-box">
                <span>{text}</span>
              </div>
            )}
    </>
  )
}

export default HoveredText;
