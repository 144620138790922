import React from "react";
import "./CustomButton.css";

const CustomButton = ({ text, className, disabled, ...rest }) => {
  return (
    <button
      {...rest}
      disabled={!!disabled}
      className={
        disabled
          ? `${className} custom-btn disable-custom-btn`
          : className
          ? `${className} custom-btn`
          : "custom-btn"
      }
    >
      {text}
    </button>
  );
};

export default CustomButton;
