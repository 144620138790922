import {
  GET_USER_FAILURE,
  GET_USER_SUCCESS,
  RESET_UPDATE_USER_FLAG,
  RESET_USER_MGMT,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_PASSWORD_FAILURE,
  UPDATE_USER_PASSWORD_SUCCESS,
  GET_USER_PENDING
} from "../constants/UserManagementConstant";

const initialState = {
  loading: false,
  userInfo: {},
  userError: {},
  isUserPopup: true,
  updateUserInfoFlag: false
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_PENDING:
      return {
        ...initialState,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        loading: false,
        userInfo: payload,
        userError: {},
      };
    case GET_USER_FAILURE:
      return {
        loading: false,
        userInfo: {},
        userError: payload,
      };

    case UPDATE_USER_SUCCESS:
      return {
        loading: false,
        userInfo: { ...payload, isUserPopup: true },
        userError: {},
        updateUserInfoFlag: true
      };
    case UPDATE_USER_PASSWORD_SUCCESS:
      return {
        loading: false,
        userInfo: { ...payload, isUserPopup: true },
        userError: {}
      };
    case UPDATE_USER_PASSWORD_FAILURE:
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        userError: payload,
        updateUserInfoFlag: false
      };
    case RESET_UPDATE_USER_FLAG:
      return {
        ...state,
        updateUserInfoFlag: false
      }
    case RESET_USER_MGMT:
      return { ...initialState };
    default:
      return state;
  }
};

export default userReducer;
