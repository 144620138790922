
import { SERVER_SETTINGS_API } from "../config/constants/Constants";
import { RestServiceUtils } from "../utils/RestServiceUtils";

const getServerSettingsService = () => {
  return RestServiceUtils.HTTP_GET(SERVER_SETTINGS_API);
};

const getCommonServerSettingsService = () => {
  return RestServiceUtils.HTTP_GET(`${SERVER_SETTINGS_API}/common-config`);
};

const updateServerSettingsService = (payload) => {
  return RestServiceUtils.HTTP_PUT(SERVER_SETTINGS_API, payload);
};

export { getServerSettingsService, updateServerSettingsService, getCommonServerSettingsService };