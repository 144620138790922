import React from "react";
import "./Overlay.css";

const Overlay = (props) => {
  return (
    <div className={props.className ? `overlay ${props.className}` : `overlay`}>
      {props.children}
    </div>
  );
};

export default Overlay;
