import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { useIntl } from 'react-intl';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { otpMaxLength } from '../../utils/Validations';
import { getUserAuth , resetAuthErrorCode} from '../../redux/actions/AuthAction';
import { useSelector , useDispatch} from 'react-redux';
import './MfaVerificationPopup.css'

export const MfaVerificationPopup = (props) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const dispatch = useDispatch();
  const { visible, updateOtpVerification, updateMfa , userid , password } = props;
  const [authenticationCode, setAuthenticationCode] = useState('');
  const { isAuthenticated , authErrorCode} = useSelector(state => state.auth);

  const [errorMsg, setErrorMsg] = useState('');
  
  const onHide = () => {
    dispatch(resetAuthErrorCode()); 
    updateOtpVerification(false);
    updateMfa(false);
  };
  useEffect(() => {
    if(authErrorCode == 401000){
      setErrorMsg(f({id : 'COM_DMS_INCORRECT_OTP_TEXT'}))
    } else if (authErrorCode == 401001) {
      setErrorMsg(f({id: 'COM_DMS_ACCOUNT_BLOCK_MSG'}))
    }
  }, [authErrorCode]);

  if(isAuthenticated){
    updateOtpVerification(true);
    updateMfa(false);
  }
  const enableVerificationButton = () => {
    if (authenticationCode.length == otpMaxLength) {
      return false;
    }
    return true;
  }
  const handleOtpInputField = (event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      setErrorMsg('');
      setAuthenticationCode(value?.trim())
    }
  }
  const handleVerification = () => {
    dispatch(getUserAuth({ loginId: userid, password:password , totp:authenticationCode}));   
  }
  const headerContent = () => {
    return (
      <div className="mfa-verification-dialog-header">
        {f({ id: 'COM_DMS_TWO_STEP_VERIFICATION_OTP' })}
      </div>
    );
  };
  return (
    <Dialog
      className="mfa-verification-dialog"
      header={headerContent}
      visible={visible}
      position={'centre'}
      onHide={() => {

      }}
    > <div className="mfa-verification-container">
        <div className="mfa-verification-content">
          <p>{f({ id: 'COM_DMS_ENTER_THE_OTP_CODE_AFTER_CHECKING_ON_YOUR_MOBILE_DEVICE' })}</p>
          <div className="row">
            
            <div className="col"> <label>
              <span style={{ fontWeight: '600', color: '#525252' , fontSize: '18px' , letterSpacing :'.09px'}}>{f({ id: 'COM_DMS_OTP_CODE' })}</span>
            </label></div>
            <div style={{position: 'relative'}}>
              <InputText
                className={errorMsg ? 'mfa-verification-input-field mfa-text-error' : "mfa-verification-input-field"}
                maxLength={otpMaxLength}
                name="otp"
                type="text"
                autoComplete="no"
                value={authenticationCode}
                onChange={handleOtpInputField}
                placeholder={f({ id: 'COM_DMS_ENTER_OTP_CODE' })}
              />
              {!!errorMsg && <div className='mfa-text-error-msg'>{errorMsg}</div>}
            </div>
          </div>
        </div>
        <div className="mfa-verification-footer">
          <Button
            onClick={onHide}
            className="mfa-verification-cancel-button"
            label={f({ id: 'COM_DMS_CANCEL' })}
          />
          <Button
            onClick={handleVerification}
            disabled={enableVerificationButton()}
            className="mfa-verification-continue-button"
            label={f({ id: 'COM_DMS_CONTINUE' })}
          />
        </div>


      </div>

    </Dialog>
  )
}