import React from "react";
import "./UserLogout.css";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Divider } from 'primereact/divider';
import SettingsIcon from "../../assets/images/settingsIcon.svg";
import LogoutIcon from "../../assets/images/logoutIcon.svg";

const UserLogout = ({ logoutUser, handleSettings }) => {
  const { userInfo } = useSelector((state) => state.user);
  const { loginId, userName } = userInfo ?? {};
  const intl = useIntl();

  return (
    <div className="user-logout">
      <div className="user-logout-header">
        <div className="user-fields">
          <div>{intl.formatMessage({ id: "COM_DMS_USER_ID" })}</div>
          <div>: {loginId}</div>
        </div>
        <div className="user-fields">
          <div>{intl.formatMessage({ id: "COM_DMS_USER_NAME" })} </div>
          <div>: {userName}</div>
        </div>
      </div>
      <Divider />
      <div className="user-logout-footer">
        <div className="user-settings-field" onClick={handleSettings}>
          <div>
            <img
              src={SettingsIcon}
              alt="settings"
              className="user-logout-icon"
            />
          </div>
          <div>{intl.formatMessage({ id: "COM_DMS_MY_INFO" })}</div>
        </div>
        <div className="user-logout-field" onClick={logoutUser}>
          <div>
            <img src={LogoutIcon} alt="logout" className="user-logout-icon" />
          </div>
          <div>{intl.formatMessage({ id: "COM_DMS_LOGOUT" })}</div>
        </div>
      </div>
    </div>
  );
};

export default UserLogout;
