import React, { useEffect, useState, useRef } from "react";
import "./ServerSettings.css";
import { Checkbox } from "primereact/checkbox";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  getServerSettings,
  resetUpdateInfo,
  updateServerSettings,
} from "../../redux/actions/ServerSettingsAction";
import Spinner from "../../components/Spinner/Spinner";
import VisibilityIcon from "../../assets/images/visibility.svg";
import VisibilityHideIcon from "../../assets/images/visibilityHideIcon.svg";
import EyeCloseIcon from '../../assets/images/eyeClose.svg';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import {MAX_SIGN_IN_FAIL_COUNT, MAX_SIGN_IN_BLOCK_DURATION, PASSWORD_EXPIRE_MAX_VALUE,
        SMTP_ID_MAX_LENGTH, SMTP_PASSWORD_MAX_LENGTH,
  SMTP_PORT_MIN_VALUE, SMTP_PORT_MAX_VALUE, MIN_SIGN_IN_FAIL_COUNT,
  MIN_SIGN_IN_BLOCK_DURATION, PASSWORD_EXPIRE_MIN_VALUE } from '../../config/constants/CommonConstants';
import { validatePasswordFields} from "../../utils/Validations";
import { isEmpty } from 'lodash';
import { Toast } from 'primereact/toast';
import { setToastComponent, showSuccessToast } from '../../services/APIResponseHandler';
import PasswordValidatorTooltip from '../../components/Tooltip/PasswordValidatorTooltip/PasswordValidatorTooltip';
import { IS_CHANGED_PASSWORD } from '../../redux/constants/ServerSettingsConstant';

const ServerSettings = ({ refreshPage }) => {
  const intl = useIntl();
  const toastRef = useRef(null);
  const { formatMessage : f } = intl
  const dispatch = useDispatch();
  const { serverSettingsInfo, loading, updateUserSuccess } = useSelector(
    (state) => state.serverSettings
  );
  const { generalSettings, passwordSettings, smtpSettings } =
    serverSettingsInfo ?? {};

  const [deviceAutoApprovalChecked, setDeviceAutoApprovalChecked] = useState(
    generalSettings?.deviceAutoApprove
  );
  const [customExpirePeriod, setCustomExpirePeriod] = useState(
    passwordSettings?.changePeriod || 1
  );
  const [signInFailureCount, setSignInFailureCount] = useState(
    generalSettings?.signInFailureCount
  );
  const [passwordExpirePeriod, setPasswordExpirePeriod] = useState(passwordSettings?.preventReuse);
  const [passwordType, setPasswordType] = useState("password");
  const [authPasswordType, setAuthPasswordType] = useState("password");
  const [defaultPasswordFlag, setDefaultPasswordFlag] = useState(
    passwordSettings?.isDefaultPasswordUsed
  );
  const [defaultPassword, setDefaultPassword] = useState(
    passwordSettings?.defaultPassword
  );
  const [passwordChangeAfterLogin, setPasswordChangeAfterLogin] = useState(
    passwordSettings?.changeAfterLogin
  );
  const [smtpAuthenticated, setSmtpAuthenticated] = useState(
    smtpSettings?.smtpUseAuth
  );
  const [isSSL, setIsSSL] = useState(smtpSettings?.smtpUseSSL);
  const [serverAddress, setServerAddress] = useState(smtpSettings?.smtpUrl);
  const [signInBlockDuration, setSignInBlockDuration] = useState(
    generalSettings?.signInBlockDuration
  );
  const [smtpEnabled, setSmtpEnabled] = useState(smtpSettings?.isSmtpEnabled);
  const [smtpId, setSmtpId] = useState(smtpSettings?.smtpId);
  const [smtpPassword, setSmtpPassword] = useState(smtpSettings?.smtpPassword);
  const [smtpPort, setSmtpPort] = useState(smtpSettings?.smtpPort);
  const [period, setPeriod] = useState(passwordSettings?.changePeriod);
  const [cancelFlag, setCancelFlag] = useState(false);
  const [generalSettingsError, setGeneralSettingsError] = useState("");
  const [passwordSettingsError, setPasswordSettingsError] = useState("");
  const [smtpSettingsError, setSmtpSettingsError] = useState("");
  const [enableCustom, setEnableCustom] = useState(false);
  const [isAnyDataChanged, setisAnyDataChanged] = useState(true);
  const [focusDefaultPassword, setFocusDefaultPassword] = useState(false);
  const [defaultPwdValidation, setDefaultPwdValidation] = useState();
  const [twoFactorAuthentication, setTwoFactorAuthentication] = useState(false);

  const ONE = f({ id: "COM_DMS_ONE" });
  const TEN = f({ id: "COM_DMS_TEN" });
  const HUNDRED = f({ id: "COM_DMS_HUNDRED" });

  const changePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else setPasswordType("password");
  };

  const changeAuthPasswordType = () => {
    if (authPasswordType === "password") {
      setAuthPasswordType("text");
    } else setAuthPasswordType("password");
  };

  const handleCancelServerSettings = () => {
    setCancelFlag(true);
  }

  const updateReduxState = () => {
    setDeviceAutoApprovalChecked(generalSettings?.deviceAutoApprove);
    setSignInFailureCount(generalSettings?.signInFailureCount);
    setPasswordChangeAfterLogin(passwordSettings?.changeAfterLogin);
    setDefaultPassword(passwordSettings?.defaultPassword);
    setSmtpAuthenticated(smtpSettings?.smtpUseAuth);
    setIsSSL(smtpSettings?.smtpUseSSL);
    setServerAddress(smtpSettings?.smtpUrl);
    setCustomExpirePeriod(passwordSettings?.changePeriod || 1);
    setSmtpEnabled(smtpSettings?.isSmtpEnabled);
    setSmtpId(smtpSettings?.smtpId);
    setSmtpPassword(smtpSettings?.smtpPassword);
    setSmtpPort(smtpSettings?.smtpPort);
    setSignInBlockDuration(generalSettings?.signInBlockDuration);
    setPasswordExpirePeriod(passwordSettings?.preventReuse);
    setPeriod(passwordSettings?.changePeriod);
    setDefaultPasswordFlag(passwordSettings?.isDefaultPasswordUsed);
    setTwoFactorAuthentication(passwordSettings?.isMfaEnabled);
  }

  useEffect(() => {
    updateReduxState();
  }, [generalSettings, passwordSettings, smtpSettings]);

  useEffect(() => { 
    if (updateUserSuccess) {
      showSuccessToast(f({ id: 'COM_DMS_CHANGES_BEEN_SAVED' }))
      dispatch(resetUpdateInfo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateUserSuccess])

  const expirationPeriod = [
    { label: `${f({ id: "COM_DMS_ONE_MONTHS" })}`, value: '1' },
    { label: `${f({ id: "COM_DMS_THREE_MONTHS" })}`, value: '3' },
    { label: `${f({ id: "COM_DMS_SIX_MONTHS" })}`, value: '6' },
    { label: `${f({ id: "COM_DMS_CUSTOM" })}`, value: '0' }
  ];

  const [selectedPeriod, setSelectedPeriod] = useState('');

  const setPasswordExpirationPeriod = (expirePeriod) => {
    for (let i = 0; i < expirationPeriod?.length; i++){
      if (expirationPeriod[i].value == expirePeriod) {
        setSelectedPeriod(expirationPeriod[i].value);
        return;
      }
    }
    setSelectedPeriod('0')
  }

  useEffect(() => {
    let isChanged = (generalSettings?.deviceAutoApprove == deviceAutoApprovalChecked) && 
      (generalSettings?.signInFailureCount == Number(signInFailureCount)) &&
      (generalSettings?.signInBlockDuration == Number(signInBlockDuration)) &&
      ((passwordSettings?.changePeriod == Number(selectedPeriod)) ||
      (passwordSettings?.changePeriod == Number(period))) &&
      (passwordSettings?.changeAfterLogin == passwordChangeAfterLogin) &&
      (passwordSettings?.defaultPassword == defaultPassword) && 
      (smtpSettings?.isSmtpEnabled == smtpEnabled) &&
      (smtpSettings?.smtpUrl == serverAddress) &&
      (smtpSettings?.smtpPort === Number(smtpPort)) &&
      (smtpSettings?.smtpUseSSL == isSSL) &&
      (smtpSettings?.smtpUseAuth == smtpAuthenticated) &&
      (smtpSettings?.smtpId == smtpId) &&
      (smtpSettings?.smtpPassword == smtpPassword) &&
      (passwordSettings?.isDefaultPasswordUsed == defaultPasswordFlag) &&
      (passwordSettings?.preventReuse == passwordExpirePeriod) &&
      (passwordSettings?.isMfaEnabled == twoFactorAuthentication);
    setisAnyDataChanged(isChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
      deviceAutoApprovalChecked,
      signInFailureCount,
      signInBlockDuration,
      selectedPeriod,
      period,
      passwordChangeAfterLogin,
      defaultPassword,
      smtpEnabled,
      serverAddress,
      smtpPort,
      isSSL,
      smtpAuthenticated,
      smtpId,
      smtpPassword,
      defaultPasswordFlag,
      passwordExpirePeriod,
      twoFactorAuthentication
    ]);

  useEffect(() => {
    setPasswordExpirationPeriod(customExpirePeriod);
    setToastComponent(toastRef.current);
  }, []);

  useEffect(() => {
    if (cancelFlag) {
      setGeneralSettingsError("");
      setPasswordSettingsError("");
      setSmtpSettingsError("");
      updateReduxState();
      setCancelFlag(false);
      setPasswordExpirationPeriod(passwordSettings?.changePeriod);
      setEnableCustom(false);
      setDefaultPasswordFlag(passwordSettings?.isDefaultPasswordUsed);
      setPasswordExpirePeriod(passwordSettings?.preventReuse);

    }
  }, [cancelFlag]);

  useEffect(() => {
    dispatch(getServerSettings());
  }, [refreshPage]);

  useEffect(() => {
    if (!defaultPasswordFlag) {
      defaultPassword?.trim().length !== 0 && setDefaultPassword(passwordSettings?.defaultPassword);
    }
  }, [defaultPasswordFlag]);

  const isDecimal = (num) => (num % 1);
 

  const handleSaveServerSettings = () => {
    if (customExpirePeriod == 1 || customExpirePeriod == 3 || customExpirePeriod == 6) {
      setEnableCustom(false);
    }
    let changePeriod = period;
    // if (passwordExpirePeriod) {
    //   changePeriod = period != 0 ? period : 1;
    // }
    const { isAlNum, isOneCapital, isOneSpecial, minPasswordLength, maxPasswordLength, containSpace} = validatePasswordFields(defaultPassword) ?? {};

    if(signInFailureCount < MIN_SIGN_IN_FAIL_COUNT || signInFailureCount > MAX_SIGN_IN_FAIL_COUNT){
      setGeneralSettingsError(f({ id: "COM_DMS_FAILURE_COUNT" }));
    }
    else if(isDecimal(signInFailureCount)){
      setGeneralSettingsError(f({ id: "COM_DMS_FAILURE_COUNT_DECIMAL" }));
    }
    else if(signInBlockDuration < MIN_SIGN_IN_BLOCK_DURATION || signInBlockDuration > MAX_SIGN_IN_BLOCK_DURATION){
      setGeneralSettingsError(f({ id: "COM_DMS_BLOCK_DURATION" }));
    }
    else if(isDecimal(signInBlockDuration)){
      setGeneralSettingsError(f({ id: "COM_DMS_BLOCK_DURATION_DECIMAL" }));
    }
    else if(passwordExpirePeriod && (period < PASSWORD_EXPIRE_MIN_VALUE || period > PASSWORD_EXPIRE_MAX_VALUE)){
      setGeneralSettingsError("");
      setPasswordSettingsError(f({ id: "COM_DMS_PWD_EXPIRE" }));
    }
    else if(passwordExpirePeriod && isDecimal(period)){
      setGeneralSettingsError("");
      setPasswordSettingsError(f({ id: "COM_DMS_PWD_EXPIRE_DECIMAL" }));
    }
    else if(defaultPasswordFlag && (!isAlNum || !isOneCapital || !isOneSpecial || !minPasswordLength || !maxPasswordLength || !containSpace)){
      setGeneralSettingsError("");
      setPasswordSettingsError(f({ id: "COM_DMS_DEFAULT_PWD_INVALID" }));
    }
    else if(smtpEnabled && (!serverAddress || !smtpPort)){
      setGeneralSettingsError("");
      setPasswordSettingsError("");
      setSmtpSettingsError(f({ id: "COM_DMS_ADDRESS_PORT_MANDATORY" }));
    }
    else if(smtpAuthenticated &&( isEmpty(smtpId) || isEmpty(smtpPassword))){
      setGeneralSettingsError("");
      setPasswordSettingsError("");
      setSmtpSettingsError(f({ id: "COM_DMS_LOGINID_PWD_MANDATORY" }));
    }
    else if(!isEmpty(smtpId) && smtpId?.length > SMTP_ID_MAX_LENGTH){
      setGeneralSettingsError("");
      setPasswordSettingsError("");
      setSmtpSettingsError(f({ id: "COM_DMS_LOGINID_MAX_LENGTH" }));
    }
    else if(!isEmpty(smtpPassword) && smtpPassword?.length > SMTP_PASSWORD_MAX_LENGTH){
      setGeneralSettingsError("");
      setPasswordSettingsError("");
      setSmtpSettingsError(f({ id: "COM_DMS_PWD_MAX_LENGTH" }));
    }
    else if(!isEmpty(smtpPort) && (isNaN(smtpPort) || smtpPort < SMTP_PORT_MIN_VALUE || smtpPort > SMTP_PORT_MAX_VALUE )){
      setGeneralSettingsError("");
      setPasswordSettingsError("");
      if(isNaN(smtpPort)){setSmtpSettingsError(f({ id: "COM_DMS_INVALID_PORT" }));}
      else{setSmtpSettingsError("Invalid port number.");}
    }
    else if (smtpEnabled && smtpAuthenticated && (isEmpty(smtpId?.trim()) || isEmpty(smtpPassword?.trim()))) {
      setSmtpSettingsError(f({ id: "COM_DMS_INVALID_CREDENTIALS" }));
    }
    else if (smtpEnabled && isEmpty(serverAddress?.trim())) {
      setSmtpSettingsError(f({ id: "COM_DMS_INVALID_IP_ADDRESS" }));
    }
    else{
      setGeneralSettingsError("");
      setPasswordSettingsError("");
      setSmtpSettingsError("");
      if (passwordChangeAfterLogin) {
        dispatch({ type: IS_CHANGED_PASSWORD });
      }

      const newSettings = {
        generalSettings: {
          signInFailureCount,
          signInBlockDuration,
          deviceAutoApprove: deviceAutoApprovalChecked,
        },
        passwordSettings: {
          defaultPassword,
          changeAfterLogin: passwordChangeAfterLogin,
          changePeriod,
          isDefaultPasswordUsed: defaultPasswordFlag,
          preventReuse: passwordExpirePeriod,
          isMfaEnabled: twoFactorAuthentication,
        },
        smtpSettings: {
          isSmtpEnabled: smtpEnabled,
          smtpUrl: serverAddress?.trim(),
          smtpUseAuth: smtpAuthenticated,
          smtpId: smtpId?.trim(),
          smtpPassword: smtpPassword?.trim(),
          smtpUseSSL: isSSL,
          smtpPort: +smtpPort,
        },
      }

      const updatedSettings = {}

      for(const key in newSettings ){
        for(const k in newSettings[key]){
          if(newSettings[key][k] != serverSettingsInfo[key][k]){
            if(updatedSettings[key] == undefined)
              updatedSettings[key] = {}
            updatedSettings[key][k] = newSettings[key][k]
          }
        }
      }

      dispatch(
        updateServerSettings(updatedSettings)
      );

    }
  };

  const handleSignInFailureCount = (e) => {
    const newSignInFailureCount = e.target.value;
    if(newSignInFailureCount >= MIN_SIGN_IN_FAIL_COUNT && newSignInFailureCount <= MAX_SIGN_IN_FAIL_COUNT  || newSignInFailureCount == ""){
      setSignInFailureCount(newSignInFailureCount)
    }
  }

  const handleSignInBlockDuration = (e) => {
    const newSignInBlockDuration = e.target.value;
    if(newSignInBlockDuration >= MIN_SIGN_IN_BLOCK_DURATION && newSignInBlockDuration <= MAX_SIGN_IN_BLOCK_DURATION || newSignInBlockDuration == ""){
      setSignInBlockDuration(newSignInBlockDuration)
    }
  }

  useEffect(() => {

    if(selectedPeriod == '0'){
      setPeriod(customExpirePeriod)
    }else{
      setPeriod(+selectedPeriod)
    }

  }, [selectedPeriod]);

  useEffect(() => {
    setDefaultPwdValidation(validatePasswordFields(defaultPassword));
    if (defaultPassword?.length === 0) {
      setPasswordType('password');
    }
  }, [defaultPassword]);

  useEffect(() => {
    if (smtpPassword?.length === 0) {
      setAuthPasswordType('password');
    }
  }, [smtpPassword]);

  useEffect(() => {
    const defaultPwdFocusIn = document
    .querySelector('#defaultPwd')
    ?.addEventListener('focus', () => {
      setFocusDefaultPassword(true);
    });
    const defaultPwdFocusOut = document
    .querySelector('#defaultPwd')
    ?.addEventListener('focusout', () => {
      setFocusDefaultPassword(false);
    });

    return () => {
      document
    .querySelector('#defaultPwd')
    ?.removeEventListener('focus', defaultPwdFocusOut);
  document
    .querySelector('#defaultPwd')
    ?.removeEventListener('focusout', defaultPwdFocusOut);
    }
  }, [defaultPassword, focusDefaultPassword]);


  return (
    <>
      <Toast ref={toastRef} position="top-center" />
      {loading ? (
        <Spinner />
      ) : (
          <>
          <div className="user-info-button-position">
            <div className="userInfo-left-btn">
              <Button
                label={f({ id: "COM_DMS_DISCARD_CHANGES" })}
                className="user-info-btn user-info-cancel-btn"
                onClick={handleCancelServerSettings}
                disabled={isAnyDataChanged}
              />
              <Button
                label={f({ id: "COM_DMS_SAVE_CHANGES" })}
                className="user-info-btn user-info-save"
                onClick={handleSaveServerSettings}
                disabled={isAnyDataChanged}
              />
            </div>
          </div>
          <div className="server_settings settings_1">
            <div className="settings_layout">
              <div className="server-settings-text">
                  {f({ id: "COM_DMS_GENERAL" })}
              </div>
              <div className="general_options">
              <label className="settings_label">
                <span style={{ fontFamily: "SamsungOne-600" }}>
                  {f({ id: "COM_DMS_DEVICE_AUTO_APPROVAL" })}
                </span>
              </label>
                <Checkbox
                    checked={!!deviceAutoApprovalChecked}
                    onChange={() =>
                      setDeviceAutoApprovalChecked(!deviceAutoApprovalChecked)
                    }
                />
              </div>
              <div className="general_options">
                <label className="settings_label">
                  <span style={{ fontFamily: "SamsungOne-600" }}>
                    {f({ id: "COM_DMS_SIGNIN_FAILURE_ATTEMPTS" })}
                  </span>
                </label>
                <div>
                <input
                      type="number"
                      min="1"
                      max="10"
                      className="customPeriod_details"
                      onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                      value={signInFailureCount}
                      onChange={handleSignInFailureCount}
                    />
                </div>
                <div className="margin-left-10 serverSettings_sub_details">{`(${ONE}~${TEN})`}</div>
              </div>
              <div className="general_options">
              <label className="settings_label">
                <span style={{ fontFamily: "SamsungOne-600" }}>
                  {f({ id: "COM_DMS_SIGNIN_BLOCK_DURATION" })}
                </span>
              </label>
              <div>
              <input
                      type="number"
                      min="1"
                      max="100"
                      onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                      className="customPeriod_details"
                      value={signInBlockDuration}
                      onChange={handleSignInBlockDuration}
                    />
                </div>
                <div className="margin-left-10 serverSettings_sub_details">{`(${ONE}~${HUNDRED}) min`}</div>
                <div className="error-message">
                <span>{generalSettingsError}</span>
              </div>
              </div>
            </div>
          </div>
          <div className="server_settings settings_2">
            <div className="settings_layout">
                <div className="server-settings-text">
                    {f({ id: "COM_DMS_PWD_POLICIES" })}
                </div>
                <div className="general_options">
                  <label className="settings_label">
                    <span style={{ fontFamily: "SamsungOne-600" }}>
                      {f({ id: "COM_DMS_EXPIRE_PERIOD" })}
                    </span>
                  </label>
                  <Checkbox
                    checked={passwordExpirePeriod}
                    onChange={(e) => {
                      setPasswordExpirePeriod(!passwordExpirePeriod);
                      !e.value && setEnableCustom(false);
                    }
                    }
                  />
                  <div
                    className="passwordExpPeriodBox"
                  >
                    {expirationPeriod.map((option, index) => (
                       <div className="flex align-items-center">
                          <RadioButton
                            key={index}
                            name="radioOption"
                            value={option.value}
                            label={option.label}
                            checked={selectedPeriod === option.value}
                            onChange={(e) => {
                              setSelectedPeriod(e.value);
                              setEnableCustom(false);
                            }}
                            disabled={!passwordExpirePeriod}
                            className="flex align-items-center"
                            />
                            <label htmlFor={option.value} className="expireLabels">{option.label}</label>
                       </div>
                    ))}
                <div className="position_radio" style={{display: "flex"}}>
                  <input
                      type="number"
                      min="1"
                      max="100"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      className="customPeriod_details"
                      value={(passwordExpirePeriod && selectedPeriod === '0') || enableCustom ? customExpirePeriod : 1}
                      disabled={!(passwordExpirePeriod && selectedPeriod === '0') && !enableCustom}
                        onChange={(e) => {
                          setCustomExpirePeriod(e.target.value);
                          selectedPeriod === '0' && setPeriod(e.target.value)
                          setEnableCustom(true);
                      }}
                    />
                  <div className="margin-left-10 expPwd_sub_details">
                    {`(${ONE}~${HUNDRED}) months`}
                  </div>
                </div>
                </div>
                </div>
                <div className="general_options">
                  <label className="settings_label">
                    <span style={{ fontFamily: "SamsungOne-600" }}>
                      {f({ id: "COM_DMS_PWD_CHANGE" })}
                    </span>
                  </label>
                  <Checkbox
                    checked={!!passwordChangeAfterLogin}
                    onChange={() =>
                      setPasswordChangeAfterLogin(!passwordChangeAfterLogin)
                    }
                  />
                  </div>
                  <div className="general_options">
                  <label className="settings_label">
                    <span style={{ fontFamily: "SamsungOne-600" }}>
                      {f({ id: "COM_DMS_DEFAULT_PWD" })}
                    </span>
                  </label>
                  <Checkbox
                    checked={defaultPasswordFlag}
                    onChange={() =>
                      setDefaultPasswordFlag(!defaultPasswordFlag)
                    }
                  />
                  <div className="defaultPwdInputDiv">
                    <InputText
                      id="defaultPwd"
                      type={passwordType}
                      disabled={!defaultPasswordFlag}
                      value={defaultPassword}
                      className="inputBox_ServerSettings"
                      onChange={(e) => setDefaultPassword(e.target.value)}
                      maxLength={50}
                    />
                    {focusDefaultPassword && (
                      <PasswordValidatorTooltip
                        className="defaultPwdTooltip"
                        {...defaultPwdValidation} />
                    )} 
                    <div className="icons pwdIcon" onClick={changePasswordType}>
                      {passwordType === "password" ? defaultPassword?.length > 0 ? (
                        <img
                        className="view_pwd_icon"
                          src={EyeCloseIcon}
                          alt="visible"
                        />
                      ) : (
                        <img
                        className="hide_pwd_icon"
                        src={VisibilityHideIcon}
                        alt="visibility"
                        />
                      ): (
                          <img
                            className="view_pwd_icon"
                            src={VisibilityIcon}
                            alt="visibility"
                          />
                      )}
                    </div>
                  </div>
                    <div className="error-message">
                        <span>{passwordSettingsError}</span>
                    </div>
                </div>
                <div className="general_options">
                  <label className="settings_label">
                    <span style={{ fontFamily: "SamsungOne-600" }}>
                      {f({ id: "COM_DMS_TWO_FACTOR_VERIFICATION" })}
                    </span>
                  </label>
                  <Checkbox
                    checked={!!twoFactorAuthentication}
                    onChange={() => setTwoFactorAuthentication(!twoFactorAuthentication)}
                  />
                    <div className='two-factor-auth-txt'>
                      {f({id: 'COM_DMS_TWO_FACTOR_AUTH_TXT'})}
                    </div>
                  </div>
              </div>
          </div>
          <div className="server_settings setting_3">
            <div className="settings_layout">
                <div className="server-settings-text">
                    {f({ id: "COM_DMS_SMTP_SERVER" })}
                </div>
                <div className="general_options">
                  <label className="settings_label">
                    <span style={{ fontFamily: "SamsungOne-600" }}>
                      {f({ id: "COM_DMS_SMTP_ENABLED" })}
                    </span>
                  </label>
                  <Checkbox
                    checked={!!smtpEnabled}
                    onChange={() => setSmtpEnabled(!smtpEnabled)}
                  />
                  </div>
                  <div className="general_options">
                  <label className="settings_label">
                    <span style={{ fontFamily: "SamsungOne-600" }}>
                      {f({ id: "COM_DMS_SERVER_ADDRESS" })}
                    </span>
                  </label>
                  <InputText
                    className="inputBox_ServerSettings"
                    value={serverAddress}
                    onChange={(e) => setServerAddress(e.target.value)}
                    disabled={!smtpEnabled}
                  />
                  </div>
                  <div className="general_options">
                  <label className="settings_label">
                    <span style={{ fontFamily: "SamsungOne-600" }}>
                      {f({ id: "COM_DMS_PORT" })}
                    </span>
                  </label>
                  <InputText
                    className="inputBox_ServerSettings"
                    value={smtpPort > 0 ? smtpPort : ""}
                    onChange={(e) => setSmtpPort(e.target.value)}
                    disabled={!smtpEnabled}
                  />
                  </div>
                  <div className="general_options">
                  <label className="settings_label">
                    <span style={{ fontFamily: "SamsungOne-600" }}>
                      {f({ id: "COM_DMS_SSL" })}
                    </span>
                  </label>
                  <Checkbox
                    checked={!!isSSL}
                    onChange={() => setIsSSL(!isSSL)}
                    disabled={!smtpEnabled}
                  />
                  </div>
                  <div className="general_options">
                  <label className="settings_label">
                    <span style={{ fontFamily: "SamsungOne-600" }}>
                      {f({ id: "COM_DMS_AUTHENTICATION" })}
                    </span>
                  </label>
                  <Checkbox
                    checked={!!smtpAuthenticated}
                    onChange={() => setSmtpAuthenticated(!smtpAuthenticated)}
                    disabled={!smtpEnabled}
                  />
                </div>
                <div className="general_options">
                  <label className="settings_label">
                    <span style={{ fontFamily: "SamsungOne-600" }}>
                      {f({ id: "COM_DMS_LOGIN_ID" })}
                    </span>
                  </label>
                  <InputText
                    className="inputBox_ServerSettings"
                    value={smtpId}
                    onChange={(e) => setSmtpId(e.target.value)}
                    disabled={!(smtpEnabled && smtpAuthenticated)}
                  />
                  </div>
                <div className="general_options">
                  <label className="settings_label">
                    <span style={{ fontFamily: "SamsungOne-600" }}>
                      {f({ id: "COM_DMS_PASSWORD" })}
                    </span>
                  </label>
                  <InputText
                    type={authPasswordType}
                    className="inputBox_ServerSettings auth-password"
                    value={smtpPassword}
                    onChange={(e) => setSmtpPassword(e.target.value)}
                    disabled={!(smtpEnabled && smtpAuthenticated)}
                  />
                  <div className="icons" onClick={changeAuthPasswordType}>
                  {authPasswordType === "password" ? smtpPassword?.length > 0 ? (
                      <img
                      className="view_pwd_icon"
                        src={EyeCloseIcon}
                        alt="visibility"
                      />
                    ) : (
                      <img
                      className="hide_pwd_icon"
                      src={VisibilityHideIcon}
                      alt="visibility"
                      />
                    ) : (
                        <img
                          className="view_pwd_icon"
                          src={VisibilityIcon}
                          alt="visibility"
                        />
                      )}
                    </div>
                    <div className="error-message">
                        <span>{smtpSettingsError}</span>
                    </div>
                  </div>
            </div>
          </div>
          </>
      )}
    </>
  );
};

export default ServerSettings;
