import React from "react";
import "./SearchBar.css";
import SearchIcon from "../../assets/images/search.svg";

const SearchBar = ({ value, setValue, placeholder, className, searchHandler, handleInputKeyDown}) => {
  return (
    <div className={`search-bar ${className}`}>
      <input type="text" value={value} placeholder={placeholder} onKeyDown={handleInputKeyDown} onChange={(e) => {setValue(e.target.value)}}/>
      <img className="search-icon" src={SearchIcon} alt="search" onClick={searchHandler}/>
    </div>
  );
};

export default SearchBar;
