import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import './AddGroupPopup.css';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import 'primeicons/primeicons.css';
import { Checkbox } from 'primereact/checkbox';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import _ from 'lodash';
import { GROUP_MGMT_API } from '../../../../config/constants/Constants';
import {
  cleanJSONTree,
  removeNullValuesFromNestedObjectOfArray,
} from '../../../../utils/CleanJSONArrayObject';
import { convertJSONDataToTreeTableFormat } from '../convertToJSONTree';
import { GroupSuggestPopup } from '../../../../components/PopUp/GroupSuggest';
import { GroupManagementService } from '../../../../services/RestServices/GroupManagementService';
import TransferIcon from '../../../../assets/images/moveRight.png';
import PlusIcon from '../../../../assets/images/plusIcon.svg';
import MinusIcon from '../../../../assets/images/minusIcon.svg';
import GroupIcon from '../../../../assets/images/groupIcon.svg';
import DeviceIcon from '../../../../assets/images/deviceGroupIcon.svg';
import flatToTree from 'flat-to-tree';
import { RestServiceUtils } from '../../../../utils/RestServiceUtils';
import InfoIcon from '../../../../assets/images/info-fill.svg';
import { Tooltip } from 'primereact/tooltip';
import CollapsableIcon from '../../../../assets/images/collapsibleIcon.svg';
import CancelRoundOutline from '../../../../assets/images/cancel-round-outline.svg';
import { validateGroupName } from '../../../../utils/Validations';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../services/APIResponseHandler';

const AddGroupPopup = ({
  setOpenAddGroup,
  selectedKey,
  setFlag,
  renderInitialGroupData,
  setGroupSearchText,
}) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;

  const [devices, setDevices] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [parentData, setParentData] = useState({});
  const [groupList, setGroupList] = useState({});
  const [visible, setVisible] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState({});
  const [selectedGroup, setSelectedGroup] = useState('');
  const [jsonGroupTree, setJsonGroupTree] = useState([]);
  const [deviceParentIds, setDeviceParentIds] = useState([]);
  const [parentGropupPath, setParentGroupPath] = useState('');
  const [selectedCheckedKeys, setSelectedCheckedKeys] = useState([]);

  const getAllGroupsList = () => {
    RestServiceUtils.HTTP_GET(GROUP_MGMT_API).then(({ data }) => {
      const convertedJSONData = flatToTree(data, {
        id: 'groupId',
        parentId: 'parentId',
      });
      convertJSONDataToTreeTableFormat(convertedJSONData);
      cleanJSONTree(convertedJSONData);
      const cleanedJSONTree =
        removeNullValuesFromNestedObjectOfArray(convertedJSONData);
      setJsonGroupTree(cleanedJSONTree);
      traverseToFindTheParentGroupPath(cleanedJSONTree, selectedKey, '');
    });
    setSelectedCheckedKeys([]);
    setDevices([]);
    setDeviceParentIds([]);
  };

  useEffect(() => {
    getAllGroupsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findParentGroupData = (jsonTree) => {
    if (!jsonTree) return;
    for (let index = 0; index < jsonTree?.length; index++) {
      const { data, key } = jsonTree[index];
      if (key === selectedKey) {
        return setParentData(data);
      }

      findParentGroupData(jsonTree[index]?.children);
    }
  };

  useEffect(() => {
    findParentGroupData(jsonGroupTree);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonGroupTree]);

  const traverseToAddDevices = (nodes, deviceData, key) => {
    if (!nodes) {
      return;
    }

    for (let index = 0; index < nodes.length; index++) {
      if (nodes[index].key === key) {
        nodes[index].children = [
          ...(nodes[index].children || []),
          ...(deviceData || []),
        ];
        if (!deviceData.length) {
          nodes[index] = { ...nodes[index], leaf: true };
        }
        return;
      } else {
        traverseToAddDevices(nodes[index]?.children, deviceData, key);
      }
    }
  };

  const traverseToRemoveDevices = (nodes, key) => {
    if (!nodes) {
      return;
    }

    for (let index = 0; index < nodes.length; index++) {
      if (nodes[index].key === key) {
        const filteredData = nodes[index]?.children?.filter(
          (node) => node?.type !== 'device'
        );
        nodes[index].children = [...(filteredData || [])];
      } else {
        traverseToRemoveDevices(nodes[index]?.children, key);
      }
    }
  };

  const onExpand = async (event) => {
    const { node } = event;
    const { key } = node;
    const keys = new Set(deviceParentIds);
    if (keys.has(key)) {
      return;
    }

    const resNodes = _.cloneDeepWith(jsonGroupTree);
    const keysOfChildren = [];

    for (let child of node?.children) {
      if (child.data.type === 'device') {
        keysOfChildren.push(child.key);
      }
    }

    const keysSet = new Set(keysOfChildren);

    RestServiceUtils.HTTP_GET(`${GROUP_MGMT_API}/${key}/devices`).then(
      ({ data }) => {
        const reformedDeviceData = [];
        data?.forEach((d) => {
          const { deviceName, deviceMac, deviceIp } = d ?? {};
          if (!keysSet.has(deviceMac))
            reformedDeviceData.push({
              key: deviceMac,
              label: deviceName,
              data: {
                groupName: deviceName,
                type: 'device',
                parentId: key,
                deviceMac,
                deviceIp,
              },
              leaf: true,
            });
        });
        traverseToAddDevices(resNodes, reformedDeviceData, key);
        setJsonGroupTree(resNodes);
        keys.add(key);
        setDeviceParentIds(Array.from(keys));
      }
    );
  };

  const movementOfDeviceBackToJSONTree = (jsonTree, deviceData, id) => {
    if (!jsonTree) {
      return;
    }
    for (let node of jsonTree) {
      if (node.key === id) {
        node.children = [...(node.children || []), deviceData];
        return;
      } else {
        movementOfDeviceBackToJSONTree(node?.children, deviceData, id);
      }
    }
  };

  const handleCancelDevice = (device) => {
    const jsonTreeNodesData = _.cloneDeepWith(jsonGroupTree);
    const { parentId, deviceMac } = device.data;
    movementOfDeviceBackToJSONTree(jsonTreeNodesData, device, parentId);
    const filteredDevices = devices.filter(
      (currDevice) => currDevice.data.deviceMac !== deviceMac
    );
    setDevices(filteredDevices);
    setJsonGroupTree(jsonTreeNodesData);
  };

  const traverseToFindTheParentGroupPath = (nodes, key, str) => {
    if (!nodes || !key) return;
    for (let idx = 0; idx < nodes?.length; idx++) {
      const { data } = nodes[idx] ?? {};
      const { groupName } = data ?? {};
      const resultantPath = str ? `${str} > ${groupName}` : groupName;
      if (nodes[idx].key === key) {
        setParentGroupPath(resultantPath);
        return;
      } else {
        traverseToFindTheParentGroupPath(
          nodes[idx].children,
          key,
          resultantPath
        );
      }
    }
  };

  const handleCreateGroup = async () => {
    if (selectedGroup) {
      const { isValid, messageId } = validateGroupName(selectedGroup);
      if (!isValid) {
        showErrorToast(f({ id: messageId }));
      } else {
        const { groupId } = parentData ?? {};
        let updatedParentId = groupId;
        if (!groupId) updatedParentId = -1;
        if (groupId === 0) updatedParentId = 0;
        const deviceIds = devices?.map(({ data }) => data.deviceMac);
        const payload = {
          groupName: selectedGroup?.trim(),
          parentId: updatedParentId,
          deviceIds,
        };

        await RestServiceUtils.HTTP_POST(GROUP_MGMT_API, payload).then(
          (res) =>
            res?.status === 200 &&
            showSuccessToast(f({ id: 'COM_DMS_GROUP_ADD_SUCCESSFUL' }))
        );
        setFlag(true);
        setOpenAddGroup(false);
        renderInitialGroupData();
        setGroupSearchText('');
      }
    }
  };

  const headerContent = () => {
    return (
      <div className="add-group-header">
        <div>{f({ id: 'COM_DMS_ADD_GROUP' })}</div>
        <div>
          <Button
            className="add-group-btn add-group-cancel-btn"
            label={f({ id: 'COM_DMS_DISCARD' })}
            onClick={() => setOpenAddGroup(false)}
          />
          <Button
            className={`add-group-btn  ${
              !selectedGroup ? 'disable-success-btn' : 'add-group-success-btn'
            }`}
            disabled={!selectedGroup}
            label={f({ id: 'COM_DMS_ADD' })}
            onClick={handleCreateGroup}
          />
        </div>
      </div>
    );
  };

  const renderDevice = (device, index) => {
    const { data } = device ?? {};
    const { groupName: deviceName, deviceMac, deviceIp } = data ?? {};
    return (
      <div
        className={`add-group-content-body-row ${
          index === devices.length - 1 ? 'add-group-content-body-row-last' : ''
        } `}
        key={index}
      >
        <div>{deviceName}</div>
        <div>{deviceMac}</div>
        <div>{deviceIp}</div>
        <div>
          <img
            src={CancelRoundOutline}
            onClick={() => handleCancelDevice(device)}
            className="cancel-round-icon"
            alt="cancel"
          />
        </div>
      </div>
    );
  };

  const moveDevicesToRight = (jsonData, deviceIds) => {
    if (!jsonData) return;
    const selectedKeysSet = new Set(selectedCheckedKeys);
    jsonData?.forEach((group, idx) => {
      if (jsonData[idx] && selectedKeysSet.has(group?.key)) {
        setDevices((devices) => [...devices, group]);
        deviceIds.push(group.key);
        delete jsonData[idx];
        selectedKeysSet.delete(group.key);
        setSelectedCheckedKeys(Array.from(selectedKeysSet));
      }
      moveDevicesToRight(group?.children, deviceIds);
    });
  };

  const onCollapse = (event) => {
    const { node } = event;
    const { key } = node;

    traverseToRemoveDevices(jsonGroupTree, key);
  };

  const handleTransferDevices = () => {
    const jsonData = _.cloneDeepWith(jsonGroupTree);
    const deviceIDs = [];
    moveDevicesToRight(jsonData, deviceIDs);
    const cleanJSONTreeWithoutNullValues = cleanJSONTree(jsonData);
    setJsonGroupTree(() => cleanJSONTreeWithoutNullValues);
  };

  const handleClickSearchSuggestions = () => {
    if (!searchText) {
      getAllGroupsList();
    } else {
      GroupManagementService.getGroupListFilter(searchText).then((res) => {
        const { data } = res ?? {};
        setDeviceParentIds([]);
        setDevices([]);
        setVisible(true);
        let map = new Map();
        let currSuggestions = [];
        const groupList = [];
        Object.values(data).forEach((val) => {
          groupList.push(val.groupId);
          map.set(
            [val.groupName, val.groupId],
            val.parents
              ? val.parents.map((parent) => [parent.groupName, parent.groupId])
              : []
          );
        });

        for (const x of map.entries()) {
          const currentNode = x[0];
          const parents = x[1];
          if (currentNode[0].toLowerCase().includes(searchText.toLowerCase())) {
            currSuggestions.push({
              groupName: currentNode[0],
              groupId: currentNode[1],
              groupParents: [...parents],
            });
          }
        }
        setSuggestions(() => currSuggestions);
      });
    }
    collapseTreeTable();
    setExpandedKeys({});
  };

  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }
    const expanded = options.expanded;
    return (
      <button
        type="button"
        className="p-treetable-toggler p-link"
        style={options.buttonStyle}
        tabIndex={-1}
        onClick={options.onClick}
      >
        <span>
          {expanded ? (
            <img src={MinusIcon} className="icon" alt="minus" />
          ) : (
            <img src={PlusIcon} className="icon" alt="plus" />
          )}
        </span>
      </button>
    );
  };

  const addGroupBody = ({ data, key }) => {
    const { type, groupName } = data ?? {};
    const selectedKeys = new Set(selectedCheckedKeys);

    return type !== 'device' ? (
      <>
        <img
          src={GroupIcon}
          alt="group"
          className="icon"
          style={{ marginRight: '1rem' }}
        />
        {groupName}
      </>
    ) : (
      <>
        <Checkbox
          checked={selectedKeys.has(key)}
          onChange={() => {
            if (selectedKeys.has(key)) {
              selectedKeys.delete(key);
            } else {
              selectedKeys.add(key);
            }
            setSelectedCheckedKeys(Array.from(selectedKeys));
          }}
          style={{ marginTop: '-0.6rem' }}
        />
        <img
          src={DeviceIcon}
          alt="device"
          className="icon"
          style={{ marginRight: '1rem' }}
        />
        {groupName}
        <Tooltip target={`#info-icon_${data?.deviceMac}`}>
          <div className="infoIcon_tooltipContent">
            <div>
              {f({ id: 'COM_DMS_ETHERNET_MAC' })}: {data?.deviceMac}
            </div>
            <div>
              {f({ id: 'COM_DMS_IP' })}: {data?.deviceIp}
            </div>
          </div>
        </Tooltip>
        <img
          src={InfoIcon}
          alt="info"
          className="info-icon icon"
          id={`info-icon_${data?.deviceMac}`}
          style={{ marginLeft: '1rem' }}
        />
      </>
    );
  };

  const collapseNodes = (list) => {
    if (!expandedKeys) return;
    let _expandedKeys = { ...expandedKeys };
    const keyMap = new Map(Object.entries(expandedKeys));
    for (let idx = 0; idx < list.length; idx++) {
      let val = '' + list[idx];
      if (keyMap.has(val)) delete _expandedKeys[val];
    }

    setExpandedKeys(_expandedKeys);
  };

  const getListOfAllChildComponents = (nodes, list) => {
    if (!nodes) return;

    for (let node of nodes) {
      list.push(node.key);
      getListOfAllChildComponents(node.children, list);
    }
  };

  const collapseTreeTable = () => {
    if (!expandedKeys) return;
    let collapsedNodes = [];
    let list = [];
    for (let node of jsonGroupTree) {
      list.push(node.key);
      if (node?.children && node?.children?.length) {
        getListOfAllChildComponents(node.children, list);
      }
      collapsedNodes.push({ ...node, leaf: false });
      collapseNodes(list);
    }
    setJsonGroupTree(collapsedNodes);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleClickSearchSuggestions();
    }
  };

  const groupNameHeader = () => {
    return (
      <div className="add-group-tree-header">
        <div onClick={collapseTreeTable} className="collapse-icon">
          <img src={CollapsableIcon} alt="collapse" className="icon" />
        </div>
        {f({ id: 'COM_DMS_GROUP_NAME' })}
      </div>
    );
  };

  return (
    <Dialog
      visible
      header={headerContent}
      className="add-group-dialog"
      position="bottom-right"
      draggable={false}
      onHide={() => setOpenAddGroup(false)}
    >
      <div className="add-group-sub-header">
        <div className="add-group-sub-parent-field">
          <div>
            <span className="add-group-important">*&nbsp;</span>
            {f({ id: 'COM_DMS_GROUP_NAME' })}
          </div>
          <div>
            <input
              value={selectedGroup}
              placeholder={f({ id: 'COM_DMS_GROUP_NAME_TEXT' })}
              onChange={(e) => setSelectedGroup(e.target.value)}
            />
          </div>
        </div>
        <div className="add-group-sub-parent-field">
          <div>&nbsp;&nbsp;&nbsp;{f({ id: 'COM_DMS_PARENT_GROUP' })}</div>
          <div>
            <input disabled value={parentGropupPath} />
          </div>
        </div>
      </div>

      <div className="add-group-contents">
        <div className="add-group-content">
          <div className="add-group-content-header">
            <div className="add-group-content-header-text">
              {f({ id: 'COM_DMS_SELECT_DEVICE' })}
            </div>
            <div>
              <SearchBar
                className="search-bar"
                value={searchText}
                setValue={setSearchText}
                setVisiblePopup={setVisible}
                handleInputKeyDown={handleInputKeyDown}
                placeholder={f({ id: 'COM_DMS_GROUP_NAME' })}
                searchHandler={handleClickSearchSuggestions}
              />
              {visible && (
                <GroupSuggestPopup
                  treeTableNode
                  visible={visible}
                  groups={suggestions}
                  groupList={groupList}
                  setVisible={setVisible}
                  className="group-suggest"
                  setGroupList={setGroupList}
                  setNodes={setJsonGroupTree}
                  setSearchText={setSearchText}
                />
              )}
            </div>
          </div>
          <div className="add-group-tree-table">
            <TreeTable
              scrollable
              scrollHeight="66vh"
              value={jsonGroupTree}
              onExpand={onExpand}
              onCollapse={onCollapse}
              expandedKeys={expandedKeys}
              togglerTemplate={togglerTemplate}
              onToggle={(e) => setExpandedKeys(e.value)}
            >
              <Column
                field="groupName"
                header={groupNameHeader}
                expander
                body={addGroupBody}
              />
            </TreeTable>
          </div>
        </div>
        <div className="add-group-content transfer-btn">
          <img
            src={TransferIcon}
            alt="transfer"
            onClick={handleTransferDevices}
          />
        </div>
        <div className="add-group-content">
          <div className="add-group-content-header">
            <div
              className="add-group-content-header-text"
              style={{ height: '2rem' }}
            >
              {f({ id: 'COM_DMS_DEVICE_SELECTED' })}
            </div>
          </div>
          <div className="add-group-content-body">
            <div className="add-group-content-body-header">
              <div className="add-group-right-content-header">
                <div>{f({ id: 'COM_DMS_DEVICES' })}</div>
                <div>{f({ id: 'COM_DMS_MAC_ID' })}</div>
                <div>{f({ id: 'COM_DMS_IP' })}</div>
              </div>
            </div>
            <div className="add-group-content-body-text">
              {devices.length !== 0 ? (
                devices?.map((device, idx) => renderDevice(device, idx))
              ) : (
                <div className="no-device-text">
                  {f({ id: 'COM_DMS_ADD_DEVICE_TEXT' })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddGroupPopup;
