import React from 'react';
import './AddToTemplatePopup.css';
import { useIntl } from 'react-intl';
import { templateNameMaxLength } from '../../../../../../src/utils/Validations'

const AddToTemplatePopup = ({
  setOpenAddToTemplate,
  templateName,
  setTemplateName,
  handleAddTemplate,
  templateMessageError,
  setTemplateMessageError,
}) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  return (
    <div className="add-to-template-popup">
      <div className="add-to-template-header">
        {f({ id: 'COM_DMS_ADD_TO_TEMPLATE' })}
      </div>
      <div className="add-to-template-body">
        <label>{f({ id: 'COM_DMS_TEMPLATE_NAME' })}</label>
        <input
          type="text"
          className={!!templateMessageError ? 'add-to-template-error' : ''}
          value={templateName}
          onChange={(e) => {
            setTemplateMessageError('');
            setTemplateName(e.target.value);
          }}
          maxLength={templateNameMaxLength}

        />

        {!!templateMessageError && (
          <div className="add-to-template-error-msg">
            {templateMessageError}
          </div>
        )}
      </div>

      <div className="add-to-template-action-btns">
        <button
          className="cancel-btn"
          onClick={() => {
            setTemplateName('');
            setTemplateMessageError('');
            setOpenAddToTemplate(false);
          }}
        >
          {f({ id: 'COM_DMS_CANCEL' })}
        </button>
        <button className="add-btn" onClick={handleAddTemplate}>
          Add
        </button>
      </div>
    </div>
  );
};

export default AddToTemplatePopup;
