import './ColorBox.css';

const ColorBox = ({ type, colorCodes, setColorCode, setResetFlag }) => {
  return (
    <div className="alert-color-box">
      {colorCodes.map((background, index) => (
        <div
          key={index}
          className="color-div"
          style={{ background }}
          onClick={() => {
            setResetFlag(true);
            setColorCode(type, background);
          }}
        />
      ))}
    </div>
  );
};

export default ColorBox;
