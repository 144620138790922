import React, { useEffect, useState } from 'react';
import './ErrorWarningMessage.css';
import { useDispatch } from 'react-redux';
import { resetAuthState } from '../../redux/actions/AuthAction';

const ErrorWarningMessage = ({ text, className, authFlag, dispatchMethod }) => {
  const [hideMessage, setHideMessage] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setHideMessage(true);
      if (!authFlag) {
        dispatch(resetAuthState());
      } else {
        if (dispatchMethod) {
          dispatch(dispatchMethod());
        }
      }
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hideMessage]);

  return (
    <div
      className={
        hideMessage
          ? 'hide-msg'
          : className
          ? `error-warning-message ${className}`
          : `error-warning-message`
      }
    >
      {text}
    </div>
  );
};
export default ErrorWarningMessage;
