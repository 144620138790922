import React from "react";
import Overlay from "../Overlay/Overlay";
import "./OkCard.css";
import CustomButton from "../CustomButton/CustomButton";

const OkCard = ({ handleOkClick, text, headerText }) => {
  return (
    <Overlay className="changePasswordOverlay">
      <div className="ok-card">
        <div className="ok-header">{headerText}</div>
        <div className="ok-card-text">{text}</div>
        <div>
          <CustomButton className="custom-btn" onClick={handleOkClick} text="Ok" />
        </div>
      </div>
    </Overlay>
  );
};

export default OkCard;
