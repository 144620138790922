import axios from "axios";
import { API_KEY } from "../config/constants/Constants";

const SetAuthToken = (user) => {
  const { token } = user ?? {};
  if (token) {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("loginUserId", user.userId);
    axios.defaults.headers.common[API_KEY] = token;
  } else delete axios.defaults.headers.common[API_KEY];
};

export default SetAuthToken;
