import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import React, { useEffect } from 'react';
import './App.css';
import RoutePaths from './router/RoutePaths';
import I18nProvider from './language/Provider';
import { LOCALES } from './language/LOCALES';
import { HashRouter as Router } from 'react-router-dom';
import { signOut } from './redux/actions/AuthAction';
import { useDispatch } from "react-redux";
import { resetServerSettings } from './redux/actions/ServerSettingsAction';

const App = () => {
  
  const user = JSON.parse(localStorage.getItem("user"));
  const { refreshToken } = user ?? {};
  const dispatch = useDispatch();
  useEffect(() => {
    const handlePopstate = () => {
      dispatch(signOut({ refreshToken }));
      dispatch(resetServerSettings());
      localStorage.clear();
    };
    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);
  
  return (
    <I18nProvider locale={LOCALES.ENGLISH}>
      <Router >
        <RoutePaths />
      </Router>
    </I18nProvider>
  );
};

export default App;
