import React, { useEffect, useRef, useState } from 'react';
import './AppManagement.css';
import flatToTree from 'flat-to-tree';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { TabPanel, TabView } from 'primereact/tabview';
import AppTable from '../../../components/AppTable/AppTable';
import { useIntl } from 'react-intl';
import { Button } from 'primereact/button';
import { appServices } from '../../../services/RestServices/appServices';
import {
  NATIVE_APPS,
  USER_APPS,
} from '../../../config/constants/CommonConstants';
import Spinner from '../../../components/Spinner/Spinner';
import { Dialog } from 'primereact/dialog';
import { RestServiceUtils } from '../../../utils/RestServiceUtils';
import { GROUP_MGMT_API } from '../../../config/constants/Constants';
import {
  cleanJSONTree,
  removeNullValuesFromNestedObjectOfArray,
} from '../../../utils/CleanJSONArrayObject';
import SelectDevices from '../../../components/SelectDevices/SelectDevices';
import { convertJSONDataToTreeTableFormat } from '../../Devices/Group/convertToJSONTree';
import CrossIcon from '../../../assets/images/crossIcon.svg';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import AndroidIcon from '../../../assets/images/androidIcon.png';
import { Toast } from 'primereact/toast';
import {
  setToastComponent,
  showSuccessToast,
} from '../../../services/APIResponseHandler';

const AppManagement = ({ refreshPage }) => {
  const [searchText, setSearchText] = useState('');
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const APP_NAME = f({ id: 'COM_DMS_APP_NAME' });
  const VERSION = f({ id: 'COM_DMS_VERSION' });
  const APP_MANAGE = f({ id: 'COM_DMS_APP_MANAGE' });
  const MANAGE_APPS = f({ id: 'COM_DMS_MANAGE_APPS' });
  const [appList, setAppList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [appsPayload, setAppsPayload] = useState({
    startIndex: 0,
    pageSize: 10,
    searchText: '',
    appType: USER_APPS,
  });
  const [appsCount, setAppsCount] = useState({});
  const [loading, setLoading] = useState(false);
  const [openManageApps, setOpenManageApps] = useState(false);
  const [appInfo, setAppInfo] = useState();
  const installedAppsActions = [
    { name: `Enable`, key: 'enable' },
    { name: `Disable`, key: 'disable' },
    { name: `Uninstall`, key: 'uninstall' },
  ];
  const [installedAppAction, setInstalledAppAction] = useState(
    installedAppsActions[0]
  );
  const nativeAppsActions = [
    { name: `Enable`, key: 'enable' },
    { name: `Disable`, key: 'disable' },
  ];
  const [jsonGroupTree, setJsonGroupTree] = useState([]);
  const [selectedCheckedKeys, setSelectedCheckedKeys] = useState([]);
  const [devices, setDevices] = useState([]);
  const [deviceParentIds, setDeviceParentIds] = useState([]);
  const [nativeAppAction, setNativeAppAction] = useState(
    installedAppsActions[0]
  );
  const [actionButton, setActionButton] = useState('Enable');
  const [action, setAction] = useState('');
  const [appPackage, setAppPackage] = useState('');
  const [dialogState, setDialogState] = useState({
    header: '',
    body: '',
    footer: '',
    action: '',
    cancel: '',
  });
  const [dialogVisible, setDialogVisible] = useState(false);
  const getAvilableGroups = () => {
    RestServiceUtils.HTTP_GET(GROUP_MGMT_API).then(({ data }) => {
      const convertedJSONData = flatToTree(data, {
        id: 'groupId',
        parentId: 'parentId',
      });
      convertJSONDataToTreeTableFormat(convertedJSONData);
      cleanJSONTree(convertedJSONData);
      const cleanedJSONTree =
        removeNullValuesFromNestedObjectOfArray(convertedJSONData);
      setJsonGroupTree(cleanedJSONTree);
    });
    setSelectedCheckedKeys([]);
    setDevices([]);
    setDeviceParentIds([]);
  };

  const toastRef = useRef(null);
  useEffect(() => {
    setToastComponent(toastRef.current);
  }, []);

  const setActionButtonHandler = (index) => {
    if (
      index === 0
        ? installedAppAction.key === 'enable'
        : nativeAppAction.key === 'enable'
    ) {
      setActionButton('Enable');
      setAction('ENABLE');
    } else if (
      index === 0
        ? installedAppAction.key === 'disable'
        : nativeAppAction.key === 'disable'
    ) {
      setActionButton('Disable');
      setAction('DISABLE');
    } else {
      setActionButton('Uninstall');
      setAction('UNINSTALL');
    }
  };

  useEffect(() => {
    setActionButtonHandler(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installedAppAction, nativeAppAction]);

  const getAppList = async () => {
    setLoading(true);
    await appServices.getAllAppList(appsPayload).then((res) => {
      const { deviceApps, totalCount } = res?.data ?? {};
      setAppList(deviceApps);
      setAppsCount(totalCount);
    });
    setLoading(false);
  };

  useEffect(() => {
    getAppList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appsPayload, refreshPage]);

  const manageAppHandler = (rowData) => {
    setInstalledAppAction(installedAppsActions[0]);
    setNativeAppAction(nativeAppsActions[0]);
    setAppInfo(rowData);
    setOpenManageApps(true);
  };

  useEffect(() => {
    appList?.map(
      (app) =>
        app?.appName === appInfo?.appName && setAppPackage(app.appPackageName)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appInfo]);

  const installedAppManageTemplate = (rowData) => {
    return (
      <>
        <Button
          className="manageApp_btn"
          label={MANAGE_APPS}
          onClick={() => manageAppHandler(rowData)}
        />
      </>
    );
  };

  const nativeAppManageTemplate = (rowData) => {
    return (
      <>
        <Button
          className="manageApp_btn"
          label={MANAGE_APPS}
          onClick={() => manageAppHandler(rowData)}
        />
      </>
    );
  };

  const appNameTemplate = (rowData) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <img
            src={rowData.imgSrc !== '' ? rowData.imgSrc : AndroidIcon}
            className="appIconStyle"
          />
          <div style={{ paddingLeft: '1rem' }}>{rowData?.appName}</div>
        </div>
      </>
    );
  };

  const columnInstalledApp = [
    {
      field: 'appName',
      header: APP_NAME,
      style: { width: '50%' },
      body: appNameTemplate,
    },
    { field: 'appVersionName', header: VERSION, style: { width: '25%' } },
    {
      field: 'appManage',
      header: APP_MANAGE,
      style: { width: '15%' },
      body: installedAppManageTemplate,
    },
  ];

  const columnNativeApp = [
    {
      field: 'appName',
      header: APP_NAME,
      style: { width: '50%' },
      body: appNameTemplate,
    },
    { field: 'appVersionName', header: VERSION, style: { width: '25%' } },
    {
      field: 'appManage',
      header: APP_MANAGE,
      style: { width: '15%' },
      body: nativeAppManageTemplate,
    },
  ];

  const searchAppHandler = () => {
    setAppsPayload({ ...appsPayload, searchText: searchText });
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      searchAppHandler();
    }
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setAppsPayload({
      ...appsPayload,
      startIndex: event.first,
      pageSize: event.rows,
    });
  };

  const handleEnable = () => {
    setDialogState({
      ...dialogState,
      header: f({ id: 'COM_DMS_ENABLE_APP' }),
      body: f({ id: 'COM_DMS_DO_YOU_REALLY_WANT_TO_ENABLE' }),
      footer: f({ id: 'COM_DMS_APP_WILL_BE_ENABLED_FOR_SELECTED_DEVICE' }),
      action: 'Yes',
      cancel: 'Cancel',
    });
    setDialogVisible(true);
  };
  const handleDisable = () => {
    setDialogState({
      ...dialogState,
      header: f({ id: 'COM_DMS_DISABLE_APP' }),
      body: f({ id: 'COM_DMS_DO_YOU_REALLY_WANT_TO_DISABLE' }),
      footer: f({ id: 'COM_DMS_APP_WILL_BE_DISABLED_FOR_SELECTED_DEVICE' }),
      action: 'Yes',
      cancel: 'Cancel',
    });
    setDialogVisible(true);
  };
  const handleUninstall = () => {
    setDialogState({
      ...dialogState,
      header: f({ id: 'COM_DMS_UNINSTALL_APP' }),
      body: f({ id: 'COM_DMS_DO_YOU_REALLY_WANT_TO_UNINSTALL' }),
      footer: f({ id: 'COM_DMS_APP_WILL_BE_UNINSTALLED_FOR_SELECTED_DEVICE' }),
      action: 'Yes',
      cancel: 'Cancel',
    });
    setDialogVisible(true);
  };

  const handleActionPerform = () => {
    if (dialogState?.header === f({ id: 'COM_DMS_ENABLE_APP' })) {
      performActionOnDevice('ENABLE');
    } else if (dialogState?.header === f({ id: 'COM_DMS_DISABLE_APP' })) {
      performActionOnDevice('DISABLE');
    } else if (dialogState?.header === f({ id: 'COM_DMS_UNINSTALL_APP' })) {
      performActionOnDevice('UNINSTALL');
    }
    setDialogVisible(false);
  };

  const performActionOnDevice = async (appAction) => {
    const deviceIds = devices.map((device) => device.data.deviceMac);
    const payload = {
      targetDevices: {
        deviceIds: deviceIds,
        groupIds: []
      },
      appAction,
    };
    let toastMessage = '';
    switch (appAction) {
      case 'UNINSTALL':
        toastMessage = f({ id: 'COM_DMS_APP_UNINSTALL_MESSAGE' });
        break;
      case 'ENABLE':
        toastMessage = f({ id: 'COM_DMS_APP_ENABLE_MESSAGE' });
        break;
      default:
        toastMessage = f({ id: 'COM_DMS_APP_DISABLE_MESSAGE' });
        break;
    }

    await appServices
      .manageAppAction(payload, appInfo.appPackageName)
      .then((res) => {
        setOpenManageApps(false);
        if (res?.status === 200) showSuccessToast(toastMessage);
      });
  };

  const manageAppHeader = () => {
    return (
      <>
        <div className="manageAppsHeader">
          <div className="manageAppsHeaderContent">{MANAGE_APPS}</div>
          <div>
            <img
              src={CrossIcon}
              className="cross-icon"
              alt="CrossIcon"
              onClick={() => setOpenManageApps(false)}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="appName">
            <img
              src={appInfo.imgSrc !== '' ? appInfo.imgSrc : AndroidIcon}
              className="appIconStyle"
            />
            <div className="appNameStyle">{appInfo?.appName}</div>
          </div>
          <div className="action-buttons">
            <Button
              className="manageApp_btn action_btn"
              onClick={handleEnable}
              disabled={devices?.length === 0}
            >
              {f({ id: 'COM_DMS_ENABLE_SMALL_CASE' })}
            </Button>
            <Button
              className="manageApp_btn action_btn"
              onClick={handleDisable}
              disabled={devices?.length === 0}
            >
              {f({ id: 'COM_DMS_DISABLE_SMALL_CASE' })}
            </Button>
            {activeTab === 0 && (
              <Button
                className="manageApp_btn action_btn uninstall_btn"
                onClick={handleUninstall}
                disabled={devices?.length === 0}
              >
                {f({ id: 'COM_DMS_UNINSTALL_SMALL_CASE' })}
              </Button>
            )}
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    getAvilableGroups();
  }, [activeTab, openManageApps]);

  return (
    <>
      {loading ? <Spinner /> : ''}
      <Toast ref={toastRef} position="top-center" />
      <div className="app_management_page">
        <div className="apps_left">
          <div className="apps_SearchBar">
            <SearchBar
              placeholder="Search"
              searchHandler={searchAppHandler}
              handleInputKeyDown={handleInputKeyDown}
              value={searchText}
              setValue={setSearchText}
            />
          </div>
        </div>
        <div className="apps_table_position">
          <TabView
            className="apps_tabView"
            activeIndex={activeTab}
            onTabChange={(e) => {
              setActiveTab(e.index);
              setSearchText('');
              setAppsPayload({
                ...appsPayload,
                appType: e.index === 0 ? USER_APPS : NATIVE_APPS,
                searchText: '',
              });
              setActionButtonHandler(e.index);
            }}
          >
            <TabPanel header="Third-Party App">
              <AppTable
                className="app_manage_table"
                columns={columnInstalledApp}
                data={appList}
                totalCount={appsCount}
                first={first}
                rows={rows}
                onPageChange={onPageChange}
                emptyMessageId={'COM_DMS_NO_APPS_TO_MANAGE'}
              />
            </TabPanel>
            <TabPanel header="Native App">
              <AppTable
                className="app_manage_table"
                columns={columnNativeApp}
                data={appList}
                totalCount={appsCount}
                first={first}
                rows={rows}
                onPageChange={onPageChange}
                emptyMessageId={'COM_DMS_NO_APPS_TO_MANAGE'}
              />
            </TabPanel>
          </TabView>
        </div>
        <Dialog
          header={manageAppHeader}
          visible={openManageApps}
          position="right"
          draggable={false}
          className="manageAppsDialog"
          onHide={() => setOpenManageApps(false)}
        >
          <SelectDevices
            jsonGroupTree={jsonGroupTree}
            setJsonGroupTree={setJsonGroupTree}
            selectedCheckedKeys={selectedCheckedKeys}
            setSelectedCheckedKeys={setSelectedCheckedKeys}
            devices={devices}
            setDevices={setDevices}
            deviceParentIds={deviceParentIds}
            setDeviceParentIds={setDeviceParentIds}
            appPackage={appPackage}
            isAppManagement={true}
            emptyTargetDeviceId={'COM_DMS_SELECT_TARGET_DEVICE'}
          />
        </Dialog>
        {dialogVisible && (
          <CustomDialog
            dialogState={dialogState}
            handleClick={handleActionPerform}
            dialogVisible={dialogVisible}
            setDialogVisible={setDialogVisible}
          />
        )}
      </div>
    </>
  );
};

export default AppManagement;
