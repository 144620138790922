import { RestServiceUtils } from '../../utils/RestServiceUtils';
import { USERS, DELETE_USERS } from "../../config/constants/Constants";

const config = {
  headers: {
    "Content-Type": "application/json"
  }
}

const getAllUsersList = (payload) => {
  const URL = USERS+"/filter";
  return RestServiceUtils.HTTP_POST(
          URL, 
          payload, 
          config);
}

const addUserInfo = (userDetails) => {
  return RestServiceUtils.HTTP_POST(
          USERS, 
          JSON.stringify(userDetails), 
          config);
};

const editUserInfo = (userDetails,userId) => {
  const URL = `${USERS}/${userId}`;
  return RestServiceUtils.HTTP_PUT(
          URL, 
          userDetails, 
          config);
};

const deleteUserInfo = (userId) => {
  const URL = DELETE_USERS + "/deleteUsers";
  return RestServiceUtils.HTTP_POST(
          URL, 
          {"userIdList": userId}, 
          config);
};

const getTemporaryPassword = () => {
  const URL = USERS+"/temporaryPassword";
  return RestServiceUtils.HTTP_GET(
              URL, 
              {},  
              config);
};

const setUserDevicePermissions = (userId,deviceGroups) => {
  const URL = USERS+`/${userId}/devicePermissions`;
  return RestServiceUtils.HTTP_PUT(
                  URL,
                  {"parentGroupIds":deviceGroups},
                  config
                );
}

const getUserDevicePermissions = (userId) => {
  const URL = USERS+`/${userId}/devicePermissions`;
  return RestServiceUtils.HTTP_GET(
                  URL,
                  {},
                  config
                );
}

const postNewUserSendMail = (payload) => {
  return RestServiceUtils.HTTP_POST(`${USERS}/newUserSendMail`, payload, config);
}

const changeUserPassword = (payload) => {
  return RestServiceUtils.HTTP_POST(`${USERS}/changePassword`, payload, config);
}

export const userServices = {
  getAllUsersList,
  addUserInfo,
  editUserInfo,
  deleteUserInfo,
  changeUserPassword,
  postNewUserSendMail,
  getTemporaryPassword,
  setUserDevicePermissions,
  getUserDevicePermissions
}
