import React from 'react';
import './SamsungDMSFooter.css';
import { useIntl } from 'react-intl';
import { AIDMS_SERVER_VERSION } from '../../config/constants/Constants';

const SamsungDMSFooter = () => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  return (
    <div className="login-footer">
      <div className="login-footer-left" />
      <div className="login-footer-right">
        <div>{`${f({
          id: 'COM_DMS_SERVER',
        })} ${AIDMS_SERVER_VERSION}`}</div>
        <div className="pipe-line"></div>
        <div>{f({ id: 'COM_DMS_COPYRIGHT' })}</div>
      </div>
    </div>
  );
};

export default SamsungDMSFooter;
