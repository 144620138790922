import React, { useEffect, useState, useRef } from 'react';
import './DeviceTagPopup.css';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { tagServices } from '../../../../services/RestServices/tagServices';
import { useIntl } from 'react-intl';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import {
  showSuccessToast,
  showErrorToast,
} from '../../../../services/APIResponseHandler';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';
import {
  tagsMaxLength,
  validateDeviceDetails,
} from '../../../../utils/Validations';

const DeviceTagPopup = ({ devices, setDevices, setSelectTag, removeTag, refreshPage, setRefreshPage, setSelectedItems, setSelectedCards }) => {
  const intl = useIntl();
  const toastRef = useRef(null);
  const { formatMessage: f } = intl;
  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [inputTag, setInputTag] = useState('');
  const [checkedTags, setCheckedTags] = useState([]);
  const [dialogState, setDialogState] = useState({
    header: '',
    body: '',
    footer: '',
    action: '',
    cancel: '',
  });
  const [suggestedTags, setSuggestedTags] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [checkedTagHeader, setCheckedTagHeader] = useState(false);
  const [suggestedRemoveTag, setSuggestedRemoveTag] = useState('');
  const [selectedCheckedKeys, setSelectedCheckedKeys] = useState('');
  const [focussedRemoveTag, setFocussedRemoveTag] = useState(false);

  useEffect(() => {
    tagServices.getAllTagList().then(({ data }) => {
      const updatedData = data?.map((val) => val.tagName);
      setAllTags(updatedData);
    });
    const deviceIds = devices?.map((device) => device.deviceId);
    if (removeTag && deviceIds.length > 0) {
      tagServices
        .commonTagsOfMultipleDevices({ deviceIds })
        .then(({ data }) => {
          if (data?.errorCode) return;
          if (data) {
            const updatedData = data?.map((val) => val.tagName);
            setTags(updatedData);
          }
        });
    } else {
      tagServices.getTagByDeviceId(deviceIds).then(({ data }) => {
        if (data?.errorCode) return;
        if (data) {
          const updatedData = data?.map((val) => val.tagName);
          setTags(updatedData);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (inputTag) {
      const newSuggestedTags = allTags?.filter((tag) =>
        tag.toLowerCase().includes(inputTag.toLowerCase())
      );
      setSuggestedTags(newSuggestedTags);
    } else {
      setSuggestedTags([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTag]);

  useEffect(() => {
    if (suggestedRemoveTag) {
      const newSuggestedTags = tags?.filter((tag) =>
        tag.toLowerCase().includes(suggestedRemoveTag.toLowerCase())
      );
      setSuggestedTags(newSuggestedTags);
    } else {
      setSuggestedTags([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedRemoveTag]);

  const removeTagRightContentRender = () => {
    return (
      <div className="device-tag-content-body">
        <div className="device-tag-content-body-header">
          <Checkbox
            checked={checkedTagHeader}
            onChange={() => {
              const checkedKeysSet = new Set(checkedTags);
              const selectedCheckedKeysSet = new Set(selectedCheckedKeys);
              if (checkedTagHeader) {
                tags?.forEach((tag) => {
                  checkedKeysSet.delete(tag);
                  selectedCheckedKeysSet.delete(tag);
                });
              } else {
                tags?.forEach((tag) => {
                  checkedKeysSet.add(tag);
                  selectedCheckedKeysSet.add(tag);
                });
              }
              setCheckedTags(Array.from(checkedKeysSet));
              setCheckedTagHeader(!checkedTagHeader);
              setSelectedCheckedKeys(Array.from(selectedCheckedKeysSet));
            }}
          />
          <div>{f({ id: 'COM_DMS_TAG_NAME' })}</div>
        </div>
        <div className="device-content-section">
          {tags?.map((tag, idx) => {
            const checkedKeys = new Set(checkedTags);
            return (
              <div key={idx} className="device-content-body">
                <Checkbox
                  checked={checkedKeys.has(tag)}
                  onChange={() => {
                    const selectedKeys = new Set(checkedTags);
                    const selectedCheckedKeysSet = new Set(selectedCheckedKeys);
                    if (selectedKeys.has(tag)) {
                      selectedKeys.delete(tag);
                      selectedCheckedKeysSet.delete(tag);
                    } else {
                      selectedKeys.add(tag);
                      selectedCheckedKeysSet.add(tag);
                    }
                    if (selectedKeys?.size !== tags.length) {
                      setCheckedTagHeader(false);
                    } else {
                      setCheckedTagHeader(true);
                    }
                    setSelectedCheckedKeys(Array.from(selectedCheckedKeysSet));
                    setCheckedTags(Array.from(selectedKeys));
                  }}
                />
                {tag}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const handleTagClick = (tagName) => {
    setInputTag('');
    let prevStates = [];
    if (tags) {
      prevStates = tags?.map((tag) => tag?.toLowerCase()) || [];
    }
    const addedNewTags = newTags?.map((tag) => tag.toLowerCase());
    const tagsSet = new Set([...prevStates, ...addedNewTags]);
    if (!tagsSet.has(tagName.toLowerCase())) {
      setNewTags((prevTags) => [...prevTags, tagName]);
    }
  };

  const addNewTag = () => {
    const addedNewTags = new Set(newTags?.map((tag) => tag.toLowerCase()));
    if (!addedNewTags.has(inputTag)) {
      const res = validateDeviceDetails('deviceTags', inputTag);
      if (res.isValid) {
        setInputTag('');
        setNewTags((prevTags) => [...prevTags, inputTag]);
      } else {
        showErrorToast(f({ id: res.messageId }));
      }
    } else {
      showErrorToast(f({ id: 'COM_DMS_TAG_ALREADY_ADDED' }));
    }
  };

  const handleDialogAction = () => {
    setSelectTag(null);
    setDialogState({ ...dialogState, action: '' });
    if (!checkedTags.length) return;
    const deviceIds = devices.map((device) => device.deviceId);
    tagServices
      .removeDeviceTags({
        deviceIds,
        tagNames: checkedTags,
      })
      .then(() => {
        showSuccessToast(f({ id: 'COM_DMS_REMOVED_TAG_MESSAGE' }));
        setSelectTag(null);
        setRefreshPage(!refreshPage);
        setDevices([]);
        setSelectedItems([]);
        setSelectedCards([]);
      });
    tagServices.getTagByDeviceId(deviceIds).then(({ data }) => {
      if (data?.errorCode) return;
      if (data) {
        const updatedData = data?.map((val) => val.tagName);
        setTags(updatedData);
      }
    });
  };

  const handleTagOperation = () => {
    if (removeTag) {
      setDialogState({
        ...dialogState,
        header: f({ id: 'COM_DMS_REMOVE_SELECTED_TAG' }),
        body: f({ id: 'COM_DMS_REMOVE_TAG_BODY' }),
        action: 'Yes',
        cancel: 'No',
      });
      setDialogVisible(true);
    } else {
      if (devices.length === 1) {
        const { deviceId } = devices[0];
        tagServices
          .addTagsToMultipleDevices({
            deviceIds: [deviceId],
            tagNames: newTags,
          })
          .then(() => {
            setSelectTag(null);
            showSuccessToast(f({ id: 'COM_DMS_ASSIGNED_TAG_MESSAGE' }));
            setRefreshPage(!refreshPage);
            setDevices([]);
            setSelectedItems([]);
            setSelectedCards([]);
          });
      } else {
        const deviceIds = devices.map((device) => device.deviceId);
        tagServices
          .addTagsToMultipleDevices({ deviceIds, tagNames: newTags })
          .then(() => {
            setSelectTag(null);
            showSuccessToast(f({ id: 'COM_DMS_ASSIGNED_TAG_MESSAGE' }));
            setRefreshPage(!refreshPage);
            setDevices([]);
            setSelectedItems([]);
            setSelectedCards([]);
          });
      }
    }
  };

  const removeDeviceTag = (tag) => {
    const newUpdatedTag = newTags?.filter((tagVal) => tagVal !== tag);
    setNewTags(() => newUpdatedTag);
  };

  const suggestedTagsValues = suggestedTags?.map((tag) => tag);

  const handleSuggestedRemoveTags = () => {
    const new_checked_list = [];
    setFocussedRemoveTag(false);
    const checked_Keys = new Set(checkedTags);
    const selected_Checked_Keys = new Set(selectedCheckedKeys);

    for (let tag of tags) {
      if (selected_Checked_Keys.has(tag) && !checked_Keys.has(tag)) {
        new_checked_list.push(tag);
      } else if (selected_Checked_Keys.has(tag) && checked_Keys.has(tag)) {
        new_checked_list.push(tag);
      }
    }
    setCheckedTags(new_checked_list);
  };

  return (
    <>
      <Toast ref={toastRef} position="top-center" />
      <Dialog
        visible
        position="bottom-right"
        draggable={false}
        style={{ width: '50vw' }}
        className="tags-dialog"
        onHide={() => setSelectTag(null)}
      >
        <div className="device-tags-header">
          <div className="device-tags-header-text">
            {removeTag
              ? f({ id: 'COM_DMS_REMOVE_TAGS' })
              : f({ id: 'COM_DMS_ASSIGN_TAGS' })}
          </div>
          <div className="device-tag-btns">
            <Button
              className="cancel-btn"
              onClick={() => {
                setSelectTag(null);
              }}
            >
              {f({ id: 'COM_DMS_CANCEL' })}
            </Button>
            <Button onClick={handleTagOperation} className="assign-btn" disabled={!removeTag ? newTags?.length === 0 : checkedTags?.length === 0}>
              {!removeTag
                ? f({ id: 'COM_DMS_ASSIGN' })
                : f({ id: 'COM_DMS_REMOVE' })}
            </Button>
          </div>
        </div>

        <div className="device-tag-body">
          <div className="device-tag-content">
            <div className="device-tag-content-header header">
              {f({ id: 'COM_DMS_DEVICE' })}
            </div>
            <div className="device-tag-content-body">
              <div className="device-tag-content-body-header">
                <div>{f({ id: 'COM_DMS_DEVICES' })}</div>
                <div>{f({ id: 'COM_DMS_MAC_ID' })}</div>
                <div>{f({ id: 'COM_DMS_IP' })}</div>
              </div>
              <div className="device-content-section">
                {devices.map((device, idx) => {
                  const { deviceName, ipAddress, deviceId } = device ?? {};
                  return (
                    <div key={idx} className="device-row">
                      <div>{deviceName}</div>
                      <div>{deviceId}</div>
                      <div>{ipAddress}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="device-tag-content">
            <div className="device-tag-content-header">
              <div className=" header">{f({ id: 'COM_DMS_TAG' })}</div>
              {!removeTag ? (
                <div>
                  <div className="device-search-bar">
                    <input
                      type="text"
                      value={inputTag}
                      placeholder={f({id : 'COM_DMS_PLACEHOLDER_SEARCH_OR_ADD_TAG'})}
                      onChange={(e) => {
                        setFocussedRemoveTag(true);
                        setInputTag(e.target.value);
                      }}
                    />
                    {inputTag && !suggestedTagsValues?.includes(inputTag) && (
                      <button
                        className="device-search-add-btn"
                        onClick={addNewTag}
                      >
                        {f({ id: 'COM_DMS_ADD' })}
                      </button>
                    )}
                  </div>
                  <div
                    className={
                      suggestedTags?.length > 0
                        ? 'suggested-tag-popup suggested-tag-border'
                        : 'suggested-tag-popup'
                    }
                  >
                    {suggestedTags?.map((tag, idx) => (
                      <div key={idx} onClick={() => handleTagClick(tag)}>
                        {tag}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="device-select-multi-tags">
                  <input
                    type="text"
                    className="device-select-input"
                    value={suggestedRemoveTag}
                    maxLength={tagsMaxLength}
                    placeholder="Search Tag"
                    onChange={(e) => {
                      setFocussedRemoveTag(true);
                      setSuggestedRemoveTag(e.target.value);
                    }}
                  />
                  <button
                    className="device-select-btn"
                    onClick={handleSuggestedRemoveTags}
                    disabled={!suggestedRemoveTag || suggestedTags.length === 0}
                  >
                    Select
                  </button>
                  {focussedRemoveTag && (
                    <div
                      className={
                        suggestedTags?.length > 0
                          ? 'suggested-tag-popup suggested-tag-border'
                          : 'suggested-tag-popup'
                      }
                    >
                      {suggestedTags?.map((tag, idx) => {
                        const checkedKeys = new Set(selectedCheckedKeys);
                        return (
                          <div key={idx} onClick={() => handleTagClick(tag)}>
                            <Checkbox
                              checked={checkedKeys.has(tag)}
                              onChange={() => {
                                const selectedKeys = new Set(
                                  selectedCheckedKeys
                                );
                                if (selectedKeys.has(tag)) {
                                  selectedKeys.delete(tag);
                                } else {
                                  selectedKeys.add(tag);
                                }
                                if (selectedKeys?.size !== tags.length) {
                                  setCheckedTagHeader(false);
                                } else {
                                  setCheckedTagHeader(true);
                                }
                                setSelectedCheckedKeys(
                                  Array.from(selectedKeys)
                                );
                              }}
                            />
                            {tag}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
            {!removeTag ? (
              <div className="device-tag-content-body">
                <div className="device-tag-content-body-header">
                  <div style={{ marginLeft: '1rem' }}>
                    {f({ id: 'COM_DMS_TAG_NAME' })}
                  </div>
                </div>
                <div className="device-content-section">
                  {(removeTag || (!removeTag && devices.length === 1)) &&
                    tags?.map((tag, idx) => (
                      <div key={idx} className="device-tag-name">
                        {tag}
                      </div>
                    ))}
                  {newTags?.map((tag, idx) => (
                    <div key={idx} className="device-new-tag-name">
                      <div>{tag}</div>
                      <div
                        className="device-cross"
                        onClick={() => removeDeviceTag(tag)}
                      >
                        x
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <>{removeTagRightContentRender()}</>
            )}
          </div>
        </div>
      </Dialog>
      {removeTag && (
        <CustomDialog
          dialogState={dialogState}
          handleClick={handleDialogAction}
          dialogVisible={dialogVisible}
          setDialogVisible={setDialogVisible}
        />
      )}
    </>
  );
};

export default DeviceTagPopup;
