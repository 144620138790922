import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  CLEAR_CHANGE_PASSWORD_ERROR,
  CLEAR_RESET_OTP_ERROR,
  CLEAR_VALIDATE_OTP_ERROR,
  DASHBOARD_HEADER_TEXT,
  GET_AUTH_USER_ERROR,
  GET_AUTH_USER_PENDING,
  GET_AUTH_USER_SUCCESS,
  IS_AUTHENTICATED_SERVER,
  IS_SMTP_ENABLED,
  RESET_INITIAL_AUTH_STATE,
  RESET_USER_ERROR,
  RESET_USER_SUCCESS,
  SIGN_OUT_FAILURE,
  SIGN_OUT_SUCCESS,
  VALIDATE_OTP_ERROR,
  VALIDATE_OTP_SUCCESS,
  RESET_AUTH_ERROR_CODE,
} from "../constants/AuthConstant";

const initialState = {
  user: {},
  loading: true,
  resetUserInfo: {},
  authErrorCode: '',
  resetErrorCode: '',
  validateOtpError: {},
  changePasswordError: {},
  validateInfo: {},
  isSignOut: false,
  isAuthenticated: false,
  changedPassword: false,
  verifiedMail: false,
  isSmtpEnabled: false,
  isMfaEnabled:false,
  dashboardText: "",
  isValidatedOTPSuccess: false,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_AUTH_USER_PENDING:
      return {
        ...state,
        loading: true,
        changedPassword: false,
        isAuthenticated: false,
        authErrorCode: payload,
        verifiedMail: false,
        resetErrorCode: '',
        validateOtpError: {},
        changePasswordError: {},
        isValidatedOTPSuccess: false,
      };
    case GET_AUTH_USER_SUCCESS:
      return {
        ...state,
        user: payload,
        loading: false,
        resetUserInfo: {},
        authErrorCode: '',
        resetErrorCode: '',
        validateOtpError: {},
        validateInfo: {},
        changePasswordError: {},
        isAuthenticated: true,
        changedPassword: false,
        errorDetails: {},
        isValidatedOTPSuccess: false,
      };
    case GET_AUTH_USER_ERROR:
      return {
        ...state,
        loading: false,
        resetUserInfo: {},
        authErrorCode: payload.errorCode,
        resetErrorCode: '',
        validateOtpError: {},
        validateInfo: {},
        changePasswordError: {},
        isAuthenticated: false,
        changedPassword: false,
        isValidatedOTPSuccess: false,
        errorDetails:payload.errorDetails
      };
    case IS_AUTHENTICATED_SERVER:
      return {
        ...state,
        loading: false,
        authErrorCode: '',
        resetErrorCode: '',
        validateOtpError: {},
        validateInfo: {},
        changePasswordError: {},
        isAuthenticated: payload,
        changedPassword: false,
        isValidatedOTPSuccess: false,
      };
    case RESET_USER_SUCCESS:
      return {
        ...state,
        user: {},
        loading: false,
        authErrorCode: '',
        validateInfo: {},
        resetErrorCode: '',
        validateOtpError: {},
        verifiedMail: true,
        changePasswordError: {},
        resetUserInfo: payload,
        changedPassword: false
      };
    case RESET_USER_ERROR:
      return {
        ...state,
        user: {},
        loading: false,
        authErrorCode: '',
        validateInfo: {},
        resetErrorCode: payload,
        validateOtpError: {},
        changePasswordError: {},
        changedPassword: false,
      };
    case VALIDATE_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        authErrorCode: '',
        validateInfo: payload,
        resetErrorCode: '',
        validateOtpError: {},
        changePasswordError: {},
        changedPassword: false,
        isValidatedOTPSuccess: true,
      };
    case VALIDATE_OTP_ERROR:
      return {
        ...state,
        loading: false,
        authErrorCode: '',
        resetErrorCode: '',
        validateOtpError: payload,
        changePasswordError: {},
        changedPassword: false,
        isValidatedOTPSuccess: false,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        authErrorCode: '',
        validateInfo: {},
        resetErrorCode: '',
        validateOtpError: {},
        changePasswordError: {},
        changedPassword: true,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        authErrorCode: '',
        resetErrorCode: '',
        validateOtpError: {},
        changePasswordError: payload,
        changedPassword: false,
      };
    case RESET_INITIAL_AUTH_STATE:
      return {
        ...initialState,
        isMfaEnabled : state.isMfaEnabled,
        isSmtpEnabled : state.isSmtpEnabled
      };
    case IS_SMTP_ENABLED:
      const {isSmtpEnabled, isMfaEnabled} = payload ?? {}
      return {
        ...state,
        isSmtpEnabled,
        isMfaEnabled
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        isSignOut: true,
      };
    case SIGN_OUT_FAILURE:
      return { ...state };
    case DASHBOARD_HEADER_TEXT:
      return { ...state, dashboardText: payload };
    case CLEAR_VALIDATE_OTP_ERROR:
      return {
        ...state,
        validateOtpError: {}
      }
    case CLEAR_RESET_OTP_ERROR:
      return {
        ...state,
       resetErrorCode: ''
      }
    case CLEAR_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordError: {}
      }
    case RESET_AUTH_ERROR_CODE:
      return{
        ...state,
        authErrorCode:''
      }
    default:
      return state;
  }
};

export default authReducer;
