import { APP_URL } from '../../config/constants/Constants';
import { RestServiceUtils } from "../../utils/RestServiceUtils";

const config = {
    headers: {
      "Content-Type": "application/json"
    }
  }

  const getAllAppList = (payload) => {
    const URL = APP_URL + "/filter";
    return RestServiceUtils.HTTP_POST(
        URL,
        payload,
        config
    )
  }

  const manageAppAction = (payload, packageName) => {
    const URL = `${APP_URL}/${packageName}`;
    return RestServiceUtils.HTTP_POST(URL,
      payload,
      config)
  }


  export const appServices = {
    getAllAppList,
    manageAppAction
  }