import axios from "axios";
import { AUTH_API_URL, LOGIN_CONFIG_API } from "../config/constants/Constants";

const headers = {
  "Content-Type": "application/json",
};

const getUserAuthService = (payload) => {
  return axios.post(AUTH_API_URL, payload, { headers });
};

const resetUserAuthService = (payload) => {
  return axios.post(`${AUTH_API_URL}/reset`, payload, { headers });
};

const validateOTPService = (payload) => {
  return axios.post(`${AUTH_API_URL}/validateOTP`, payload, { headers });
};

const changePaswordService = (payload) => {
  return axios.post(`${AUTH_API_URL}/resetPassword`, payload, { headers });
};

const loginConfigService = () => {
  return axios.get(LOGIN_CONFIG_API);
};

const signOutService = (payload) => {
  return axios.post(`${AUTH_API_URL}/signOut`, payload);
};

export {
  getUserAuthService,
  resetUserAuthService,
  validateOTPService,
  changePaswordService,
  loginConfigService,
  signOutService,
};
