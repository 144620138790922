
import SetAuthToken from "../../utils/SetAuthToken";
import {GROUP_MGMT_API} from "../../config/constants/Constants";
import { RestServiceUtils } from '../../utils/RestServiceUtils';

const headers = {
  "Content-Type": "application/json",
};

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};


const getGroupList = (groupId) => {
  const user = JSON.parse(localStorage.getItem("user"));
  SetAuthToken(user);
  return RestServiceUtils.HTTP_GET(`${GROUP_MGMT_API}/${groupId}`, {}, headers);
};

const getAllGroups = () => {
  const URL = `${GROUP_MGMT_API}`;
  return RestServiceUtils.HTTP_GET(
              URL, 
              {},  
              headers);
}

const getGroupListFilter = (searchText) => {
    const params = {searchText}
    const user = JSON.parse(localStorage.getItem("user"));
    SetAuthToken(user);
    return RestServiceUtils.HTTP_POST(GROUP_MGMT_API + "/filter", params,  { headers });
  };

  const getDMSGroups = () => {
    const URL = `${GROUP_MGMT_API}`;
    return RestServiceUtils.HTTP_GET(URL,{}, config);
  };

  const getDMSGroupDetails = () => {
    const URL = `${GROUP_MGMT_API}/groupDetails`;
    return RestServiceUtils.HTTP_GET(URL,{}, config);
};
  
const deleteSelectedGroups = (groupId) => {
  const URL = `${GROUP_MGMT_API}/${groupId}`;
  return RestServiceUtils.HTTP_DELETE(URL,{}, config);
};

const handleMoveGroups = (groupId, payload) => {
  const URL = `${GROUP_MGMT_API}/${groupId}`;
  return RestServiceUtils.HTTP_PUT(URL,payload, config);
}

const onExpandGroups = (key) => {
  const URLS = [ `${GROUP_MGMT_API}/${key}/subGroupDetails`, `${GROUP_MGMT_API}/${key}/devices` ];
  return RestServiceUtils.HTTP_ALL(URLS,{}, config);

}
export const GroupManagementService = {
  getGroupList,
  getGroupListFilter,
  getAllGroups,
  getDMSGroups,
  getDMSGroupDetails,
  deleteSelectedGroups,
  handleMoveGroups,
  onExpandGroups
}