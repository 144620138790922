import { LOCALES } from '../LOCALES';

const enUs = {
  [LOCALES.ENGLISH]: {
    COM_DMS_COPYRIGHT:
      'Copyright@2023, Samsung Electronics Co. Ltd, All Rights Reserved.',
    COM_DMS_WELCOME: 'Welcome',
    COM_DMS_TO_THE: 'to the',
    COM_DMS_S_DMS: 'Samsung Whiteboard DMS',
    COM_DMS_T_N_C: 'Terms and Conditions',
    COM_DMS_PRIVACY: 'Privacy Policy',
    COM_DMS_COOKIE: 'Cookie Policy',
    COM_DMS_CONTACT_US: 'Contact Us',
    COM_DMS_FORGOT_PWD: 'Forgot Password?',
    COM_DMS_SAMSUNG: 'Samsung',
    COM_DMS_WHITEBOARD_DMS: 'Whiteboard DMS',
    COM_DMS_PWD_RESET_ADMIN: 'Please contact admin to reset password.',
    COM_DMS_AGREEMENT_POLICY: 'Please check User Agreement and Privacy Policy.',
    COM_DMS_MAIL_TEXT:
      'Enter your user id/registered E-mail to receive a verification code to reset the password.',
    COM_DMS_INVALID_USER_ID_TEXT: 'Please enter a valid user id',
    COM_DMS_LOGIN_UNAUTHORIZED_TEXT:
      'The user Id or password you entered is incorrect.',
    COM_DMS_UNAUTHORIZED_USER_LOCKED:
      'You have entered an incorrect password multiple times. Your account has been blocked temporarily.',
    COM_DMS_USER_DATA_NOT_FOUND:
      'Unable to send verification code, please contact admin to reset password.',
    COM_DMS_INVALID_PWD_TEXT: 'Please enter a valid password',
    COM_DMS_VERIFICATION_TEXT: 'We have sent verification code to your E-mail',
    COM_DMS_USER_ID: 'User ID',
    COM_DMS_USER_NAME: 'User Name',
    COM_DMS_EMAIL_ID: 'E-mail',
    COM_DMS_MOBILE: 'Contact Number',
    COM_DMS_USER_INFO: 'My Information',
    COM_DMS_CHANGE_PWD_TXT: 'Change Password',
    COM_DMS_CURR_PWD: 'Current Password',
    COM_DMS_NEW_PWD: 'New Password',
    COM_DMS_CONFIRM_NEW_PWD: 'Confirm New Password',
    COM_DMS_DEVICE: 'Device',
    COM_DMS_APPS: 'Apps',
    COM_DMS_STATS: 'Stats',
    COM_DMS_USERS: 'Users',
    COM_DMS_SETTINGS: 'Settings',
    COM_DMS_LOGOUT: 'Logout',
    COM_DMS_GENERAL: 'General',
    COM_DMS_DEVICE_AUTO_APPROVAL: 'Device auto approval',
    COM_DMS_SIGNIN_FAILURE_ATTEMPTS: 'Number of Sign-In Failures',
    COM_DMS_PWD_POLICIES: 'Password Policies',
    COM_DMS_EXPIRE_PERIOD: 'Password Expiration Period',
    COM_DMS_ONE_MONTHS: '1 Month',
    COM_DMS_THREE_MONTHS: '3 Months',
    COM_DMS_SIX_MONTHS: '6 Months',
    COM_DMS_CUSTOM: 'Custom',
    COM_DMS_PREVENT_PWD_REUSE: 'Prevent Password Reuse',
    COM_DMS_PWD_CHANGE: 'Password Change after Login',
    COM_DMS_SMTP_SERVER: 'SMTP Server',
    COM_DMS_SERVER_ADDRESS: 'Server Address',
    COM_DMS_AUTHENTICATION: 'Authentication',
    COM_DMS_LOGIN_ID: 'Login ID',
    COM_DMS_PORT: 'Port',
    COM_DMS_SSL: 'SSL',
    COM_DMS_SIGNIN_BLOCK_DURATION: 'Sign-In Block Duration',
    COM_DMS_SMTP_ENABLED: 'Enable',
    COM_DMS_PASSWORD: 'Password',
    COM_DMS_SECURE_PWD_TEXT:
      'To make your account secure, please create a new password to replace the current password.',
    COM_DMS_NEW_PWD_TEXT: 'Enter new password',
    COM_DMS_NEW_PWD_CONFIRM_TEXT: 'Enter new password again',
    COM_DMS_CURR_PWD_TEXT: 'Enter current password',
    COM_DMS_OK_TEXT:
      'Your password is expired as per Password policies.\n Please click on Ok button to change the password',
    COM_DMS_CANCEL: 'Cancel',
    COM_DMS_SAVE: 'Save',
    COM_DMS_ADD_USER: 'Add User',
    COM_DMS_EDIT_USER: 'Edit User',
    COM_DMS_DELETE_USER: 'Delete User',
    COM_DMS_DEVICE_PERMISSION: 'Device Permissions',
    COM_DMS_ARE_YOU_SURE: 'Are you sure?',
    COM_DMS_DELETE_USER_MESSAGE:
      'Do you really want to delete the selected user(s)?',
    COM_DMS_UNDONE: 'This process cannot be undone.',
    COM_DMS_INFORMATION: 'Information',
    COM_DMS_CONTACT: 'Contact Number',
    COM_DMS_ROLE: 'Role',
    COM_DMS_CNF_PWD: 'Confirm Password',
    COM_DMS_DEFAULT_PWD: 'Default Password',
    COM_DMS_RESET_PWD: 'Reset Password',
    COM_DMS_EVENT_TYPE: 'Event Type',
    COM_DMS_EVENT_DESCRIPTION: 'Event Description',
    COM_DMS_EVENT_OCCURANCE_TIME: 'Event Occurance Time',
    COM_DMS_EVENT_IP_ADDRESS: 'IP Address',
    COM_DMS_FILTER_BY: 'Filter By',
    COM_DMS_SELECT_THE_PERIOD: 'Select The Period',
    COM_DMS_ALL: 'All',
    COM_DMS_USER: 'User',
    COM_DMS_USER_LOGIN: 'User Login',
    COM_DMS_APP: 'App',
    COM_DMS_FIRMWARE: 'Firmware',
    COM_DMS_A_WEEK_AGO: 'A Week Ago',
    COM_DMS_A_MONTH_AGO: 'A Month Ago',
    COM_DMS_THREE_MONTHS_AGO: 'Three Months Ago',
    COM_DMS_SIX_MONTHS_AGO: 'Six Months Ago',
    COM_DMS_A_YEAR_AGO: 'A Year Ago',
    COM_DMS_PWD_RESET_SUCCESS: 'Password changed successfully.',
    COM_DMS_CREATE_PASSWORD: 'Reset Password',
    COM_DMS_NEW_PASSWORD_TXT: 'Please enter a new password.',
    COM_DMS_LAST_PWD_TXT: 'It must not be your last password.',
    COM_DMS_MY_INFO: 'My Information',
    COM_DMS_SERVER_SETTINGS: 'Server Settings',
    COM_DMS_GROUP: 'Group',
    COM_DMS_PRESET: 'Preset',
    COM_DMS_CLIENT_UPDATE: 'Client Update',
    COM_DMS_ONE: '1',
    COM_DMS_TEN: '10',
    COM_DMS_HUNDRED: '100',
    COM_DMS_USER_ADMIN: 'Admin',
    COM_DMS_USER_SUB_ADMIN: 'Sub-Admin',
    COM_DMS_USER_DEVICE_MANAGER: 'Device-Manager',
    COM_DMS_USER_DEVICE_OPERATOR: 'Device-Operator',
    COM_DMS_PASSWORD_RESET_SUCCESS: 'Password reset successful',
    COM_DMS_NEW_PWD_TXT: 'Now you can use your new password to login.',
    COM_DMS_SIGN_IN: 'Sign In',
    COM_DMS_I_ACCEPT: 'I accept the',
    COM_DMS_USER_AGREEMENT: 'User Agreement',
    COM_DMS_CONFIRM_ACCEPTANCE_TEXT: 'and confirm acceptance of the',
    COM_DMS_EXPORT: 'Export',
    COM_DMS_ADD_GROUP: 'Add Group',
    COM_DMS_EDIT_GROUP: 'Edit Group',
    COM_DMS_MOVE_GROUP: 'Move Group',
    COM_DMS_MOVE: 'Move',
    COM_DMS_ADD: 'Add',
    COM_DMS_DELETE: 'Delete',
    COM_DMS_SELECT_COMMAND: 'Select Command',
    COM_DMS_SELECT_COMMANDS: 'Select Commands',
    COM_DMS_DEVICES: 'Devices',
    COM_DMS_GROUP_NAME: 'Group Name',
    COM_DMS_COMMAND: 'Command',
    COM_DMS_UNAPPROVED: 'Unapproved',
    COM_DMS_DELETE_DEVICE_MESSAGE:
      'Do you really want to delete the selected device(s)?',
    COM_DMS_SEARCH_BY_GROUP: 'Group Name',
    COM_DMS_ETHERNET_MAC: 'MAC',
    COM_DMS_IP: 'IP',
    COM_DMS_MAC_ID_VAL: 'MAC ID',
    COM_DMS_MODEL_NAME: 'Model Name',
    COM_DMS_SELECT_ALL: 'Select ALL',
    COM_DMS_TAG_INFORMATION: 'Tag Information',
    COM_DMS_CURRENT_DEVICE: 'Current Device',
    COM_DMS_SELECT_DEVICE: 'Select Device',
    COM_DMS_DEVICE_NAME: 'Device Name',
    COM_DMS_PARENT_GROUP: 'Parent Group',
    COM_DMS_SURE: 'Are you Sure?',
    COM_DMS_ENABLE_APP: 'Enable App',
    COM_DMS_DO_YOU_REALLY_WANT_TO_ENABLE: 'Do you really want to enable?',
    COM_DMS_APP_WILL_BE_ENABLED_FOR_SELECTED_DEVICE:
      'App will be enabled for selected device(s)',
    COM_DMS_DISABLE_APP: 'Disable App',
    COM_DMS_DO_YOU_REALLY_WANT_TO_DISABLE: 'Do you really want to disable?',
    COM_DMS_APP_WILL_BE_DISABLED_FOR_SELECTED_DEVICE:
      'App will be disabled in selected device(s)',
    COM_DMS_UNINSTALL_APP: 'Uninstall App',
    COM_DMS_DO_YOU_REALLY_WANT_TO_UNINSTALL: 'Do you really want to uninstall?',
    COM_DMS_APP_WILL_BE_UNINSTALLED_FOR_SELECTED_DEVICE:
      'App will be uninstalled in selected device',
    COM_DMS_DELETE_GROUP_TEXT:
      'Do you really want to delete the selected group(s)?',
    COM_DMS_DELETE_GROUP_SUB_TEXT:
      'All sub-group(s) and device(s) will be moved to the associated parent group.',
    COM_DMS_SCREEN_CAPTURE_OFF: 'Screen Preview is off',
    COM_DMS_SCREEN_CAPTURE_OFF_SUBTEXT:
      ' To check the latest screen preview, toggle the switch.',
    COM_DMS_BACK_LABEL: 'Back',
    COM_DMS_CURRENT_LOCATION: 'Current Location',
    COM_DMS_NEW_LOCATION: 'New Location',
    COM_DMS_SET_NEW_LOCATION: 'Set New Location',
    COM_DMS_SERIAL: 'Serial',
    COM_DMS_TAG: 'Tags',
    COM_DMS_TAG_NAME: 'Tag Name',
    COM_DMS_HARDWARE_INFO: 'Hardware Info',
    COM_DMS_DEVICE_TYPE: 'Device Type',
    COM_DMS_DEVICE_ID: 'Device Id',
    COM_DMS_CPU: 'CPU',
    COM_DMS_TOUCH_FRAME: 'Touch Frame',
    COM_DMS_SOFTWARE_INFO: 'Software Info',
    COM_DMS_OPERATING_SYSTEM: 'Operating System',
    COM_DMS_OS_VERSION: 'OS Version',
    COM_DMS_CLIENT_VERSION: 'Client Version',
    COM_DMS_NETWORK_INFO: 'Network Info',
    COM_DMS_WIFI: 'Wi-Fi',
    COM_DMS_NW_INTERFACE: 'N/W Interface',
    COM_DMS_TYPE: 'Type',
    COM_DMS_MAC_ADDRESS: 'MAC Address',
    COM_DMS_IP_ADDRESS: 'IP Address',
    COM_DMS_APP_INSTALL: 'App Install',
    COM_DMS_APP_INSTALLATION: 'App Installation',
    COM_DMS_APP_MANAGEMENT: 'App Management',
    COM_DMS_APP_NAME: 'App Name',
    COM_DMS_PLATFORM: 'Platform',
    COM_DMS_PACKAGE_TYPE: 'Package Type',
    COM_DMS_VERSION: 'Version',
    COM_DMS_APP_SIZE: 'App Size',
    COM_DMS_SIZE: 'Size',
    COM_DMS_UPLOAD_APP: 'Upload App',
    COM_DMS_INSTALL: 'Install',
    COM_DMS_APP_MANAGE: 'Manage App',
    COM_DMS_DEVICES_INSTALLED_COUNT: 'Installed in number of Devices',
    COM_DMS_SEARCH: 'Search',
    COM_DMS_EMAIL: 'E-mail',
    COM_DMS_DEVICE_GROUP: 'Device Group',
    COM_DMS_JOINED_DATE: 'Joined Date',
    COM_DMS_DEVICE_PERMISSIONS: 'Device Permissions',
    COM_DMS_SELECT: 'Select',
    COM_DMS_ERROR: 'Error',
    COM_DMS_ERROR_MESSAGE: 'Something went wrong. Please check with Admin.',
    COM_DMS_MANAGE_APPS: 'Manage Apps',
    COM_DMS_ASSIGN: 'Assign',
    COM_DMS_MAC_ID: 'Ethernet MAC',
    COM_DMS_DEVICE_WARNING:
      'Do you really want to delete the selected device(s)?',
    COM_DMS_REMOVE: 'Remove',
    COM_DMS_ASSIGN_TAGS: 'Assign Tags',
    COM_DMS_REMOVE_TAGS: 'Remove Tags',
    COM_DMS_QUICK_SELECT: 'Quick Control',
    COM_DMS_DETAILS_SCREEN: 'Screen',
    COM_DMS_DETAILS_APP: 'App',
    COM_DMS_DETAILS_DATA: 'Data',
    COM_DMS_VIEW: 'View',
    COM_DMS_DETAILS_SCREEN_PREVIEW: 'Screen Preview',
    COM_DMS_ON: 'On',
    COM_DMS_OFF: 'Off',
    COM_DMS_ENABLE: 'ENABLE',
    COM_DMS_DISABLE: 'DISABLE',
    COM_DMS_UNINSTALL: 'UNINSTALL',
    COM_DMS_ENABLE_SMALL_CASE: 'Enable',
    COM_DMS_DISABLE_SMALL_CASE: 'Disable',
    COM_DMS_UNINSTALL_SMALL_CASE: 'Uninstall',
    COM_DMS_THIRD_PARTY_APPS: 'Third-Party Apps',
    COM_DMS_TAGS: 'Tags',
    COM_DMS_NW_TYPE: 'Type',
    COM_DMS_MAC: 'MAC Address',
    COM_DMS_DEVICE_LOCK: 'Device Lock',
    COM_DMS_UPLOAD_CLIENT_APP: 'Upload Client App',
    COM_DMS_APK_MISMATCH_ERROR: 'Please upload a valid apk file',
    COM_DMS_SCREEN_CAPTURE: 'Screen Preview',
    COM_DMS_OS: 'OS',
    COM_DMS_SERIAL_NUMBER: 'Serial Number',
    COM_DMS_APP_ACTION: 'Enable/Disable',
    COM_DMS_NATIVE_APPS: 'Native Apps',
    COM_DMS_SCREEN_PREVIEW: 'Screen Preview',
    COM_DMS_UPDATE: 'Update',
    COM_DMS_SERVER: 'Server',
    COM_DMS_DISCARD: 'Discard',
    COM_DMS_USER_ID_NAME_TEXT: 'User ID/Name',
    COM_DMS_COPY_PASSWORD: 'Copy Password',
    COM_DMS_USER_ID_TXT: 'Enter User ID',
    COM_DMS_USER_NAME_TXT: 'Enter User Name',
    COM_DMS_EMAIL_TXT: 'Enter E-mail',
    COM_DMS_CONTACT_NO_TXT: 'Enter Contact Number',
    COM_DMS_SELECT_ROLE_TXT: 'Select a role',
    COM_DMS_DELETE_USER_TXT: 'Delete User',
    COM_DMS_FAILURE_COUNT: 'Invalid sign in failure count.',
    COM_DMS_BLOCK_DURATION: 'Invalid sign in block duration.',
    COM_DMS_PWD_EXPIRE: 'Invalid password expiration period.',
    COM_DMS_DEFAULT_PWD_INVALID: 'Invalid default password.',
    COM_DMS_ADDRESS_PORT_MANDATORY:
      'Server address and port are mandatory if SMTP is enabled.',
    COM_DMS_LOGINID_PWD_MANDATORY:
      'Login id and password are mandatory if authentication is enabled.',
    COM_DMS_LOGINID_MAX_LENGTH: 'Login id can be upto 200 characters.',
    COM_DMS_PWD_MAX_LENGTH: 'Password can be upto 200 characters.',
    COM_DMS_INVALID_PORT: 'Invalid port number.',
    COM_DMS_USERNAME_EMPTY: 'User name cannot be empty.',
    COM_DMS_INVALID_CONTACT_NUMBER: 'Invalid contact number.',
    COM_DMS_GENERATE_PASSWORD: 'Generate Password',
    COM_DMS_PASSWORD_EMPTY_TEXT: 'Password cannot be empty',
    COM_DMS_INVALID_PASSWORD: 'Password not meeting the defined criteria',
    COM_DMS_INVALID_NEW_PASSWORD:
      'New password not meeting the defined criteria',
    COM_DMS_INVALID_CONFIRM_PASSWORD:
      'Confirm password not meeting the criteria',
    COM_DMS_PASSWORD_MISMATCH:
      'New password and Confirm new password do not match',
    COM_DMS_RESET_PASSWORD_BLOCK:
      'Reset password is blocked. Please retry after sometime.',
    COM_DMS_ERROR_MAIL_SENDING: 'Failed to send the mail.',
    COM_DMS_PASSWORD_SAME: 'New password cannot be the same as old password.',
    COM_DMS_INVALID_OTP: 'Invalid OTP. Please retry',
    COM_DMS_POWER: 'Power',
    COM_DMS_INPUT: 'Input',
    COM_DMS_LOCK: 'Lock',
    COM_DMS_UNLOCK: 'Unlock',
    COM_DMS_OK: 'Ok',
    COM_DMS_REBOOT: 'Reboot',
    COM_DMS_SETTINGS_LOCK: 'Settings Lock',
    COM_DMS_VERIFICATION_CODE: 'Verification Code',
    COM_DMS_BACK_TO_LOGIN: 'Back to Login',
    COM_DMS_RESEND_CODE: 'Resend Code',
    COM_DMS_TIME_REMAINING: 'Time Remaining',
    COM_DMS_TARGET_DEVICE: 'Target Device',
    COM_DMS_CONTINUE: 'Continue',
    COM_DMS_VERIFY_CODE_TEXT: 'Enter verify code',
    COM_DMS_DEVICE_SELECTED: 'Device Selected',
    COM_DMS_GROUP_NAME_TEXT: 'Enter Group Name',
    COM_DMS_DELETE_GROUP: 'Delete Group',
    COM_DMS_DEVICE_INFO: 'Device Info',
    COM_DMS_INPUT_SOURCE: 'Input Source',
    COM_DMS_SAVE_CHANGE: 'Save Change',
    COM_DMS_CLOSE: 'Close',
    COM_DMS_SAVE_CHANGES: 'Save Changes',
    COM_DMS_DISCARD_CHANGES: 'Discard Changes',
    COM_DMS_NEW_USER_TEXT: 'New user added',
    COM_DMS_CREATE_PASSWORD_TXT: 'Create Password',
    COM_DMS_PASSWORD_COPIED_TO_CLIPBOARD: 'Password copied to clipboard',
    COM_DMS_SELECTED_USERS_DELETE_TXT: 'Selected user(s) are deleted',
    COM_DMS_CHANGES_BEEN_SAVED: 'Your changes are saved',
    COM_DMS_SAVE_SYNC: 'Save & Sync',
    COM_DMS_SAVE_SYNC_MSG:
      'Do you really want to update changes you have made?',
    COM_DMS_YES: 'Yes',
    COM_DMS_NO: 'No',
    COM_DMS_STATUS: 'Status',
    COM_DMS_INVALID_URL: 'Invalid URL.',
    COM_DMS_URL_ALREADY_PRESENT: 'URL already present.',
    COM_DMS_ALLOW: 'ALLOW',
    COM_DMS_BLOCK: 'BLOCK',
    COM_DMS_RESET: 'RESET',
    COM_DMS_URL_PERMISSIONS: 'URL Permissions',
    COM_DMS_SAVE_AND_SYNC: 'Save and sync',
    COM_DMS_URLS: "URL's",
    COM_DMS_EMPTY_ALLOW_LIST_MSG: 'No URL in the allowed list',
    COM_DMS_EMPTY_BLOCK_LIST_MSG: 'No URL in the blocked list',
    COM_DMS_SECURITY: 'Security',
    COM_DMS_USER_ID_EMAIL: 'User ID / E-mail',
    COM_DMS_ADD_DEVICE_TEXT: 'Please add device',
    COM_DMS_INVALID_CREDENTIALS: 'Invalid Login id and password',
    COM_DMS_INVALID_IP_ADDRESS: 'Invalid IP address.',
    COM_DMS_INVALID_USER_ID: 'Invalid user id.',
    COM_DMS_INVALID_USER_NAME: 'Invalid user name.',
    COM_DMS_INVALID_EMAIL: 'Invalid E-mail.',
    COM_DMS_INVALID_PASSWORD_LENGTH:
      'Password must be of minimum 8 characters.',
    COM_DMS_CONFIRM_PASSWORD_INVALID: 'Invalid confirm password.',
    COM_DMS_USER_ID_CONTAIN_ALPHA_NUM_PERIODS_AT:
      'User id can only contain alphanumeric characters, periods(.) and at(@).',
    COM_DMS_CONSECUTIVE_SPACES_NOT_ALLOWED: 'Consecutive spaces not allowed.',
    COM_DMS_USER_ID_MAX_LENGTH_EXCEEDED:
      'User id must be less than 60 characters.',
    COM_DMS_USER_NAME_MAX_LENGTH_EXCEEDED:
      'User name must be less than 60 characters.',
    COM_DMS_INVALID_PASSWORD_CONTAIN_SPACE: 'Password cannot contain spaces.',
    COM_DMS_CONTACT_NUMBER_MAX_LENGTH_EXCEEDED:
      'Contact Number must be less than 15 characters.',
    COM_DMS_PASSWORD_CONFIRM_PASSWORD_NOT_MATCHING:
      'Password and confirm password do not match',
    COM_DMS_INVALID_DEVICE_ID: 'Invalid device id.',
    COM_DMS_DEVICE_NAME_MAX_LENGTH_EXCEEDED:
      'Device name must be less than 50 characters.',
    COM_DMS_INVALID_DEVICE_NAME: 'Invalid device name.',
    COM_DMS_DEVICE_NAME_CONTAIN_ALPHA_NUM_PERIODS_HYPHEN_UNDERSCORE_SPACE:
      'Device name can only contain alphanumeric characters, periods(.), hyphen(-) underscore(_) and space(s).',
    COM_DMS_DEVICE_TAG_MAX_LENGTH_EXCEEDED:
      'Device Tag must be less than 15 characters.',
    COM_DMS_INVALID_DEVICE_TAG: 'Invalid Tag.',
    COM_DMS_DEVICE_TAG_CONTAIN_ALPHA_NUM_PERIODS_HYPHEN_UNDERSCORE:
      'Device tag can only contain alphanumeric characters, periods(.), hyphen(-) and underscore(_).',
    COM_DMS_TAG_ALREADY_ADDED: 'Tag already added',
    COM_DMS_APP_UNINSTALL_MESSAGE:
      'App uninstall command sent to selected device(s).',
    COM_DMS_APP_ENABLE_MESSAGE:
      'App enable command sent to selected device(s).',
    COM_DMS_APP_DISABLE_MESSAGE:
      'App disable command sent to selected device(s).',
    COM_DMS_REMOVE_SELECTED_TAG: 'Remove selected tag',
    COM_DMS_REMOVE_TAG_BODY: 'Do you really want to remove?',
    COM_DMS_ASSIGNED_TAG_MESSAGE: 'Tags assigned to selected device(s)',
    COM_DMS_REMOVED_TAG_MESSAGE: 'Tags removed from selected device(s)',
    COM_DMS_LAST_COMMAND_MESSAGE: 'Command sent to selected device(s)',
    COM_DMS_NO_RESULT: 'No device(s) found',
    COM_DMS_DEVICE_SUBGROUP_TOOLTIP_TEXT:
      'The device(s) in the groups & sub groups will automatically execute the commands that are associated.',
    COM_DMS_DEVICE_ALL_TOOLTIP_TEXT:
      'Selected device(s) will automatically execute the commands that are associated.',
    COM_DMS_APPLY: 'Apply',
    COM_DMS_RESET_TEXT: 'Reset',
    COM_DMS_COMMAND_SENT_SUCCESSFULLY: 'Command sent successfully',
    COM_DMS_RESET_URL_PERMISSION_TEXT:
      'Do you really want to reset URL permission for this device?',
    COM_DMS_APP_UPLOAD_SUCCESS_MESSAGE: 'App uploaded successfully',
    COM_DMS_CLIENT_APP_UPLOAD_SUCCESS_MESSAGE:
      'Client app uploaded successfully',
    COM_DMS_FAILURE_COUNT_DECIMAL:
      'Sign in failure count cannot be a decimal value.',
    COM_DMS_BLOCK_DURATION_DECIMAL:
      'Sign in block duration cannot be a decimal value.',
    COM_DMS_PWD_EXPIRE_DECIMAL:
      'Password expire period cannot be a decimal value.',
    COM_DMS_DEVICE_DELETE_SUCCESSFUL: 'Device(s) deleted successfully',
    COM_DMS_CLIENT_APP_UPDATE_COMMAND_SENT:
      'Client app update command sent successfully',
    COM_DMS_APP_INSTALL_MESSAGE: 'App install command sent successfully',
    COM_DMS_NO_APPS_TO_INSTALL: 'No app(s) to install.',
    COM_DMS_NO_APPS_TO_MANAGE: 'No app(s) to manage.',
    COM_DMS_NO_HISTORY_RECORDS: 'No history records found.',
    COM_DMS_NO_CLIENT_APP_TO_UPDATE: 'No client app to update.',
    COM_DMS_SELECT_TARGET_DEVICE: 'Please select target device !',
    COM_DMS_GROUP_NAME_CONTAIN_ALPHA_NUM_PERIODS_HYPHEN_UNDERSCORE_SPACE:
      'Group name can only contain alphanumeric characters, periods(.), hyphen(-) underscore(_) and space(s).',
    COM_DMS_INVALID_GROUP_NAME: 'Invalid group name',
    COM_DMS_GROUP_NAME_MAX_LENGTH_EXCEEDED:
      'Group name must be less than 50 characters.',
    COM_DMS_DEFAULT_GROUP: 'Default Group',
    COM_DMS_DEFAULT_GROUP_UNIQUE_NAME_INVALID:
      'A group name similar to "Default Group" is not allowed.',
    COM_DMS_NO_PREVIEW: 'No Preview',
    COM_DMS_SCREEN_PREVIEW_AWAITED: 'Screen preview awaited',
    COM_DMS_POWER_OFF: 'Power Off',
    COM_DMS_DEVICE_DETAILS_TOOLTIP_TEXT:
      'The device will automatically execute the commands that is associated.',
    COM_DMS_GROUP_DELETE_SUCCESSFUL: 'Group deleted successfully',
    COM_DMS_GROUP_ADD_SUCCESSFUL: 'Group added successfully',
    COM_DMS_GROUP_MOVE_SUCCESSFUL: 'Group moved successfully',
    COM_DMS_GROUP_EDITED_SUCCESSFUL: 'Group edited successfully',
    COM_DMS_DELETE_APP_MESSAGE:
      'Do you really want to delete the selected app?',
    COM_DMS_MAIL_NOT_CONFIGURE_TEXT: 'Mail server not configured',
    COM_DMS_MAKE_TOP_LEVEL_GROUP: 'Make Top Level Group',
    COM_DMS_PLACEHOLDER_ADD_TAG: 'Add Tag',
    COM_DMS_PLACEHOLDER_SEARCH_TAG: 'Search Tag',
    COM_DMS_PLACEHOLDER_USER_ID_NAME: 'User ID / Name',
    COM_DMS_DASHBOARD: 'Dashboard',
    COM_DMS_OLD_VERSION: 'Old Version',
    COM_DMS_DEVICE_FIRMWARE: 'Device Firmware',
    COM_DMS_ERROR_WARNING_DEVICES: 'Errors / Warnings Devices',
    COM_DMS_UNAUTHORIZED_ACCESS_ATTEMPTED: 'Unauthorized Access Attempted',
    COM_DMS_TREND_OF_DEVICE_ENROLLMENT: 'Trend of Device Enrollment',
    COM_DMS_MOST_USED_APPS: 'Most Used Apps',
    COM_DMS_RECENT_INSTALLED_APPS: 'Recently Installed Apps',
    COM_DMS_ENROLLED_DEVICES: 'Enrolled Devices',
    COM_DMS_ACTIVE_DEVICES: 'Active Devices',
    COM_DMS_REGISTERED_USERS: 'Registered Users',
    COM_DMS_USING: 'Using',
    COM_DMS_SCREEN_ON_TIME_BEYOND_LIMIT: 'Screen ON Time Beyond Limit',
    COM_DMS_HIGH_STORAGE_RAM_USAGE: 'High Storage and Ram Usage',
    COM_DMS_USER_ADDED_SUCCESS_MESSAGE: 'User Added Successfully',
    COM_DMS_APP_DELETE_MESSAGE: 'App Deleted Successfully',
    COM_DMS_FIRMWARE_UPDATE: 'Firmware Update',
    COM_DMS_UPLOAD_FIRMWARE: 'Upload Firmware',
    COM_DMS_NO_FIRMWARE_TO_INSTALL: 'No firmware to install',
    COM_DMS_FIRMWARE_VERSION: 'Firmware Name',
    COM_DMS_ACTION: 'Action',
    COM_DMS_FIRMWARE_SIZE: 'Firmware Size',
    COM_DMS_FIRMWARE_VERSION_NAME: 'Firmware Name',
    COM_DMS_BROWSE_FILE: 'Browse File',
    COM_DMS_UPLOAD: 'Upload',
    COM_DMS_CHOOSE_FILE: 'Choose File',
    COM_DMS_MESSAGE_OR_ALERT: 'Message / Alert',
    COM_DMS_LATEST_VERSION: 'Latest Version',
    COM_DMS_CLEAR: 'Clear',
    COM_DMS_INSTALLATION: 'Installation',
    COM_DMS_INSTALLATIONS: 'Installations',
    COM_DMS_REGISTER_OTP_TWO_STEP_VERIFICATION:
      'Register OTP Two-Step Verification',
    COM_DMS_TWO_STEP_VERIFICATION_OTP: 'Two-Step Verification (OTP)',
    COM_DMS_OTP_CODE: 'OTP Code',
    COM_DMS_ENTER_OTP_CODE: 'Enter the OTP Code',
    COM_DMS_ENTER_CODE: 'Enter Code',
    COM_DMS_ENTER_THE_OTP_CODE_AFTER_CHECKING_ON_YOUR_MOBILE_DEVICE:
      'Enter the OTP Code after checking the OTP on your mobile device.',
    COM_DMS_RESEND_OTP: 'Resend Code',
    COM_DMS_OTP_REGISTRATION_COMPLETE:
      'OTP registration is complete.Use the authenticator app to sign in to Samsung Whiteboard DMS.',
    COM_DMS_OTP_REGISTRATION_STEP_ONE:
      '1. Install an OTP authenticator app from Google Play Store or Apple Store. After installation , click the Next button.',
    COM_DMS_OTP_REGISTRATION_STEP_TWO:
      '2. Open the OTP authenticator app and select the Register button',
    COM_DMS_OTP_REGISTRATION_STEP_THREE:
      '3. Scan QR code.If you are unable to scan , manually enter the key below.',
    COM_DMS_OTP_REGISTRATION_STEP_FOUR:
      '4. Enter the 6-digit code displayed in the app.',
    COM_DMS_OTP_AUTHENTICATOR_OPTIONS:
      'Here are some popular authenticator app options:',
    COM_DMS_OTP_AUTHENTICATOR: 'Authy',
    COM_DMS_OTP_AUTHENTICATOR_GOOGLE: 'Google Authenticator',
    COM_DMS_OTP_AUTHENTICATOR_MICROSOFT: 'Microsoft Authenticator',
    COM_DMS_TEMPLATE_NAME: 'Template Name',
    COM_DMS_ADD_TO_TEMPLATE: 'Add To Template',
    COM_DMS_SEND: 'Send',
    COM_DMS_MESSAGE_TEXT: 'Enter Message',
    COM_DMS_ALERT_MESSAGE_TEXT: 'Enter Alert Message',
    COM_DMS_POSITION: 'Position',
    COM_DMS_FONT_SIZE: 'Font Size',
    COM_DMS_FONT_COLOR: 'Font Color',
    COM_DMS_BACKGROUND_COLOR: 'Background Color',
    COM_DMS_TEMPLATE_LIST: 'Template List',
    COM_DMS_TARGET_GROUP: 'Target Group',
    COM_DMS_SAVE_AS: 'Save As',
    COM_DMS_COMMON_ALERT_IMAGE: 'Common Alert Image',
    COM_DMS_UPLOAD_IMAGE: 'Upload Image',
    COM_DMS_DELETE_TEMPLATE: 'Delete Template',
    COM_DMS_DELETE_TEMPLATE_TEXT:
      'Do you really want to delete this template ?',
    COM_DMS_SEND_MESSAGE: 'Send Message',
    COM_DMS_SEND_ALERT: 'Send Alert',
    COM_DMS_END_TIME: 'End Time',
    COM_DMS_RECALL_ALERT: 'Recall Alert',
    COM_DMS_RECALL_ALERT_TEXT: 'Do you really want to recall this alert ?',
    COM_DMS_ONE_DAY: 'One Day',
    COM_DMS_TWO_DAYS: 'Two Days',
    COM_DMS_ACTIVE: 'Active',
    COM_DMS_RECALL: 'Recall',
    COM_DMS_INACTIVE: 'Inactive',
    COM_DMS_RECALLED: 'Recalled',
    COM_DMS_DELETE_FIRMWARE: 'Delete Firmware',
    COM_DMS_DELETE_FIRMWARE_TEXT:
      'Do you really want to delete this firmware ?',
    COM_DMS_NO_FIRMWARE_UPDATE: 'No firmware to update.',
    COM_DMS_FW_UPDATE_SUCCESS_MSG: 'Firmware Update has been sent',
    COM_DMS_MESSAGE_SENT_TEXT: 'Message has been sent',
    COM_DMS_ALERT_SENT_TEXT: 'Alert has been sent',
    COM_DMS_TEMPLATE_EXISTS_MSG: 'Template Name already exist',
    COM_DMS_TWO_FACTOR_VERIFICATION: 'Two Factor Verification (OTP)',
    COM_DMS_TWO_FACTOR_AUTH_TXT:
      'Uses two-step verification via one-time password (OTP) at login.',
    COM_DMS_RESET_MFA_INFO: 'Reset MFA Info',
    COM_DMS_INCORRECT_OTP_TEXT: 'Incorrect OTP. Please retry!',
    COM_DMS_ACCOUNT_BLOCK_MSG: 'Account locked. Please try after some time.',
    COM_DMS_TARGET_GROUP_MSG: 'Please select target Group.',
    COM_DMS_TARGET_DEVICE_MSG: 'Please select target Device.',
    COM_DMS_EMPTY_TEMPLATE_NAME_MSG: 'Template Name sould not be empty',
    COM_DMS_SMTP_DISABLED_MSG: 'SMTP not configured or Invalid E-mail',
    COM_DMS_DEVICE_NAME_NULL: "Device name can't be empty",
    COM_DMS_PLACEHOLDER_SEARCH_OR_ADD_TAG: 'Search Tag or Add New',
    COM_DMS_EMPTY_FIRMWARE_NAME_MSG: 'Firmware Name should not be empty',
    COM_DMS_SELECT_FIRMWARE_FILE_MSG: 'Select Firmware file',
    COM_DMS_NEW_FIRMWARE_UPLOADED: 'New Firmware Uploaded',
    COM_DMS_NEW_TEMPLATE_TXT: 'New Template Added',
    COM_DMS_TEMPLATE_SAVE_SUCCESS: 'Template Saved Successfully',
    COM_DMS_MAX_RESOLUTION_TEXT: 'Max Resolution : 1280 X 720, Max Size : 3 MB',
    COM_DMS_ALERT_NAME: 'Alert Name',
    COM_DMS_SENT_ON: 'Sent On',
    COM_DMS_ALERT_RECALL_ERROR_MSG: "Alert can't be recalled",
    COM_DMS_ALERT_RECALL_SUCCESS_MSG: 'Selected alert has been recalled.',
    COM_DMS_BY_GROUP: 'By Group',
    COM_DMS_BY_DEVICE: 'By Device',
  },
};

export default enUs;
