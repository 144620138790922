import React, { useState, useEffect, useRef } from "react";
import "./Unapproved.css";
import { Button } from "primereact/button";
import DataTableComponent from "../../../components/DataTable/Datatable";
import { Paginator } from "primereact/paginator";
import { useIntl } from "react-intl";
import Moment from "moment";
import { SelectGroup } from "../../../components/SelectGroupPopup/SelectGroup";
import { deviceServices } from "../../../services/RestServices/deviceServices";
import { showSuccessToast } from "../../../services/APIResponseHandler";
import SearchBar from "../../../components/SearchBar/SearchBar";
import { setToastComponent } from '../../../services/APIResponseHandler';
import export_icon from '../../../assets/images/exportIcon.svg';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';
import Spinner from '../../../components/Spinner/Spinner';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';

const Unapproved = ({ refreshPage }) => {
  const { formatMessage: f } = useIntl();
  const [selectedItems, setSelectedItems] = useState([]);
  const [deviceToBeDeleted, setDeviceToBeDeleted] = useState([]);
  const [isApproveDeviceClicked, setApproveDeviceClicked] = useState(true);
  const [AddApproveDeviceVisible, setApproveDeviceVisible] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [deviceDataPayload, setDeviceDataPayload] = useState({
    startIndex: 0,
    pageSize: 10,
    searchText: "",
    isEnrolled: false,
    groupId: "",
    tagIds: [],
    inputSources: [],
    connectionStatus: ""
  });
  const [devicesCount, setDevicesCount] = useState({});
  const [searchText, setSearchText] = useState("");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [showExtraTagsDeviceId, setShowExtraTagsDeviceId] = useState('');
  const [loading, setLoading] = useState(false);

  const [deleteDialogState, setDeleteDialogState] = useState({
    header: f({ id: "COM_DMS_ARE_YOU_SURE" }),
    body: f({ id: "COM_DMS_DELETE_DEVICE_MESSAGE" }),
    footer: f({ id: "COM_DMS_UNDONE" }),
    action: f({ id: "COM_DMS_DELETE" }),
    cancel: f({ id: "COM_DMS_CANCEL" })
  });
  const [dialogVisible, setDialogVisible] = useState(false);

  const toastRef = useRef(null);

  useEffect(() => {
    setToastComponent(toastRef.current);
  }, []);

  const handleEventExport = async () => {
    let excelExportRequestBody = deviceDataPayload;
    excelExportRequestBody.pageSize = -1;
    excelExportRequestBody.startIndex = -1;
    setLoading(true);
    await deviceServices.exportDevices(excelExportRequestBody)
    .then((res) => {
        let fileName = res.headers.get('content-disposition');
        if (fileName) {
            fileName = fileName.replace(/"/gi, '');
            if (fileName && fileName.length > 0) {
                let fileNames = fileName.split(';');
                if (fileNames.length > 0) {
                    fileNames = fileNames[1].split('=');
                    if (fileNames.length > 0) {
                        fileName = fileNames[1];
                    }
                }
            }
        }
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        console.log(e);
      });
  };

  const getDeviceList = async() => {
    setLoading(true);
    await deviceServices.getAllDeviceList(deviceDataPayload).then(res => {
      const { items, totalCount } = res?.data ?? {};
      setDeviceList(items);
      setDevicesCount(totalCount);
    });
    setLoading(false);
    setSelectedItems([]);
  };

  useEffect(() => {
    getDeviceList();
  }, [deviceDataPayload, refreshPage]);

  const onPageChange = event => {
    setFirst(event.first);
    setRows(event.rows);
    setDeviceDataPayload({
      ...deviceDataPayload,
      startIndex: event.first,
      pageSize: event.rows
    });
  };

  const headerInfo = [
    { field: "deviceName", header: "Device Name" },
    { field: "deviceId", header: "Ethernet MAC" },
    { field: "ipAddress", header: "IP" },
    { field: "deviceModelName", header: "Model Name" },
    { field: "deviceSerial", header: "Serial" },
    { field: "tags", header: "Tags" },
    { field: "registerDate", header: "Registered Date" }
  ];

  const onDeleteDeviceClick = e => {
      setDialogVisible(true);
  };

  const onApproveDeviceClick = e => {
    e.preventDefault();
    setApproveDeviceClicked(!isApproveDeviceClicked);
    setApproveDeviceVisible(!AddApproveDeviceVisible);
  };

  const searchDeviceHandler = () => {
    setDeviceDataPayload({ ...deviceDataPayload, searchText: searchText });
  };

  const handleInputKeyDown = event => {
    if (event.key === "Enter") {
      searchDeviceHandler();
    }
  };

  const deleteDevicesHandler = async () => {
    await Promise.all([deviceServices.deleteDevices(deviceToBeDeleted)
        .then(
          (res) =>
            res?.status === 200 &&
            showSuccessToast(f({id: 'COM_DMS_DEVICE_DELETE_SUCCESSFUL'}))
        ),
    ]);
    getDeviceList();
    setDialogVisible(false);
  };

  function DropdownButton(extraTags, rowData) {
    return (
      <div className="floating-window-container" onMouseEnter={() => setShowExtraTagsDeviceId(rowData.deviceId)} onMouseLeave={() => setShowExtraTagsDeviceId('')}>
        {extraTags?.map(tag => (
          <div className='tagItem'>
            {tag}
          </div>
        ))}
      </div>
    );
  }

  const deviceTagsTemplate = (rowData) => {
    let tags = rowData?.tags;
    const [t0, t1, ...restTags] = tags ?? [];
    const tagsLen = tags?.length;
    return(
      tags && tagsLen > 2 ? (
      <div style={{display:'flex'}}>
          <Tag className="setupDeviceTags" value={t0} rounded/>
          <Tag className="setupDeviceTags" value={t1} rounded/>
          <div>
            <div onMouseEnter={() => setShowExtraTagsDeviceId(rowData.deviceId)} onMouseLeave={() => setShowExtraTagsDeviceId('')}>
              <Tag className="setupDeviceTags extraTags" value={`+${tagsLen-2}`} rounded/>
            </div>
            {showExtraTagsDeviceId === rowData.deviceId && DropdownButton(restTags, rowData)}
          </div>
      </div>):(
        <div>
        {tags && tags?.length > 0 && tags?.map((tag) => 
            <Tag className="setupDeviceTags" value={tag} rounded/>)}
    </div>
      ));
  };


  const registeredDateTemplate = rowData => {
    let registerDate = rowData.registerDate;
    return (
      Moment(registerDate).format('YYYY-MM-DD hh:mm')
    )
  };


  useEffect(() => {
    let deviceIds = [];
    if (selectedItems !== undefined) {
      for (let i = 0; i < selectedItems.length; i++) {
        deviceIds.push(selectedItems[i].deviceId);
      }
      setDeviceToBeDeleted(deviceIds);
    }
  }, [selectedItems]);

  const SEARCH_BAR_PLACEHOLDER = `${f({ id: "COM_DMS_DEVICE_NAME" })}, ${f({ id: "COM_DMS_ETHERNET_MAC" })}, ${f({ id: "COM_DMS_IP" })}, ${f({ id: "COM_DMS_MODEL_NAME" })}, ${f({ id: "COM_DMS_SERIAL" })}`

  return (
    <>
       {loading ? <Spinner /> : ''}
      <Toast ref={toastRef} position="top-center" />
      <div className="card-users">
      <div className="btn-users-all">
        <Button
          className="device-pg-btn"
          label="Approve"
          onClick={onApproveDeviceClick}
          size="small"
          disabled={selectedItems !== undefined && selectedItems.length === 0}
        />
        <Button
          className="device-pg-btn"
          label="Delete"
          size="small"
          onClick={onDeleteDeviceClick}
          disabled={selectedItems !== undefined && selectedItems.length === 0}
        />
        <div className="all_unapproved_device_Search">
          <SearchBar
            placeholder={SEARCH_BAR_PLACEHOLDER}
            searchHandler={searchDeviceHandler}
            value={searchText}
            setValue={setSearchText}
            handleInputKeyDown={handleInputKeyDown}
          />
        </div>
      </div>
      <div className="export-btn-row">
      <Button
          className="export-btn-unapproved"
          label="Export"
          onClick={handleEventExport}
          size="small"
        >  <img src={export_icon} alt="" className="exportIconStyle" /> </Button>
      </div>
      <DataTableComponent
        className="unapproved-table"
        data={deviceList}
        headerInfo={headerInfo}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        deviceTagsTemplate={deviceTagsTemplate}
        registeredDateTemplate={registeredDateTemplate}
      />
      <div className="custom-paginator">
        <Paginator
          first={first}
          rows={rows}
          totalRecords={devicesCount}
          onPageChange={onPageChange}
          rowsPerPageOptions={[10, 20, 30, 50]}
        />
      </div>
      {AddApproveDeviceVisible && (
        <SelectGroup
          setVisible={setApproveDeviceVisible}
          visible={AddApproveDeviceVisible}
          setDeviceList={setDeviceList}
          setDevicesCount={setDevicesCount}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          deviceToBeDeleted={deviceToBeDeleted}
          deviceDataPayload={deviceDataPayload}
        />
      )}
      {dialogVisible && (
         <CustomDialog
         dialogState={deleteDialogState}
         handleClick={deleteDevicesHandler}
         dialogVisible={dialogVisible}
         setDialogVisible={setDialogVisible}/>
      )}
    </div>
    </>
  );
};

export default Unapproved;
