import React, { useEffect, useRef, useState } from 'react';
import './SamsungDMSHeader.css';
import UserLogout from '../../modules/UserLogout/UserLogout';
import { resetAuthState, signOut } from '../../redux/actions/AuthAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { LOGIN_PATH } from '../../router/RouteConstants';
import UpArrowIcon from '../../assets/images/upArrow.svg';
import DownArrowIcon from '../../assets/images/downArrow.svg';
import { useIntl } from 'react-intl';
import { resetServerSettings } from '../../redux/actions/ServerSettingsAction';

const SamsungDMSHeader = ({ text, isLogin, handleSettings, currentActive }) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const [toggleUser, setToggleUser] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isSignOut } = useSelector((state) => state.auth);
  const { userInfo } = useSelector((state) => state.user);
  const { loginId, needPasswordReset } = userInfo ?? {};
  const { serverSettingsInfo, changedPassword } = useSelector(
    (state) => state.serverSettings
  );
  const { passwordSettings } = serverSettingsInfo ?? {};
  const { changeAfterLogin } = passwordSettings ?? {};
  const USERS = f({ id: 'COM_DMS_USERS' });
  const DASHBOARD = f({ id: 'COM_DMS_DASHBOARD' });

  const user = JSON.parse(localStorage.getItem('user'));
  const { refreshToken } = user ?? {};

  const toggleRef = useRef(null);
  const [toggleArrow, setToggleArrow] = useState(false);

  useEffect(() => {
    const toggleUserClick = (e) => {
      if (!toggleRef.current?.contains(e.target)) {
        setToggleUser(false);
      } else {
        setToggleUser(!toggleUser);
        setToggleArrow(!toggleArrow);
      }
    };
    document.addEventListener('click', toggleUserClick);
    return () => {
      document.removeEventListener('click', toggleUserClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleArrow]);

  useEffect(() => {
    if (isSignOut) {
      localStorage.removeItem('user');
      navigate(LOGIN_PATH);
      dispatch(resetAuthState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignOut]);

  const logoutUser = (e) => {
    e.stopPropagation();
    dispatch(signOut({ refreshToken }));
    dispatch(resetServerSettings());
    localStorage.clear();
  };

  return (
    <div className="dms-header">
      <div className="dms-header-logo">
        <span className="dms-header-logo-text">
          {f({ id: 'COM_DMS_SAMSUNG' })}
          <div>{f({ id: 'COM_DMS_WHITEBOARD_DMS' })}</div>
        </span>
      </div>
      {text && !(needPasswordReset && changeAfterLogin) && (
        <div className="header_partition"></div>
      )}
      {!(needPasswordReset && changeAfterLogin && !changedPassword) &&
        currentActive !== undefined && (
          <div className="dms-header-text">
            {currentActive === USERS || currentActive === DASHBOARD
              ? currentActive
              : `${currentActive} > ${text}`}
          </div>
        )}
      {!(needPasswordReset && changeAfterLogin && !changedPassword) &&
        isLogin && (
          <div className="dms-right-side-icon">
            <div className="dms-login-id">{loginId}</div>
            <div
              className="dms-right-arrow-toggle"
              ref={toggleRef}
              onClick={() => setToggleArrow(!toggleArrow)}
            >
              {!toggleUser ? (
                <img
                  src={DownArrowIcon}
                  alt="down"
                  className="dms-header-icon"
                />
              ) : (
                <img src={UpArrowIcon} alt="up" className="dms-header-icon" />
              )}
              {toggleUser && (
                <UserLogout
                  logoutUser={logoutUser}
                  handleSettings={() => {
                    handleSettings();
                    setToggleUser(!toggleUser);
                  }}
                />
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default SamsungDMSHeader;
