export const DEFAULT_START_DATE = "01 Jan 1970";
export const USER_APPS = "User";
export const NATIVE_APPS = "Native";
export const DEVICE_OPERATOR = 'Device-Operator';
export const DEVICE_MANAGER = 'Device-Manager';
export const ADMIN = 'Admin';
export const SUB_ADMIN = 'Sub-Admin';
export const MIN_SIGN_IN_FAIL_COUNT = 1;
export const MIN_SIGN_IN_BLOCK_DURATION = 1;
export const PASSWORD_EXPIRE_MIN_VALUE = 1;
export const MAX_SIGN_IN_FAIL_COUNT = 10;
export const MAX_SIGN_IN_BLOCK_DURATION = 100;
export const PASSWORD_EXPIRE_MAX_VALUE = 100;
export const DEFAULT_PASSWORD_MAX_LENGTH = 20;
export const USERNAME_MAX_LENGTH = 60;
export const SMTP_ID_MAX_LENGTH = 200;
export const SMTP_PASSWORD_MAX_LENGTH = 200;
export const SMTP_PORT_MIN_VALUE = 1 ;
export const SMTP_PORT_MAX_VALUE = 65535;