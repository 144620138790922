import React from 'react';
import { privacyPolicyContent } from '../../../assets/documents/privacyPolicy';

const UserAgreement = () => {

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: privacyPolicyContent }} />
    </>
  )
}

export default UserAgreement;