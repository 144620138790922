export const API_KEY = 'api_key';
export const SPECIAL_CHAR_REGEX = /[$&+,:;=?@#|'<>.^*()%!-]/;
export const EMAIL_REGEX = /^[\w-']+(\.[\w-']+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
export const NUMBER_REGEX =/^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/;
export const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
export const MAC_ADDRESS_REGEX = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$/;
export const DOMAIN_NAME_FROM_URL = /^(?:https?:\/\/)?(?:[^@\\/\n]+@)?(?:www\.)?([^:\\/\n]+)/;
export const AIDMS_SERVER_VERSION = '23.1000.16';
export const API_URL_PREFIX = `${window.location.origin}/aidms/restapi/v1.0`;
export const AUTH_API_URL = `${API_URL_PREFIX}/auth`;
export const USER_MGMT_API_URL = `${API_URL_PREFIX}/ums/accountInfo/`;
export const SERVER_SETTINGS_API = `${API_URL_PREFIX}/settings/configurations`;
export const LOGIN_CONFIG_API = `${API_URL_PREFIX}/settings/loginConfig`;
export const EVENT_LOG_API = `${API_URL_PREFIX}/stats/eventLogs`;
export const USERS = `${API_URL_PREFIX}/ums/users`;
export const DELETE_USERS = `${API_URL_PREFIX}/ums`;
export const GROUP_MGMT_API = `${API_URL_PREFIX}/gms/groups`;
export const DEVICES = `${API_URL_PREFIX}/dms`;
export const TAG_LIST = `${API_URL_PREFIX}/tms/tags`;
export const APPROVE_DEVICES = `${API_URL_PREFIX}/dms/devices/approve`;
export const TAGS = `${API_URL_PREFIX}/tms`;
export const APP_URL = `${API_URL_PREFIX}/ams/apps`;
export const CLIENT_APP_URL = `${API_URL_PREFIX}/ams/clientApp`;
export const DEVICES_URL = `${DEVICES}/devices`;
export const DASHBOARD_URL = `${DEVICES}/dashboard`;
export const FIRMWARE_URL = `${DEVICES}/firmware`;
export const MESSAGE_ALERT_URL = `${API_URL_PREFIX}/mam`;
export const MESSAGE_ALERT_HISTORY_URL = `${MESSAGE_ALERT_URL}/alertHistory`;
