import React, { useEffect, useState } from 'react';
import './Filter.css';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { tagServices } from '../../services/RestServices/tagServices';
import { deviceServices } from '../../services/RestServices/deviceServices';

const Filter = ({position, visible, setVisible, className, deviceDataPayload, setDeviceDataPayload, setDeviceList, setDevicesCount, connectionStatus, setConnectionStatus, selectedTags, setSelectedTags, selectedSource, setSelectedSource, screenPreviewState, setScreenPreviewState, filterPayload, first, setConnectedDevicesFlag}) => {

 const [tags, setTags] = useState([]);
 const [sources, setSources] = useState([]);

 const showConnectionStatusFilterOption = (connectionStatus) => {
    return(
        connectionStatus === "" ? "" :
    <Tag className="tag_Status_layout" value={connectionStatus} rounded></Tag>) 
 } 

 const showTagFilterOption = (tag) => {
    return(
    <Tag className="tag_Tags_layout" value={tag} rounded></Tag>) 
 }

 const showInputSourceFilterOption = (source) => {
    return(
    <Tag className="tag_Source_layout" value={source} rounded></Tag>) 
 } 

 const showScreenPreviewFilterOption = (screenPreviewState) => {
    return(
        screenPreviewState === "" ? "" :
    <Tag className="tag_ScreenPreview_layout" value={screenPreviewState} rounded></Tag>) 
 } 

  const getAvailableTags = () => {
    tagServices.getAllTagList().then((res) => { 
        const {data}  = res ?? {};
        setTags(data);
    })
    setDeviceDataPayload({...deviceDataPayload, connectionStatus: "ALL"});
  }

  const inputSources = [
        {source: "ANDROID"},
        {source: "HDMI1"},
        {source: "HDMI2"},
        {source: "HDMI3"},
        {source: "VGA"}, 
        {source: "TYPE_C1"}
    ]

  useEffect(() => {
    getAvailableTags();
    setSources(inputSources);
    setSelectedTags(selectedTags);
    setScreenPreviewState(screenPreviewState);
  },[]);

    useEffect(() => {
    if (connectionStatus === "Connected")
        setDeviceDataPayload({...deviceDataPayload, connectionStatus: "ONLINE"});
    else if(connectionStatus === "Disconnected")
          setDeviceDataPayload({ ...deviceDataPayload, connectionStatus: "OFFLINE" });
    else {
        setDeviceDataPayload({...deviceDataPayload, connectionStatus: ""});
    }
    }, [connectionStatus]);
    

  useEffect(() => {
    if(screenPreviewState === "On")
        setDeviceDataPayload({...deviceDataPayload,  screenPreview: "On"});
    else if(screenPreviewState === "Off")
          setDeviceDataPayload({ ...deviceDataPayload, screenPreview: "Off" });
    else {
        setDeviceDataPayload({...deviceDataPayload, screenPreview: ""});
    }
  }, [screenPreviewState]);

  useEffect(() => {
    const tagList = [];
    for(let i=0; i<selectedTags.length; i++){
        tagList.push(selectedTags[i].tagId);
    }
    setDeviceDataPayload({...deviceDataPayload, tagIds: selectedTags.length === 0 ? [] : tagList});
  }, [selectedTags]);

  useEffect(() => {
    const sourceList = [];
    for(let i=0; i<selectedSource.length; i++){
        sourceList.push(selectedSource[i].source);
    }
    setDeviceDataPayload({...deviceDataPayload, inputSources: sourceList});
  }, [selectedSource]);

    const filterDeviceListHandler = () => {
      deviceServices.getAllDeviceList(deviceDataPayload)
        .then(res => {
            const { items, totalCount } = res?.data ?? {};
            setDeviceList(items);
            setDevicesCount(totalCount);
        });
        setVisible(false);
    }

  const clearDeviceFilter = () => {
    setConnectedDevicesFlag(() => false);
    setDeviceDataPayload({ ...filterPayload, startIndex: first });
    deviceServices.getAllDeviceList({...filterPayload, startIndex: first}).then(res => {
        const { items, totalCount } = res?.data ?? {};
        setDeviceList(items);
        setDevicesCount(totalCount);
      });
    }

  return (
    <>
    <Dialog className={className} visible={visible} position={position} style={{ width: '52rem' }} draggable={false} resizable={false} onHide={() => {
              setVisible(false); 
              clearDeviceFilter();
              setConnectionStatus("All");
              setSelectedSource([]);
              setSelectedTags([]);
              setScreenPreviewState("All");
      }}>
      <div className="filter_header">
        <div className="applyFilterHeader">
            <span className="filtersText">Filters</span>
            <div className="filter_actions">
                <Button label="Clear" className="view_btn" style={{ marginRight: "0.5rem" }} onClick={() => {  
                    setVisible(false); 
                    clearDeviceFilter();
                    setConnectionStatus("All");
                    setSelectedSource([]);
                    setSelectedTags([]);
                    setScreenPreviewState("All");
                }}></Button>
                <Button label="Apply" className="view_btn" onClick={filterDeviceListHandler}></Button>
            </div>
        </div>
        <div className="filterTagsPosition">
        {showConnectionStatusFilterOption(connectionStatus)}
        {selectedTags.map((val) => showTagFilterOption(val.tagName))}
        {selectedSource.length === sources.length ? 
            <Tag className="tag_Source_layout" value="All" rounded></Tag> : selectedSource.map((val) => showInputSourceFilterOption(val.source))}
        {showScreenPreviewFilterOption(screenPreviewState)}       
        </div>
      </div>
        <div className="m-0">
            <div className="filter">
                <div className="filter_type">
                    <h5>
                    <span className="device_type" style={{background: "rgb(255, 166, 70)"}}>
                        <span>Connection Status</span>
                    </span>
                    </h5>
                    <div className="filter-options">
                        <ul id='select-connection-status'>
                            <li onClick={()=>{setConnectionStatus("All")}} className={connectionStatus==="All" ? "selected-filter-val" : ""}>All</li>
                            <li onClick={()=>{setConnectionStatus("Connected")}} className={connectionStatus==="Connected" ? "selected-filter-val" : ""}>Connected</li>
                            <li onClick={()=>{setConnectionStatus("Disconnected")}} className={connectionStatus==="Disconnected" ? "selected-filter-val" : ""}>Disconnected</li>
                        </ul>
                    </div>
                </div>
                <div className="filter_type">
                    <h5>
                    <span className="device_type" style={{background: "rgb(255, 166, 166)"}}>
                        <span>Tags</span>
                    </span>
                    </h5>
                    <div style={{overflowY: "auto"}}>
                        <DataTable value={tags} className='filter-table tags-filter-table' selection={selectedTags} onSelectionChange={(e) => setSelectedTags(e.value)}
                        scrollable scrollHeight='12rem'>
                        <Column selectionMode="multiple" headerStyle={{width: "0.1rem"}}/>
                        <Column field="tagName" header="All" headerStyle={{textAlign:"left"}}/>
                        </DataTable>
                    </div>
                </div>
                <div className="filter_type">
                    <h5>
                    <span className="device_type" style={{background: "rgb(136, 57, 57)"}}>
                        <span>Input Source</span>
                    </span>
                    </h5>
                    <div style={{overflowY: "auto"}}>
                    <DataTable value={sources} className='filter-table' selection={selectedSource} onSelectionChange={(e) => setSelectedSource(e.value)}
                        scrollable scrollHeight='12rem'>
                        <Column selectionMode="multiple" headerStyle={{width: "0.1rem"}}/>
                        <Column field="source" header="All" headerStyle={{textAlign:"left"}}/>
                        </DataTable>
                    </div>
                </div>
                <div className="filter_type">
                    <h5>
                    <span className="device_type" style={{background: "rgb(96, 207, 166"}}>
                        <span>Screen Preview</span>
                    </span>
                    </h5>
                    <div className="filter-options">
                        <ul id='select-screen-preview'>
                            <li onClick={()=>{setScreenPreviewState("All")}} className={screenPreviewState==="All" ? "selected-filter-val" : ""}>All</li>
                            <li onClick={()=>{setScreenPreviewState("On")}} className={screenPreviewState==="On" ? "selected-filter-val" : ""}>On</li>
                            <li onClick={()=>{setScreenPreviewState("Off")}} className={screenPreviewState==="Off" ? "selected-filter-val" : ""}>Off</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </Dialog>
    </>
  )
}

export default Filter
