import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import './CustomDialog.css';

const CustomDialog = ({
  dialogState,
  handleClick,
  dialogVisible,
  setDialogVisible,
}) => {
  const footerContent = (
    <div className="btns-position">
      <Button
        label={dialogState?.action}
        onClick={handleClick}
        className="actionButton"
      />
      <Button
        label={dialogState?.cancel}
        onClick={() => setDialogVisible(false)}
        className="actionButton cancel-button"
      />
    </div>
  );

  const headerContent = () => {
    return (
      <div className="customDialogHeader">
        {dialogState?.header}
      </div>
    );
  };

  return (
    <div className="card flex justify-content-center">
      <Dialog
        header={headerContent}
        visible={dialogVisible}
        style={{ align: 'center', width: '35vw' }}
        onHide={() => setDialogVisible(false)}
        footer={footerContent}
      >
        <div className="textBodyContent_1">{dialogState?.body}</div>
        <div className="textBodyContent_2">{dialogState?.footer}</div>
      </Dialog>
    </div>
  );
};

export default CustomDialog;
