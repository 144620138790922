import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import UserReducer from "./UserManagementReducer";
import serverSettings from "./ServerSettingsReducer";

export default combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  serverSettings: serverSettings,
});
