import React from 'react';
import './LoginCard.css';
import { useIntl } from 'react-intl';
import SamsungDMSHeader from '../SamsungDMSHeader/SamsungDMSHeader';
import SamsungDMSFooter from '../SamsungDMSFooter/SamsungDMSFooter';

const LoginCard = (props) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  
  return (
    <div className="login-card">
      <SamsungDMSHeader />
      <div className="login-page">
        <div className="login-auth-page">
          <div className="login-auth-left">{props.children}</div>
          <div className="login-auth-right">
            <div>{`${f({ id: 'COM_DMS_WELCOME' })} ${f({ id: 'COM_DMS_TO_THE' })}`}</div>
            <div>{f({ id: 'COM_DMS_S_DMS' })}</div>
          </div>
        </div>
      </div>
      <SamsungDMSFooter />
    </div>
  );
};

export default LoginCard;
