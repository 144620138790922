import React from 'react';
import { userAgreementContent } from '../../../assets/documents/userAgreement';

const UserAgreement = () => {

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: userAgreementContent }} />
    </>
  )
}

export default UserAgreement;