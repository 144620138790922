import React,{ useEffect, useRef, useState }  from 'react';
import './DeviceDetails.css';
import { TabPanel, TabView } from 'primereact/tabview';
import Screen from '../../../../components/DeviceContent/ScreenTab/Screen';
import { useIntl } from 'react-intl';
import AppTab from '../../../../components/DeviceContent/AppTab/AppTab';
import Data from '../../../../components/DeviceContent/DataTab/Data';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { deviceServices } from '../../../../services/RestServices/deviceServices';
import DeviceDetailedInfo from './DeviceDetailedInfo';
import SelectCommandPopup from '../../SelectCommandPopup/SelectCommandPopup';
import { isDeviceOperator } from '../../../../utils/helper';
import { Toast } from 'primereact/toast';
import { setToastComponent, showErrorToast } from '../../../../services/APIResponseHandler';
import editIcon from '../../../../assets/images/edit_icon.svg';
import { deviceNameMaxLength, validateDeviceDetails } from '../../../../utils/Validations';
import HoveredText from '../../../../components/HoveredText/HoveredText';
import { Tooltip } from 'primereact/tooltip';
import CommandIcon from '../../../../assets/images/commandIcon.svg';
import { tagServices } from '../../../../services/RestServices/tagServices';

const DeviceDetails = ({ setPageToShow, details, setDetails, deviceID }) => {
  
  const toastRef = useRef(null);

  const intl = useIntl();
  const { formatMessage: f } = intl;
  const [tags, setTags] = useState([]);
  const [updatedScreen, setUpdatedScreen] = useState(false);
  const [editingCell, setEditingCell] = useState(false);
  const [appData, setAppData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [openCommandsPopup, setOpenCommandsPopup] = useState(false);
  const [screenPreviewAction, setScreenPreviewAction] = useState(details.deviceInfo?.deviceScreenPreview === 'START');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setToastComponent(toastRef.current);
  }, []);

  const onEditIconClick = () => {
    setEditingCell(!editingCell);
  };

  const onSaveClick = async (value) => {
    const res = validateDeviceDetails('deviceName', value);
    const isValid = res.isValid;

    if(isValid) {
      await Promise.all([
        deviceServices.setDeviceName(value, [deviceID]),
      ]);
      await Promise.all([deviceServices.getDeviceInfoById(deviceID).
        then(({data}) => 
          setDetails(data)
        )]);
      setEditingCell(false);
    } else {
      showErrorToast(f({ id: res.messageId }));
    }
  };

  const onCancelClick = () => {
    setEditingCell(false);
  };

  const editDeviceNameTemplate = (deviceName) => {
    let editvalue = deviceName;
    if (editingCell) {
      return (
        <>
          <InputText
            maxLength={deviceNameMaxLength}
            className="editDeviceNameView"
            defaultValue={editvalue}
            onChange={(e) => (editvalue = e.target.value)}
          />
          <Button
            className="editOptions editOptionsView"
            icon="pi pi-check"
            onClick={() => onSaveClick(editvalue)}
          />
          <Button
            className="editOptions editOptionsView"
            icon="pi pi-times"
            onClick={onCancelClick}
          />
        </>
      );
    } else {
      return (
        <div className="content_deviceName">
          <HoveredText text={editvalue}  className="dname_layout"/>
          {!isDeviceOperator() && <img
            className="editIcon"
            onClick={() => onEditIconClick(editvalue)}
            src={editIcon}
          />}
        </div>
      );
    }
  };

  const getDeviceAppList = () => {
    deviceServices.getAppListByDeviceId(deviceID).
    then(({data}) => 
      setAppData(data)
    );
  }

  const getUsageInfo = async() => {
    setLoading(true);
    await Promise.all([
      deviceServices.getDeviceUsageInfo(deviceID)
    ]);
    setTimeout(() => {
      deviceServices.getDeviceInfoById(deviceID).
        then(({data}) => 
          setDetails(data)
      )
      setLoading(false);
    }, 5000);
  }

  useEffect(() => {
    activeIndex === 1 && getDeviceAppList();
    activeIndex === 2 && getUsageInfo();
  },[activeIndex]);

  const refreshDeviceDetails = async () => {
    setLoading(true);
    getDeviceAppList();
    await Promise.all([
      deviceServices.getDeviceUsageInfo(deviceID)
    ]);
    setTimeout(() => {
      deviceServices.getDeviceInfoById(deviceID).
        then(({ data }) => {
          setDetails(data)
          tagServices.getTagByDeviceId(details.deviceId).then(({ data }) => 
        setTags(data));
        }
      )
      setLoading(false);
    }, 5000);
}

  return (
    <>
    <Toast ref={toastRef} position="top-center" />
    {openCommandsPopup && (
        <SelectCommandPopup
          deviceIds={[deviceID]}
          setSelectCommandPopup={setOpenCommandsPopup}
        />
      )}
    <div className="deviceDetailsPage">
        <div className="contentsWrap">
            <span className="deviceBackBtn" onClick={() => setPageToShow("ALL")}>{`< `}{f({ id: "COM_DMS_BACK_LABEL" })}</span>
            <div className="contentHeader">
             <div className="contentHeaderLeft">
             <i className = {details.deviceInfo?.connectionStatus !== undefined && details.deviceInfo.connectionStatus ? "pi pi-circle-on connectionStatus on posIcon" : "pi pi-circle-on connectionStatus off posIcon"} />
              {editDeviceNameTemplate(details.deviceInfo?.deviceName)}
              {!editingCell && <i className="pi pi-refresh refreshIcon" onClick={refreshDeviceDetails} />}
            </div>
            <div className='info_command_btn_pos'>
                <Tooltip target=".details_InfoIcon" position='left' >
                  <div className="infoIcon_tooltipContent">
                    <div>{f({ id: 'COM_DMS_DEVICE_DETAILS_TOOLTIP_TEXT' })}</div>
                  </div>
                </Tooltip>
                <img
                  src={CommandIcon}
                  alt="command"
                  tooltipOptions={{ position: 'left' }}
                  className="details_InfoIcon select-command-icon"
                />
                <Button className="selectCommands_btn" label="Select Commands" onClick={() => setOpenCommandsPopup(true)}/>
              </div>
            </div>
            <div className="contentBody">
              <TabView className="deviceContent" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Screen">
                  <Screen
                    details={details}
                    updatedScreen={updatedScreen}
                    setUpdatedScreen={setUpdatedScreen}
                    screenPreviewAction={screenPreviewAction}
                    setScreenPreviewAction={setScreenPreviewAction}
                    loading={loading}
                />
                </TabPanel>
                {!isDeviceOperator() && <TabPanel header="App">
                <AppTab appData={appData} setAppData={setAppData} details={details} deviceConnectionStatus={details.deviceInfo?.connectionStatus} emptyMessageId={'COM_DMS_NO_APPS_TO_MANAGE'} loading={loading} setLoading={setLoading} />
                </TabPanel>}
                <TabPanel header="Data">
                  <Data details={details} loading={loading}/>
                </TabPanel>
             </TabView>
            </div>
        </div>
        <div className="infoWrap">
          <DeviceDetailedInfo details={details} setDetails={setDetails} tags={tags} setTags={ setTags} />
        </div>
    </div>
    </>
  )
}

export default DeviceDetails;
