export const isAdminUser = () => {
  const loginUserRoleName = localStorage.getItem('loginUserRoleName');
  return loginUserRoleName === 'Admin';
};

export const isAdminOrSubAdmin = () => {
  const loginUserRoleName = localStorage.getItem('loginUserRoleName');
  return loginUserRoleName === 'Admin' || loginUserRoleName === 'Sub-Admin';
};

export const isDeviceManager = () => {
  const loginUserRoleName = localStorage.getItem('loginUserRoleName');
  return loginUserRoleName === 'Device-Manager';
};

export const isDeviceOperator = () => {
  const loginUserRoleName = localStorage.getItem('loginUserRoleName');
  return loginUserRoleName === 'Device-Operator';
};

export function addNoOfDaysDayToDate(date = new Date(), noOfDays) {
  date.setDate(date.getDate() + noOfDays);
  return new Date(date).getTime();
}

export const isAdminOrSubAdminByRoleName = (roleName) => {
  return roleName === 'Admin' || roleName === 'Sub-Admin';
};
