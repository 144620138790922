import './AppInstallPopup.css';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { GROUP_MGMT_API } from '../../../config/constants/Constants';
import SelectDevices from '../../../components/SelectDevices/SelectDevices';
import { RestServiceUtils } from '../../../utils/RestServiceUtils';
import { convertJSONDataToTreeTableFormat } from '../../Devices/Group/convertToJSONTree';
import {
  cleanJSONTree,
  removeNullValuesFromNestedObjectOfArray,
} from '../../../utils/CleanJSONArrayObject';
import flatToTree from 'flat-to-tree';

const AppInstallPopup = ({
  openDialog,
  appRowData,
  devices,
  setDevices,
  manageAppHeader,
  setOpenDialog
}) => {
  const [jsonGroupTree, setJsonGroupTree] = useState([]);
  const [deviceParentIds, setDeviceParentIds] = useState([]);
  const [selectedCheckedKeys, setSelectedCheckedKeys] = useState([]);

  useEffect(() => {
    RestServiceUtils.HTTP_GET(GROUP_MGMT_API).then(({ data }) => {
      const convertedJSONData = flatToTree(data, {
        id: 'groupId',
        parentId: 'parentId',
      });
      convertJSONDataToTreeTableFormat(convertedJSONData);
      cleanJSONTree(convertedJSONData);
      const cleanedJSONTree =
        removeNullValuesFromNestedObjectOfArray(convertedJSONData);
      setJsonGroupTree(cleanedJSONTree);
    });
    setSelectedCheckedKeys([]);
    setDevices([]);
    setDeviceParentIds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      visible={openDialog}
      header={manageAppHeader}
      className="app-install-dialog"
      position="bottom-right"
      draggable={false}
      onHide={() => setOpenDialog(false)}
    >
      <SelectDevices
        devices={devices}
        setDevices={setDevices}
        jsonGroupTree={jsonGroupTree}
        deviceParentIds={deviceParentIds}
        setJsonGroupTree={setJsonGroupTree}
        appPackage={appRowData?.appPackageName}
        setDeviceParentIds={setDeviceParentIds}
        selectedCheckedKeys={selectedCheckedKeys}
        emptyTargetDeviceId={'COM_DMS_SELECT_TARGET_DEVICE'}
        setSelectedCheckedKeys={setSelectedCheckedKeys}
      />
    </Dialog>
  );
};

export default AppInstallPopup;
