import React, { useEffect, useState } from 'react';
import './SelectDeviceComponent.css';
import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import { Checkbox } from 'primereact/checkbox';
import _ from 'lodash';
import {
  cleanJSONTree,
  removeNullValuesFromNestedObjectOfArray,
} from '../../utils/CleanJSONArrayObject';
import PlusIcon from '../../assets/images/plusIcon.svg';
import MinusIcon from '../../assets/images/minusIcon.svg';
import GroupIcon from '../../assets/images/groupIcon.svg';
import { useIntl } from 'react-intl';
import flatToTree from 'flat-to-tree';
import { Tooltip } from 'primereact/tooltip';
import { TabPanel, TabView } from 'primereact/tabview';
import InfoIcon from '../../assets/images/info-fill.svg';
import SearchBar from '../../components/SearchBar/SearchBar';
import { RestServiceUtils } from '../../utils/RestServiceUtils';
import { GROUP_MGMT_API } from '../../config/constants/Constants';
import { GroupSuggestPopup } from '../../components/PopUp/GroupSuggest';
import { deviceServices } from '../../services/RestServices/deviceServices';
import DeviceIcon from '../../assets/images/deviceGroupIcon.svg';
import CollapsableIcon from '../../assets/images/collapsibleIcon.svg';
import CancelRoundOutline from '../../assets/images/cancel-round-outline.svg';
import { GroupManagementService } from '../../services/RestServices/GroupManagementService';
import { convertJSONDataToTreeTableFormat } from '../../modules/Devices/Group/convertToJSONTree';

const SelectDeviceComponent = ({
  appPackage,
  jsonGroupTree,
  activeTabIndex,
  isAppManagement,
  deviceParentIds,
  currentFWVersion,
  setJsonGroupTree,
  selectedGroupKeys,
  isFirmwareVersion,
  setActiveTabIndex,
  setDeviceParentIds,
  isClientAppVersion,
  selectedCheckedKeys,
  setSelectedGroupKeys,
  setSelectedCheckedKeys,
  currentClientAppVersion,
  handleSelectDeviceRightSideComponentHeader,
}) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const [searchText, setSearchText] = useState('');
  const [visible, setVisible] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [groupList, setGroupList] = useState({});
  const [expandedKeys, setExpandedKeys] = useState({});
  const [expandedGroupKeys, setExpandedGroupKeys] = useState({});
  const [jsonGroupDataTree, setJsonGroupDataTree] = useState([]);
  const [groupTableData, setGroupTableData] = useState([]);
  const [deviceTableData, setDeviceTableData] = useState([]);

  const collapseNodes = (list, keys, setKeys) => {
    let _expandedKeys = { ...keys };
    const keyMap = new Map(Object.entries(keys));
    for (let idx = 0; idx < list.length; idx++) {
      let val = '' + list[idx];
      if (keyMap.has(val)) {
        delete _expandedKeys[val];
      }
    }

    setKeys(_expandedKeys);
  };

  const traverseToFilterGroupOrDevices = (nodes, deviceData, key) => {
    if (!nodes) {
      return;
    }

    for (let index = 0; index < nodes.length; index++) {
      if (nodes[index].key === key) {
        deviceData.push(nodes[index].data);
        return;
      } else {
        traverseToFilterGroupOrDevices(nodes[index]?.children, deviceData, key);
      }
    }
  };

  useEffect(() => {
    if (activeTabIndex === 0) {
      let groupData = [];
      const resNodes = _.cloneDeepWith(jsonGroupDataTree);
      for (let key of selectedGroupKeys) {
        traverseToFilterGroupOrDevices(resNodes, groupData, key);
      }

      setGroupTableData(groupData);
    } else {
      let deviceData = [];
      const resNodes = _.cloneDeepWith(jsonGroupTree);
      for (let key of selectedCheckedKeys) {
        traverseToFilterGroupOrDevices(resNodes, deviceData, key);
      }
      setDeviceTableData(deviceData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTabIndex,
    jsonGroupDataTree,
    jsonGroupTree,
    selectedCheckedKeys,
    selectedGroupKeys,
  ]);

  const getAllGroupsList = () => {
    RestServiceUtils.HTTP_GET(GROUP_MGMT_API).then(({ data }) => {
      const convertedJSONData = flatToTree(data, {
        id: 'groupId',
        parentId: 'parentId',
      });
      convertJSONDataToTreeTableFormat(convertedJSONData);
      cleanJSONTree(convertedJSONData);
      const cleanedJSONTree =
        removeNullValuesFromNestedObjectOfArray(convertedJSONData);
      setJsonGroupTree(cleanedJSONTree);
      setJsonGroupDataTree(cleanedJSONTree);
    });
    setSelectedCheckedKeys([]);
    setDeviceParentIds([]);
  };

  useEffect(() => {
    getAllGroupsList();
    collapseTreeTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onExpand = async (event) => {
    const { node } = event;
    const { key } = node;
    const keys = new Set(deviceParentIds);
    if (keys.has(key)) {
      return;
    }

    if (activeTabIndex === 0) {
      return;
    }

    const keysOfChildren = [];
    for (let child of node?.children) {
      if (child.data.type === 'device') {
        keysOfChildren.push(child.key);
      }
    }

    const keysSet = new Set(keysOfChildren);
    const resNodes = _.cloneDeepWith(jsonGroupTree);

    if (!isClientAppVersion && !isFirmwareVersion) {
      deviceServices
        .getDevicesByGroupAndAppPackage(key, isAppManagement ? appPackage : {})
        .then(({ data }) => {
          const reformedDeviceData = [];
          data?.forEach((d, idx) => {
            const { deviceName, deviceMac, deviceIp } = d ?? {};
            if (!keysSet.has(deviceMac))
              reformedDeviceData.push({
                key: deviceMac,
                label: deviceName,
                data: {
                  groupName: deviceName,
                  type: 'device',
                  parentId: key,
                  deviceMac,
                  deviceIp,
                },
                leaf: true,
              });
          });
          traverseToAddDevices(resNodes, reformedDeviceData, key);
          setJsonGroupTree(resNodes);
          keys.add(key);
          setDeviceParentIds(Array.from(keys));
        });
    } else {
      if (isFirmwareVersion) {
        deviceServices
          .getDevicesByFirmwareVersion(
            key,
            isFirmwareVersion ? currentFWVersion : {}
          )
          .then(({ data }) => {
            const reformedDeviceData = [];
            data?.forEach((d, idx) => {
              const { deviceName, deviceMac, deviceIp } = d ?? {};
              if (!keysSet.has(deviceMac))
                reformedDeviceData.push({
                  key: deviceMac,
                  label: deviceName,
                  data: {
                    groupName: deviceName,
                    type: 'device',
                    parentId: key,
                    deviceMac,
                    deviceIp,
                  },
                  leaf: true,
                });
            });
            traverseToAddDevices(resNodes, reformedDeviceData, key);
            setJsonGroupTree(resNodes);
            keys.add(key);
            setDeviceParentIds(Array.from(keys));
          });
      } else {
        deviceServices
          .getDevicesByClientAppVersion(
            key,
            isClientAppVersion ? currentClientAppVersion : {}
          )
          .then(({ data }) => {
            const reformedDeviceData = [];
            data?.forEach((d, idx) => {
              const { deviceName, deviceMac, deviceIp } = d ?? {};
              if (!keysSet.has(deviceMac))
                reformedDeviceData.push({
                  key: deviceMac,
                  label: deviceName,
                  data: {
                    groupName: deviceName,
                    type: 'device',
                    parentId: key,
                    deviceMac,
                    deviceIp,
                  },
                  leaf: true,
                });
            });
            traverseToAddDevices(resNodes, reformedDeviceData, key);
            setJsonGroupTree(resNodes);
            keys.add(key);
            setDeviceParentIds(Array.from(keys));
          });
      }
    }
  };

  const onCollapse = (event) => {
    if (activeTabIndex === 0) return;
    const { node } = event;
    const { key } = node;

    traverseToRemoveDevices(jsonGroupTree, key);
  };

  const traverseToRemoveDevices = (nodes, key) => {
    if (!nodes) {
      return;
    }

    for (let index = 0; index < nodes.length; index++) {
      if (nodes[index].key === key) {
        const filteredData = nodes[index]?.children?.filter(
          (node) => node?.type !== 'device'
        );
        nodes[index].children = [...(filteredData || [])];
      } else {
        traverseToRemoveDevices(nodes[index]?.children, key);
      }
    }
  };

  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }
    const expanded = options.expanded;
    return (
      <button
        type="button"
        className="p-treetable-toggler p-link"
        style={options.buttonStyle}
        tabIndex={-1}
        onClick={options.onClick}
      >
        <span>
          {expanded ? (
            <img
              src={MinusIcon}
              className="icon"
              alt="minus"
              style={{ height: '1rem' }}
            />
          ) : (
            <img
              src={PlusIcon}
              className="icon"
              alt="plus"
              style={{ height: '1rem' }}
            />
          )}
        </span>
      </button>
    );
  };

  const getAllChildren = (treeData, subGroupIds, key) => {
    for (let index = 0; index < treeData?.length; index++) {
      if (treeData[index]?.key === key) {
        subGroupIds.push(treeData[index]?.allChildren);
      }
      getAllChildren(treeData[index]?.children, subGroupIds, key);
    }
  };

  const addGroupBody = ({ data, key }) => {
    const { type, groupName } = data ?? {};
    const selectedCheckedData = new Set(selectedCheckedKeys);
    const selectedGroupCheckedKeys = new Set(selectedGroupKeys);

    return activeTabIndex === 1 ? (
      <>
        {type !== 'device' ? (
          <>
            <img
              src={GroupIcon}
              alt="group"
              style={{ marginRight: '1rem', height: '1rem' }}
            />
            {groupName}
          </>
        ) : (
          <>
            <Checkbox
              checked={selectedCheckedData.has(key)}
              onChange={() => {
                if (selectedCheckedData.has(key)) {
                  selectedCheckedData.delete(key);
                } else {
                  const subGroupIds = [];
                  getAllChildren(jsonGroupTree, subGroupIds, key);
                  subGroupIds?.flat()?.forEach((id) => {
                    if (selectedCheckedData.has(id)) {
                      selectedCheckedData.delete(id);
                    }
                  });
                  selectedCheckedData.add(key);
                }
                setSelectedCheckedKeys(Array.from(selectedCheckedData));
              }}
              style={{ marginTop: '-0.6rem' }}
            />
            <img
              src={DeviceIcon}
              alt="device"
              style={{ marginRight: '1rem', height: '1rem' }}
            />
            {groupName}
            <Tooltip target={`#info-icon_${data.deviceMac}`}>
              <div className="infoIcon_tooltipContent">
                <div>
                  {f({ id: 'COM_DMS_ETHERNET_MAC' })}: {data?.deviceMac}
                </div>
                <div>
                  {f({ id: 'COM_DMS_IP' })}: {data?.deviceIp}
                </div>
              </div>
            </Tooltip>
            <img
              src={InfoIcon}
              alt="info"
              className="info-icon icon"
              id={`info-icon_${data.deviceMac}`}
              style={{ marginLeft: '1rem' }}
            />
          </>
        )}
      </>
    ) : (
      <>
        {activeTabIndex === 1 && (
          <Checkbox
            checked={selectedGroupCheckedKeys.has(key)}
            onChange={() => {
              if (selectedGroupCheckedKeys.has(key)) {
                selectedGroupCheckedKeys.delete(key);
              } else {
                selectedGroupCheckedKeys.add(key);
              }
              setSelectedGroupKeys(Array.from(selectedGroupCheckedKeys));
            }}
            style={{ marginTop: '-0.6rem' }}
          />
        )}
        <img
          src={GroupIcon}
          alt="group"
          style={{ marginRight: '1rem', height: '1rem' }}
        />
        {groupName}
      </>
    );
  };

  const traverseToAddDevices = (nodes, deviceData, key) => {
    if (!nodes) {
      return;
    }

    for (let index = 0; index < nodes.length; index++) {
      if (nodes[index].key === key) {
        nodes[index].children = [
          ...(nodes[index].children || []),
          ...(deviceData || []),
        ];
        if (!deviceData.length) {
          nodes[index] = { ...nodes[index], leaf: true };
        }
        return;
      } else {
        traverseToAddDevices(nodes[index]?.children, deviceData, key);
      }
    }
  };

  const handleClickSearchSuggestions = () => {
    if (searchText === '') {
      getAllGroupsList();
    } else {
      GroupManagementService.getGroupListFilter(searchText).then((res) => {
        const { data } = res ?? {};
        setVisible(true);
        let map = new Map();
        let currSuggestions = [];
        setDeviceParentIds([]);

        const groupList = [];
        Object.values(data).forEach((val) => {
          groupList.push(val.groupId);
          map.set(
            [val.groupName, val.groupId],
            val.parents
              ? val.parents.map((parent) => [parent.groupName, parent.groupId])
              : []
          );
        });

        for (const x of map.entries()) {
          const currentNode = x[0];
          const parents = x[1];
          if (currentNode[0].toLowerCase().includes(searchText.toLowerCase())) {
            currSuggestions.push({
              groupName: currentNode[0],
              groupId: currentNode[1],
              groupParents: [...parents],
            });
          }
        }
        setSuggestions(() => currSuggestions);
      });
    }
    collapseTreeTable();
  };

  const getListOfAllChildComponents = (nodes, list) => {
    if (!nodes) return;

    for (let node of nodes) {
      list.push(node.key);
      getListOfAllChildComponents(node.children, list);
    }
  };

  const collapseTreeTable = () => {
    if (!expandedKeys) return;
    let collapsedNodes = [];
    let list = [];
    const currentJsonTree =
      activeTabIndex === 0 ? jsonGroupDataTree : jsonGroupTree;
    for (let node of currentJsonTree) {
      list.push(node.key);
      if (node?.children && node?.children?.length) {
        getListOfAllChildComponents(node.children, list);
      }
      collapsedNodes.push({ ...node, leaf: false });
      if (activeTabIndex === 0) {
        collapseNodes(list, expandedGroupKeys, setExpandedGroupKeys);
      } else {
        collapseNodes(list, expandedKeys, setExpandedKeys);
      }
    }
    if (activeTabIndex === 0) {
      setJsonGroupDataTree(collapsedNodes);
    } else {
      setJsonGroupTree(collapsedNodes);
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleClickSearchSuggestions();
    }
  };

  const groupNameHeader = () => {
    return (
      <div className="select_group_tree_header">
        <div onClick={collapseTreeTable} className="collapse_icon">
          <img src={CollapsableIcon} alt="collapse" className="icon" />
        </div>
        {f({ id: 'COM_DMS_GROUP_NAME' })}
      </div>
    );
  };

  const renderDeviceGroupTable = () => {
    const checkedColumn = (data) => {
      const { key } = data ?? {};
      const selectedGroupCheckedKeys = new Set(selectedGroupKeys);

      return (
        <div>
          <Checkbox
            checked={selectedGroupCheckedKeys.has(key)}
            onChange={() => {
              if (selectedGroupCheckedKeys.has(key)) {
                selectedGroupCheckedKeys.delete(key);
              } else {
                selectedGroupCheckedKeys.add(key);
              }
              setSelectedGroupKeys(Array.from(selectedGroupCheckedKeys));
            }}
            style={{ marginTop: '-0.6rem' }}
          />
        </div>
      );
    };

    const groupColumns = [
      {
        expander: true,
        field: 'groupName',
        body: addGroupBody,
        header: groupNameHeader,
        style: { width: '93%' },
      },
    ];

    if (activeTabIndex === 0) {
      groupColumns.unshift({
        field: 'checkedGroup',
        header: '',
        body: checkedColumn,
        className: 'checked-col',
        style: { width: '7%' },
      });
    }

    return (
      <div>
        <div className="select_devices">
          <div className="select_device_header">
            {f({ id: 'COM_DMS_SELECT_DEVICE' })}
          </div>
          <div className="search_section">
            <SearchBar
              className="search-bar"
              value={searchText}
              setValue={setSearchText}
              setVisiblePopup={setVisible}
              handleInputKeyDown={handleInputKeyDown}
              placeholder={f({ id: 'COM_DMS_GROUP_NAME' })}
              searchHandler={handleClickSearchSuggestions}
            />
            {visible && (
              <GroupSuggestPopup
                visible={visible}
                setVisible={setVisible}
                groups={suggestions}
                groupList={groupList}
                setGroupList={setGroupList}
                setSearchText={setSearchText}
                setNodes={
                  activeTabIndex === 0 ? setJsonGroupDataTree : setJsonGroupTree
                }
                treeTableNode
                className="select-device-group-suggest"
              />
            )}
          </div>
        </div>
        <div className="select_device_table">
          <TreeTable
            onExpand={onExpand}
            onCollapse={onCollapse}
            expandedKeys={
              activeTabIndex === 0 ? expandedGroupKeys : expandedKeys
            }
            togglerTemplate={togglerTemplate}
            onToggle={(e) => {
              activeTabIndex === 0
                ? setExpandedGroupKeys(e.value)
                : setExpandedKeys(e.value);
            }}
            value={activeTabIndex === 0 ? jsonGroupDataTree : jsonGroupTree}
            scrollable
            scrollHeight="66vh"
          >
            {groupColumns.map((col) => (
              <Column {...col} />
            ))}
          </TreeTable>
        </div>
      </div>
    );
  };

  const renderTargetDevicesOrGroups = () => {
    const renderEmptyTargetDevice = () => {
      return activeTabIndex === 0 ? (
        <div className="select-target-centered">
          {f({ id: 'COM_DMS_TARGET_GROUP_MSG' })}
        </div>
      ) : (
        <div className="select-target-centered">
          {f({ id: 'COM_DMS_TARGET_DEVICE_MSG' })}
        </div>
      );
    };

    const renderGroup = ({ groupName, groupId }) => {
      const selectedGroupIconOnClick = () => {
        const filteredGroups = groupTableData?.filter(
          (data) => data.groupId !== groupId
        );
        const selectedGroupCheckedKeys = selectedGroupKeys.filter(
          (key) => key !== groupId
        );
        setGroupTableData(filteredGroups);
        setSelectedGroupKeys(selectedGroupCheckedKeys);
      };
      return (
        <div key={groupId} className="target-group">
          <div>{groupName}</div>
          <img
            src={CancelRoundOutline}
            alt="cancel"
            className="icon"
            onClick={selectedGroupIconOnClick}
          />
        </div>
      );
    };

    const renderDevice = ({ groupName, deviceMac, deviceIp }) => {
      const selectedDeviceIconOnClick = () => {
        const filteredDevices = groupTableData?.filter(
          (data) => data.deviceMac !== deviceMac
        );
        const selectedDevicesCheckedKeys = selectedCheckedKeys.filter(
          (key) => key !== deviceMac
        );
        setDeviceTableData(filteredDevices);
        setSelectedCheckedKeys(selectedDevicesCheckedKeys);
      };

      return (
        <div key={deviceMac} className="target-device">
          <div>{groupName}</div>
          <div>{deviceMac}</div>
          <div>
            <div className="target-device-ip">{deviceIp}</div>
            <img
              src={CancelRoundOutline}
              alt="cancel"
              className="icon"
              onClick={selectedDeviceIconOnClick}
            />
          </div>
        </div>
      );
    };

    return (
      <div className="select_device_row">
        <div className="select_device_row_table">
          <div className="select_devices">
            <div className="select_device_header">
              {activeTabIndex === 0
                ? f({ id: 'COM_DMS_TARGET_GROUP' })
                : f({ id: 'COM_DMS_TARGET_DEVICE' })}
            </div>
          </div>

          {activeTabIndex === 0 ? (
            <div className="target_device_table">
              <div style={{ width: '100%' }} className="group-header">
                {f({ id: 'COM_DMS_GROUP_NAME' })}
              </div>

              <div className="target_device_table_content">
                {groupTableData.length !== 0
                  ? groupTableData?.map((device) => renderGroup(device))
                  : renderEmptyTargetDevice()}
              </div>
            </div>
          ) : (
            <div className="target_device_table">
              <div className="device-header">
                <div style={{ width: '30%' }}>
                  {f({ id: 'COM_DMS_DEVICES' })}
                </div>
                <div style={{ width: '35%' }}>
                  {f({ id: 'COM_DMS_MAC_ID_VAL' })}
                </div>
                <div style={{ width: '35%' }}>{f({ id: 'COM_DMS_IP' })}</div>
              </div>
              {deviceTableData.length !== 0
                ? deviceTableData?.map((device) => renderDevice(device))
                : renderEmptyTargetDevice()}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="select_device_body">
      {(handleSelectDeviceRightSideComponentHeader &&
        handleSelectDeviceRightSideComponentHeader()) ||
        null}
      <div className="select_device_row">
        <TabView
          className="select_device_tabs"
          activeIndex={activeTabIndex}
          onTabChange={(e) => setActiveTabIndex(e.index)}
        >
          <TabPanel
            header={f({ id: 'COM_DMS_BY_GROUP' })}
            style={{ background: '#ffffff' }}
          >
            {renderDeviceGroupTable()}
          </TabPanel>
          <TabPanel
            header={f({ id: 'COM_DMS_BY_DEVICE' })}
            style={{ background: '#ffffff' }}
          >
            {renderDeviceGroupTable()}
          </TabPanel>
        </TabView>
      </div>
      {renderTargetDevicesOrGroups()}
    </div>
  );
};

export default SelectDeviceComponent;
