import React from 'react';
import './Information.css';
import DataTableComponent from '../../../../components/DataTable/Datatable';
import { Paginator } from 'primereact/paginator';

const Information = ({editDeviceNameTemplate, deviceList, devicesCount, first, rows, onPageChange, selectedItems, setSelectedItems, connectionStatusTemplate}) => {

  const headerInfo = [
    {field: "connectionStatus", header: "", width: '1px'},
    { field: "deviceName", header: "Device Name" },
    { field: "deviceId", header: "Ethernet MAC" },
    { field: "ipAddress", header: "IP" },
    { field: "deviceModelName", header: "Model" },
    { field: "deviceSerial", header: "Serial" },
    { field: "deviceType", header: "Operating System" },
    { field: "deviceOsVersion", header: "OS Version" },
    { field: "dmsClientVersion", header: "Client Version" }
  ];

  return (
    <> 
      <div className="device-information-table">
        <DataTableComponent className="devices-table-layout" 
          data={deviceList} headerInfo={headerInfo} selectedItems={selectedItems} setSelectedItems={setSelectedItems} editDeviceNameTemplate={editDeviceNameTemplate} connectionStatusTemplate = {connectionStatusTemplate}
          />
        <div className="custom-paginator">
          <Paginator first={first} rows={rows} totalRecords={devicesCount} onPageChange={onPageChange} rowsPerPageOptions={[10,20,30,50]}/>
        </div>
      </div>
    </>
  );
}

export default Information
