import React, { useEffect, useRef, useState } from 'react';
import './Group.css';
import CustomButton from '../../../components/CustomButton/CustomButton';
import SearchBar from '../../../components/SearchBar/SearchBar';
import AddGroupPopup from './AddGroupPopup/AddGroupPopup';
import { useIntl } from 'react-intl';
import 'primeicons/primeicons.css';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import _ from 'lodash';
import EditGroupPopup from './EditGroupPopup/EditGroupPopup';
import MoveGroupPopup from './MoveGroupPopup/MoveGroupPopup';
import DeleteGroup from './DeleteGroup/DeleteGroup';
import CommandIcon from '../../../assets/images/commandIcon.svg';
import CollapsableIcon from '../../../assets/images/collapsibleIcon.svg';
import GroupIcon from '../../../assets/images/groupIcon.svg';
import DeviceIcon from '../../../assets/images/deviceGroupIcon.svg';
import { GroupSuggestPopup } from '../../../components/PopUp/GroupSuggest';
import { GroupManagementService } from '../../../services/RestServices/GroupManagementService';
import PlusIcon from '../../../assets/images/plusIcon.svg';
import MinusIcon from '../../../assets/images/minusIcon.svg';
import SelectCommandPopup from '../SelectCommandPopup/SelectCommandPopup';
import { Toast } from 'primereact/toast';
import { setToastComponent } from '../../../services/APIResponseHandler';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import InfoIcon from '../../../assets/images/info-fill.svg';
import Spinner from '../../../components/Spinner/Spinner';

const Group = ({ refreshPage }) => {
  const loginUserId = localStorage.getItem('loginUserId');

  const intl = useIntl();
  const { formatMessage: f } = intl;
  const [flag, setFlag] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [openCreateGroup, setOpenCreateGroup] = useState(false);
  const [openEditGroup, setOpenEditGroup] = useState(false);
  const [openMoveGroup, setOpenMoveGroup] = useState(false);
  const [openDeleteGroup, setOpenDeleteGroup] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState(null);
  const [selectedNodeKeys, setSelectedNodeKeys] = useState(null);
  const [selectedCheckedKeys, setSelectedCheckedKeys] = useState([]);
  const [selectedEditKey, setSelectedEditKey] = useState('');
  const [searchText, setSearchText] = useState('');
  const [groupList, setGroupList] = useState({});
  const [visible, setVisible] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [selectCommandPopup, setSelectCommandPopup] = useState(false);
  const DEVICE_OPERATOR = 'Device-Operator';
  const DEVICE_MANAGER = 'Device-Manager';
  const [loading, setLoading] = useState(false);

  const toastRef = useRef(null);
  useEffect(() => {
    setToastComponent(toastRef.current);
  }, []);

  const collapseNodes = (list) => {
    if (!expandedKeys) return;
    let _expandedKeys = { ...expandedKeys };
    const keyMap = new Map(Object.entries(expandedKeys));
    for (let idx = 0; idx < list.length; idx++) {
      let val = '' + list[idx];
      if (keyMap.has(val)) delete _expandedKeys[val];
    }

    setExpandedKeys(_expandedKeys);
  };

  const role = localStorage.getItem('loginUserRoleName');
  const hasAllDevicePermission = !(role === DEVICE_OPERATOR || role === DEVICE_MANAGER);

  const renderInitialGroupData = async () => {
    setLoading(true);
    const resNodes = [];
    let defaultGroupData = null;
    const { data } = await GroupManagementService.getDMSGroupDetails();
    for (let val of data) {
      const { groupId, groupName, deviceCount } = val;
      const currentDeviceNode = {
        key: groupId,
        data: {
          type: 'group',
          groupName,
          deviceCount,
        },
        leaf: false,
      };
      if (groupId !== 0) {
        resNodes.push(currentDeviceNode);
      } else {
        defaultGroupData = currentDeviceNode;
      }
    }
    if (!!defaultGroupData) {
      resNodes.unshift(defaultGroupData);
    }
    setNodes(resNodes);
    setLoading(false);
  };

  useEffect(() => {
    renderInitialGroupData();
    setExpandedKeys(null);
    setSelectedCheckedKeys([]);
  }, [refreshPage]);

  useEffect(() => {
    if (flag) {
      renderInitialGroupData();
      setExpandedKeys(null);
      setSelectedCheckedKeys([]);
      setFlag(false);
    }
  }, [flag]);

  const getListOfAllChildComponents = (nodes, list) => {
    if (!nodes) return;

    for (let node of nodes) {
      list.push(node.key);
      getListOfAllChildComponents(node.children, list);
    }
  };

  const collapseTreeTable = () => {
    if (!expandedKeys) return;
    let collapsedNodes = [];
    let list = [];
    for (let node of nodes) {
      list.push(node.key);
      if (node?.children && node?.children?.length) {
        getListOfAllChildComponents(node.children, list);
        collapsedNodes.push({ ...node, children: [], leaf: false });
      } else {
        collapsedNodes.push({ ...node, leaf: false });
      }
      collapseNodes(list);
    }
    setNodes(collapsedNodes);
  };

  const handleClickSearchSuggestions = () => {
    if (searchText === '') {
      renderInitialGroupData();
    } else {
      GroupManagementService.getGroupListFilter(searchText).then(
        (res) => {
          const { data } = res ?? {};
          setVisible(true);
          let map = new Map();
          let currSuggestions = [];
          const groupList = [];
          Object.values(data).forEach((val) => {
            groupList.push(val.groupId);
            map.set(
              [val.groupName, val.groupId],
              val.parents
                ? val.parents.map((parent) => [
                    parent.groupName,
                    parent.groupId,
                  ])
                : []
            );
          });

          for (const x of map.entries()) {
            const currentNode = x[0];
            const parents = x[1];
            if (
              currentNode[0].toLowerCase().includes(searchText.toLowerCase())
            ) {
              currSuggestions.push({
                groupName: currentNode[0],
                groupId: currentNode[1],
                groupParents: [...parents],
              });
            }
          }
          setSuggestions(() => currSuggestions);
        }
      );
    }
    collapseTreeTable();
    setExpandedKeys(null);
    setSelectedCheckedKeys([]);
  };

  const ColumnRowHeader = () => {
    return (
      <div className="group-prime-header">
        <div className="group-header-left">
          <div onClick={collapseTreeTable}>
            <img
              className="icon"
              src={CollapsableIcon}
              alt="collapsable"
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div>{f({ id: 'COM_DMS_GROUP_NAME' })}</div>
        </div>
      </div>
    );
  };

  const checkedColumn = (data) => {
    const { key, data: listData } = data ?? {};
    const { type } = listData ?? {};
    const selectedKeys = new Set(selectedCheckedKeys);
    return (
      <div>
        {type === 'group' && (
          <Checkbox
            checked={selectedKeys.has(key)}
            onChange={() => {
              if (selectedKeys.has(key)) {
                selectedKeys.delete(key);
              } else {
                selectedKeys.add(key);
              }
              setSelectedCheckedKeys(Array.from(selectedKeys));
            }}
          />
        )}
      </div>
    );
  };

  const groupColumn = ({ data, key }) => {
    const { type, groupName, deviceIp, deviceMac } = data ?? {};
    const innerGroupContent = () => {
      return (
        <>
          <img
            src={GroupIcon}
            alt="device"
            className="icon"
            style={{ marginRight: '1rem' }}
          />
          {groupName}
        </>
      );
    };
    if (type === 'device') {
      return (
        <>
          <img src={DeviceIcon} alt="device" className="device-icon" />
          {groupName}
          <Tooltip target={`#info-icon_${deviceMac}`}>
            <div className='infoIcon_tooltipContent'>
              <div>
                {f({ id: 'COM_DMS_ETHERNET_MAC' })}: {deviceMac}
              </div>
              <div>
                {f({ id: 'COM_DMS_IP' })}: {deviceIp}
              </div>
            </div>
          </Tooltip>
          <img
            src={InfoIcon}
            alt="info"
            id={`info-icon_${deviceMac}`}
            className="info-icon"
          />
        </>
      );
    } else {
      return !hasAllDevicePermission ? (
        <div className="group-name-component_device_permission">{innerGroupContent()}</div>
      ) : (
        <div
          className="group-name-component"
          onClick={() => {
            setOpenEditGroup(true);
            setSelectedEditKey(key);
          }}
        >
          {innerGroupContent()}
        </div>
      );
    }
  };

  const columns = [
    {
      field: 'checkedGroup',
      header: '',
      body: checkedColumn,
      className: 'checked-col',
      style: { width: '3%' },
    },
    {
      field: 'groupName',
      header: ColumnRowHeader,
      body: groupColumn,
      expander: true,
      style: { width: '87%' },
    },
    {
      field: 'deviceCount',
      style: { width: '10%' },
      header: f({ id: 'COM_DMS_DEVICES' }),
    },
  ];

  const traverseToRemoveChildrenOfAGroup = (keyNodes, key) => {
    if (!keyNodes) return;
    for (let index = 0; index < keyNodes.length; index++) {
      if (keyNodes[index].key === key) {
        keyNodes[index].children = [];
        return;
      } else {
        traverseToRemoveChildrenOfAGroup(keyNodes[index]?.children, key);
      }
    }
  };

  const traverseToAddChildrenOfAGroup = (
    keyNodes,
    key,
    children,
    deviceData
  ) => {
    if (!keyNodes) return;
    for (let index = 0; index < keyNodes.length; index++) {
      if (keyNodes[index].key === key) {
        keyNodes[index].children = [
          ...(keyNodes[index].children || []),
          ...children,
          ...(deviceData || []),
        ];
        if (!children.length && !deviceData?.length) {
          keyNodes[index] = { ...keyNodes[index], leaf: true };
        }
        return;
      } else {
        traverseToAddChildrenOfAGroup(
          keyNodes[index]?.children,
          key,
          children,
          deviceData
        );
      }
    }
  };

  const onExpand = async (event) => {
    const { node } = event;
    const { key } = node ?? {};
    const childrenNodes = [];
    const resNodes = _.cloneDeepWith(nodes);
    await GroupManagementService.onExpandGroups(key).then((data) => {
        const groupData = data?.[0]?.data;
        const deviceData = data?.[1]?.data;
        for (let index = 0; index < groupData?.length; index++) {
          const { groupId, groupName, preset, lastCommand, deviceCount } =
            groupData[index] ?? {};
          childrenNodes.push({
            key: groupId,
            data: {
              type: 'group',
              groupName,
              lastCommand,
              deviceCount,
              presetName: preset,
            },
            leaf: false,
          });
        }

        const reformedDeviceData = [];
        deviceData?.forEach((data, idx) => {
          const { deviceName, connectionStatus, deviceIp, deviceMac } =
            data ?? {};
          reformedDeviceData.push({
            key: `${key}#${idx}`,
            data: {
              type: 'device',
              groupName: deviceName,
              connectionStatus,
              deviceIp,
              deviceMac,
              leaf: true,
            },
          });
        });

        traverseToAddChildrenOfAGroup(
          resNodes,
          key,
          childrenNodes,
          reformedDeviceData
        );
        setNodes(resNodes);
      });
  };

  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }

    const expanded = options.expanded;

    return (
      <button
        type="button"
        className="p-treetable-toggler p-link"
        style={options.buttonStyle}
        tabIndex={-1}
        onClick={options.onClick}
      >
        <span>
          {expanded ? (
            <img src={MinusIcon} className="icon" alt="minus" />
          ) : (
            <img src={PlusIcon} className="icon" alt="plus" />
          )}
        </span>
      </button>
    );
  };

  const onCollapse = (event) => {
    const { node } = event;
    const { key } = node ?? {};
    const list = [];
    const resNodes = _.cloneDeepWith(nodes);
    traverseToRemoveChildrenOfAGroup(resNodes, key);
    list.push(key);
    getListOfAllChildComponents(node.children || [], list);
    collapseNodes(list);
    setNodes(resNodes);
  };
  const isDefaultGroupSelected = new Set(selectedCheckedKeys)?.has(0);

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleClickSearchSuggestions();
    }
  };

  return (
    <>
      {loading ? <Spinner /> : ''}
      <Toast ref={toastRef} position="top-center" />
      {openDeleteGroup && (
        <DeleteGroup
          setFlag={setFlag}
          setOpenDeleteGroup={setOpenDeleteGroup}
          selectedIds={selectedCheckedKeys}
        />
      )}
      <div className="group">
        {selectCommandPopup && (
          <SelectCommandPopup
            groupIds={selectedCheckedKeys}
            setSelectCommandPopup={setSelectCommandPopup}
          />
        )}

        {openCreateGroup && (
          <AddGroupPopup
            setFlag={setFlag}
            setOpenAddGroup={setOpenCreateGroup}
            renderInitialGroupData={renderInitialGroupData}
            setGroupSearchText={setSearchText}
            selectedKey={selectedCheckedKeys?.[0] || null}
          />
        )}
        {openEditGroup && (
          <EditGroupPopup
            setFlag={setFlag}
            setEditGroup={setOpenEditGroup}
            setGroupSearchText={setSearchText}
            selectedKey={selectedEditKey}
          />
        )}
        {openMoveGroup && (
          <MoveGroupPopup
            setFlag={setFlag}
            setOpenMoveGroup={setOpenMoveGroup}
            setGroupSearchText={setSearchText}
            selectedKey={selectedCheckedKeys?.[0]}
            renderInitialGroupData={renderInitialGroupData}
          />
        )}
        <div>
          <div className="device-group-header">
            <div>
              {hasAllDevicePermission && <CustomButton
                className="device-group-btn"
                text={f({ id: 'COM_DMS_ADD_GROUP' })}
                onClick={() => setOpenCreateGroup(true)}
                disabled={
                  selectedCheckedKeys.length > 1 ||
                  isDefaultGroupSelected ||
                  role === DEVICE_OPERATOR ||
                  role === DEVICE_MANAGER
                }
              />}
              {hasAllDevicePermission && <CustomButton
                className="device-group-btn"
                text={f({ id: 'COM_DMS_MOVE_GROUP' })}
                onClick={() => setOpenMoveGroup(true)}
                disabled={
                  selectedCheckedKeys.length !== 1 ||
                  isDefaultGroupSelected ||
                  role === DEVICE_OPERATOR ||
                  role === DEVICE_MANAGER
                }
              />}
              {hasAllDevicePermission && <CustomButton
                className="device-group-btn"
                text={f({ id: 'COM_DMS_DELETE_GROUP' })}
                disabled={
                  selectedCheckedKeys.length !== 1 ||
                  isDefaultGroupSelected ||
                  role === DEVICE_OPERATOR ||
                  role === DEVICE_MANAGER
                }
                onClick={() => setOpenDeleteGroup(true)}
              />}
            </div>
            <div className="device-search">
              <div className="device-select-command">
                <div>
                  <Tooltip target=".group-select-command-icon" position="left">
                    <div className="infoIcon_tooltipContent">
                      <div>
                        {f({ id: 'COM_DMS_DEVICE_SUBGROUP_TOOLTIP_TEXT' })}
                      </div>
                    </div>
                  </Tooltip>
                  <img
                    src={CommandIcon}
                    alt="command"
                    className="group-select-command-icon select-command-icon"
                  />
                </div>
                <Button
                  className="selectCommandsAll_btn gr_commandBtn"
                  onClick={() => setSelectCommandPopup(true)}
                  disabled={selectedCheckedKeys?.length === 0}
                >
                  {f({ id: 'COM_DMS_SELECT_COMMANDS' })}
                </Button>
              </div>
              <SearchBar
                value={searchText}
                className="search-txt"
                setValue={setSearchText}
                handleInputKeyDown={handleInputKeyDown}
                searchHandler={handleClickSearchSuggestions}
                placeholder={f({ id: 'COM_DMS_SEARCH_BY_GROUP' })}
              />
              {visible && (
                <GroupSuggestPopup
                  visible={visible}
                  setVisible={setVisible}
                  groups={suggestions}
                  groupList={groupList}
                  setGroupList={setGroupList}
                  setSearchText={setSearchText}
                  setNodes={setNodes}
                  treeTableNode
                  isGroupList
                  className="group-suggest"
                />
              )}
            </div>
          </div>
        </div>
        <div className="device-group card flex flex-column align-items-center">
          <TreeTable
            lazy
            value={nodes}
            scrollable
            onExpand={onExpand}
            scrollHeight="70vh"
            onCollapse={onCollapse}
            expandedKeys={expandedKeys}
            selectionKeys={selectedNodeKeys}
            togglerTemplate={togglerTemplate}
            onToggle={(e) => setExpandedKeys(e.value)}
            onSelectionChange={(e) => setSelectedNodeKeys(e.value)}
          >
            {columns.map((col, idx) => (
              <Column {...col} key={idx} />
            ))}
          </TreeTable>
        </div>
      </div>
    </>
  );
};

export default Group;
