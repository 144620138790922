import React, { useState, useEffect } from 'react';
import './ChangePassword.css';
import Overlay from '../../components/Overlay/Overlay';
import { useIntl } from 'react-intl';
import CustomButton from '../../components/CustomButton/CustomButton';
import PasswordValidatorTooltip from '../../components/Tooltip/PasswordValidatorTooltip/PasswordValidatorTooltip';
import VisibilityIcon from '../../assets/images/visibility.svg';
import VisibilityHideIcon from '../../assets/images/visibilityHideIcon.svg';
import EyeCloseIcon from '../../assets/images/eyeClose.svg';
import FloatLabel from '../../components/FloatLabel';
import { passwordMaxLength, validatePasswordFields } from "../../utils/Validations";

const ChangePassword = ({
  text,
  childText,
  changePwd,
  currentPwd,
  handleCancel,
  newPwd,
  setNewPwd,
  onSubmit,
  temporaryPwd,
  confirmNewPwd,
  setTemporaryPwd,
  setConfirmNewPwd,
  hideBackground,
  errorMessage,
}) => {
  const intl = useIntl();

  const newPwdValidation = validatePasswordFields(newPwd);

  const [focussedNewPassword, setFocussedNewPassword] = useState(false);
  const [currentPwdType, setCurrentPwdType] = useState("password");
  const [newPwdType, setNewPwdType] = useState("password");
  const [confirmPwdType, setConfirmPwdType] = useState('password');

    const onCurrentPasswordVisibilityChange = () => {
    if(temporaryPwd?.length <= 0){return;}
    if (currentPwdType === "text") {
      setCurrentPwdType("password");
    } else {
      setCurrentPwdType("text");
    }
  }

  const onNewPasswordVisibilityChange = () => {
    if(newPwd?.length <= 0){return;}
    if (newPwdType === "text") {
      setNewPwdType("password");
    } else {
      setNewPwdType("text");
    }
  }
  const onConfirmPasswordVisibilityChange = () => {
    if(confirmNewPwd?.length <= 0){return;}
    if (confirmPwdType === "text") {
      setConfirmPwdType("password");
    } else {
      setConfirmPwdType("text");
    }
  };

  useEffect(() => {
    document
      .querySelector('.user-change-pwd-card #newPwd')
      ?.addEventListener('focus', () => {
        setFocussedNewPassword(true);
      });
    document
      .querySelector('.user-change-pwd-card #newPwd')
      ?.addEventListener('focusout', () => {
        setFocussedNewPassword(false);
      });
  }, [focussedNewPassword]);

  useEffect(() => {
    if (temporaryPwd?.length === 0) {
      setCurrentPwdType('password');
    }
  }, [temporaryPwd]);

  useEffect(() => {
    if (newPwd?.length === 0) {
      setNewPwdType('password');
    }
  }, [newPwd]);

  useEffect(() => {
    if (confirmNewPwd?.length === 0) {
      setConfirmPwdType('password');
    }
  }, [confirmNewPwd]);

  return (
    <Overlay
      className={
        hideBackground ? 'forgotPasswordOverlay' : 'changePasswordOverlay'
      }
    >
      <div className="user-change-pwd">
        <div
          className={'user-change-pwd-card'}
        >
          <div style={{position:'absolute',width: '-webkit-fill-available'}}>
          <span className="changePasswordErrorStyle">{errorMessage}</span>
          </div>
          <div
            className={`user-change-pwd-card-header change-pwd-margin`}
          >
            {text}
          </div>
          {childText && <div className="user-change-pwd-text">{childText}</div>}
          <div className="change-pwd-input">
            <FloatLabel
              value={temporaryPwd}
              label={currentPwd}
              className={temporaryPwd ? 'float-input' : ''}
            >
              <input
                id="tempPwd"
                type={currentPwdType}
                value={temporaryPwd}
                maxLength={passwordMaxLength}
                onChange={(e) => setTemporaryPwd(e.target.value)}
              />
              {currentPwdType === "password" ? temporaryPwd?.length > 0 ?
                (
                  <img
                    src={EyeCloseIcon}
                    alt="visible"
                    className="confirm-pwd-hide"
                    style={{ height: "0.9rem" }}
                    onClick={onCurrentPasswordVisibilityChange}
                  />
                ) : (
                  <img
                    src={VisibilityHideIcon}
                    alt="visible"
                    className="confirm-pwd-hide"
                    onClick={onCurrentPasswordVisibilityChange}
                  />
                ) : (
                  <img
                    src={VisibilityIcon}
                    alt="visible"
                    className="confirm-pwd-visible"
                    onClick={onCurrentPasswordVisibilityChange}
                  />
                )}
            </FloatLabel>
          </div>
          <div className="change-pwd-input">
            <FloatLabel
              value={newPwd}
              label={intl.formatMessage({ id: 'COM_DMS_NEW_PWD' })}
              className={newPwd ? 'float-input' : ''}
            >
              <input
                id="newPwd"
                value={newPwd}
                type={newPwdType}
                maxLength={passwordMaxLength}
                onChange={(e) => setNewPwd(e.target.value)}
              />
               {newPwdType === "password" ?  newPwd?.length > 0 ? (
                      <img
                        src={EyeCloseIcon}
                        alt="visible"
                        className="confirm-pwd-hide"
                        style={{ height: "0.9rem" }}
                        onClick={onNewPasswordVisibilityChange}
                      />
                    ) : (
                  <img
                    src={VisibilityHideIcon}
                    alt="visible"
                    className="confirm-pwd-hide"
                    onClick={onNewPasswordVisibilityChange}
                  />
                ) : (
                  <img
                    src={VisibilityIcon}
                    alt="visible"
                    className="confirm-pwd-visible"
                    onClick={onNewPasswordVisibilityChange}
                  />
                )}
              {focussedNewPassword && (
                <PasswordValidatorTooltip {...newPwdValidation} />
              )}
            </FloatLabel>
          </div>
          <div className="change-pwd-input">
            <FloatLabel
              value={confirmNewPwd}
              className={confirmNewPwd ? 'float-input' : ''}
              label={intl.formatMessage({ id: 'COM_DMS_CONFIRM_NEW_PWD' })}
            >
              <input
                id="confirmNewPwd"
                type={confirmPwdType}
                value={confirmNewPwd}
                className="confirmNewPwd"
                maxLength={passwordMaxLength}
                onChange={(e) => setConfirmNewPwd(e.target.value)}
              />
               {confirmPwdType === "password" ? confirmNewPwd?.length > 0 ? (
                      <img
                        src={EyeCloseIcon}
                        alt="visible"
                        className="confirm-pwd-hide"
                        style={{ height: "0.9rem" }}
                        onClick={onConfirmPasswordVisibilityChange}
                      />
                    ) : (
                <img
                  src={VisibilityHideIcon}
                  alt="visible"
                  className="confirm-pwd-hide"
                  onClick={onConfirmPasswordVisibilityChange}
                />
              ) : (
                <img
                  src={VisibilityIcon}
                  alt="visible"
                  className="confirm-pwd-visible"
                  onClick={onConfirmPasswordVisibilityChange}
                />
              )}
            </FloatLabel>
            
          </div>
          <div className="change_password_buttons">
            <CustomButton
              className="user-change-pwd-btn cancel-btn"
              text={
                hideBackground
                  ? intl.formatMessage({ id: 'COM_DMS_LOGOUT' })
                  : intl.formatMessage({ id: 'COM_DMS_DISCARD' })
              }
              onClick={handleCancel}
            />
            <CustomButton
              className="user-change-pwd-btn"
              text={intl.formatMessage({ id: 'COM_DMS_CHANGE_PWD_TXT' })}
              onClick={onSubmit}
              disabled={!(temporaryPwd && newPwd && confirmNewPwd)}
            />
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export default ChangePassword;
