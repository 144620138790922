import React, { useEffect, useState } from 'react';
import './DeviceDetails.css';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { tagServices } from '../../../../services/RestServices/tagServices';
import { Tag } from 'primereact/tag';
import { useIntl } from 'react-intl';
import { tagsMaxLength, validateDeviceDetails } from '../../../../utils/Validations';
import { showErrorToast } from '../../../../services/APIResponseHandler';
import CancelRoundOutline from '../../../../assets/images/cancel-round-outline.svg';

const DeviceDetailedInfo = ({details, deviceLock, setDeviceLock, tags, setTags}) => {

  const intl = useIntl();
  const { formatMessage: f } = intl;
  const [suggestedTags, setSuggestedTags] = useState([]);
  const [inputTag, setInputTag] = useState('');
  const [addNewTag, setAddNewTag] = useState(false);

  const showTagListHandler = () => {
    return(
    <div className="device_TagsContainer">
        {tags && tags?.length > 0 && tags?.map((tag) =>
            <Tag className="deviceTags" id="device_Tags" value={tag.tagName} rounded>
            <img src={CancelRoundOutline} onClick={() => { removeAssignedTag(tag.tagName) }} className="remove-tag" alt="cancel" />
            </Tag>)}
    </div>)
  }

  const addNewTagHandler = async() => {
    setAddNewTag(false);

    const res = validateDeviceDetails('deviceTags', inputTag);
    const isValid = res.isValid;

    if(isValid) {
        setInputTag('');
        await Promise.all([tagServices.addTagsToMultipleDevices({
            deviceIds: [details.deviceId],
            tagNames: [inputTag],
        })]);
        setAddNewTag(true);
    } else {
        showErrorToast(f({ id: res.messageId }));
    }

  };

  const removeAssignedTag = async(tag) => {
    setAddNewTag(false);
    await Promise.all([tagServices.removeDeviceTags({
        deviceIds: [details.deviceId],
        tagNames: [tag],
      })]);
    setAddNewTag(true);
  }

  useEffect(() => {
    tagServices.getTagByDeviceId(details.deviceId).then(({ data }) => 
        setTags(data));
    showTagListHandler();
  }, [addNewTag]);

  useEffect(() => {
    if (inputTag) {
      const newSuggestedTags = tags && tags.length > 0 && tags?.filter((tag) =>
        tag.tagName.toLowerCase() === (inputTag.toLowerCase())
      );
      setSuggestedTags(newSuggestedTags);
    } else {
      setSuggestedTags([]);
    }
  }, [inputTag]);

  return (
    <>
      <div className="detailedPageLayout">
       <div className="groupSection"> 
           <div className="postionGroupSection">
              <div className="detailedPageInfo">{f({ id: "COM_DMS_DEVICE_INFO" })}</div>
              <div className="detailedPageSectionHeader">{f({ id: "COM_DMS_GROUP" })}</div>
              <div style={{marginRight: '2rem'}}>
              </div>
           </div>
            <div className="detailedPageValue">{details.deviceInfo?.groupName}</div>
        </div>
        <div className="sourceSection">
           <div className="detailedPageSectionHeader">{f({ id: "COM_DMS_INPUT_SOURCE" })}</div>
           <div className="detailedPageValue">{details.deviceInfo?.deviceCurrentInputSource}</div>
        </div>
        <div className="tagSection">
            <div style={{display: "flex"}}> 
                <div className="detailedPageSectionHeader">{f({ id: "COM_DMS_TAGS" })}</div>
                <div style={{display: "flex"}}>
                    <InputText 
                        className="detailsPageTagBox"
                        placeholder={f({id: "COM_DMS_PLACEHOLDER_ADD_TAG"})}
                        value={inputTag}
                        maxLength={tagsMaxLength}
                        onChange={(e) => {setInputTag(e.target.value)}}
                    />
                    {inputTag && !suggestedTags?.length && (
                    <Button
                      className="addNewTagButton"
                      onClick={() => addNewTagHandler()}
                      label={f({ id: "COM_DMS_ADD" })}
                    />
                  )}
                </div>
            </div>
            <div>{showTagListHandler()}</div>
        </div>
        <div className="hardwareSection">
            <div className="detailedPageSectionHeader">{f({ id: "COM_DMS_HARDWARE_INFO" })}</div>
            <div className="sectionInfoLayout">
                <label className="info_label">
                    <span className="infoSubLabel">
                    {f({ id: "COM_DMS_DEVICE_TYPE" })}
                    </span>
                </label>
                <span>:</span>
                <span className="infoSubLabelDetail">{details.deviceInfo?.deviceType !== undefined ? details.deviceInfo?.deviceType : '-'}</span>
            </div>
            <div className="sectionInfoLayout">
                <label className="info_label">
                    <span className="infoSubLabel">
                    {f({ id: "COM_DMS_DEVICE_ID" })}
                    </span>
                </label>
                <span>:</span>
                <span className="infoSubLabelDetail">{details.deviceId}</span>
            </div>
            <div className="sectionInfoLayout">
                <label className="info_label">
                    <span className="infoSubLabel">
                    {f({ id: "COM_DMS_MODEL_NAME" })}
                    </span>
                </label>
                <span>:</span>
                <span className="infoSubLabelDetail">{details.deviceInfo?.deviceModelName !== undefined ? details.deviceInfo?.deviceModelName : '-'}</span>
            </div>
            <div className="sectionInfoLayout">
                <label className="info_label">
                    <span className="infoSubLabel">
                    {f({ id: "COM_DMS_CPU" })}
                    </span>
                </label>
                <span>:</span>
                <span className="infoSubLabelDetail">{details.deviceHardwareInfo !== undefined && details.deviceHardwareInfo.cpuType !== undefined ? details.deviceHardwareInfo.cpuType : '-'}</span>
            </div>
            <div className="sectionInfoLayout">
                <label className="info_label">
                    <span className="infoSubLabel">
                    {f({ id: "COM_DMS_TOUCH_FRAME" })}
                    </span>
                </label>
                <span>:</span>
                <span className="infoSubLabelDetail">{details.deviceHardwareInfo !== undefined &&  details.deviceHardwareInfo.touchFrameType !== undefined ? details.deviceHardwareInfo.touchFrameType : "-"}</span>
            </div>
            <div className="sectionInfoLayout">
                <label className="info_label">
                    <span className="infoSubLabel">
                    {f({ id: "COM_DMS_SERIAL_NUMBER" })}
                    </span>
                </label>
                <span>:</span>
                <span className="infoSubLabelDetail">{details.deviceInfo?.deviceSerial !== undefined ? details.deviceInfo.deviceSerial : "-"}</span>
            </div>
        </div>
        <div className="softwareSection">
            <div className="detailedPageSectionHeader">{f({ id: "COM_DMS_SOFTWARE_INFO" })}</div>
            <div className="sectionInfoLayout">
                <label className="info_label">
                    <span className="infoSubLabel">
                    {f({ id: "COM_DMS_OS" })}
                    </span>
                </label>
                <span>:</span>
                <span className="infoSubLabelDetail">{details.deviceInfo?.deviceType !== undefined ? details.deviceInfo.deviceType : '-'}</span>
            </div>
            <div className="sectionInfoLayout">
                <label className="info_label">
                    <span className="infoSubLabel">
                    {f({ id: "COM_DMS_OS_VERSION" })}
                    </span>
                </label>
                <span>:</span>
                <span className="infoSubLabelDetail">{details.deviceInfo?.deviceOsVersion !== undefined ? details.deviceInfo.deviceOsVersion : '-'}</span>
            </div>
            <div className="sectionInfoLayout">
                <label className="info_label">
                    <span className="infoSubLabel">
                    {f({ id: "COM_DMS_CLIENT_VERSION" })}
                    </span>
                </label>
                <span>:</span>
                <span className="infoSubLabelDetail">{details.deviceInfo?.dmsClientVersion !== undefined ? details.deviceInfo.dmsClientVersion : '-'}</span>
            </div>
        </div>
        {details.deviceNetworkInfoList !== undefined && details.deviceNetworkInfoList.length > 0  &&
        <div className="networkSection">
            <div className="detailedPageSectionHeader">{f({ id: "COM_DMS_NETWORK_INFO" })}</div>
            {details.deviceNetworkInfoList.map((data) =>
            <div className="networkSubSection">
            <div className="sectionInfoLayout">
              <label className="info_label">
                  <span className="infoSubLabel">
                  {f({ id: "COM_DMS_NW_INTERFACE" })}
                  </span>
              </label>
              <span>:</span>
              <span className="infoSubLabelDetail">{data.interfaceName === "" ? '-' : data.interfaceName}</span>
            </div>
            <div className="sectionInfoLayout">
            <label className="info_label">
                <span className="infoSubLabel">
                {f({ id: "COM_DMS_NW_TYPE" })}
                </span>
            </label>
            <span>:</span>
            <span className="infoSubLabelDetail">{data.interfaceType === "" ? '-' : data.interfaceType}</span>
          </div>
          <div className="sectionInfoLayout">
            <label className="info_label">
                <span className="infoSubLabel">
                {f({ id: "COM_DMS_MAC" })}
                </span>
            </label>
            <span>:</span>
            <span className="infoSubLabelDetail">{data.interfaceMacAddress === "" ? '-' : data.interfaceMacAddress}</span>
          </div><div className="sectionInfoLayout">
            <label className="info_label">
                <span className="infoSubLabel">
                {f({ id: "COM_DMS_IP" })}
                </span>
            </label>
            <span>:</span>
            <span className="infoSubLabelDetail">{data.interfaceIpAddressList === "" ? '-' : data.interfaceIpAddressList}</span>
          </div>
            </div>)}
        </div>}
        <div className="settingsSection">
            <div className="detailedPageSectionHeader">{f({ id: "COM_DMS_SECURITY" })}</div>
            <div className="sectionInfoLayout">
                <label className="info_label">
                    <span className="infoSubLabel">
                    {f({ id: "COM_DMS_DEVICE_LOCK" })}
                    </span>
                </label>
                <span>:</span>
                <span className="infoSubLabelDetail">{details.deviceInfo?.deviceLockStatus !== undefined ? details.deviceInfo?.deviceLockStatus ? f({ id: 'COM_DMS_ON' }) : f({ id: 'COM_DMS_OFF' }) : '-'}</span>
            </div>
            <div className="sectionInfoLayout">
                <label className="info_label">
                    <span className="infoSubLabel">
                    {f({ id: "COM_DMS_SETTINGS_LOCK" })}
                    </span>
                </label>
                <span>:</span>
                <span className="infoSubLabelDetail">{details.deviceInfo?.settingsLockStatus !== undefined ? details.deviceInfo?.settingsLockStatus ? f({ id: 'COM_DMS_ON' }) : f({ id: 'COM_DMS_OFF' }) : '-'}</span>
            </div>
        </div>
      </div>
    </>
  )
}

export default DeviceDetailedInfo;
