import React, { useState } from 'react';
import './SelectDevices.css';
import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import { Checkbox } from 'primereact/checkbox';
import _ from 'lodash';
import {
  cleanJSONTree,
  removeNullValuesFromNestedObjectOfArray,
} from '../../utils/CleanJSONArrayObject';
import TransferIcon from '../../assets/images/moveRight.png';
import PlusIcon from '../../assets/images/plusIcon.svg';
import MinusIcon from '../../assets/images/minusIcon.svg';
import GroupIcon from '../../assets/images/groupIcon.svg';
import DeviceIcon from '../../assets/images/deviceGroupIcon.svg';
import { deviceServices } from '../../services/RestServices/deviceServices';
import { useIntl } from 'react-intl';
import SearchBar from '../../components/SearchBar/SearchBar';
import { GroupSuggestPopup } from '../../components/PopUp/GroupSuggest';
import { GroupManagementService } from '../../services/RestServices/GroupManagementService';
import { GROUP_MGMT_API } from '../../config/constants/Constants';
import { RestServiceUtils } from '../../utils/RestServiceUtils';
import flatToTree from 'flat-to-tree';
import { convertJSONDataToTreeTableFormat } from '../../modules/Devices/Group/convertToJSONTree';
import { Tooltip } from 'primereact/tooltip';
import InfoIcon from '../../assets/images/info-fill.svg';
import CollapsableIcon from '../../assets/images/collapsibleIcon.svg';
import CancelRoundOutline from '../../assets/images/cancel-round-outline.svg';

const SelectDevices = ({
  jsonGroupTree,
  setJsonGroupTree,
  selectedCheckedKeys,
  setSelectedCheckedKeys,
  devices,
  setDevices,
  deviceParentIds,
  setDeviceParentIds,
  appPackage,
  isAppManagement,
  emptyTargetDeviceId,
}) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const [searchText, setSearchText] = useState('');
  const [visible, setVisible] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [groupList, setGroupList] = useState({});
  const [expandedKeys, setExpandedKeys] = useState({});
  const handleTransferDevices = () => {
    const jsonData = _.cloneDeepWith(jsonGroupTree);
    const deviceIDs = [];
    moveDevicesToRight(jsonData, deviceIDs);
    const cleanJSONTreeWithoutNullValues = cleanJSONTree(jsonData);
    setJsonGroupTree(() => cleanJSONTreeWithoutNullValues);
  };

  const collapseNodes = (list) => {
    let _expandedKeys = { ...expandedKeys };
    const keyMap = new Map(Object.entries(expandedKeys));
    for (let idx = 0; idx < list.length; idx++) {
      let val = '' + list[idx];
      if (keyMap.has(val)) {
        delete _expandedKeys[val];
      }
    }
    setExpandedKeys(_expandedKeys);
  };

  const moveDevicesToRight = (jsonData, deviceIds) => {
    if (!jsonData) return;
    const selectedKeysSet = new Set(selectedCheckedKeys);
    jsonData?.forEach((group, idx) => {
      if (jsonData[idx] && selectedKeysSet.has(group?.key)) {
        setDevices((devices) => [...devices, group]);
        deviceIds.push(group.key);
        delete jsonData[idx];
        selectedKeysSet.delete(group.key);
        setSelectedCheckedKeys(Array.from(selectedKeysSet));
      }
      moveDevicesToRight(group?.children, deviceIds);
    });
  };

  const getAllGroupsList = () => {
    RestServiceUtils.HTTP_GET(GROUP_MGMT_API).then(({ data }) => {
      const convertedJSONData = flatToTree(data, {
        id: 'groupId',
        parentId: 'parentId',
      });
      convertJSONDataToTreeTableFormat(convertedJSONData);
      cleanJSONTree(convertedJSONData);
      const cleanedJSONTree =
        removeNullValuesFromNestedObjectOfArray(convertedJSONData);
      setJsonGroupTree(cleanedJSONTree);
    });
    setSelectedCheckedKeys([]);
    setDevices([]);
    setDeviceParentIds([]);
  };

  const onExpand = async (event) => {
    const { node } = event;
    const { key } = node;
    const keys = new Set(deviceParentIds);
    if (keys.has(key)) {
      return;
    }

    const keysOfChildren = [];
    for (let child of node?.children) {
      if (child.data.type === 'device') {
        keysOfChildren.push(child.key);
      }
    }

    const keysSet = new Set(keysOfChildren);
    const resNodes = _.cloneDeepWith(jsonGroupTree);
    deviceServices
      .getDevicesByGroupAndAppPackage(key, isAppManagement ? appPackage : {})
      .then(({ data }) => {
        const reformedDeviceData = [];
        data?.forEach((d, idx) => {
          const { deviceName, deviceMac, deviceIp } = d ?? {};
          if (!keysSet.has(deviceMac))
            reformedDeviceData.push({
              key: deviceMac,
              label: deviceName,
              data: {
                groupName: deviceName,
                type: 'device',
                parentId: key,
                deviceMac,
                deviceIp,
              },
              leaf: true,
            });
        });
        traverseToAddDevices(resNodes, reformedDeviceData, key);
        setJsonGroupTree(resNodes);
        keys.add(key);
        setDeviceParentIds(Array.from(keys));
      });
  };

  const onCollapse = (event) => {
    const { node } = event;
    const { key } = node;

    traverseToRemoveDevices(jsonGroupTree, key);
  };

  const traverseToRemoveDevices = (nodes, key) => {
    if (!nodes) {
      return;
    }

    for (let index = 0; index < nodes.length; index++) {
      if (nodes[index].key === key) {
        const filteredData = nodes[index]?.children?.filter(
          (node) => node?.type !== 'device'
        );
        nodes[index].children = [...(filteredData || [])];
      } else {
        traverseToRemoveDevices(nodes[index]?.children, key);
      }
    }
  };

  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }
    const expanded = options.expanded;
    return (
      <button
        type="button"
        className="p-treetable-toggler p-link"
        style={options.buttonStyle}
        tabIndex={-1}
        onClick={options.onClick}
      >
        <span>
          {expanded ? (
            <img
              src={MinusIcon}
              className="icon"
              alt="minus"
              style={{ height: '0.7rem' }}
            />
          ) : (
            <img
              src={PlusIcon}
              className="icon"
              alt="plus"
              style={{ height: '0.7rem' }}
            />
          )}
        </span>
      </button>
    );
  };

  const addGroupBody = ({ data, key }) => {
    const { type, groupName } = data ?? {};
    const selectedKeys = new Set(selectedCheckedKeys);

    return type !== 'device' ? (
      <>
        <img
          src={GroupIcon}
          alt="group"
          style={{ marginRight: '1rem', height: '1rem' }}
        />
        {groupName}
      </>
    ) : (
      <>
        <Checkbox
          checked={selectedKeys.has(key)}
          onChange={() => {
            if (selectedKeys.has(key)) {
              selectedKeys.delete(key);
            } else {
              selectedKeys.add(key);
            }
            setSelectedCheckedKeys(Array.from(selectedKeys));
          }}
          style={{ marginTop: '-0.6rem' }}
        />
        <img
          src={DeviceIcon}
          alt="device"
          style={{ marginRight: '1rem', height: '1rem' }}
        />
        {groupName}
        <Tooltip target={`#info-icon_${data.deviceMac}`}>
          <div className="infoIcon_tooltipContent">
            <div>
              {f({ id: 'COM_DMS_ETHERNET_MAC' })}: {data?.deviceMac}
            </div>
            <div>
              {f({ id: 'COM_DMS_IP' })}: {data?.deviceIp}
            </div>
          </div>
        </Tooltip>
        <img
          src={InfoIcon}
          alt="info"
          className="info-icon icon"
          id={`info-icon_${data.deviceMac}`}
          style={{ marginLeft: '1rem' }}
        />
      </>
    );
  };

  const renderDevice = (device) => {
    const { data } = device ?? {};
    const { groupName: deviceName, deviceIp, deviceMac } = data ?? {};
    return (
      <div className="device_info_row">
        <div className="device_info name">{deviceName}</div>
        <div className="device_info mac">{deviceMac}</div>
        <div className="device_info ip">{deviceIp}</div>
        <div className=" cross">
          <img
            src={CancelRoundOutline}
            onClick={() => handleCancelDevice(device)}
            className="remove-device"
            alt="cancel"
          />
        </div>
      </div>
    );
  };

  const renderEmptyTargetDevice = () => {
    return (
      <div className="device_info_empty_row">
        {f({ id: emptyTargetDeviceId })}
      </div>
    );
  };

  const movementOfDeviceBackToJSONTree = (jsonTree, deviceData, id) => {
    if (!jsonTree) {
      return;
    }
    for (let node of jsonTree) {
      if (node.key === id) {
        node.children = [...(node.children || []), deviceData];
        return;
      } else {
        movementOfDeviceBackToJSONTree(node?.children, deviceData, id);
      }
    }
  };

  const traverseToAddDevices = (nodes, deviceData, key) => {
    if (!nodes) {
      return;
    }

    for (let index = 0; index < nodes.length; index++) {
      if (nodes[index].key === key) {
        nodes[index].children = [
          ...(nodes[index].children || []),
          ...(deviceData || []),
        ];
        if (!deviceData.length) {
          nodes[index] = { ...nodes[index], leaf: true };
        }
        return;
      } else {
        traverseToAddDevices(nodes[index]?.children, deviceData, key);
      }
    }
  };

  const handleCancelDevice = (device) => {
    const jsonTreeNodesData = _.cloneDeepWith(jsonGroupTree);
    const { parentId, deviceMac } = device.data;
    movementOfDeviceBackToJSONTree(jsonTreeNodesData, device, parentId);
    const filteredDevices = devices.filter(
      (currDevice) => currDevice.data.deviceMac !== deviceMac
    );
    setDevices(filteredDevices);
    setJsonGroupTree(jsonTreeNodesData);
  };

  const handleClickSearchSuggestions = () => {
    if (searchText === '') {
      getAllGroupsList();
    } else {
      GroupManagementService.getGroupListFilter(searchText).then((res) => {
        const { data } = res ?? {};
        setVisible(true);
        let map = new Map();
        let currSuggestions = [];
        setDeviceParentIds([]);
        setDevices([]);

        const groupList = [];
        Object.values(data).forEach((val) => {
          groupList.push(val.groupId);
          map.set(
            [val.groupName, val.groupId],
            val.parents
              ? val.parents.map((parent) => [parent.groupName, parent.groupId])
              : []
          );
        });

        for (const x of map.entries()) {
          const currentNode = x[0];
          const parents = x[1];
          if (currentNode[0].toLowerCase().includes(searchText.toLowerCase())) {
            currSuggestions.push({
              groupName: currentNode[0],
              groupId: currentNode[1],
              groupParents: [...parents],
            });
          }
        }
        setSuggestions(() => currSuggestions);
      });
    }
    collapseTreeTable();
  };

  const getListOfAllChildComponents = (nodes, list) => {
    if (!nodes) return;

    for (let node of nodes) {
      list.push(node.key);
      getListOfAllChildComponents(node.children, list);
    }
  };

  const collapseTreeTable = () => {
    if (!expandedKeys) return;
    let collapsedNodes = [];
    let list = [];
    for (let node of jsonGroupTree) {
      list.push(node.key);
      if (node?.children && node?.children?.length) {
        getListOfAllChildComponents(node.children, list);
      }
      collapsedNodes.push({ ...node, leaf: false });
      collapseNodes(list);
    }
    setJsonGroupTree(collapsedNodes);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleClickSearchSuggestions();
    }
  };

  const groupNameHeader = () => {
    return (
      <div className="select-group-tree-header">
        <div onClick={collapseTreeTable} className="collapse-icon">
          <img src={CollapsableIcon} alt="collapse" className="icon" />
        </div>
        {f({ id: 'COM_DMS_GROUP_NAME' })}
      </div>
    );
  };

  return (
    <>
      <div className="move_items_box">
        <div className="tree_component">
          <div className="select-devices">
            <div className="select_device_header">
              {f({ id: 'COM_DMS_SELECT_DEVICE' })}
            </div>
            <div className="search_section">
              <SearchBar
                className="search-bar"
                value={searchText}
                setValue={setSearchText}
                setVisiblePopup={setVisible}
                handleInputKeyDown={handleInputKeyDown}
                placeholder={f({ id: 'COM_DMS_GROUP_NAME' })}
                searchHandler={handleClickSearchSuggestions}
              />
              {visible && (
                <GroupSuggestPopup
                  visible={visible}
                  setVisible={setVisible}
                  groups={suggestions}
                  groupList={groupList}
                  setGroupList={setGroupList}
                  setSearchText={setSearchText}
                  setNodes={setJsonGroupTree}
                  treeTableNode
                  className="select-device-group-suggest"
                />
              )}
            </div>
          </div>
          <div className="select-device-table">
            <TreeTable
              value={jsonGroupTree}
              onExpand={onExpand}
              onCollapse={onCollapse}
              expandedKeys={expandedKeys}
              togglerTemplate={togglerTemplate}
              onToggle={(e) => setExpandedKeys(e.value)}
              scrollable
              scrollHeight="69vh"
            >
              <Column
                expander
                field="groupName"
                body={addGroupBody}
                header={groupNameHeader}
              />
            </TreeTable>
          </div>
        </div>
        <div onClick={handleTransferDevices}>
          <img src={TransferIcon} alt="transfer" style={{ height: '1.5rem' }} />
        </div>
        <div className="tree_component">
          <div className="select-devices">
            <div className="select_device_header">
              {f({ id: 'COM_DMS_TARGET_DEVICE' })}
            </div>
          </div>
          <div className="target-device-table">
            <div className="device-header">
              <div style={{ width: '30%' }}>Devices</div>
              <div style={{ width: '35%' }}>MAC ID</div>
              <div style={{ width: '35%' }}>IP</div>
            </div>
            {devices.length !== 0
              ? devices?.map((device) => renderDevice(device))
              : renderEmptyTargetDevice()}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectDevices;
