import React from 'react';
import './Card.css';

const Card = (props) => {
  const { className, onCardClick } = props ?? {};
  return (
    <div className={`common-card ${className}`} onClick={onCardClick}>
      {props.children}
    </div>
  );
};

export default Card;
