import jwtDecode from "jwt-decode";
import { isAuthenticated } from "../redux/actions/AuthAction";
import SetAuthToken from "./SetAuthToken";

export const authenticate = () => {
  const user = JSON.parse(localStorage?.getItem("user"));
  const { token } = user ?? {};
  if (token) {
    let jwtExpTime = jwtDecode(token).exp;
    if (jwtExpTime * 1000 < new Date().getTime()) {
      localStorage.removeItem("user");
      return false;
    } else {
      isAuthenticated(true);
      SetAuthToken(token);
      return true;
    }
  }
  return false;
};
