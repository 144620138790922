import React, { useEffect, useRef, useState } from 'react'; 
import { Card } from 'primereact/card';
import './Grid.css';
import deviceOFF from '../../assets/images/DeviceOff.svg';
import deviceON from '../../assets/images/NoPreview.svg';
import info from '../../assets/images/info-fill-black.svg';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { deviceServices } from '../../services/RestServices/deviceServices';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import HoveredText from '../HoveredText/HoveredText';

export const DeviceGrid = ({
  visible,
  deviceName,
  mac,
  OS,
  IP,
  group,
  data,
  checkedInfo,
  checkedAll,
  selectedItems,
  setSelectedItems,
  checkedTag,
  setPageToShow,
  setDetails,
  onChange,
  selectAll,
  setSelectAll,
  refreshPage,
  setDeviceID,
  rows
}) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const [checkedDevice, setCheckedDevice] = useState(false);
  const [updatedScreen, setUpdatedScreen] = useState(false);
  const [showDetailLayer, setShowDetailLayer] = useState(false);
  const { deviceId, deviceScreenPreview, connectionStatus } = data ?? {};
  const [screenPreview, setScreenPreview] = useState("");
  const [toggleScreen, setToggleScreen] = useState(false);
  const [showExtraTags, setShowExtraTags] = useState(false);

  useEffect(() => {
    setUpdatedScreen(!updatedScreen);
  }, []);

  const triggerScreenPreviewHandler = async () => {
    if (deviceScreenPreview === 'START') {
      deviceServices.getScreenPreview(deviceId, 220, 124).then((response) => {
        if (response.status === 200) {
          const { src } = response?.data ?? '';
          setScreenPreview(src);
        } else setScreenPreview("");
      });
    }
    setUpdatedScreen((updatedScreen) => !updatedScreen);
  };

  useEffect(() => {
      if(deviceScreenPreview === 'START'){
        if(toggleScreen){
          deviceServices.triggerScreenPreviewEvent(
            deviceId,
            'START'
          ).then((response) => {
            if(deviceScreenPreview === 'START'){
              if(response.status === 200){
                triggerScreenPreviewHandler();
              }
              else{
                setScreenPreview("");
              }
            }
          })
        }
        else if(deviceScreenPreview === 'START'){
          triggerScreenPreviewHandler();
        }
      }
      else if(deviceScreenPreview === 'STOP' && toggleScreen){
        deviceServices.triggerScreenPreviewEvent(
          deviceId,
          'STOP'
        )
      }
  }, [deviceScreenPreview === 'START']);
  
  useEffect(() => {
    setCheckedDevice(false);
  }, [refreshPage, rows]);

  useEffect(() => {
    if (deviceScreenPreview === 'START' && connectionStatus) {
      setTimeout(() => triggerScreenPreviewHandler(), 16000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedScreen]);

  const handleCardSelection = (data) => {
    setSelectAll(false);
    setCheckedDevice((checkedDevice) => !checkedDevice);
    const isDuplicate = selectedItems?.some(item => item.deviceId === data.deviceId);
    if (!isDuplicate) {
      setSelectedItems((selectedItems) => [...selectedItems, data]);
    } else {
         setSelectedItems(
        selectedItems.filter((item) => item?.deviceId !== deviceId));
    }
  };

  const handleShowDetails = async () => {
    setDeviceID(data.deviceId);
    await Promise.all([
      deviceServices.getDeviceUsageInfo(deviceId)
    ]);
    await Promise.all([deviceServices.getDeviceInfoById(deviceId).
            then(({data}) => 
              setDetails(data)
            )]);
    setPageToShow('ALL_DEVICE_DETAILS');
  };

  useEffect(() => {
    if (selectedItems?.length !== 0) {
      if (selectAll) {
        checkedAll ? setCheckedDevice(true) : setCheckedDevice(false);
      } else {
        setCheckedDevice((checkedDevice) => checkedDevice)
      }
    }
  }, [selectAll]);

  useEffect(() => {
    if (selectedItems?.length === 0) {
      setCheckedDevice(false);
    }
    else {
      if (!checkedAll) {
       !selectAll && setCheckedDevice((checkedDevice) => !checkedDevice);
      }
      else {
        setCheckedDevice((checkedDevice) => !checkedDevice)
      }
    }
  }, [checkedAll]);

  function DropdownButton(extraTags) {
    return (
      <div className="floating-tag-container" onMouseOver={() => setShowExtraTags(true)} onMouseLeave={() => setShowExtraTags(false)}>
        {extraTags?.map(tag => (
          <div className='tagItem'>
            {tag}
          </div>
        ))}
      </div>
    );
  }

  const tagRef = useRef(null);
    
  const handleTagMouseEnter = () => {
    const { scrollWidth, clientWidth } = tagRef.current;
    if (scrollWidth > clientWidth) {
      setShowExtraTags(true);
    }
  };

  const header = (
    <div className={`stacked_div_container`} onMouseOver={() => (!checkedInfo && !checkedTag) && setShowDetailLayer(true)} onMouseLeave={() => setShowDetailLayer(false)}>
      {((showDetailLayer || checkedTag) && data?.tags?.length !== 0) ?
        <div className={`${(showDetailLayer || checkedInfo) ? "deviceTagLayer" : "deviceInfoLayer"}  ${!checkedInfo && !showDetailLayer ? "boxInfoHeight deviceInfoLayerBG" : ""}`}
          onClick={() => handleCardSelection(data)}>
          <div className={`infoLayerPosition ${(showDetailLayer || checkedInfo) ? "setInfo_TagPosition" : ""}`}>
            <span>
              <i>Tags</i>
              <span className="infoElement" ref={tagRef} onMouseOver={handleTagMouseEnter} onMouseLeave={() => setShowExtraTags(false)}>
                {data.tags.map((tag) => tag + ' ')}
              </span>
              {showExtraTags && DropdownButton(data.tags)}
            </span>
          </div> 
        </div> : null}
      {(showDetailLayer || checkedInfo) ?
        <div
        className={`deviceInfoLayer  ${(!checkedTag && !showDetailLayer) || data?.tags?.length === 0 ? "boxInfoHeight deviceInfoLayerBG" : ""}`}
          onClick={() => handleCardSelection(data)}
        >
          <div className="infoLayerPosition">
            <span className="infoElementsTitle">{f({ id: 'COM_DMS_INFORMATION' })}</span>
            <span id="infoElementPosition">
              <i>{f({ id: 'COM_DMS_ETHERNET_MAC' })}</i>
              <span className="infoElement">{mac}</span>
            </span>
            <span id="infoElementPosition">
              <i>{f({ id: 'COM_DMS_IP' })}</i>
              <span className="infoElement">{IP}</span>
            </span>
            <span id="infoElementPosition">
              <i>{f({ id: 'COM_DMS_OS' })}</i>
              <span className="infoElement">{OS}</span>
            </span>
            <span id="infoElementPosition">
              <i>{f({ id: 'COM_DMS_GROUP' })}</i>
              <span className="infoElement">{group}</span>
            </span>
          </div>
        </div> : null}
      {(checkedAll || checkedDevice) ? <Button  className={`checkedDevice ${checkedInfo || checkedTag || deviceScreenPreview === 'START' ? "dim" : ""}`} icon="pi pi-check" onClick={() => handleCardSelection(data)} /> : null}
      {deviceScreenPreview === 'START' ? screenPreview !== "" ? <img src={screenPreview} className="screenCaptureWindow" alt="screen-preview" onClick={() => handleCardSelection(data)} /> : <div className="off_box" onClick={() => handleCardSelection(data)}><div><img src={deviceON} className="deviceViewBox content" alt="screen-preview" /><div className="deviceStatus">{!data?.connectionStatus ? f({ id: 'COM_DMS_NO_PREVIEW' }) : f({ id: 'COM_DMS_SCREEN_PREVIEW_AWAITED' })}</div></div></div> : null}
      {deviceScreenPreview === 'STOP' && <div className="off_box" onClick={() => handleCardSelection(data)}><div><img src={data.connectionStatus ? deviceON : deviceOFF} className="deviceViewBox content" alt="screen-preview" /><div className="deviceStatus">{data?.connectionStatus ? f({ id: 'COM_DMS_NO_PREVIEW' }) : f({ id: 'COM_DMS_POWER_OFF' })}</div></div></div>}
    </div>
  );

  const footer = (
    <div className="footer">
      <div className="footer_left">
        <div className="footer-header">
          {f({ id: 'COM_DMS_SCREEN_PREVIEW' })}
        </div>
        <InputSwitch
          className={`screenCaptureSwitch ${showExtraTags ? "disableScreenCapture" : ""}`}
          checked={deviceScreenPreview === 'START'}
          onChange={() => {
            onChange(deviceId);
            setToggleScreen(true);
          }}
        />
      </div>
      <div className="footer_right">
        <span className="inputSourceText">Source: {data.deviceCurrentInputSource}</span>
      </div>
    </div>
  );


  return (
    <div className="gridViewPageLayout">
      <Card
        position="top"
        header={header}
        footer={footer}
        visible={visible}
        className="grid-view-card"
      >
        <div className="gridTitlePos">
          <div style={{display: "flex"}}>
            <HoveredText text={deviceName} className='gridTitle' />
            <i
              className={
                data.connectionStatus
                  ? 'pi pi-circle-on connectionStatus on'
                  : 'pi pi-circle-on connectionStatus off'
              }
            />
          </div>
          <div>
            <img
              src={info}
              className="gridInfoIcon"
              onClick={handleShowDetails}
              alt="info"
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
