import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import './MoveGroupPopup.css';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import _ from 'lodash';
import { removeNullValuesFromNestedObjectOfArray } from '../../../../utils/CleanJSONArrayObject';
import { convertJSONDataToTreeTableFormat } from '../convertToJSONTree';
import { GroupSuggestPopup } from '../../../../components/PopUp/GroupSuggest';
import { GroupManagementService } from '../../../../services/RestServices/GroupManagementService';
import flatToTree from 'flat-to-tree';
import MinusIcon from '../../../../assets/images/minusIcon.svg';
import PlusIcon from '../../../../assets/images/plusIcon.svg';
import GroupIcon from '../../../../assets/images/groupIcon.svg';
import { showSuccessToast } from '../../../../services/APIResponseHandler';
import { Checkbox } from 'primereact/checkbox';
import CollapsableIcon from '../../../../assets/images/collapsibleIcon.svg';

const MoveGroupPopup = ({
  setOpenMoveGroup,
  selectedKey,
  setFlag,
  setGroupSearchText,
  renderInitialGroupData,
}) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const [nodes, setNodes] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [groupList, setGroupList] = useState({});
  const [visible, setVisible] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [currentGroupPath, setCurrentGroupPath] = useState('');
  const [newGroupPath, setNewGroupPath] = useState('');
  const [groupData, setGroupData] = useState({});
  const [isDefaultGroupChecked, setIsDefaultGroupChecked] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState({});
  const [groupDataWithDefaultChecked, setGroupDataWithDefaultChecked] =
    useState([]);

  const traverseToFindGroupPath = (nodes, str) => {
    for (let idx = 0; idx < nodes?.length; idx++) {
      const { data } = nodes[idx] ?? {};
      const { groupName } = data ?? {};
      const resultantPath = str ? `${str} > ${groupName}` : groupName;
      if (nodes[idx].key === selectedKey) {
        setCurrentGroupPath(resultantPath);
      } else {
        traverseToFindGroupPath(nodes[idx]?.children, resultantPath);
      }
    }
  };

  const traverseToFindSelectedGroupPath = (nodes, str, key) => {
    for (let idx = 0; idx < nodes?.length; idx++) {
      const { data, children } = nodes[idx] ?? {};
      const { groupName } = data ?? {};
      const resultantPath = str ? `${str} > ${groupName}` : groupName;
      if (nodes[idx].key === key) {
        setNewGroupPath(resultantPath);
        return;
      }
      traverseToFindSelectedGroupPath(children, resultantPath, key);
    }
  };
  useEffect(() => {
    if (selectedNodeKey !== selectedKey)
      traverseToFindSelectedGroupPath(nodes, '', selectedNodeKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodeKey]);

  function reformatJSONTree(data) {
    for (let index = 0; index < data?.length; index++) {
      if (!data[index]?.children?.length) {
        data[index].leaf = true;
      }
      if (data[index].key === selectedKey) {
        data[index].children = [];
        data[index].leaf = true;
      }
      reformatJSONTree(data[index]?.children);
    }
  }

  const getGroupList = async () => {
    await GroupManagementService.getDMSGroups().then(({ data }) => {
      const formattedNodes = _.cloneDeepWith(data);
      const convertedJSONData = flatToTree(formattedNodes, {
        id: 'groupId',
        parentId: 'parentId',
      });
      convertJSONDataToTreeTableFormat(convertedJSONData);
      reformatJSONTree(convertedJSONData);
      const cleanedJSONTree =
        removeNullValuesFromNestedObjectOfArray(convertedJSONData);
      setNodes(cleanedJSONTree.filter((data) => data.key !== 0));
      traverseToFindGroupPath(cleanedJSONTree, '');
      const initialParentGroupData = [];
      cleanedJSONTree
        .filter((data) => data.key !== 0)
        .forEach((row) => {
          initialParentGroupData.push({
            ...row,

            children: [],
          });
        });
      setGroupDataWithDefaultChecked(initialParentGroupData);
    });
  };

  useEffect(() => {
    getGroupList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findGroupData = (jsonTree) => {
    if (!jsonTree) return;
    for (let index = 0; index < jsonTree?.length; index++) {
      const { data, key } = jsonTree[index];
      if (key === selectedKey) {
        return setGroupData(data);
      }

      findGroupData(jsonTree[index]?.children);
    }
  };

  useEffect(() => {
    findGroupData(nodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes]);

  const handleMoveGroup = async () => {
    const { groupId, groupName } = groupData ?? {};
    const payload = {
      groupName,
      groupId,
      deviceIds: [],
      parentId: isDefaultGroupChecked ? -1 : selectedNodeKey,
    };
    setFlag(true);
    await GroupManagementService.handleMoveGroups(groupId, payload).then(
      (res) =>
        res?.status === 200 &&
        showSuccessToast(f({ id: 'COM_DMS_GROUP_MOVE_SUCCESSFUL' }))
    );
    setOpenMoveGroup(false);
    setGroupSearchText('');
    renderInitialGroupData();
  };

  const handleClickSearchSuggestions = () => {
    if (!searchText) {
      getGroupList();
    } else {
      GroupManagementService.getGroupListFilter(searchText).then((res) => {
        const { data } = res ?? {};
        setVisible(true);
        let map = new Map();
        let currSuggestions = [];

        Object.values(data).forEach((val) =>
          map.set(
            [val.groupName, val.groupId],
            val.parents
              ? val.parents.map((parent) => [parent.groupName, parent.groupId])
              : []
          )
        );

        for (const x of map.entries()) {
          const currentNode = x[0];
          const parents = x[1];
          if (
            currentNode?.[0]?.toLowerCase()?.includes(searchText?.toLowerCase())
          ) {
            currSuggestions.push({
              groupName: currentNode[0],
              groupId: currentNode[1],
              groupParents: [...parents],
            });
          }
        }
        setSuggestions(() => currSuggestions);
      });
    }
    collapseTreeTable();
  };

  const headerContent = () => {
    return (
      <div className="add-group-header">
        <div>{f({ id: 'COM_DMS_MOVE_GROUP' })}</div>
        <div>
          <Button
            className="add-group-btn add-group-cancel-btn"
            label={f({ id: 'COM_DMS_CANCEL' })}
            onClick={() => setOpenMoveGroup(false)}
          />
          <Button
            className="add-group-btn add-group-success-btn"
            label={f({ id: 'COM_DMS_MOVE' })}
            onClick={handleMoveGroup}
            disabled={!(selectedNodeKey || isDefaultGroupChecked)}
          />
        </div>
      </div>
    );
  };

  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }
    const expanded = options.expanded;
    return (
      <button
        type="button"
        className="p-treetable-toggler p-link"
        style={options.buttonStyle}
        tabIndex={-1}
        onClick={options.onClick}
      >
        <span>
          {!isDefaultGroupChecked ? (
            expanded ? (
              <img src={MinusIcon} className="icon" alt="minus" />
            ) : (
              <img src={PlusIcon} className="icon" alt="plus" />
            )
          ) : (
            <img
              src={PlusIcon}
              className={`${
                isDefaultGroupChecked ? 'icon disable-group-icon' : 'icon'
              }`}
              alt="plus"
            />
          )}
        </span>
      </button>
    );
  };

  const groupBody = ({ data, key }) => {
    const { groupName } = data ?? {};
    return (
      <>
        <img
          src={GroupIcon}
          alt="group"
          style={{ marginRight: '1rem' }}
          className="icon"
        />
        <div
          className={
            key === selectedKey || isDefaultGroupChecked
              ? `selectedGroup`
              : 'moveGroupName'
          }
        >
          {groupName}
        </div>
      </>
    );
  };

  const traverseToRemoveDevices = (nodes, key) => {
    if (!nodes) {
      return;
    }

    for (let index = 0; index < nodes.length; index++) {
      if (nodes[index].key === key) {
        const filteredData = nodes[index]?.children?.filter(
          (node) => node?.type !== 'device'
        );
        nodes[index].children = [...(filteredData || [])];
      } else {
        traverseToRemoveDevices(nodes[index]?.children, key);
      }
    }
  };

  const onCollapse = (event) => {
    const { node } = event;
    const { key } = node;

    traverseToRemoveDevices(nodes, key);
  };

  const collapseNodes = (list) => {
    let _expandedKeys = { ...expandedKeys };
    const keyMap = new Map(Object.entries(expandedKeys));
    for (let idx = 0; idx < list.length; idx++) {
      let val = '' + list[idx];
      if (keyMap.has(val)) delete _expandedKeys[val];
    }
    setExpandedKeys(_expandedKeys);
  };

  const getListOfAllChildComponents = (nodes, list) => {
    if (!nodes) return;

    for (let node of nodes) {
      list.push(node.key);
      getListOfAllChildComponents(node.children, list);
    }
  };

  const collapseTreeTable = () => {
    if (!expandedKeys) return;
    let collapsedNodes = [];
    let list = [];
    for (let node of nodes) {
      list.push(node.key);
      if (node?.children && node?.children?.length) {
        getListOfAllChildComponents(node.children, list);
        collapsedNodes.push({ ...node, leaf: false });
      } else {
        collapsedNodes.push({ ...node, leaf: true });
      }
      collapseNodes(list);
    }
    setNodes(collapsedNodes);
  };

  const moveGroupHeader = () => {
    return (
      <div className="group-header">
        <div className="collapse-icon" onClick={collapseTreeTable}>
          <img src={CollapsableIcon} alt="collapse" className="icon" />
          <span className="group-header-text">
            {f({ id: 'COM_DMS_GROUP_NAME' })}
          </span>
        </div>
      </div>
    );
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleClickSearchSuggestions();
    }
  };

  return (
    <Dialog
      visible
      header={headerContent}
      className="move-group-dialog"
      position="bottom-right"
      draggable={false}
      onHide={() => setOpenMoveGroup(false)}
    >
      <div className="move-group-location">
        <div>{f({ id: 'COM_DMS_CURRENT_LOCATION' })} </div>
        <div>: {currentGroupPath}</div>
      </div>
      <div className="move-group-location">
        <div>{f({ id: 'COM_DMS_NEW_LOCATION' })} </div>
        <div>
          :{' '}
          {!isDefaultGroupChecked
            ? newGroupPath
            : currentGroupPath?.split('>')?.pop()}
        </div>
      </div>
      <div className="move-group-location">
        <div>{f({ id: 'COM_DMS_MAKE_TOP_LEVEL_GROUP' })}</div>
        <div>
          <Checkbox
            checked={isDefaultGroupChecked}
            onChange={() => {
              if (!isDefaultGroupChecked) {
                setSelectedNodeKey(null);
              }
              setIsDefaultGroupChecked(!isDefaultGroupChecked);
            }}
          />
        </div>
      </div>

      <div className="move-group-content-header">
        <div className="content-header-text">
          {f({ id: 'COM_DMS_SET_NEW_LOCATION' })}
        </div>
        <div style={{ position: 'relative' }}>
          <SearchBar
            value={searchText}
            setValue={setSearchText}
            setVisiblePopup={setVisible}
            handleInputKeyDown={handleInputKeyDown}
            searchHandler={handleClickSearchSuggestions}
            placeholder={f({ id: 'COM_DMS_GROUP_NAME' })}
          />
          {visible && (
            <GroupSuggestPopup
              treeTableNode
              setNodes={setNodes}
              visible={visible}
              groups={suggestions}
              groupList={groupList}
              setVisible={setVisible}
              setGroupList={setGroupList}
              setSearchText={setSearchText}
              className="move-group-suggest"
            />
          )}
        </div>
      </div>
      <div
        className={`move-group-content-section ${
          isDefaultGroupChecked ? 'move-group-content-section-disable' : ''
        }`}
      >
        <TreeTable
          value={!isDefaultGroupChecked ? nodes : groupDataWithDefaultChecked}
          scrollable
          scrollHeight="66vh"
          onCollapse={onCollapse}
          expandedKeys={expandedKeys}
          selectionMode="single"
          selectionKeys={selectedNodeKey}
          togglerTemplate={togglerTemplate}
          onSelectionChange={(e) => {
            if (e.value !== selectedKey && e.value !== 0) {
              if (isDefaultGroupChecked) {
                setSelectedNodeKey(null);
              } else {
                setSelectedNodeKey(() => e.value);
              }
            }
          }}
          onToggle={(e) => setExpandedKeys(e.value)}
        >
          <Column
            field="groupName"
            header={moveGroupHeader}
            expander
            body={groupBody}
          />
        </TreeTable>
      </div>
    </Dialog>
  );
};

export default MoveGroupPopup;
