import React, { useEffect, useMemo, useRef, useState } from 'react';
import './All.css';
import { Button } from 'primereact/button';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { TabView, TabPanel } from 'primereact/tabview';
import Information from './InformationTab/Information';
import Setup from './SetupTab/Setup';
import Network from './NetworkTab/Network';
import { Checkbox } from 'primereact/checkbox';
import { useIntl } from 'react-intl';
import Filter from '../../../components/Filter/Filter';
import { Dialog } from 'primereact/dialog';
import { DeviceGrid } from '../../../components/DeviceGrid/Grid';
import { InputText } from 'primereact/inputtext';
import { deviceServices } from '../../../services/RestServices/deviceServices';
import { Paginator } from 'primereact/paginator';
import Spinner from '../../../components/Spinner/Spinner';
import DeviceTagPopup from './DeviceTagPopup/DeviceTagPopup';
import UrlPermissionPopup from './UrlPermissionPopup/UrlPermissionPopup';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import {
  setToastComponent,
  showErrorToast,
  showSuccessToast,
} from '../../../services/APIResponseHandler';
import CommandIcon from '../../../assets/images/commandIcon.svg';
import export_icon from '../../../assets/images/exportIcon.svg';
import editIcon from '../../../assets/images/edit_icon.svg';
import SelectCommandPopup from '../SelectCommandPopup/SelectCommandPopup';
import { isAdminOrSubAdmin, isDeviceOperator } from '../../../utils/helper';
import { useSelector } from 'react-redux';
import {
  deviceNameMaxLength,
  validateDeviceDetails,
} from '../../../utils/Validations';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import filterIcon from '../../../assets/images/filter_alt_black_-1.svg';
import HoveredText from '../../../components/HoveredText/HoveredText';

const All = ({
  setPageToShow,
  refreshPage,
  setDetails,
  setRefreshPage,
  setDeviceID,
  deviceList,
  setDeviceList,
  connectedDevicesFlag,
  setConnectedDevicesFlag,
  filterPayload,
  devicesCount,
  setDevicesCount,
}) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const { userInfo } = useSelector((state) => state.user);
  const { serverSettingsInfo } = useSelector((state) => state.serverSettings);
  const { passwordSettings } = serverSettingsInfo ?? {};
  const { changeAfterLogin } = passwordSettings ?? {};
  const [viewList, setViewList] = useState(
    localStorage.getItem('VIEW_MODE') || 'LIST'
  );
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedInfo, setCheckedInfo] = useState(false);
  const [checkedTag, setCheckedTag] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showQuickControl, setShowQuickControl] = useState(false);
  const [editingCell, setEditingCell] = useState(null);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [editDeviceName, setEditDeviceName] = useState('');
  const [showUrlPermission, setShowUrlPermission] = useState(false);
  const [showTagPopup, setShowTagPopup] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(
    !connectedDevicesFlag ? 'All' : 'Connected'
  );
  const [screenPreviewState, setScreenPreviewState] = useState('All');
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedSource, setSelectedSource] = useState([]);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [selectTag, setSelectTag] = useState(null);
  const [selectCommandPopup, setSelectCommandPopup] = useState(false);
  const [deviceDataPayload, setDeviceDataPayload] = useState(filterPayload);
  const [deviceNameIconFlag, setDeviceNameIconFlag] = useState('');
  const [showExtraTagsDeviceId, setShowExtraTagsDeviceId] = useState('');

  const toastRef = useRef(null);

  const handleCheckboxChange = (cardId) => {
    const updatedCheckboxData = deviceList.map((card) => {
      if (card.deviceId === cardId) {
        const newCheckboxState =
          card.deviceScreenPreview === 'START' ? 'STOP' : 'START';
        return {
          ...card,
          deviceScreenPreview: newCheckboxState,
        };
      }
      return card;
    });
    setDeviceList(updatedCheckboxData);
  };

  useEffect(() => {
    setToastComponent(toastRef.current);
    setDeviceDataPayload({
      ...deviceDataPayload,
      connectionStatus: connectedDevicesFlag ? 'ONLINE' : '',
    });
  }, []);

  const getDeviceList = async () => {
    setLoading(true);
    await deviceServices
      .getAllDeviceList(
        {
          ...deviceDataPayload,
          connectionStatus: connectedDevicesFlag ? 'ONLINE' : '',
        },
        changeAfterLogin
      )
      .then((res) => {
        const { items, totalCount } = res?.data ?? {};
        setDeviceList(items);
        setDevicesCount(totalCount);
        if (connectedDevicesFlag) {
          setConnectedDevicesFlag(false);
        }
        setLoading(false);
      });
    setSelectedItems([]);
    setSelectedCards([]);
    setCheckedAll(false);
  };

  useEffect(() => {
    if (viewList === 'LIST') {
      setSelectedDevices(selectedItems);
    } else {
      setSelectedDevices(selectedCards);
    }
  }, [viewList, selectedItems, selectedCards]);

  useEffect(() => {
    setSelectedItems([]);
    setSelectedCards([]);
  }, [viewList]);

  useEffect(() => {
    !visible &&
      Object.keys(userInfo).length !== 0 &&
      Object.keys(serverSettingsInfo).length !== 0 &&
      getDeviceList();
    if (refreshPage) {
      setStatus('All');
      setDeviceDataPayload({ ...deviceDataPayload, connectionStatus: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage, userInfo, serverSettingsInfo]);

  const deleteDevicesHandler = async () => {
    let deviceIds = [];
    if (viewList === 'LIST' && selectedItems !== undefined) {
      for (let i = 0; i < selectedItems.length; i++) {
        deviceIds.push(selectedItems[i].deviceId);
      }
    } else if (selectedCards !== undefined) {
      for (let i = 0; i < selectedCards.length; i++) {
        deviceIds.push(selectedCards[i].deviceId);
      }
    }
    await Promise.all([
      deviceServices
        .deleteDevices(deviceIds)
        .then(
          (res) =>
            res?.status === 200 &&
            showSuccessToast('Device(s) Unenrolled Successfully!')
        ),
    ]);
    getDeviceList();
    setSelectedItems([]);
    setSelectedCards([]);
    setDeleteVisible(false);
    setCheckedAll(false);
  };

  const quickControlHeader = () => {
    return (
      <div className="controlBoxHeader">
        Quick Control
        <Button
          className="closeDialog"
          icon="pi pi-times"
          onClick={() => setShowQuickControl(false)}
        />
      </div>
    );
  };

  const onEditIconClick = (rowData, field) => {
    setEditingCell({ rowData, field });
  };

  const onSaveClick = async (rowData, value) => {
    const res = validateDeviceDetails('deviceName', value);
    const isValid = res.isValid;
    if (!value) {
      setEditingCell(null);
      return;
    }

    if (isValid) {
      await Promise.all([
        deviceServices
          .setDeviceName(value, [rowData.deviceId])
          .then(
            (res) =>
              res?.status === 200 && setEditDeviceName('') &&
              showSuccessToast('Device Name Updated Successfully!')
          ),
      ]);
      getDeviceList();

      setSelectedItems([]);
      setEditingCell(null);
    } else {
      showErrorToast(f({ id: res.messageId }));
    }
  };

  const onCancelClick = () => {
    setEditingCell(null);
  };

  const getDeviceDetailedInfo = async (deviceId) => {
    await Promise.all([deviceServices.getDeviceUsageInfo(deviceId)]);
    await Promise.all([
      deviceServices.getDeviceInfoById(deviceId).then(({ data }) => {
        setDetails(data);
      }),
    ]);
    setPageToShow('ALL_DEVICE_DETAILS');
  };

  const editDeviceNameTemplate = (rowData, field) => {
    if (editingCell && editingCell.rowData.deviceId === rowData.deviceId) {
      return (
        <>
          <InputText
            className="cell_Edit"
            defaultValue={rowData.deviceName}
            onChange={(e) => setEditDeviceName(e.target.value)}
            maxLength={deviceNameMaxLength}
          />
          <Button
            className="editOptions"
            icon="pi pi-check"
            onClick={() => onSaveClick(rowData, editDeviceName)}
          />
          <Button
            className="editOptions"
            icon="pi pi-times"
            onClick={onCancelClick}
          />
        </>
      );
    } else {
      return (
        <div
          className="deviceNameStyle"
          onMouseEnter={() => setDeviceNameIconFlag(rowData.deviceId)}
          onMouseLeave={() => setDeviceNameIconFlag('')}
        >
          <HoveredText
            text={rowData.deviceName}
            className="showDetailsOptn"
            onClick={() => {
              getDeviceDetailedInfo(rowData.deviceId);
              setDeviceID(rowData.deviceId);
            }}
          />
          {!isDeviceOperator() && deviceNameIconFlag === rowData.deviceId && (
            <img
              className=" deviceName_style editIconSize"
              onClick={() => onEditIconClick(rowData, field)}
              src={editIcon}
            />
          )}
        </div>
      );
    }
  };

  const screenPreviewTemplate = (rowData) => {
    return (
      <div>
        {rowData.deviceScreenPreview === 'START'
          ? f({ id: 'COM_DMS_ON' })
          : f({ id: 'COM_DMS_OFF' })}
      </div>
    );
  };

  const connectionStatusTemplate = (rowData) => {
    return (
      <div>
        <i
          className={
            rowData.connectionStatus
              ? 'pi pi-circle-on device_connectionStatus on'
              : 'pi pi-circle-on device_connectionStatus off'
          }
        />
      </div>
    );
  };

  function DropdownButton(extraTags, rowData) {
    return (
      <div
        className="floating-window-container"
        onMouseEnter={() => setShowExtraTagsDeviceId(rowData.deviceId)}
        onMouseLeave={() => setShowExtraTagsDeviceId('')}
      >
        {extraTags?.map((tag) => (
          <div key={tag} className="tagItem">
            {tag}
          </div>
        ))}
      </div>
    );
  }

  const deviceTagsTemplate = (rowData) => {
    let tags = rowData?.tags;
    const [t0, t1, ...restTags] = tags ?? [];
    const tagsLen = tags?.length;
    return tags && tagsLen > 2 ? (
      <div style={{ display: 'flex' }}>
        <Tag className="setupDeviceTags" value={t0} rounded />
        <Tag className="setupDeviceTags" value={t1} rounded />
        <div>
          <div
            onMouseEnter={() => setShowExtraTagsDeviceId(rowData.deviceId)}
            onMouseLeave={() => setShowExtraTagsDeviceId('')}
          >
            <Tag
              className="setupDeviceTags extraTags"
              value={`+${tagsLen - 2}`}
              rounded
            />
          </div>
          {showExtraTagsDeviceId === rowData.deviceId &&
            DropdownButton(restTags, rowData)}
        </div>
      </div>
    ) : (
      <div>
        {tags &&
          tags?.length > 0 &&
          tags?.map((tag) => (
            <Tag className="setupDeviceTags" value={tag} rounded />
          ))}
      </div>
    );
  };

  const searchDeviceHandler = () => {
    setDeviceDataPayload({ ...deviceDataPayload, searchText: searchText });
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      searchDeviceHandler();
    }
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setStatus('All');
    setSelectedSource([]);
    setSelectedTags([]);
    setScreenPreviewState('All');
    setDeviceDataPayload({
      ...deviceDataPayload,
      startIndex: event.first,
      pageSize: event.rows,
    });
    setSelectedItems([]);
    setSelectedCards([]);
  };
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const handleEventExport = async () => {
    let excelExportRequestBody = deviceDataPayload;
    excelExportRequestBody.pageSize = -1;
    excelExportRequestBody.startIndex = -1;
    setLoading(true);
    await deviceServices
      .exportDevices(excelExportRequestBody)
      .then((res) => {
        res?.status === 200 &&
          showSuccessToast('Device List Exported Successfully!');
        let fileName = res.headers.get('content-disposition');
        if (fileName) {
          fileName = fileName.replace(/"/gi, '');
          if (fileName && fileName.length > 0) {
            let fileNames = fileName.split(';');
            if (fileNames.length > 0) {
              fileNames = fileNames[1].split('=');
              if (fileNames.length > 0) {
                fileName = fileNames[1];
              }
            }
          }
        }
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const footerContent = (
    <div className="delete-btns-position">
      <Button
        label="Unenroll"
        icon="pi pi-times"
        onClick={deleteDevicesHandler}
        className="delete_button"
      />
      <Button
        label="Cancel"
        icon="pi pi-check"
        onClick={() => setDeleteVisible(false)}
        className="delete_button delete-cancel-button"
      />
    </div>
  );

  const headerContent = () => {
    return (
      <div className="delete-flex-title">
        {f({ id: 'COM_DMS_ARE_YOU_SURE' })}
      </div>
    );
  };

  const tagsOptions = [
    { name: 'Assign Tags', code: 'assignTag' },
    { name: 'Remove Tags', code: 'removeTag' },
    { name: 'URL Permission', code: 'urlPermission' },
  ];

  useEffect(() => {
    if (!!!selectTag) {
      setShowUrlPermission(false);
    } else if (selectTag?.code === 'urlPermission') {
      setShowUrlPermission(true);
      setShowTagPopup(false);
    } else {
      setShowTagPopup(true);
      setShowUrlPermission(false);
    }
  }, [selectTag]);

  const deviceIds = useMemo(
    () => selectedDevices?.map((device) => device?.deviceId),
    [selectedDevices]
  );

  useEffect(() => {
    if (checkedAll) {
      setSelectedDevices(deviceList);
      setSelectedItems(deviceList);
      setSelectedCards(deviceList);
    } else {
      setSelectedDevices([]);
      setSelectedItems([]);
      if (selectedCards?.length === deviceList?.length) {
        setSelectedCards([]);
      }
    }
  }, [checkedAll]);

  useEffect(() => {
    if (selectedCards?.length === 0) {
      setCheckedAll(false);
    } else if (selectedCards?.length === deviceList?.length) {
      setCheckedAll(true);
    } else if (selectedCards?.length !== deviceList?.length) {
      setCheckedAll(false);
    }
  }, [selectedCards]);

  return (
    <>
      {selectTag?.code === 'urlPermission' && (
        <UrlPermissionPopup
          setShowUrlPermission={setShowUrlPermission}
          setSelectTag={setSelectTag}
          devices={selectedDevices}
        />
      )}

      {loading ? <Spinner /> : ''}
      <Toast ref={toastRef} position="top-center" />
      {(selectTag?.code === 'removeTag' || selectTag?.code === 'assignTag') && (
        <DeviceTagPopup
          devices={selectedDevices}
          setSelectTag={setSelectTag}
          setDevices={setSelectedDevices}
          setSelectedItems={setSelectedItems}
          setSelectedCards={setSelectedCards}
          removeTag={selectTag?.code === 'removeTag'}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
        />
      )}
      {selectCommandPopup && (
        <SelectCommandPopup
          deviceIds={deviceIds}
          setSelectCommandPopup={setSelectCommandPopup}
        />
      )}
      <div className="add_devices_page">
        <div className="device_operations">
          <div className="devices-left-button">
            {isAdminOrSubAdmin() && (
              <Button
                className="allDevices_btn"
                label="Unenroll"
                disabled={
                  viewList === 'LIST'
                    ? selectedItems.length === 0
                    : selectedCards.length === 0
                }
                onClick={() => setDeleteVisible(true)}
              />
            )}
            <Dropdown
              value={selectTag}
              onChange={(e) => setSelectTag(e.value)}
              options={tagsOptions}
              optionLabel="name"
              editable={false}
              disabled={
                viewList === 'LIST'
                  ? !selectedItems?.length
                  : !selectedCards?.length
              }
              placeholder={f({ id: 'COM_DMS_QUICK_SELECT' })}
              className="w-full md:w-14rem quickActiondrp"
            />
          </div>
          <div className="devices-right-button">
            <div className="device-select-command allDevice-select-command">
              <div>
                <Tooltip target=".all-device-icon" position="left">
                  <div className="infoIcon_tooltipContent">
                    <div>{f({ id: 'COM_DMS_DEVICE_ALL_TOOLTIP_TEXT' })}</div>
                  </div>
                </Tooltip>
                <img
                  src={CommandIcon}
                  alt="command"
                  tooltipOptions={{ position: 'left' }}
                  className="all-device-icon select-command-icon"
                />
              </div>
              <Button
                className="selectCommandsAll_btn"
                onClick={() => setSelectCommandPopup(true)}
                disabled={
                  viewList === 'LIST'
                    ? selectedItems.length === 0
                    : selectedCards.length === 0
                }
              >
                {f({ id: 'COM_DMS_SELECT_COMMANDS' })}
              </Button>
            </div>
            <div className="filter_box filter_container">
              <img
                src={filterIcon}
                alt="Filter"
                onClick={() => setVisible(true)}
                className="filterIconPosition"
              />
            </div>
            <div className="viewMode view_container">
              <Button
                className={viewList === 'LIST' ? 'view_list on' : 'view_list'}
                onClick={() => {
                  setViewList('LIST');
                  localStorage.setItem('VIEW_MODE', 'LIST');
                }}
              >
                <span></span>
              </Button>
              <Button
                className={viewList === 'LIST' ? 'view_thum' : 'view_thum on'}
                onClick={() => {
                  setViewList('IMAGE');
                  localStorage.setItem('VIEW_MODE', 'IMAGE');
                }}
              >
                <span></span>
              </Button>
            </div>
            <div className="all_device_Search">
              <SearchBar
                placeholder="Search"
                value={searchText}
                setValue={setSearchText}
                searchHandler={searchDeviceHandler}
                handleInputKeyDown={handleInputKeyDown}
              />
            </div>
          </div>
        </div>
        <div className="add_devices_list">
          <div>
            <Button
              className="allDevices_btn setExportPos"
              label="Export"
              onClick={handleEventExport}
            >
              <img src={export_icon} alt="" className="exportIconStyle" />
            </Button>
            {viewList === 'LIST' ? (
              <TabView>
                <TabPanel header="Information" className="deviceInformation">
                  <Information
                    deviceList={deviceList}
                    devicesCount={devicesCount}
                    first={first}
                    rows={rows}
                    onPageChange={onPageChange}
                    editDeviceNameTemplate={editDeviceNameTemplate}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    connectionStatusTemplate={connectionStatusTemplate}
                  />
                </TabPanel>
                <TabPanel header="Setup">
                  <Setup
                    deviceList={deviceList}
                    devicesCount={devicesCount}
                    first={first}
                    rows={rows}
                    onPageChange={onPageChange}
                    editDeviceNameTemplate={editDeviceNameTemplate}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    deviceTagsTemplate={deviceTagsTemplate}
                    screenPreviewTemplate={screenPreviewTemplate}
                    connectionStatusTemplate={connectionStatusTemplate}
                  />
                </TabPanel>
                <TabPanel header="Network" className="deviceNetwork">
                  <Network
                    deviceList={deviceList}
                    devicesCount={devicesCount}
                    first={first}
                    rows={rows}
                    onPageChange={onPageChange}
                    editDeviceNameTemplate={editDeviceNameTemplate}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    connectionStatusTemplate={connectionStatusTemplate}
                  />
                </TabPanel>
              </TabView>
            ) : (
              <div className="devices-view-thum">
                <div className="gridView_options">
                  <div className="check-all">
                    <Checkbox
                      checked={checkedAll}
                      onChange={() => {
                        setSelectAll(true);
                        setCheckedAll((checkedAll) => !checkedAll);
                      }}
                      disabled={!deviceList.length}
                    />
                    <span className="check-label">
                      {f({ id: 'COM_DMS_SELECT_ALL' })}
                    </span>
                  </div>
                  <div className="check-info">
                    <div className="set-info-layout">
                      <div style={{ paddingRight: '1rem' }}>
                        <Checkbox
                          checked={checkedInfo}
                          onChange={(e) => {
                            setCheckedInfo(e.checked);
                          }}
                        />
                        <span className="check-label">
                          {f({ id: 'COM_DMS_INFORMATION' })}
                        </span>
                      </div>
                      <div>
                        <Checkbox
                          checked={checkedTag}
                          onChange={(e) => {
                            setCheckedTag(e.checked);
                          }}
                        />
                        <span className="check-label">
                          {f({
                            id: 'COM_DMS_TAG_INFORMATION',
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-position">
                  {deviceList?.length !== 0 ? (
                    deviceList?.map((data) => (
                      <DeviceGrid
                        key={data.deviceId}
                        rows={rows}
                        deviceName={data.deviceName}
                        mac={data.deviceId}
                        IP={data.ipAddress}
                        group={data.groupName}
                        OS={data.deviceOsVersion}
                        data={data}
                        checkedInfo={checkedInfo}
                        checkedAll={checkedAll}
                        setCheckedAll={setCheckedAll}
                        selectedItems={selectedCards}
                        setSelectedItems={setSelectedCards}
                        checkedTag={checkedTag}
                        setPageToShow={setPageToShow}
                        setDetails={setDetails}
                        onChange={handleCheckboxChange}
                        selectAll={selectAll}
                        setSelectAll={setSelectAll}
                        refreshPage={refreshPage}
                        setDeviceID={setDeviceID}
                      />
                    ))
                  ) : (
                    <span className="noDevices_Grid">
                      {f({ id: 'COM_DMS_NO_RESULT' })}
                    </span>
                  )}
                </div>
                <div className="custom-paginator">
                  <Paginator
                    className="paginator"
                    first={first}
                    rows={rows}
                    totalRecords={devicesCount}
                    onPageChange={onPageChange}
                    rowsPerPageOptions={[10, 20, 30, 50]}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {visible ? (
        <Filter
          position="center"
          visible={visible}
          setVisible={setVisible}
          deviceDataPayload={deviceDataPayload}
          setDeviceDataPayload={setDeviceDataPayload}
          setDeviceList={setDeviceList}
          setDevicesCount={setDevicesCount}
          className="filter_position_popup"
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
          connectionStatus={status}
          setConnectionStatus={setStatus}
          screenPreviewState={screenPreviewState}
          setScreenPreviewState={setScreenPreviewState}
          filterPayload={filterPayload}
          first={first}
          setConnectedDevicesFlag={setConnectedDevicesFlag}
        />
      ) : (
        ''
      )}
      <Dialog
        header={quickControlHeader}
        visible={showQuickControl}
        position="bottom-right"
        className="quickControlBox"
        draggable={false}
      />
      <div className="card flex justify-content-center">
        <Dialog
          header={headerContent}
          visible={deleteVisible}
          style={{ align: 'center', width: '35vw' }}
          footer={footerContent}
          onHide={() => setDeleteVisible(false)}
        >
          <div className="text-flex">{f({ id: 'COM_DMS_DEVICE_WARNING' })}</div>
          <div className="text-flex-1">{f({ id: 'COM_DMS_UNDONE' })}</div>
        </Dialog>
      </div>
    </>
  );
};

export default All;
